import React, { useState } from 'react';

import * as authService from '../../../services/authService';
import * as notificationService from "../../../services/domain/warehouse/notificationService";

const initialState = {
    agencyId: authService.getAgencyId,
    setAgencyId: () => {},
    user: authService.getUser(),
    setUser: () => {},
    notifications: [],
    setNotifications: () => {},
    isNotificationsLoading: false,
    refreshNotifications: () => {}
}

export const AppContext = React.createContext(initialState);

const useAppContext = () => React.useContext(AppContext);

const AppProvider = (props) => {
    const [agencyId, _setAgencyId] = useState(initialState.agencyId);
    const [user, setUser] = useState(initialState.user);

    const [notifications, setNotifications] = useState(initialState.notifications);
    const [isNotificationsLoading, setIsNotificationsLoading] = useState(initialState.isNotificationsLoading);

    const setAgencyId = (agencyId) => {
        //TODO: cambiar por usePersistedState
        authService.setAgencyId(agencyId);
        _setAgencyId(agencyId);
    }

    const refreshNotifications = () => {
        if (agencyId) {
            setIsNotificationsLoading(true);

            setTimeout(() => {
                //TODO: HEADERS ALREADY SENT
                notificationService.getNotifications()
                    .then(res => {
                        setNotifications(res.data.notifications);
                    })
                    .catch(() => {
                        setNotifications([]);
                    })
                    .finally(() => {
                        setIsNotificationsLoading(false);
                    })
            }, 1000);

        } else setNotifications([]);
    }

    return (
        <AppContext.Provider
            value={
                {agencyId, setAgencyId, user, setUser, notifications, setNotifications, refreshNotifications, isNotificationsLoading}
            }
        >
            {props.children}
        </AppContext.Provider>
    )
}


export { useAppContext, AppProvider }
