import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as employeeService from '../../../../services/domain/warehouse/employeeService';
import * as vehicleService from '../../../../services/domain/warehouse/vehicleService';

import Table from '../../../../components/table';
import ZoneChip from './ZoneChip';


const ZonesTable = (props) => {
    const {loading, vehicles, zones} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        if (vehicles && zones) {
            setData(zones.map(item => convertRowData(item)));
        }

    }, [vehicles, zones]);

    const headers = [
        { id: 'id1', align: "left", label: intl.formatMessage({id: 'generic.domain.itinerary'}), width: 150, formatter: (name) => <ZoneChip label={name} /> },
        { id: 'id2', align: "left", label: intl.formatMessage({id: 'app.vehicles.vehicle'}), width: 150 },
        { id: 'id3', align: "left", label: intl.formatMessage({id: 'generic.domain.driver'}), width: 400 },
        { id: 'id4', align: "left", label: intl.formatMessage({id: 'app.employees.employee'}), width: 400 },
    ];

    const convertRowData = item => {
        const driver = item.vehicles.length > 0 ? vehicleService.getDriverFromVehicles(vehicles, item.vehicles[0].plate) : null;
        return {
            id1: item.name,
            id2: item.vehicles.length > 0 ? item.vehicles[0].plate : "",
            id3: driver ? employeeService.getFullName(driver) : "-",
            id4: item.helpers.length > 0 ? item.helpers[0].name + " " + item.helpers[0].surname : "",
        }
    };

    return (
        <div >
            <Table
                headers={headers}
                data={data}
                isLoading={loading}
                totalRows
                ordered
                isExportable
            />
        </div>
    )
};

export default ZonesTable;
