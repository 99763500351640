import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as employeeService from '../../../../services/domain/warehouse/employeeService';

import Chip from '../../../../components/Chip';
import Table from '../../../../components/table';
import Grid from '@material-ui/core/Grid';


export const HelpersTable = (props) => {
    const {loading, driver} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(driver.helpers.map(item => convertRowData(item)));
    }, [driver]);

    const headers = [
        { id: 'id1', align: "left", label: intl.formatMessage({id: 'app.employees.helpers'}), width: 200 },
    ];

    const convertRowData = item => {
        const code = item.code !== null ? item.code : "";
        const label = code  + " " + employeeService.getFullName(item);
        return {
            id1: <Chip label={label} onDelete={() => props.onDelete(driver.id, item.id)} />,
        }
    };

    return (
        <div>
            <Table
                headers={headers}
                data={data}
                isLoading={loading}
            />
        </div>
    )
};

export default HelpersTable;
