import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import { RightBox } from '../../../../components/Box';



export const CageForm = (props) => {
    const {isAuthorization, cage, cages, errors} = props;
    
    const intl = useIntl();

    const [cageItems, setCageItems] = useState([]);

    useEffect(() => {
        setCageItems(cages.map((cage) => {
            return {value: cage.id, label: cage.name};
        }))
    }, [cages]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        name="cageId"
                        label={intl.formatMessage({id: 'generic.domain.cage'})}
                        items={cageItems}
                        value={cage.cageId}
                        error={errors.cageId}
                        onChange={props.onChange}
                        disabled={!isAuthorization}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.charge"})}/>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="unitsAuth"
                        label={intl.formatMessage({id: 'generic.domain.cages'})}
                        value={cage.unitsAuth}
                        error={errors.unitsAuth}
                        onChange={props.onChange}
                        disabled={!isAuthorization}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.return"})}/>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="unitsReturned"
                        label={intl.formatMessage({id: 'generic.domain.cages'})}
                        value={cage.unitsReturned}
                        error={errors.unitsReturned}
                        onChange={props.onChange}
                        disabled={isAuthorization}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>
                            {intl.formatMessage({id: "generic.cancel"})}
                        </Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>
                            {intl.formatMessage({id: "generic.save"})}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    );
};

export default CageForm;