import * as apiService from '../../apiService';
import * as authService from '../../authService';

const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const getVehicles = async (filter) => {
    return await apiService.get(`${URL}/vehicles`, filter);
};

export const assignDriver = async (vehicleId, driverId) => {
    return await apiService.post(
        `${URL}/vehicles/` + vehicleId + `/driver/` + driverId + `/enable`,
        {agencyId: authService.getAgencyId(), createdBy: authService.getUser().userName}
    );
};
export const unassignDriver = async (vehicleId, driverId) => {
    return await apiService.patch(
        `${URL}/vehicles/` + vehicleId + `/driver/` + driverId + `/disable`,
        {agencyId: authService.getAgencyId(), updatedBy: authService.getUser().userName}
    );
};

export const assignZone = async (vehicleId, zoneId) => {
    return await apiService.post(
        `${URL}/vehicles/` + vehicleId + `/zones/` + zoneId + `/enable`,
        {agencyId: authService.getAgencyId(), createdBy: authService.getUser().userName}
    );
};
export const unassignZone = async (vehicleId, zoneId) => {
    return await apiService.patch(
        `${URL}/vehicles/` + vehicleId + `/zones/` + zoneId + `/disable`,
        {agencyId: authService.getAgencyId(), updatedBy: authService.getUser().userName}
    );
};

export const getVehicleItems = (vehicles = []) => {
    return vehicles.map(vehicle => {return {value: vehicle.id, label: vehicle.plate}});
}

export const getDriverFromVehicles = (vehicles = [], plate) => {
    const vehicle = vehicles.filter(vehicle => vehicle.plate === plate);
    return vehicle.length > 0 && vehicle[0].drivers && vehicle[0].drivers.length > 0 ? vehicle[0].drivers[0] : null;
}

//TODO: este método es porque las zonas vienen mal en el vehículo
export const getAssignedZonesFromVehicle = (vehicle, string = false) => {
    if (string)
        return vehicle.zones.map(item => {
            return item.zone.name;
        });
    else
        return vehicle.zones.map(item => {
            return item.zone;
        });
};
