import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import InputNative from "../../../../components/form/Input/InputNative";
import Table from '../../../../components/table';
import { RightBox } from '../../../../components/Box';

import {commissionFormatter} from "../../../../helpers/formatter";



const PointOfSaleCommissionTable = (props) => {
    const {pos, commissions} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(commissions.map(item => convertRowData(item)));
    }, [commissions]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "right",   label: intl.formatMessage({id:"generic.domain.commission"}), sublabel: null, formatter: null, width: 90 },
    ];

    const convertRowData = item => {
        return {
            id1: item.product ? item.product.name : "",
            id2: <InputNative name="commission" value={item.commission} formatter={commissionFormatter} onChange={props.onChange} style={{width:'90px', textAlign:'right'}} data-product={item.productId} />,
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
                <Table
                    headers={headers}
                    data={data}
                />
            </Grid>
            <Grid item xs={3}></Grid>

            <Grid item xs={12}>
                <RightBox>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        style={{marginLeft: '10px'}}
                        onClick={(e) => {e.preventDefault();props.onSubmit()}}
                        disabled={pos.id === null}
                    >
                        {intl.formatMessage({id: "generic.save"})}
                    </Button>
                </RightBox>
            </Grid>
        </Grid>
    )

}

export default PointOfSaleCommissionTable;