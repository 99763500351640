import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from "../../../../components/form/Button";
import Input from "../../../../components/form/Input/Input";
import {RightBox} from "../../../../components/Box";



export const ProductProviderCommissionsForm = (props) => {
    const {commissions, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3}>
                    <Input
                        name="activityCommission"
                        label={intl.formatMessage({id: 'generic.domain.commission.provider.activity'})}
                        value={commissions.activityCommission}
                        error={errors.activityCommission}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        name="qualityCommission"
                        label={intl.formatMessage({id: 'generic.domain.commission.provider.quality'})}
                        value={commissions.qualityCommission}
                        error={errors.qualityCommission}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        name="deliveryCommission"
                        label={intl.formatMessage({id: 'generic.domain.commission.provider.delivery'})}
                        value={commissions.deliveryCommission}
                        error={errors.deliveryCommission}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        name="subscribeCommission"
                        label={intl.formatMessage({id: 'generic.domain.commission.provider.subscribe'})}
                        value={commissions.subscribeCommission}
                        error={errors.subscribeCommission}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}><br/><br/><br/><br/></Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            style={{marginLeft: '10px'}}
                            onClick={() => props.onClick()}
                        >
                            {intl.formatMessage({id: "generic.save"})}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
}

export default ProductProviderCommissionsForm;