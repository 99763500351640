import * as apiService from '../../apiService';
import * as authService from '../../authService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const getProducts = async () => {
    return await apiService.get(`${URL}/products`);
};

export const updatePrice = async (id, data) => {
    data = {
        ...data,
        agencyId: authService.getAgencyId()
    };
    return await apiService.put(`${URL}/products/${id}/price`, data);
};

export const updateDeliveryCommissions = async (id, data) => {
    return await apiService.put(`${URL}/products/${id}/delivery-commissions`, data);
};

export const getDeliveryCommissionsFromProduct = (product) => {
    return product.productDeliveryCommissions.map(commission => {
        return {
            ...commission,
            saleChannelId: commission.saleChannel.id
        }
    });
};

export const addSupplementPrice = async (id, data) => {
    const supplement = {
        ...data,
        agencyId: authService.getAgencyId(),
        createdBy: authService.getUserName() //TODO: gestionar esto via API
    };
    return await apiService.post(`${URL}/products/${id}/supplement-price`, supplement);
};
export const deleteSupplementPrice = async (id) => {
    const supplement = {
        agencyId: authService.getAgencyId(),
        updatedBy: authService.getUserName() //TODO: gestionar esto via API
    };
    return await apiService.patch(`${URL}/products/supplement-price/${id}/disable`, supplement);
};

export const updateProviderCommissions = async (id, data) => {
    return await apiService.put(`${URL}/products/${id}/provider-commissions`, data);
}

//TODO: hacer la request
export const getSupplementTypeItems = () => {
    return [
        {value: 1, label: "Canal de venta"},
        {value: 2, label: "Zona"},
        {value: 3, label: "Franja horaria"},
        {value: 4, label: "Fin de semana"},
    ]
};
