import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import { RightBox } from '../../../../components/Box';

import * as productSituationService from '../../../../services/domain/warehouse/productSituationService';


export const AddProductForm = (props) => {
    const {product, products, productSituationItems, errors} = props;

    const intl = useIntl();

    const [productItems, setProductItems] = useState([]);

    useEffect(() => {
        setProductItems(products.map((product) => {
            return {value: product.id, label: product.name};
        }))
    }, [products]);


    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        name="productId"
                        label={intl.formatMessage({id: 'generic.domain.product'})}
                        items={productItems}
                        value={product.productId}
                        error={errors.productId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        name="productSituationId"
                        label={intl.formatMessage({id: 'generic.type'})}
                        items={productSituationItems}
                        value={product.productSituationId}
                        error={errors.productSituationId}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.containers"})}/>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="containersGiven"
                        label={intl.formatMessage({id: 'generic.given.plural.male'})}
                        value={product.containersGiven}
                        error={errors.containersGiven}
                        onChange={props.onChange}
                        disabled={productSituationService.isAltaSituationById(product.productSituationId)}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="containersReceived"
                        label={intl.formatMessage({id: 'generic.received.plural.male'})}
                        value={product.containersReceived}
                        error={errors.containersReceived}
                        onChange={props.onChange}
                        disabled={productSituationService.isBajaSituationById(product.productSituationId) || productSituationService.isCanjeSituationById(product.productSituationId)}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit(product)}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddProductForm;