import MainLayout from '../core/layouts/main/Layout';
import NotificationList from './notifications';


const routes = [
    {
        path: '/notifications',
        layout: MainLayout,
        component: NotificationList,
        exact: true,
        private: true,
    },
];

export default routes;