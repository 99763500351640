import * as validator from '../helpers/validator';

export const AGENCY = 'AGENCY';
export const CP_FORMAT = 'CP_FORMAT';
export const EMAIL_FORMAT = 'EMAIL_FORMAT';
export const NIF_FORMAT = 'NIF_FORMAT';
export const TF_FORMAT = 'TF_FORMAT';

export const DATE_FORMAT = 'DATE_FORMAT';
export const DATE_FUTURE = 'DATE_FUTURE';
export const DATE_FUTURE_OR_TODAY = 'DATE_FUTURE_OR_TODAY';
export const DATE_PAST = 'DATE_PAST';
export const DATE_PAST_OR_TODAY = 'DATE_PAST_OR_TODAY';
export const TIME_FORMAT = 'TIME_FORMAT';

export const NUMBER_BETWEEN = 'NUMBER_BETWEEN';
export const NUMBER_GREATER_OR_EQUAL_TO = 'NUMBER_GREATER_OR_EQUAL_TO';
export const NUMBER_GREATER_OR_EQUAL_TO_ZERO = 'NUMBER_GREATER_OR_EQUAL_TO_ZERO';
export const NUMBER_GREATER_THAN = 'NUMBER_GREATER_THAN';
export const NUMBER_GREATER_THAN_ZERO = 'NUMBER_GREATER_THAN_ZERO';
export const NUMBER_MULTIPLE_OF_35 = 'NUMBER_MULTIPLE_OF_35';
export const NUMBER_NON_ZERO = 'NUMBER_NON_ZERO';
export const NUMBER_ODD = 'NUMBER_ODD';
export const NUMBER_PAIR = 'NUMBER_PAIR';
export const NUMBER_FORMAT = 'NUMBER_FORMAT';

export const MAX_LENGTH = 'MAX_LENGTH';

export const REQUIRED = 'REQUIRED';
export const UNIQUE = 'UNIQUE';



export const validate = (obj, objValidations = {}) => {
    let errors = {};

    for (const [field, validations] of Object.entries(objValidations)) {
        let error = validateField(obj[field], validations);
        if (error !== null) {
            errors = {
                ...errors,
                [field]: error
            }
        }
    }

    return errors;
};

export const validateArray = (objs = [], objValidations = {}) => {
    const errors = [];

    for (let i = 0; i < objs.length; i++) {
        const objErrors = validate(objs[i], objValidations);
        errors.push(objErrors);
    }

    return errors;
}

export const hasErrors = (errors) => {
    if (Array.isArray(errors)) {
        for (let i = 0; i < errors.length; i++) {
            if (Object.keys(errors[i]).length !== 0) return true;
        }
        return false;
    } else return Object.keys(errors).length !== 0;
};

/**
 *
 * @param value
 * @param validations [array] of {type:... , value:...}
 * @returns {*}
 */
const validateField = (value, validations) => {
    let error = null;

    validations.forEach(validation => {
        const validationValue = validation.value;
        switch (validation.type) {
            case REQUIRED:
                if (validator.isEmpty(value)) error = "app.error.code.REQUIRED";
                break;

            case UNIQUE:
                    //TODO:
                break;

            case AGENCY:
                if (!validator.isAgency(value)) error = "app.error.code.INVALID_AGENCY";
                break;
            case CP_FORMAT:
                if (!validator.isEmpty(value) && !validator.isCP(value)) error = "app.error.code.INVALID_CP";
                break;
            case EMAIL_FORMAT:
                if (!validator.isEmpty(value) && !validator.isEmail(value)) error = "app.error.code.BAD_FORMAT";
                break;
            case NIF_FORMAT:
                if (!validator.isEmpty(value) && !validator.isNif(value)) error = "app.error.code.BAD_FORMAT";
                break;
            case TF_FORMAT:
                if (!validator.isEmpty(value) && !validator.isTf(value)) error = "app.error.code.BAD_FORMAT";
                break;


            case DATE_FORMAT:
                if (!validator.isEmpty(value) && !validator.isDate(value)) error = "app.error.code.BAD_FORMAT";
                break;
            case TIME_FORMAT:
                if (!validator.isEmpty(value) && !validator.isTime(value)) error = "app.error.code.BAD_FORMAT";
                break;
            case DATE_FUTURE:
                if (!validator.isEmpty(value) && !validator.isFutureDate(value)) error = "app.error.code.INVALID_DATE";
                break;
            case DATE_FUTURE_OR_TODAY:
                if (!validator.isEmpty(value) && validator.isPastDate(value)) error = "app.error.code.INVALID_DATE";
                break;
            case DATE_PAST:
                if (!validator.isEmpty(value) && !validator.isPastDate(value)) error = "app.error.code.INVALID_DATE";
                break;
            case DATE_PAST_OR_TODAY:
                if (!validator.isEmpty(value) && validator.isFutureDate(value)) error = "app.error.code.INVALID_DATE";
                break;


            case NUMBER_FORMAT:
                if (!validator.isNumber(value)) error = "app.error.code.BAD_FORMAT";
                break;
            case NUMBER_BETWEEN:
                if (!validator.isEmpty(value) && !validator.isBetween(value, validationValue)) error = {id: "app.error.code.NUMBER_BETWEEN", min: validationValue[0], max: validationValue[1]};
                break;
            case NUMBER_GREATER_OR_EQUAL_TO:
                if (!validator.isEmpty(value) && !validator.isGreaterOrEqualTo(value, validationValue)) error = {id: "app.error.code.NUMBER_GREATER_OR_EQUAL_TO", value: validationValue};
                break;
            case NUMBER_GREATER_OR_EQUAL_TO_ZERO:
                if (!validator.isEmpty(value) && !validator.isGreaterOrEqualToZero(value)) error = "app.error.code.NUMBER_GREATER_OR_EQUAL_TO_ZERO";
                break;
            case NUMBER_GREATER_THAN_ZERO:
                if (!validator.isEmpty(value) && !validator.isGreaterThanZero(value)) error = "app.error.code.NUMBER_GREATER_THAN_ZERO";
                break;
            case NUMBER_NON_ZERO:
                if (!validator.isEmpty(value) && validator.isEqualToZero(value)) error = "app.error.code.NUMBER_NON_ZERO";
                break;
            case NUMBER_MULTIPLE_OF_35:
                if (!validator.isEmpty(value) && !validator.isMultipleOf(value, 35)) error = "app.error.code.INCORRECT_VALUE";
                break;
            case NUMBER_ODD:
                if (!validator.isEmpty(value) && validator.isPair(value)) error = "app.error.code.ODD";
                break;
            case NUMBER_PAIR:
                if (!validator.isEmpty(value) && !validator.isPair(value)) error = "app.error.code.PAIR";
                break;

            case MAX_LENGTH:
                if (!validator.isEmpty(value) && !validator.isMaxLength(value, validationValue)) error = {id: "app.error.code.MAX_LENGTH", value: validationValue};
                break;
        }
        if (error !== null) return error;
    });
    return error;
};
