import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as pointOfSaleService from '../../../../services/domain/warehouse/pointOfSaleService';

import Grid from '@material-ui/core/Grid';

import Checkbox from "../../../../components/form/Checkbox";
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';



export const DeliveryNotePosForm = (props) => {
    const {deliveryNotePos, pos, errors} = props;

    const intl = useIntl();

    const [posItems, setPosItems] = useState([]);

    useEffect(() => {
        setPosItems(pos.map(item => {
            return {value: item.id, label: pointOfSaleService.getNameWithAlias(item)};
        }));
    }, [pos]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Input
                        name="number"
                        label={intl.formatMessage({id: 'generic.domain.number'})}
                        value={deliveryNotePos.number}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                    <Input
                        type="date"
                        name="date"
                        label={intl.formatMessage({id: 'generic.date'})}
                        value={deliveryNotePos.date}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        name="posId"
                        label={intl.formatMessage({id: 'generic.domain.pos'})}
                        items={posItems}
                        value={deliveryNotePos.posId}
                        error={errors.posId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: '-20px'}}>
                    <Checkbox
                        name="paid"
                        label={intl.formatMessage({id: 'generic.domain.liquidatedToEmployee'})}
                        value={deliveryNotePos.paid}
                        onChange={props.onChange}
                    />
                </Grid>
            </Grid>
        </form>
    )
};

export default DeliveryNotePosForm;