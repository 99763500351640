import React from 'react';

import ShopTwoIcon from '@material-ui/icons/ShopTwo';


const AgencyIcon = (props) => {
    
    return (
        <ShopTwoIcon style={{color: "#FFF"}} />
    )        
}

export default AgencyIcon;
