import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as employeeService from '../../../../services/domain/warehouse/employeeService';
import * as paymentTypeService from '../../../../services/domain/warehouse/paymentTypeService';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Button from "../../../../components/form/Button";
import Divider from "../../../../components/Divider";
import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";
import {RightBox} from "../../../../components/Box";




const DeliveryConfirmationForm = (props) => {
    const {deliveryConfirmation, employees, products, saleChannels, authorizations, errors} = props;

    const intl = useIntl();

    const [authorizationItems, setAuthorizationItems] = useState([]);
    const [employeeItems, setEmployeeItems] = useState([]);
    const [paymentTypeItems, setPaymentTypeItems] = useState([]);
    const [productItems, setProductItems] = useState([]);
    const [saleChannelItems, setSaleChannelItems] = useState([]);

    useEffect(() => {
        setPaymentTypeItems(paymentTypeService.getPaymentTypeItems());
    }, []);
    useEffect(() => {
        setEmployeeItems(
            employees.map(employee => { return {value: employee.id, label: employeeService.getFullName(employee)}})
        );
    }, [employees]);
    useEffect(() => {
        setProductItems(
            products.map(product => { return {value: product.id, label: product.name}})
        );
    }, [products]);
    useEffect(() => {
        setSaleChannelItems(
            saleChannels.map(saleChannel => { return {value: saleChannel.id, label: saleChannel.channel}})
        );
    }, [saleChannels]);

    useEffect(() => {
        setAuthorizationItems(
            authorizationService.getAuthorizationItems(authorizations)
        );
    }, [authorizations]);


    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Select
                        name="authorizationId"
                        label={intl.formatMessage({id: 'generic.domain.authorization'})}
                        items={authorizationItems}
                        value={deliveryConfirmation.authorizationId}
                        error={errors.authorizationId}
                        onChange={props.onChange}
                        required
                        disabled={deliveryConfirmation.employeeId === null || deliveryConfirmation.id !== null}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={9}>
                    <Select
                        name="employeeId"
                        label={intl.formatMessage({id: 'generic.domain.driver'})}
                        items={employeeItems}
                        value={deliveryConfirmation.employeeId}
                        error={errors.employeeId}
                        onChange={props.onChange}
                        disabled={deliveryConfirmation.id !== null}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.product"})}/>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Select
                        name="productId"
                        label={intl.formatMessage({id: 'generic.domain.product'})}
                        items={productItems}
                        value={deliveryConfirmation.productId}
                        error={errors.productId}
                        onChange={props.onChange}
                        disabled={deliveryConfirmation.id !== null}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Input
                        name="charges"
                        label={intl.formatMessage({id: 'generic.domain.charges'})}
                        value={deliveryConfirmation.charges}
                        error={errors.charges}
                        onChange={props.onChange}
                        disabled={deliveryConfirmation.id !== null}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.deliverData"})}/>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Select
                        name="saleChannelId"
                        label={intl.formatMessage({id: 'generic.domain.saleChannel'})}
                        items={saleChannelItems}
                        value={deliveryConfirmation.saleChannelId}
                        error={errors.saleChannelId}
                        onChange={props.onChange}
                        disabled={deliveryConfirmation.id !== null}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        name="referenceNumber"
                        label={intl.formatMessage({id: 'generic.domain.referenceNumber'})}
                        value={deliveryConfirmation.referenceNumber}
                        error={errors.referenceNumber}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="observations"
                        label={intl.formatMessage({id: 'generic.domain.obs'})}
                        value={deliveryConfirmation.observations}
                        error={errors.observations}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault();props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
};

export default DeliveryConfirmationForm;
