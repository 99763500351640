import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from './Tooltip';

//import OrderIcon from '../icons/OrderIcon';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';



export class EnhancedTableHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    createSortHandler = properties => event => {
        this.props.onRequestSort(event, properties);
    };

    renderSubLabel(col) {
        const {classes} = this.props;

        if (typeof col.sublabel !== "undefined" && col.sublabel !== null && col.sublabel !== "") {
            return (
                <Box className={classes.headerSublabel} style={{height: 'auto', textAlign: col.align}}>
                    {col.sublabel}
                </Box>
            )
        } else return null;
    }

    renderCellContent(col) {
        const { classes, withLabels = true, ordered = false, order, orderBy } = this.props;
        const orderIcon = () => (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <ArrowDropUpIcon style={{marginTop: '-10px'}} fontSize="small" />
                <ArrowDropDownIcon style={{marginTop: '-10px'}} fontSize="small" />
            </div>)

        const sortableColumn = col.sortable || typeof col.sortable === "undefined";
        return (
            <Box display="flex" alignItems="center" style={{height: 'auto'}}>
                { withLabels && (
                    <Box flexGrow={1} style={{flexDirection: 'column', alignSelf: 'stretch'}}>
                        <Box className={classes.headerLabel} style={{height: 'auto', textAlign: col.align}} >
                            {col.label}
                        </Box>
                        {this.renderSubLabel(col)}
                    </Box>
                )}

                { (ordered && col.label !== "" && typeof col.label !== "undefined" && sortableColumn) && (
                    <Box style={{marginLeft: 0, height: 'auto'}}>
                        <TableSortLabel
                            active={orderBy === col.id}
                            direction={order}
                            onClick={this.createSortHandler({columnId:col.id, order:'toggle'})}
                            IconComponent={orderIcon}
                        />
                    </Box>
                )}
            </Box>
        )
    }

    render() {
        const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, headers, subHeaders, isChecked, withLabels = true } = this.props;
        const hasSubHeaders = typeof subHeaders !== "undefined";

        return (
            <TableHead>
                <TableRow>
                    {
                        isChecked === 'true' ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </TableCell>
                        : null
                    }
                    {
                        headers.map(col => (
                            <TableCell
                                key={col.id}
                                align='left'
                                padding={col.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === col.id ? order : false}
                                className={ (!withLabels) ? classes.tableCell : "" + classes.tableHeaderCell}
                                style={ col.width ? {width: col.width, paddingBottom: '5px'} : {paddingBottom: '5px'} }
                            >
                                {
                                    col.tooltip ?
                                    <Tooltip title={col.tooltip} placement="top" arrow>
                                        {this.renderCellContent(col)}
                                    </Tooltip>
                                    :
                                    this.renderCellContent(col)
                                }
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
                {
                    hasSubHeaders && (
                        <TableRow>
                            {
                                subHeaders.map(subheader => {
                                    return subheader.map((col, index) => {
                                        return (
                                            <TableCell
                                                key={index}
                                                align='left'
                                                colSpan={col.colSpan ? col.colSpan : 1}
                                                className={col.className ? classes[col.className] : classes.tableCell}
                                                style={{paddingBottom: '5px'}}
                                            >
                                                {typeof col.formatter === 'function' ? col.formatter(col.label, index) :col.label}
                                            </TableCell>
                                        )
                                    })
                                })
                            }
                        </TableRow>
                    )
                }
            </TableHead>
        );
    }
}

EnhancedTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    ordered: PropTypes.bool,
    headers: PropTypes.array.isRequired,
    subHeaders: PropTypes.array,
    rowCount: PropTypes.number.isRequired,
    withLabels: PropTypes.bool
};
