import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MuiPrintIcon from '@material-ui/icons/Print';


const PrintIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><MuiPrintIcon/></IconButton>
        );
    }
    return (<MuiPrintIcon/>);
};

export default PrintIcon;
