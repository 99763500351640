import React from 'react';
import { useIntl } from 'react-intl';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Select from "../../../../components/form/Select";



export const StreetListFilterForm = (props) => {
    const {filter, itineraries = []} = props;

    const intl = useIntl();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" width="100%">
                    <Box marginRight={1}>
                        <Select
                            name="itineraryId"
                            label={intl.formatMessage({id: 'generic.domain.itinerary'})}
                            items={itineraries.getSelectItems()}
                            value={filter.itineraryId}
                            onChange={props.onChange}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default StreetListFilterForm;