import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/icons/ErrorOutline';


const ErrorIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><Icon/></IconButton>
        );
    }
    return (<Icon/>);
};

export default ErrorIcon;
