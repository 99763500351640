import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUISwitch from '@material-ui/core/Switch';



const Switch = (props) => {
    const {label, fullWidth = false, color = 'primary', ...other} = props;

    return (
        <FormControl margin="dense">
            <FormControlLabel
                control={
                    <MUISwitch
                        color={color}
                        {...other}
                    />
                }
                label={label}
            />
        </FormControl>
    )
};

export default Switch;
