import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import withMessageBox from '../../../../../hocs/withMessageBox';

import * as employeeService from '../../../../../services/domain/warehouse/employeeService';
import * as productService from '../../../../../services/domain/warehouse/productService';
import * as plsService from '../../../../../services/domain/warehouse/productLiquidationSupplementService';
import * as saleChannelService from '../../../../../services/domain/warehouse/saleChannelService';
import * as validateService from "../../../../../services/validateService";
import {OBJ_NOT_EXISTS, ORDER_DESC} from "../../../../../helpers/array";
import {getValueFromField} from "../../../../../helpers/form";
import {createHeaderFromData} from "../../../../../helpers/table";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../../../../../components/Breadcrumbs/index';
import Button from '../../../../../components/form/Button';
import DeleteIcon from "../../../../../components/icons/Delete";
import EditIcon from "../../../../../components/icons/Edit";
import InputNative from '../../../../../components/form/Input/InputNative';
import Table from '../../../../../components/table';
import {RightBox} from "../../../../../components/Box";

import DefaultSaleChannelSupplementForm from "./DefaultSaleChannelSupplementForm";
import DriverSupplementForm from "./DriverSupplementForm";



export const ProductLiquidationSupplementList = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.pls'})}
    ];

    const newDefaultSupplement = {
        productId: null,
        supplement: 0
    }
    const defaultSupplementValidations = {
        productId: [{type: validateService.REQUIRED}],
        supplement: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
    }

    const newDefaultSCSupplement = {
        productId: null,
        saleChannelId: null,
        supplement: 0
    }
    const defaultSCSupplementValidations = {
        productId: [{type: validateService.REQUIRED}],
        saleChannelId: [{type: validateService.REQUIRED}],
        supplement: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
    }
    const newDriverSupplement = {
        productId: null,
        saleChannelId: null,
        employeeId: null,
        supplement: 0
    }
    const driverSupplementValidations = {
        productId: [{type: validateService.REQUIRED}],
        driverId: [{type: validateService.REQUIRED}],
        supplement: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
    }

    const newSupplements = {
        defaults: [],
        saleChannelsDefaults: [],
        drivers: [],
    }

    const [loading, setLoading] = useState(true);

    const [drivers, setDrivers] = useState([]);
    const [products, setProducts] = useState([]);
    const [saleChannels, setSaleChannels] = useState([]);

    const [defaultSupplement, setDefaultSupplement] = useState(newDefaultSupplement);
    const [defaultSCSupplement, setDefaultSCSupplement] = useState(newDefaultSCSupplement);
    const [driverSupplement, setDriverSupplement] = useState(newDriverSupplement);

    const [supplements, setSupplements] = useState(newSupplements);

    const [defaultSCSupplementErrors, setDefaultSCSupplementErrors] = useState({});
    const [driverSupplementErrors, setDriverSupplementErrors] = useState({});

    const [defaultTableHeaders, setDefaultTableHeaders] = useState([]);
    const [defaultTableData, setDefaultTableData] = useState([]);
    const [defaultSCTableData, setDefaultSCTableData] = useState([]);
    const [driverTableData, setDriverTableData] = useState([]);

    const [indexD, setIndexD] = useState(null);
    const [indexSC, setIndexSC] = useState(null);
    const [refresh, setRefresh] = useState(null);

    const defaultSCTableHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.saleChannel"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.supplement"}), sublabel: null, formatter: null },
        { id: 'actions', align: "center", width: 110 },
    ];
    const driverTableHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.driver"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.saleChannel"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.supplement"}), sublabel: null, formatter: null },
        { id: 'actions', align: "center", width: 110 },
    ];

    useEffect(() => {

        async function load() {
            const responsePLS = await plsService.getSupplements();
            const responseP = await productService.getProducts();
            const responseSC = await saleChannelService.getSaleChannels();
            const responseD = await employeeService.getDrivers();

            if (responseP.status === 200 && responseSC.status === 200 && responseP.status === 200 && responsePLS.status === 200) {
                const sortedProducts = responseP.data.products.sortBy('name', ORDER_DESC);

                setSupplements(hydrate(responsePLS.data, sortedProducts));

                setProducts(sortedProducts);
                setSaleChannels(responseSC.data.saleChannels);
                setDrivers(responseD);

                const defaultHeaders = createHeaderFromData(sortedProducts, 'name');
                setDefaultTableHeaders(defaultHeaders);

                setRefresh(new Date());
            } else {
                console.error('Loading error')
            }

            setLoading(false);
        }
        load();


    }, []);

    useEffect(() => {
        if (supplements) {
            setDefaultSCTableData(
                supplements.saleChannelsDefaults.map((sup, index) => {return convertDefaultSCRowData(sup, index)})
            );
            setDriverTableData(
                supplements.drivers.map((sup, index) => convertDriverSupRowData(sup, index))
            );
        }
    }, [refresh]);



    const hydrate = (supps, products) => {
        if (supps.defaults.length === 0) {
            for (let i = 0; i < products.length; i++) {
                supps.defaults.push({productId: products[i].id, supplement: 0});
            }
        } else {
            for (let i = 0; i < supps.defaults.length; i++) {
                supps.defaults[i] = {
                    ...supps.defaults[i],
                    productId: supps.defaults[i].product.id
                }
            }
        }
        for (let i = 0; i < supps.saleChannelsDefaults.length; i++) {
            supps.saleChannelsDefaults[i] = {
                ...supps.saleChannelsDefaults[i],
                productId: supps.saleChannelsDefaults[i].product.id,
                saleChannelId: supps.saleChannelsDefaults[i].saleChannel.id
            }
        }
        for (let i = 0; i < supps.drivers.length; i++) {
            supps.drivers[i] = {
                ...supps.drivers[i],
                productId: supps.drivers[i].product.id,
                saleChannelId: supps.drivers[i].saleChannel ? supps.drivers[i].saleChannel.id : null,
                employeeId: supps.drivers[i].employee.id
            }
        }

        return supps;
    }

    const convertDefaultSCRowData = (item, index) => {
        const saleChannel = item.saleChannel ? item.saleChannel.channel : saleChannels.getObjectById(item.saleChannelId).channel;
        const product = item.product ? item.product.name : products.getObjectById(item.productId).name;
        return {
            id1: saleChannel,
            id2: product,
            id3: item.supplement,
            actions:
                <React.Fragment>
                    <EditIcon button onClick={() => handleEditDefaultSC(item, index)} />
                    <DeleteIcon button onClick={() => handleDeleteDefaultSC(item, index)} />
                </React.Fragment>
        }
    }
    const convertDriverSupRowData = (item, index) => {
        const allLabel = intl.formatMessage({id: "generic.all.plural.male"});
        const driver = item.employee ? item.employee : drivers.getObjectById(item.employeeId);
        const saleChannel = item.saleChannel ? item.saleChannel.channel : item.saleChannelId ? saleChannels.getObjectById(item.saleChannelId).channel : "[" + allLabel.toUpperCase() + "]";
        const product = item.product ? item.product.name : products.getObjectById(item.productId).name;
        return {
            id1: employeeService.getFullName(driver),
            id2: saleChannel,
            id3: product,
            id4: item.supplement,
            actions:
                <React.Fragment>
                    <EditIcon button onClick={() => handleEditDrivers(item, index)} />
                    <DeleteIcon button onClick={() => handleDeleteDrivers(item, index)} />
                </React.Fragment>
        }
    }

    const handleChangeDefault = (e) => {
        const sup = {...supplements};
        const index = e.target.dataset.index;
        sup.defaults[index].supplement = getValueFromField(e, null, "float");
        setSupplements(sup);
    }


    /** sale channels supplements */
    const handleChangeDefaultSC = (e) => {
        setDefaultSCSupplement({
            ...defaultSCSupplement,
            [e.target.name]: getValueFromField(e)
        });
    }
    const handleSubmitDefaultSC = () => {
        const err = validateService.validate(defaultSCSupplement, defaultSCSupplementValidations);
        setDefaultSCSupplementErrors(err);
        if (!validateService.hasErrors(err)) {
            if (indexSC == null) {
                supplements.saleChannelsDefaults.push(defaultSCSupplement);
            } else {
                supplements.saleChannelsDefaults[indexSC] = defaultSCSupplement;
            }
            supplements.saleChannelsDefaults.sortBy("saleChannelId"); //TODO: revisar
            setRefresh(new Date());
            setDefaultSCSupplement(newDefaultSCSupplement);
            setIndexSC(null);
        }
    }
    const handleEditDefaultSC = (item, itemIndex) => {
        setIndexSC(itemIndex);
        if (supplements && supplements.saleChannelsDefaults.length > 0) {
            setDefaultSCSupplement(supplements.saleChannelsDefaults[itemIndex]);
        }
    }
    const handleDeleteDefaultSC = (item, itemIndex) => {
        if (supplements && supplements.saleChannelsDefaults.length > 0) {
            supplements.saleChannelsDefaults.splice(itemIndex, 1);
            setIndexSC(null);
        }
        setRefresh(new Date());
    }
    const handleClearDefaultSC = () => {
        setIndexSC(null);
        setDefaultSCSupplement(newDefaultSCSupplement);
    }

    /** drivers supplement */
    const handleChangeDrivers = (e) => {
        setDriverSupplement({
            ...driverSupplement,
            [e.target.name]: getValueFromField(e)
        });
    }
    const handleSubmitDrivers = () => {
        const err = validateService.validate(driverSupplement, driverSupplementValidations);
        setDriverSupplementErrors(err);
        if (!validateService.hasErrors(err)) {
            if (indexD == null) {
                supplements.drivers.push(driverSupplement);
            } else {
                supplements.drivers[indexD] = driverSupplement;
            }
            supplements.drivers.sortBy("saleChannelId"); //TODO: revisar
            setRefresh(new Date());
            setDriverSupplement(newDriverSupplement);
            setIndexD(null);
        }
    }
    const handleEditDrivers = (item, itemIndex) => {
        if (supplements && supplements.drivers.length > 0) {
            setIndexD(itemIndex);
            setDriverSupplement(supplements.drivers[itemIndex]);
        }
    }
    const handleDeleteDrivers = (item, itemIndex) => {
        if (supplements && supplements.drivers.length > 0) {
            supplements.drivers.splice(itemIndex, 1);
            setIndexD(null);
        }
        setRefresh(new Date());
    }
    const handleClearDrivers = () => {
        setIndexD(null);
        setDriverSupplement(newDriverSupplement);
    }


    const handleSubmit = () => {
        let errs = validateService.validateArray(supplements.defaults, defaultSupplementValidations);
        if (!validateService.hasErrors(errs)) {
            setLoading(true);
            const response = plsService.updateSupplements(supplements);

            response
                .then(res => {
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    let message = "Error";
                    if (err.response && err.response.data) message = err.response.data.message;
                    props.errorMessage(message);
                })
                .finally(() => setLoading(false));
        } else {
            console.error(errs);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/><br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" width="100%">
                    <Box flexGrow={1}>
                        <h4 style={{margin: 0}}>{intl.formatMessage({id: "generic.domain.supplementsByProduct"})}</h4>
                        <h6 style={{marginTop: 0, color: 'gray'}}>{intl.formatMessage({id: "generic.defaultValues"})}</h6>
                    </Box>
                    <Box>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            {intl.formatMessage({id: "generic.save"})}
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {loading && (<div>loading...</div>)}
                {!loading && (
                    <table>
                        <thead>
                        <tr>
                            <th></th>
                            {products.map((product, index) => (<th key={index}>{product.name}</th>))}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{paddingRight: '30px'}}>{intl.formatMessage({id: "generic.domain.supplement"})}</td>
                            {
                            products.map((product, index) => {
                                return (
                                    <td key={index}>
                                        <InputNative
                                            name={"n"+index}
                                            value={supplements.defaults[index].supplement}
                                            style={{width:'90px', textAlign:'right'}}
                                            onChange={handleChangeDefault}
                                            data-index={index}
                                        />
                                    </td>
                                )
                            })
                        }</tr>
                        </tbody>
                    </table>
                )}
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <h4 style={{marginBottom: 0}}>{intl.formatMessage({id: "generic.domain.supplementsBySaleChannel"})}</h4>
                <h6 style={{marginTop: 0, color: 'gray'}}>{intl.formatMessage({id: "generic.defaultValues"})}</h6>
            </Grid>
            <Grid item xs={8}>
                <Table
                    headers={defaultSCTableHeaders}
                    data={defaultSCTableData}
                    isLoading={loading}
                />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
                <DefaultSaleChannelSupplementForm
                    index={indexSC}
                    supplement={defaultSCSupplement}
                    products={products}
                    saleChannels={saleChannels}
                    errors={defaultSCSupplementErrors}
                    onChange={handleChangeDefaultSC}
                    onSubmit={handleSubmitDefaultSC}
                    onClear={handleClearDefaultSC}
                />
            </Grid>
            <Grid item xs={12}>
                <h4>{intl.formatMessage({id: "generic.domain.supplementsByDriver"})}</h4>
            </Grid>
            <Grid item xs={9}>
                <Table
                    headers={driverTableHeaders}
                    data={driverTableData}
                    isLoading={loading}
                />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
                <DriverSupplementForm
                    index={indexD}
                    supplement={driverSupplement}
                    drivers={drivers}
                    products={products}
                    saleChannels={saleChannels}
                    errors={driverSupplementErrors}
                    onChange={handleChangeDrivers}
                    onSubmit={handleSubmitDrivers}
                    onClear={handleClearDrivers}
                />
            </Grid>
            <Grid item xs={8}>
                <RightBox>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {intl.formatMessage({id: "generic.save"})}
                    </Button>
                </RightBox>
                <br/><br/>
            </Grid>
        </Grid>
    )
}

export default withMessageBox(ProductLiquidationSupplementList);