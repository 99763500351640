import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import Switch from '../../../../components/form/Switch';


export const WarehouseMovementForm = (props) => {
    const {movement, warehouses, warehouseMovementTypes, errors} = props;

    const intl = useIntl();

    const [warehouseItems, setWarehouseItems] = useState([]);
    const [warehouseMovementItems, setWarehouseMovementItems] = useState([]);

    useEffect(() => {
        setWarehouseItems(
            warehouses.map(warehouse => {
                return {value: warehouse.id, label: warehouse.name};
            })
        );
    }, [warehouses]);
    useEffect(() => {
        setWarehouseMovementItems(
            warehouseMovementTypes.map(item => {
                return {value: item.id, label: item.type};
            })
        );
    }, [warehouseMovementTypes]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <Input
                        name="number"
                        label={intl.formatMessage({id: 'generic.domain.number'})}
                        value={movement.number}
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        type="date"
                        name="date"
                        label={intl.formatMessage({id: 'generic.date'})}
                        value={movement.date}
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        name="warehouseId"
                        label={intl.formatMessage({id: 'generic.domain.warehouse'})}
                        items={warehouseItems}
                        value={movement.warehouseId}
                        error={errors.warehouseId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={3}>
                    <Switch
                        name="canceled"
                        label={intl.formatMessage({id: 'generic.canceled.fem'})}
                        checked={movement.canceled === true || movement.canceled === 1}
                        onChange={props.onChange}
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        name="movementTypeId"
                        label={intl.formatMessage({id: 'generic.type'})}
                        items={warehouseMovementItems}
                        value={movement.movementTypeId}
                        error={errors.movementTypeId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="obs"
                        label={intl.formatMessage({id: 'generic.domain.obs'})}
                        value={movement.obs}
                        error={errors.obs}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
}

export default WarehouseMovementForm;