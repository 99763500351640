import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const getSupplements = async (productId = null, saleChannelId = null, driverId = null) => {
    return await apiService.get(
        `${URL}/products/liquidation-supplements`,
    {productId: productId, saleChannelId: saleChannelId, employeeId: driverId}
    );
}

export const updateSupplements = async (data) => {
    return await apiService.put(`${URL}/products/liquidation-supplements`, data);
}

export const getSupplement = (supplements = null, productId, saleChannelId, driverId) => {
    if (supplements === null) return 0;

    let supplementToApply;
    let supplement = 0;
    supplementToApply = supplements.drivers.filter(sup =>
        sup.productId === productId && sup.saleChannelId === saleChannelId && sup.employeeId === driverId
    );
    if (supplementToApply.length === 0) {
        supplementToApply = supplements.drivers.filter(sup =>
            sup.productId === productId && sup.saleChannelId === null && sup.employeeId === driverId
        );
    }
    if (supplementToApply.length === 0) {
        supplementToApply = supplements.saleChannelsDefaults.filter(sup => sup.productId === productId && sup.saleChannelId === saleChannelId);
    }
    if (supplementToApply.length === 0) {
        supplementToApply = supplements.defaults.filter(sup => sup.productId === productId);
    }
    if (supplementToApply.length > 0) {
        supplement = supplementToApply[0].supplement;
    }

    return supplement;
}

export const hydrate = (supplements) => {
    for (let i = 0; i < supplements.defaults.length; i++) {
        supplements.defaults[i] = {
            ...supplements.defaults[i],
            productId: supplements.defaults[i].product.id
        }
    }

    for (let i = 0; i < supplements.saleChannelsDefaults.length; i++) {
        supplements.saleChannelsDefaults[i] = {
            ...supplements.saleChannelsDefaults[i],
            productId: supplements.saleChannelsDefaults[i].product.id,
            saleChannelId: supplements.saleChannelsDefaults[i].saleChannel.id
        }
    }
    for (let i = 0; i < supplements.drivers.length; i++) {
        supplements.drivers[i] = {
            ...supplements.drivers[i],
            productId: supplements.drivers[i].product.id,
            saleChannelId: supplements.drivers[i].saleChannel ? supplements.drivers[i].saleChannel.id : null,
            employeeId: supplements.drivers[i].employee.id
        }
    }

    return supplements;
}