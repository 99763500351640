import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const list = async (filter) => {
    return await apiService.get(`${URL}/delivery-confirmations`, filter);
}

export const add = async (data) => {
    return await apiService.post(`${URL}/delivery-confirmations`, data);
}

export const update = async (data) => {
    return await apiService.put(`${URL}/delivery-confirmations/${data.id}`, data);
}

export const del = async (data) => {
    return await apiService.del(`${URL}/delivery-confirmations/${data.id}`);
}