import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const ACCOUNTING_ACCOUNT_TYPE_CASH = "EFECTIVO";
export const ACCOUNTING_ACCOUNT_TYPE_BANK = "BANCO";
export const ACCOUNTING_ACCOUNT_TYPE_TPV = "BANCO_TPV";
export const ACCOUNTING_ACCOUNT_TYPE_PAYMENTS = "COBROS_PENDIENTES_IDENTIF";
export const ACCOUNTING_ACCOUNT_TYPE_BONUS = "BONOS_LIQUIDACION";
export const ACCOUNTING_ACCOUNT_TYPE_MERCHANDASING = "MERCADERIAS";
export const ACCOUNTING_ACCOUNT_TYPE_SUPPLEMENT = "SUPLEMENTO_CARGAS";
export const ACCOUNTING_ACCOUNT_TYPE_RENTING_GAS = "ALQUILER_GAS";
export const ACCOUNTING_ACCOUNT_TYPE_SUBS_GAS = "ALTAS_GAS";
export const ACCOUNTING_ACCOUNT_TYPE_UNSUBS_GAS = "BAJAS_GAS";
export const ACCOUNTING_ACCOUNT_TYPE_INCIDENCE_GAS = "INCIDENCIAS_GAS";

export const ACCOUNTING_ACCOUNT_CODE_BONUS_PO = "BONOS_PAGO_OFICINA";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_PT = "BONOS_PAGO_TRANSFERENCIA";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_PTB = "BONOS_PIDE_TU_BOMBONA";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_B24 = "BONOS_B24";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_ARMY = "BONOS_EJERCITO";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_SUP = "BONOS_SUPLEMENTOS";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_DISC_I350 = "BONOS_DESCUENTO_I350";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_DISC_UD110 = "BONOS_DESCUENTO_UD110";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_DMF = "BONOS_DMF";
export const ACCOUNTING_ACCOUNT_CODE_BONUS_PAID_REPSOL = "BONOS_PAGADO_A_REPSOL";


/** Accounting accounts */
export const getAccountingAccounts = async (filter = {}) => {
    return await apiService.get(`${URL}/accounting-accounts`, filter);
};
export const updateDefaultAccountingAccounts = async (data = {}) => {
    return await apiService.put(`${URL}/accounting-accounts/default`, data);
};

export const getAccountingAccount = (accountingAccounts, typeCode, codeId = null) => {
    const account = accountingAccounts.filter(accountingAccount => {
        if (codeId === null)
            return accountingAccount.accountType.code === typeCode;
        else
            return accountingAccount.accountType.code === typeCode &&
                accountingAccount.codeId === codeId;
    });
    return account.length > 0 ? account[0] : null;
}

/** Accounting movements */
export const listAccountingMovements = async (filter = {}) => {
    return await apiService.get(`${URL}/accounting-movements`, filter);
};
export const downloadAccountingMovements = (data) => {
    const fileName = "Diario operaciones " + (data.startDate === data.endDate ? data.startDate : data.startDate + ' a ' + data.endDate);
    apiService.downloadFile(`${URL}/accounting-movements/download`, data, fileName);
};
export const exportAccountingMovements = async (data) => {
    const fileName = "Contabilidad " + (data.startDate === data.endDate ? data.startDate : data.startDate + ' a ' + data.endDate);
    return await apiService.downloadFile(`${URL}/accounting-movements/export`, data, fileName);
};
export const addAccountingMovement = async (data = {}) => {
    return await apiService.post(`${URL}/accounting-movements`, data);
};
export const updateAccountingMovement = async (data = {}) => {
    return await apiService.put(`${URL}/accounting-movements/${data.id}`, data);
};
export const createPayment = async (data = {}) => {
    return await apiService.post(`${URL}/accounting-movements/payments`, data);
};
