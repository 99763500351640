import axios from 'axios';

import * as authService from './authService';

export const FILE_TYPE_DOCX = "application/vnd.openxmlformats-officedocument.wordprocessing";
export const FILE_TYPE_PDF = "application/pdf";
export const FILE_TYPE_XLS = "application/vnd.ms-excel";
export const FILE_TYPE_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const ERRORCODE_AGENCY_REQUIRED = 'AGENCY_REQUIRED';
const ERRORCODE_AGENCY_NOT_AUTHORIZED = 'AGENCY_NOT_AUTHORIZED';
const ERRORCODE_DOWNLOAD_FILE = 'DOWNLOAD_FILE';


/** AXIOS CONFIG */
axios.defaults.headers.common = { 'Content-Type': 'application/json' };
axios.interceptors.request.use(
request => {
        const token = authService.getAccessToken();
        if (token) {
            request.headers['Authorization'] = 'Bearer ' + token;
        }
        request.headers['Accept-Language'] = 'es';
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                //TODO: fer el refreshToken: https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
            }
            return Promise.reject(error);
        }
    }
);
/** ********** */



/**
 * request
 * @param url {string}
 * @param method {string}
 * @param data {object}
 * @param params {object}
 * @param headers {object}
 * @param timeout {int}
 * @returns {Promise<{response, error: null, headers}>}
 */
const request = ({url = '/', method = 'GET', data = {}, params = {}, headers = {}, options = {}}) => {
    try {
        const agencyId = authService.getAgencyId();
        if (!url.includes('/login')) {
            if (!agencyId) return sendError(ERRORCODE_AGENCY_REQUIRED, 400); //TODO: Posar algun missatge o redirigir a una pag d'error
            if (!authService.userHasAgencyAuthorization(agencyId)) return sendError(ERRORCODE_AGENCY_NOT_AUTHORIZED, 403);
        }

        switch (method) {
            case 'GET':
                params = {agencyId: authService.getAgencyId(), ...params};
                break;
            case 'POST':
            case 'PUT':
            case 'PATCH':
            case 'DELETE':
                data = {agencyId: authService.getAgencyId(), ...data};
                break;
        }

        return axios({
            url: url,
            method: method,
            data: data,
            headers: headers,
            params: params,
            timeout: 300000,
            ...options
        });
    } catch (e) {
        console.error(e);
    }
};



export const get = (url, params) =>  request({ url, params });
export const post = (url, data) => request({ url, method: 'POST', data });
export const patch = (url, data) => request({ url, method: 'PATCH', data });
export const put = (url, data) => request({ url, method: 'PUT', data });
export const del = (url) => request({ url, method: 'DELETE' });
const getFile = (url, params) => request({ url, params, options: {responseType: 'blob'} });


function sendError(code, status, message = null) {
    return new Promise((resolve, reject) => {
        reject({
            code: code,
            status: status,
            message: message
        })
    })
}

export const downloadFile = (url, params, fileName) => {
    return new Promise((resolve, reject) => {
        const response = getFile(url, params);
        response
            .then(res => {
                download(res.data, fileName + getExtension(res.data.type));
                resolve(true);
            })
            .catch(err => {
                console.error('Error downloading file: ', err);
                reject({code: ERRORCODE_DOWNLOAD_FILE, message: err.message});
            });
    })
}

export const download = (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}
const getExtension = (contentType) => {
    contentType = contentType.replace(";charset=UTF-8", "");
    switch (contentType) {
        case FILE_TYPE_PDF:
            return ".pdf";
        case FILE_TYPE_XLS:
            return ".xls";
        case FILE_TYPE_XLSX:
            return ".xlsx";
        case FILE_TYPE_DOCX:
            return ".docx";
        default:
            console.error('Unknown extension for content type: ' + contentType);
    }
}
