import React from 'react';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


const OrderIcon = (props) => {

    return (
        <ShoppingCartIcon style={{color: "#FFF"}} />
    )
}

export default OrderIcon;
