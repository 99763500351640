import * as apiService from '../../apiService';

const URL = process.env.REACT_APP_API_SERVER + '/api/v1/street-map';

export const ODDS_NUM_I = 1;
export const ODDS_NUM_F = 9999;
export const PAIRS_NUM_I = 0;
export const PAIRS_NUM_F = 9998;


export const getStreets = async (filter = {}) => {
    return await apiService.get(`${URL}/streets`, filter);
}
export const addStreet = async (data) => {
    return await apiService.post(`${URL}/streets`, data);
}
export const updateStreet = async (id, data) => {
    return await apiService.put(`${URL}/streets/${id}`, data);
}
export const deleteStreet = async (id) => {
    return await apiService.del(`${URL}/streets/${id}`);
}

export const getStreetTypes = async (filter = {}) => {
    return await apiService.get(`${URL}/street-types`, filter);
}
export const getStreetsByItinerary = async (itineraryId) => {
    return await apiService.get(`${URL}/streets/itineraries/${itineraryId}`);
}

export const getParity = () => {
    return [
        {id: "I", value: "Impar"},
        {id: "P", value: "Par"},
    ]
}

export const getProvinces = async (filter = {}) => {
    return await apiService.get(`${URL}/provinces`, filter);
}
export const getTowns = async (filter = {}) => {
    return await apiService.get(`${URL}/towns`, filter);
}

export const hydrateStreet = (street) => {
    const odds = street.stretches.odds.map(item => {return {...item, itineraryId: item.itinerary.id}});
    const pairs = street.stretches.pairs.map(item => {return {...item, itineraryId: item.itinerary.id}});
    return {
        ...street,
        stretches: {
            odds: odds,
            pairs: pairs
        },
        streetTypeId: street.streetType ? street.streetType.id : null,
        townId: street.town ? street.town.id : null,
        provinceId: street.town && street.town.province ? street.town.province.id : null
    }
}

export const getItineraries = async (filter = {}) => {
    return await apiService.get(`${URL}/itineraries`, filter);
}
export const addItinerary = async (data = {}) => {
    return await apiService.post(`${URL}/itineraries`, data);
}
export const updateItinerary = async (id, data) => {
    return await apiService.put(`${URL}/itineraries/${id}`, data);
}

export const getRoutes = async (filter = {}) => {
    return await apiService.get(`${URL}/routes`, filter);
}
export const addRoute = async (data = {}) => {
    return await apiService.post(`${URL}/routes`, data);
}
export const updateRoute = async (id, data) => {
    return await apiService.put(`${URL}/routes/${id}`, data);
}
export const deleteRoute = async (id) => {
    return await apiService.del(`${URL}/routes/${id}`);
}
