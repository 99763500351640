import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputIcon from '@material-ui/icons/Input';



const ReturnIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><InputIcon/></IconButton>
        );
    }
    return (<InputIcon/>);
};

export default ReturnIcon;
