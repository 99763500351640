import React from 'react';
import { useIntl } from 'react-intl';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";
import Checkbox from "../../../../components/form/Checkbox";


const StreetFilterForm = (props) => {
    const {filter, streetTypes = [], towns = [], provinces = []} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container>
                <Grid item xs={12}>
                    <Box display="flex" width="100%">
                        <Box marginRight={1}>
                            <Select
                                name="streetTypeId"
                                label={intl.formatMessage({id: 'generic.domain.streetType'})}
                                items={streetTypes.getSelectItems()}
                                value={filter.streetTypeId}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                name="name"
                                label={intl.formatMessage({id: 'generic.domain.street'})}
                                value={filter.name}
                                onChange={props.onChange}
                                width={200}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                name="raw"
                                label={intl.formatMessage({id: 'generic.inRaw'}, {label: intl.formatMessage({id: 'generic.domain.street'})})}
                                value={filter.raw}
                                onChange={props.onChange}
                                width={200}
                            />
                        </Box>
                        <Box>
                            <Checkbox
                                name="startBy"
                                label={intl.formatMessage({id: 'generic.startBy'})}
                                value={filter.startBy}
                                onChange={props.onChange}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" width="100%">
                        <Box marginRight={1}>
                            <Select
                                name="provinceId"
                                label={intl.formatMessage({id: 'generic.domain.province'})}
                                items={provinces.getSelectItems()}
                                value={filter.provinceId}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Select
                                name="townId"
                                label={intl.formatMessage({id: 'generic.domain.town'})}
                                items={towns.getSelectItems()}
                                value={filter.townId}
                                onChange={props.onChange}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

export default StreetFilterForm;