import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import { withStyles } from "@material-ui/core/styles/index";

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ClearButton from "../form/Button/clear";
import SearchButton from "../form/Button/search";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';


const StyledExpansionPanelSummary = withStyles({

})(ExpansionPanelSummary);
const StyledExpansionPanelDetails = withStyles({
    root: {
        padding: '5px 30px 0 30px'
    }
})(ExpansionPanelDetails);


const Filter = (props) => {
    const {children, defaultExpanded, isLoading} = props;
    const intl = useIntl();

    let canExpand = true;
    const [expanded, setExpanded] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        if (defaultExpanded) setExpanded(true);
    }, [defaultExpanded]);
    useEffect(() => {
        if (isLoading === false) setIsSearching(false);
    }, [isLoading]);

    const handleExpandCollapse = (e, expanded) => {
        e.preventDefault();
        if (canExpand) {
            setExpanded(expanded);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setIsSearching(true);
        if (expanded === false) {
            canExpand = false;
            setTimeout(() => {canExpand = true}, 500);
        }
        if (typeof props.onSearch === "function") {
            props.onSearch();
        }
    }

    return (
        <Box>
            <ExpansionPanel
                defaultExpanded={defaultExpanded}
                expanded={expanded}
                onChange={handleExpandCollapse}
            >
                <StyledExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Box display="flex" width="100%">
                        <Box paddingTop={1}>
                            <YoutubeSearchedForIcon />
                        </Box>
                        <Box flexGrow={1} paddingTop={1} marginLeft={2} color="gray">
                            <Badge color="secondary" variant="dot" invisible={typeof props.badge==='undefined'}>
                                {intl.formatMessage({id: 'generic.filters'})}
                            </Badge>
                        </Box>
                        <Box>
                            {expanded === false && (
                                <SearchButton name="search" onClick={handleSearch} disabled={isLoading || props.onSearch === undefined} isLoading={isSearching} />
                            )}
                        </Box>
                    </Box>
                </StyledExpansionPanelSummary>
                <StyledExpansionPanelDetails>
                    <div style={{ overflowX: 'auto', overflowY: 'hidden', padding: '0 5px' }}>
                        {children}
                    </div>
                </StyledExpansionPanelDetails>
                <ExpansionPanelActions>
                    <Box marginRight={4}>
                        <ClearButton onClick={props.onClear} />
                        <SearchButton onClick={handleSearch} disabled={isLoading || props.onSearch === undefined} isLoading={isSearching} />
                    </Box>
                </ExpansionPanelActions>
            </ExpansionPanel>
        </Box>
    )
}

export default Filter;
