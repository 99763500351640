import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as pointOfSaleService from '../../../../services/domain/warehouse/pointOfSaleService';
import * as validator from "../../../../helpers/validator";

import Grid from "@material-ui/core/Grid";

import Checkbox from '../../../../components/form/Checkbox';
import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";


const DeliveryNotePosForm = (props) => {
    const {deliveryNotePos, pos, errors} = props;

    const intl = useIntl();

    const [posItems, setPosItems] = useState([]);

    useEffect(() => {
        setPosItems(pos.map(item => {
            return {value: item.id, label: pointOfSaleService.getNameWithAlias(item)};
        }));
    }, [pos]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <Input
                        name="number"
                        label={intl.formatMessage({id: 'generic.domain.number'})}
                        value={deliveryNotePos.number}
                        error={errors.number}
                        onChange={props.onChange}
                        disabled={validator.isTrue(deliveryNotePos.invoiced)}
                        required
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        type="date"
                        name="date"
                        label={intl.formatMessage({id: 'generic.date'})}
                        value={deliveryNotePos.date}
                        error={errors.date}
                        onChange={props.onChange}
                        disabled={validator.isTrue(deliveryNotePos.invoiced)}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="authorization"
                        label={intl.formatMessage({id: 'generic.domain.liquidation'})}
                        value={deliveryNotePos.authorization.number}
                        onChange={props.onChange}
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        name="vehicle"
                        label={intl.formatMessage({id: 'generic.domain.vehicle'})}
                        value={deliveryNotePos.authorization.vehicle.plate}
                        onChange={props.onChange}
                        disabled
                    />
                </Grid>
                <Grid item xs={9}>
                    <Input
                        name="driver"
                        label={intl.formatMessage({id: 'generic.domain.driver'})}
                        value={deliveryNotePos.authorization.driver.name}
                        onChange={props.onChange}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        name="posId"
                        label={intl.formatMessage({id: 'generic.domain.pos'})}
                        items={posItems}
                        value={deliveryNotePos.posId}
                        error={errors.posId}
                        onChange={props.onChange}
                        disabled={validator.isTrue(deliveryNotePos.invoiced)}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: '-20px'}}>
                    <Checkbox
                        name="paid"
                        label={intl.formatMessage({id: 'generic.domain.liquidatedToEmployee'})}
                        value={deliveryNotePos.paid}
                        onChange={props.onChange}
                        disabled
                    />
                    <Checkbox
                        name="invoiced"
                        label={intl.formatMessage({id: 'generic.domain.invoiced'})}
                        value={deliveryNotePos.invoiced}
                        onChange={props.onChange}
                        disabled
                    />
                </Grid>

            </Grid>
        </form>
    )
}

export default DeliveryNotePosForm;