import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as validateService from "../../../../services/validateService";
import * as streetMapService from "../../../../services/domain/streetMap/streetMapService";
import {getValueFromField} from "../../../../helpers/form";

import Grid from "@material-ui/core/Grid";

import AddIcon from "../../../../components/icons/Add";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Button from '../../../../components/form/Button/index';
import EditIcon from "../../../../components/icons";
import Modal from "../../../../components/Modal/Modal";
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";

import ItineraryForm from "./ItineraryForm";


export const ItineraryList = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.streetMap'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management.itineraries'})}
    ];

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.itinerary"}), sublabel: null, formatter: null, width: 200 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.route"}), sublabel: null, formatter: null },
        { id: 'actions', align: "center", width: 60 },
    ];

    const newItinerary = {
        id: null,
        name: null,
        routeId: null
    }
    const validations = {
        name: [{type: validateService.REQUIRED}],
    }

    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [itinerary, setItinerary] = useState(newItinerary);


    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseI = await streetMapService.getItineraries();
            const responseR = await streetMapService.getRoutes();
            if (responseI.status === 200 && responseR.status === 200) {
                setData(responseI.data.itineraries.map(item => convertRowData(item)));
                setRoutes(responseR.data.routes);
                setLoading(false);
            }
        }
        load();
    }, [refresh]);

    const convertRowData = item => {
        return {
            id1: item.name,
            id2: item.route ? item.route.name : "-",
            actions: <EditIcon button onClick={() => handleEdit(item)}/>
        }
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleNew = () => {
        setItinerary(newItinerary);
        setOpen(true);
    };
    const handleEdit = (itinerary) => {
        setItinerary({
            ...itinerary,
            routeId: itinerary.route ? itinerary.route.id : itinerary.routeId ? itinerary.routeId : null
        });
        setOpen(true);
    };
    const handleChange = (e) => {
        e.preventDefault();
        setItinerary({
            ...itinerary,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSubmit = () => {
        const err = validateService.validate(itinerary, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            let response;
            if (itinerary.id === null) {
                response = streetMapService.addItinerary(itinerary);
            } else {
                response = streetMapService.updateItinerary(itinerary.id, itinerary);
            }
            response
                .then(() => {
                    setRefresh(new Date());
                    setOpen(false);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                });

        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={6}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew}><AddIcon color="white"/></Button>
                </RightBox>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
            <Grid item xs={12}>
                <Modal
                    open={open}
                    title={intl.formatMessage({id: "generic.domain.route"})}
                    onClose={handleClose}
                    height={300}
                    width={500}
                >
                    <ItineraryForm
                        itinerary={itinerary}
                        routes={routes}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
}

export default withMessageBox(ItineraryList);