import React, { useEffect, useState } from 'react';

import { getAgenciesStatus } from '../../../../services/domain/warehouse/proceedingService';

import { Box, Card, List, ListItem } from '@material-ui/core';
import Loader from '../../../../components/Loader/Loader';

export const ReportStatus = () => {

  const [status, setStatus] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();


  useEffect(() => {
    setLoading(true);
    setError(undefined);
    getAgenciesStatus()
    .then(res => setStatus(res.data.closureAgenciesStatus))
    .catch(err => setError('Ha habido un error'))
    .finally(() => setLoading(false));
  }, [])

  return (
      <Box>
          Estado agencias
          <List>
            {isLoading && <Loader />}
            {!isLoading && error && <span className='text-error'>{error}</span>}
            {!isLoading && !error && status.map((status, index) => (
                <ListItem key={index}>
                  <Card style={{padding: '10px', minWidth: '500px'}}>
                      <h4>Agencia: {status.agency?.id} [{status.agency?.name ? status.agency?.name : '-'}]</h4>
                    {status.closureWarehouseStatusList.map((cw, index) => (
                      <ul key={index} style={{paddingBottom: '10px'}}>
                          <li style={{listStyleType: 'none'}}><strong>Almacén: {cw.warehouse.name} [id: {cw.warehouse.id}] {cw.warehouse.enabled === false && <span className='text-error'>-BAJA-</span>}</strong></li>
                        {cw.warehouse.enabled === true && (
                          <>
                            <li>Estado: {cw.status}</li>
                            <li>Cierre hoy: {cw.currentClosureAt ? (<>{cw.currentClosureAt} por {cw.currentClosureBy}</>) : '-'}</li>
                            <li>{cw.currentClosureAt ? 'Cierre anterior':'Último cierre'}: {cw.lastClosureAt ? (<>{cw.lastClosureAt} por {cw.lastClosureBy}</>) : '-'}</li>
                            <li>Último movimiento: {cw.lastMovementAt} {cw.lastMovementAt ? <>[{cw.lastMovementType}]</> : '-'}</li>
                          </>
                          )}
                      </ul>
                    ))}
                  </Card>
                </ListItem>
            ))}
          </List>
      </Box>
  )
}

export default ReportStatus;