import React from 'react';

import Grid from '@material-ui/core/Grid';

import ReportStatus from './reportStatus/ReportStatus';

export const Dashboard = () => {

    return (
        <Grid container>
            <Grid item xs={12}>
                <h4>Dashboard</h4>
                <ReportStatus />
            </Grid>
        </Grid>
    )
}

export default Dashboard;