import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import InputNative from "../../../../components/form/Input/InputNative";
import Table from '../../../../components/table';
import { RightBox } from '../../../../components/Box';

import { commissionFormatter, priceFormatter } from "../../../../helpers/formatter";


export const ProductComissionTable = (props) => {
    const {commissions} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(commissions.map(item => convertRowData(item)));
    }, [commissions]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.saleChannel"}), sublabel: null, formatter: null },
        { id: 'id2', align: "right",   label: intl.formatMessage({id:"generic.domain.charge"}), sublabel: null, formatter: null, width: 90 },
        { id: 'id3', align: "right",   label: intl.formatMessage({id:"generic.domain.unsubscribe"}), sublabel: null, formatter: null, width: 90 },
        { id: 'id4', align: "right",   label: intl.formatMessage({id:"generic.domain.change"}), sublabel: null, formatter: null, width: 90 },
        { id: 'id5', align: "right",   label: intl.formatMessage({id:"generic.domain.dmf"}), sublabel: null, formatter: null, width: 90 },
        { id: 'id6', align: "right",   label: intl.formatMessage({id:"generic.domain.rental"}), sublabel: null, formatter: null, width: 90 },
    ];

    const convertRowData = item => {
        return {
            id1: item.saleChannel ? item.saleChannel.channel : "",
            id2: <InputNative name="charge" value={item.charge} formatter={commissionFormatter} onChange={props.onChange} style={{width:'90px', textAlign:'right'}} data-product={item.productId} data-channel={item.saleChannel.id}/>,
            id3: <InputNative name="unsubscribe" value={item.unsubscribe} formatter={commissionFormatter} onChange={props.onChange} style={{width:'90px', textAlign:'right'}} data-product={item.productId} data-channel={item.saleChannel.id}/>,
            id4: <InputNative name="change" value={item.change} formatter={commissionFormatter} onChange={props.onChange} style={{width:'90px', textAlign:'right'}} data-product={item.productId} data-channel={item.saleChannel.id}/>,
            id5: <InputNative name="dmf" value={item.dmf} formatter={commissionFormatter} onChange={props.onChange} style={{width:'90px', textAlign:'right'}} data-product={item.productId} data-channel={item.saleChannel.id}/>,
            id6: <InputNative name="rental" value={item.rental} formatter={commissionFormatter} onChange={props.onChange} style={{width:'90px', textAlign:'right'}} data-product={item.productId} data-channel={item.saleChannel.id}/>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    totalRows
                />
            </Grid>
            <Grid item xs={12}>
                <br/>
                <br/>
                <RightBox>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        style={{marginLeft: '10px'}}
                        onClick={props.onClick}
                    >
                        {intl.formatMessage({id: "generic.save"})}
                    </Button>
                </RightBox>
            </Grid>
        </Grid>
    )
};

export default ProductComissionTable;
