import React from 'react';

import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import MuiModal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';

import CloseIcon from '../icons/Close';


const Modal = (props) => {

    const height = props.height || 700;
    const width = props.width || 800;
    return (
        <MuiModal open={props.open} style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
            <Fade in={props.open}>
                <Paper style={{height: height, width: width, padding: '0px 15px', overflowY: 'hidden'}}>
                    <Box display="flex" paddingLeft="20px">
                        <Box component="h3" flexGrow={1} style={{marginBottom: 0}}>{props.title}</Box>
                        <Box><CloseIcon button onClick={props.onClose}/></Box>
                    </Box>
                    <Box style={{overflowY: 'auto', height: height - 60}}>
                        <Box style={{overflow: 'hidden', width: '100%', padding: '0px 20px'}}>
                            {props.children}
                        </Box>
                    </Box>
                </Paper>
            </Fade>
        </MuiModal>
    )
};

export default Modal;
