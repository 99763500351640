import * as saleChannelService from './saleChannelService';

//TODO: Hacer request GET de prepagos
export const getBonusTypes = () => {
    return [
        {id: 1, type: 'Pagado en oficina'},
        {id: 8, type: 'Pagado por transferencia'},
        {id: 2, type: 'PTB - onLine'},
        {id: 10, type: 'Pagado a Repsol'},
        {id: 9, type: 'Bonos'},
        {id: 7, type: 'Descuento UD110'},
        {id: 6, type: 'Descuento I350'},
        {id: 11, type: 'DMF'},
        //{id: 3, type: 'Butano24'},
        //{id: 5, type: 'Cargas sin suplementos'},
        {id: 4, type: 'Vales del ejército'},
    ]
}

export const getBonusTypesItems = () => {
    const bonusTypes = getBonusTypes();
    return bonusTypes.getSelectItems('type');
}

export const getBonusTypeById = (id) => {
    const bonusTypes = getBonusTypes();
    return bonusTypes.getObjectById(id);
}

//TODO: Cambiar cuando esté el GET de prepagos
export const referenceNumberRequired = (bonusTypeId) => {
    return [2, 4, 9, 10, 11].filter(id => id === bonusTypeId).length === 1;
}

export const isDiscount = (bonusTypeId) => {
    return [6, 7].filter(id => id === bonusTypeId).length === 1;
}

export const getProductIdFromDiscount = (bonusTypeId, products = []) => {
    const discountBonus = getBonusTypeById(bonusTypeId);
    const discountProductName = discountBonus.type.replace('Descuento ', '');
    const filteredProducts = products.filter(product => product.name.includes(discountProductName));
    return filteredProducts.length > 0 ? filteredProducts[0].id : null;
}

//TODO: Cambiar cuando esté el GET de prepagos
export const getSaleChannel = (bonusTypeId, saleChannels) => {
    let saleChannel;
    switch (bonusTypeId) {
        case 1:
        case 8:
        case 9:
            saleChannel = saleChannels.getObjectByKeyValue('code', saleChannelService.SALE_CHANNEL_PEDIDOS_OFICINA_PREPAGADO);
            break;
        case 2:
            saleChannel = saleChannels.getObjectByKeyValue('code', saleChannelService.SALE_CHANNEL_PTB);
            break;
        case 3:
            saleChannel = saleChannels.getObjectByKeyValue('code', saleChannelService.SALE_CHANNEL_B24);
            break;
        case 4:
        case 10:
        case 11:
            saleChannel = saleChannels.getObjectByKeyValue('code', saleChannelService.SALE_CHANNEL_FACTURABLE_POR_REPSOL);
            break;
        default:
            saleChannel = null
    }
    return saleChannel;
}
