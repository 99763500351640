import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import Switch from '../../../../components/form/Switch';
import { RightBox } from '../../../../components/Box';

import * as employeeService from '../../../../services/domain/warehouse/employeeService';


const EmployeeForm = (props) => {
    const {employee, companies, errors} = props;

    const intl = useIntl();

    //TODO: hidratar
    const employeeTypeItems = [
        {value: 1, label: "Repartidor"},
        {value: 2, label: "Ayudante"},
    ];
    const contractTypeItems = [
        {value: 1, label: "Empleado propio"},
        {value: 2, label: "Empleado ajeno"},
        {value: 3, label: "Autónomo"},
    ];

    const [companyItems, setCompanyItems] = useState([]);

    useEffect(() => {
        setCompanyItems(
            companies.map(company => {
                return {value: company.id, label: company.name}
            })
        );

    }, [companies]);

    const renderDocuments = () => {
        if (typeof employee.documents === "undefined") return (<div></div>);

        return (
            <ul>
                {
                    employee.documents.map(doc => {
                        return (<li>{doc.description}</li>)
                    })
                }
            </ul>
        )
    };
    const renderHelpers = () => {
        return(
            <ul>
                {
                    employee.helpers.map(helper => {
                        return (<li>{employeeService.getFullName(helper)}</li>)
                    })
                }
            </ul>
        )
    };
    const renderDriver = () => {
        if (employee.driver === null) return null;
        return (<ul><li>{employeeService.getFullName(employee.driver)}</li></ul>)
    };

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <img width="200px" height="120px" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTIVnYRTc8Gr1qKXFK4HHjKwpCWoijGYifC7lMakbX9X4egNaeI&usqp=CAU"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="code"
                        label={intl.formatMessage({id: 'generic.domain.code'})}
                        value={employee.code}
                        error={errors.code}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        name="employeeType"
                        label={intl.formatMessage({id: 'app.employees.employeeType'})}
                        items={employeeTypeItems}
                        value={employee.employeeTypeId ? employee.employeeTypeId : employee.employeeType.id}
                        error={errors.employeeTypeId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="name"
                        label={intl.formatMessage({id: 'app.employees.name'})}
                        value={employee.name}
                        error={errors.name}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="surname"
                        label={intl.formatMessage({id: 'app.employees.surname'})}
                        value={employee.surname}
                        error={errors.surname}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="nif"
                        label={intl.formatMessage({id: 'app.employees.nif'})}
                        value={employee.nif}
                        error={errors.nif}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        type="date"
                        name="nifExpiration"
                        label={intl.formatMessage({id: 'app.employees.nifExpiration'})}
                        value={employee.nifExpiration}
                        error={errors.nifExpiration}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        type="date"
                        name="birthDate"
                        label={intl.formatMessage({id: 'app.employees.birthDate'})}
                        value={employee.birthDate}
                        error={errors.birthDate}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="mobile"
                        label={intl.formatMessage({id: 'app.employees.mobile'})}
                        value={employee.mobile}
                        error={errors.mobile}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="email"
                        type="email"
                        label={intl.formatMessage({id: 'app.employees.email'})}
                        value={employee.email}
                        error={errors.email}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "app.employees.address"})}/>
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="address"
                        label={intl.formatMessage({id: 'app.employees.address'})}
                        value={employee.address}
                        error={errors.address}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Input
                        name="zipCode"
                        label={intl.formatMessage({id: 'app.employees.zipCode'})}
                        value={employee.zipCode}
                        error={errors.zipCode}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Input
                        name="town"
                        label={intl.formatMessage({id: 'app.employees.town'})}
                        value={employee.town}
                        error={errors.town}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Input
                        name="province"
                        label={intl.formatMessage({id: 'app.employees.province'})}
                        value={employee.province}
                        error={errors.province}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "app.employees.documentation"})}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        type="date"
                        name="adrExpiration"
                        label={intl.formatMessage({id: 'app.employees.adrExpiration'})}
                        value={employee.adrExpiration}
                        error={errors.adrExpiration}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="driverLicenseNumber"
                        label={intl.formatMessage({id: 'app.employees.driverLicenseNumber'})}
                        value={employee.driverLicenseNumber}
                        error={errors.driverLicenseNumber}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        type="date"
                        name="driverLicenseExpiration"
                        label={intl.formatMessage({id: 'app.employees.driverLicenseExpiration'})}
                        value={employee.driverLicenseExpiration}
                        error={errors.driverLicenseExpiration}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="driverLicenseType"
                        label={intl.formatMessage({id: 'app.employees.driverLicenseType'})}
                        value={employee.driverLicenseType}
                        error={errors.driverLicenseType}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        name="companyId"
                        label={intl.formatMessage({id: 'app.companies.company'})}
                        items={companyItems}
                        value={employee.companyId ? employee.companyId : employee.company ? employee.company.id : ""}
                        error={errors.companyId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        name="contractType"
                        label={intl.formatMessage({id: 'app.employees.contractType'})}
                        items={contractTypeItems}
                        value={employee.contractTypeId ? employee.contractTypeId : employee.contractType.id}
                        error={errors.contractTypeId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="ss"
                        label={intl.formatMessage({id: 'app.employees.ss'})}
                        value={employee.ss}
                        error={errors.ss}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="irpf"
                        label={intl.formatMessage({id: 'app.employees.irpf'})}
                        value={employee.irpf}
                        error={errors.irpf}
                        onChange={props.onChange}
                        required
                        disabled={employee.contractTypeId ? employee.contractTypeId !==3 : employee.contractType.id !== 3}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        type="date"
                        name="contractDate"
                        label={intl.formatMessage({id: 'app.employees.contractDate'})}
                        value={employee.contractDate}
                        error={errors.contractDate}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        type="date"
                        name="contractCancellationDate"
                        label={intl.formatMessage({id: 'app.employees.contractCancellationDate'})}
                        value={employee.contractCancellationDate}
                        error={errors.contractCancellationDate}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        type="date"
                        name="cap"
                        label="CAP"
                        value={employee.cap}
                        error={errors.cap}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "app.employees.incidenceBalance"})}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="incidenceBalanceGas"
                        label={intl.formatMessage({id: 'app.employees.incidenceBalanceGas'})}
                        value={employee.incidenceBalanceGas}
                        error={errors.incidenceBalanceGas}
                        onChange={props.onChange}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="incidenceBalanceLimit"
                        label={intl.formatMessage({id: 'app.employees.incidenceBalanceLimit'})}
                        value={employee.incidenceBalanceLimit}
                        error={errors.incidenceBalanceLimit}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.documents"})}/>
                </Grid>
                <Grid item xs={12}>
                    {renderDocuments()}
                </Grid>
                { employeeService.isDriver(employee) && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Divider label={intl.formatMessage({id: "app.employees.helpers"})}/>
                        </Grid>
                        <Grid item xs={12}>
                            {renderHelpers()}
                        </Grid>
                    </React.Fragment>
                )}
                { employeeService.isHelper(employee) && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Divider label={intl.formatMessage({id: "app.employees.driver"})}/>
                        </Grid>
                        <Grid item xs={12}>
                            {renderDriver()}
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "app.system"})}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="username"
                        label={intl.formatMessage({id: 'app.employees.username'})}
                        value={employee.username}
                        error={errors.username}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Switch
                        name="enabled"
                        label={intl.formatMessage({id: 'generic.enabled'})}
                        checked={employee.enabled === 1}
                        onChange={props.onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit(employee)}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
};

export default EmployeeForm;
