import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';
import * as pointOfSaleService from "../../../../services/domain/warehouse/pointOfSaleService";
import * as productService from "../../../../services/domain/warehouse/productService";
import * as validateService from "../../../../services/validateService";

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from '../../../../components/icons/Enabled';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Table from '../../../../components/table';
import { RightBox } from '../../../../components/Box/index';
import {fullAddressFormatter} from '../../../../helpers/formatter';
import Modal from '../../../../components/Modal/Modal';
import {getValueFromField} from '../../../../helpers/form';

import PointOfSale from './PointOfSale';



export const PointsOfSellList = (props) => {

    const intl = useIntl();

    const newPos = {
        id: null,
        name: null,
        alias: null,
        nif: null,
        address: null,
        town: null,
        province: null,
        zipCode: null,
        tf: null,
        email: null,
        pointOfSaleTypeId: null,
        repsol: 0, //TODO: treure
        enabled: 1,
        accountingAccountDebtor: null,
        accountingAccountCreditor: null
    };
    const validations = {
        nif: [{type: validateService.NIF_FORMAT}],
        name: [{type: validateService.REQUIRED}],
        address: [{type: validateService.REQUIRED}],
        town: [{type: validateService.REQUIRED}],
        province: [{type: validateService.REQUIRED}],
        zipCode: [{type: validateService.REQUIRED}],
        email: [{type: validateService.EMAIL_FORMAT}],
        pointOfSaleTypeId: [{type: validateService.REQUIRED}]
    };

    const commissionValidations = {
        productId: [{type: validateService.REQUIRED}],
        commission: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}]
    }

    const [open, setOpen] = useState(false);
    const [pos, setPos] = useState([]);
    const [posTypes, setPosTypes] = useState([]);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [commissions, setCommissions] = useState([]);
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        async function loadPOS() {
            setLoading(true);
            const response = await pointOfSaleService.getPointOfSale();
            const responseP = await productService.getProducts();
            if (response.status === 200 && responseP.status === 200) {
                setData(response.data.pointsOfSale.map(item => convertRowData(item)));
                setPosTypes(pointOfSaleService.getPointOfSaleTypeItems());
                setProducts(responseP.data.products);
            } else {}
            setLoading(false);
        }
        loadPOS();
    }, [reload]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.pos'})}
    ];

    const handleClose = () => {
        setOpen(false);
    };
    const handleNew = () => {
        setPos(newPos);
        setCommissions(
            products.map(product => {
                return {id: null, productId: product.id, product: product, commission: 0}
            })
        );
        setOpen(true);
    };
    const handleEdit = (item) => {
        setPos({
            ...item,
            pointOfSaleTypeId: item.pointOfSaleType ? item.pointOfSaleType.id : null
        });
        setCommissions(
            item.productCommissions.map(com => {
                return {id: com.id, productId: com.product.id, product: com.product, commission: com.commissionU}
            })
        );
        setOpen(true);
    };
    const handleChange = (e) => {
        setPos({
            ...pos,
            [e.target.name]: getValueFromField(e)
        })
    };
    const handleSubmit = () => {
        const err = validateService.validate(pos, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            let response;
            if (pos.id === null) {
                response = pointOfSaleService.addPointOfSale(pos);
            } else {
                response = pointOfSaleService.updatePointOfSale(pos.id, pos);
            }
            response
                .then(() => {
                    setReload(reload + 1);
                    setOpen(false);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {props.errorMessage('Error');console.error(err.response)});
        }
    };

    const handleChangeCommissions = (e) => {
        const productId = parseInt(e.target.dataset.product);
        const value = e.target.value.replace("€", "").replace(",", "").trim();

        const commissionsModified =
            commissions.map(commission => {
                if (commission.productId === productId) {
                    return {
                        ...commission,
                        [e.target.name]: value
                    }
                } else return commission;
            });
        setCommissions(commissionsModified);
    }

    const handleSubmitCommissions = () => {
        const err = validateService.validateArray(commissions, commissionValidations);
        if (!validateService.hasErrors(err)) {
            const response = pointOfSaleService.updateCommissions(pos.id, {productCommissions: commissions});
            response
                .then(() => {
                    setReload(reload + 1);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {props.errorMessage('Error');console.error(err.response)});
        }
    }

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"app.pos.name"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"app.pos.address"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"app.pos.tf"}), sublabel: null, formatter: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null },
        { id: 'id5', align: "center",   label: intl.formatMessage({id:"generic.enabled"}), sublabel: null, formatter: null, width: 80 },
        { id: 'actions', align: "center", width: 80 },
    ];

    const convertRowData = item => {
    const alias = item.alias ? " (" + item.alias + ")" : "";
        return {
            id1: item.name + alias,
            id2: fullAddressFormatter(item.address, item.zipCode, item.town, item.province),
            id3: item.tf,
            id4: item.pointOfSaleType ? item.pointOfSaleType.type : "",
            id5: <EnabledIcon value={item.enabled} />,
            actions: <EditIcon button onClick={() => handleEdit(item)}/>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal open={open} title={intl.formatMessage({id: "app.pos.pos"})} onClose={handleClose}>
                    <PointOfSale
                        pos={pos}
                        posTypes={posTypes}
                        commissions={commissions}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}

                        onChangeCommissions={handleChangeCommissions}
                        onSubmitCommissions={handleSubmitCommissions}
                    />

                </Modal>
            </Grid>
        </Grid>
    )
};

export default withMessageBox(PointsOfSellList);
