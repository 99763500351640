import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import PrintButton from '../../../../components/form/Button/print';
import Select from '../../../../components/form/Select/index';



export const WarehouseStockCountForm = (props) => {
    const {stockCount, warehouses, errors} = props;

    const intl = useIntl();

    const [warehouseItems, setWarehouseItems] = useState([]);

    useEffect(() => {
        setWarehouseItems(
            warehouses.map(warehouse => {
                return {value: warehouse.id, label: warehouse.name};
            })
        );
    }, [warehouses]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Select
                        name="warehouseId"
                        label={intl.formatMessage({id: 'generic.domain.warehouse'})}
                        items={warehouseItems}
                        value={stockCount.warehouseId}
                        error={errors.warehouseId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={9}>
                    <Input
                        type="date"
                        name="date"
                        label={intl.formatMessage({id: 'generic.date'})}
                        value={stockCount.date}
                        error={errors.date}
                        onChange={props.onChange}
                        required
                    />
                    <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px', marginTop: '8px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>
                        {intl.formatMessage({id: "generic.search"})}
                    </Button>
                    <PrintButton style={{marginLeft: '10px', marginTop: '8px'}} onClick={props.onDownload} />
                </Grid>
            </Grid>
        </form>
    )
}

export default WarehouseStockCountForm;
