import { combineRoutes } from './services/routerService';

import coreRoutes from './features/core/routes';
import commonRoutes from './features/common/routes';
import ordersRoutes from './features/orders/routes';
import streetMapRoutes from './features/streetMap/routes';
import warehouseRoutes from './features/warehouse/routes';

const routes = combineRoutes([
    ordersRoutes,
    warehouseRoutes,
    streetMapRoutes,
    commonRoutes,
    coreRoutes,
]);

export default routes;
