import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import withMessageBox from '../../../../hocs/withMessageBox';

import * as formatter from "../../../../helpers/formatter";
import * as validator from "../../../../helpers/validator";
import * as statsService from '../../../../services/domain/warehouse/statsService';
import * as warehouseService from '../../../../services/domain/warehouse/warehouseService';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import Filter from "../../../../components/Filter/Filter";
import Table from '../../../../components/table';
import {getValueFromField} from "../../../../helpers/form";
import {TEXT_COLOR} from "../../../../assets/themes/theme";

import PurchaseStatsFilterForm from "./PurchaseStatsFilterForm";



export const PurchaseStats = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.stats'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.stats.purchases'})}
    ];

    const newHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.warehouse"}), sublabel: null, formatter: null },
    ];
    const newFilter = {
        warehouseId: null,
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter()
    }

    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState(newHeaders);
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const [warehouses, setWarehouses] = useState([]);


    useEffect(() => {
        async function load() {
            setIsLoading(true);
            const response = await warehouseService.listWarehouses();
            if (response.status === 200) {
                setWarehouses(response.data.warehouses);
            }
            setIsLoading(false);
        }
        load();
    }, []);

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        const response = statsService.purchases(filter);
        response
            .then((res) => {
                const generatedHeaders = generateHeaders(res.data.purchases[0]);
                const generatedData = res.data.purchases.map(item => convertRowData(item, generatedHeaders));

                setHeaders(generatedHeaders);
                setData(generatedData);

                const warehouseName = filter.warehouseId === null ? "" : " " + warehouses.getObjectById(filter.warehouseId).name;
                const dates = validator.isSameDate(filter.startDate, filter.endDate) ?
                    formatter.dateFormatter(filter.startDate) :
                    formatter.dateFormatter(filter.startDate) + " - " + formatter.dateFormatter(filter.endDate);
                setTitle(intl.formatMessage({id: 'generic.domain.purchase.plural'}) + warehouseName + " " + dates);
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)})
            .finally(() => setIsLoading(false));
    }
    const generateHeaders = item => {
        let index = 1;
        const warehouseHeader = [{ id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.warehouse"}), sublabel: null, formatter: null }];
        const productHeaders = item.products.map(item => {
            index ++;
            return { id: 'id' + (index), align: "center",   label: item.product.name, sublabel: null, formatter: cellFormatter };
        });
        index ++;
        return warehouseHeader
            .concat(productHeaders);
    }
    const getHeaderId = (headers, label, subLabel = null) => {
        for (let i = 0; i < headers.length; i++) {
            if (label === headers[i].label && subLabel === headers[i].sublabel) return headers[i].id;
        }
        return "undefined";
    }
    const convertRowData = (item, generatedHeaders) => {
        const row = {id1: item.warehouse ? item.warehouse.name : ""};
        let id;

        item.products.forEach(product => {
            id = getHeaderId(generatedHeaders, product.product.name);
            row[id] = product.charges;
        });

        return row;
    }
    const cellFormatter = (value) => {
        const color = value > 0 ? TEXT_COLOR : 'silver';
        return <Box color={color}>{value}</Box>;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Filter onSearch={handleSearch} onClear={handleFilterClear}>
                    <PurchaseStatsFilterForm
                        filter={filter}
                        warehouses={warehouses}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Table
                    title={title}
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(PurchaseStats);
