import * as apiService from '../../apiService';
import {dateApiFormatter} from "../../../helpers/formatter";


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const getProceeding = async (params) => {
    return await apiService.get(`${URL}/proceeding`, {date: dateApiFormatter(params.date), warehouseId: params.warehouseId});
};

export const getCalculateProceeding = async (params) => {
    return await apiService.get(`${URL}/proceeding/calculate`, {date: dateApiFormatter(params.date), warehouseId : params.warehouseId});
}

export const getAgenciesStatus = async(params) => {
    return await apiService.get(`${URL}/proceeding/agencies-status`);
}

export const close = async (data) => {
    return await apiService.post(`${URL}/proceeding/closure`, data);
};

export const downloadVerification = (data) => {
    const fileName = "Comprobacion General " + data.date;
    apiService.downloadFile(`${URL}/proceeding/verification`, data, fileName);
}
