import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as deliveryNoteService from '../../../../services/domain/warehouse/deliveryNoteService';
import * as pointOfSaleService from '../../../../services/domain/warehouse/pointOfSaleService';
import * as formatter from "../../../../helpers/formatter";
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import EditIcon from "../../../../components/icons/Edit";
import EnabledIcon from "../../../../components/icons/Enabled";
import Filter from '../../../../components/Filter/Filter';
import Table from '../../../../components/table';

import DeliveryNotePosFilterForm from './DeliveryNotePosFilterForm';




export const DeliveryNotePosList = (props) => {

    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.deliveryNotesPos'})}
    ];

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.deliveryNote"}), width: 150 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateFormatter, width: 120 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.authorization"}), width: 150 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.pos"}) },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.paid"}), formatter: (val) => <EnabledIcon value={val}/>, width: 100 },
        { id: 'id6', align: "left",   label: intl.formatMessage({id:"generic.domain.invoiced"}), formatter: (val) => <EnabledIcon value={val}/>, width: 100 },
        { id: 'id7', align: "left",   label: intl.formatMessage({id:"generic.canceled.male"}), formatter: (val) => <EnabledIcon value={val}/>, width: 100 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const newFilter = {
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter(),
        invoiced: false
    }

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(false);
    const [pos, setPos] = useState([]);

    useEffect(() => {
        async function load() {
            setIsLoading(true);
            const responseP = await pointOfSaleService.getPointOfSale();

            if (responseP.status === 200) {
                setPos(
                    responseP.data.pointsOfSale.filter(item => item.enabled === 1).sortBy('name')
                );
                setIsLoading(false);
            }
        }
        load();
    }, []);

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        const response = deliveryNoteService.list(filter)
        response
            .then(res => {
                setData(
                    res.data.deliveryNotes.map(item => convertRowData(item))
                );
            })
            .catch(err => {})
            .finally(() => setIsLoading(false));
    }

    const convertRowData = item => {
        return {
            id1: item.number,
            id2: item.date,
            id3: item.authorization.number,
            id4: pointOfSaleService.getNameWithAlias(item.pointOfSale),
            id5: item.paid,
            id6: item.invoiced,
            id7: item.canceled,
            actions: <RouterLink to={{pathname: `delivery-notes/${item.number}`, state: {deliveryNote: item}}}><EditIcon button /></RouterLink>
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={isLoading}>
                    <DeliveryNotePosFilterForm
                        filter={filter}
                        pos={pos}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(DeliveryNotePosList);