import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";



const DeliveryConfirmationFilterForm = (props) => {
    const {filter} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box display="flex" width="100%">
                        <Box marginRight={1}>
                            <Input
                                name="authorizationNumber"
                                label={intl.formatMessage({id: 'generic.domain.authorizationNumber'})}
                                value={filter.authorizationNumber}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                name="referenceNumber"
                                label={intl.formatMessage({id: 'generic.domain.reference'})}
                                value={filter.referenceNumber}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="startDate"
                                label={intl.formatMessage({id: 'generic.date.init'})}
                                value={filter.startDate}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="endDate"
                                label={intl.formatMessage({id: 'generic.date.end'})}
                                value={filter.endDate}
                                onChange={props.onChange}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

export default DeliveryConfirmationFilterForm;