import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {isTrue} from "../../../../helpers/validator";

import Grid from '@material-ui/core/Grid';

import Checkbox from "../../../../components/form/Checkbox";
import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";
import Button from "../../../../components/form/Button";
import {RightBox} from "../../../../components/Box";



export const StreetStretchForm = (props) => {
    const {stretch, parity = [], itineraries, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        name="parityId"
                        label={intl.formatMessage({id: 'generic.domain.parity'})}
                        items={parity.getSelectItems("value")}
                        value={stretch.parityId}
                        error={errors.parityId}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        name="allStreet"
                        label={intl.formatMessage({id: 'generic.domain.allStreet'})}
                        value={isTrue(stretch.allStreet)}
                        onChange={props.onChange}
                        disabled={stretch.parityId === null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="initialNumber"
                        label={intl.formatMessage({id: 'generic.domain.numI'})}
                        value={stretch.initialNumber}
                        error={errors.initialNumber}
                        onChange={props.onChange}
                        disabled={stretch.parityId === null}
                        helperText={intl.formatMessage({id: 'generic.domain.valueBetween'}, {min: 0, max: 9999})}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="finalNumber"
                        label={intl.formatMessage({id: 'generic.domain.numF'})}
                        value={stretch.finalNumber}
                        error={errors.finalNumber}
                        onChange={props.onChange}
                        disabled={stretch.parityId === null}
                        helperText={intl.formatMessage({id: 'generic.domain.valueBetween'}, {min: 0, max: 9999})}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        name="itineraryId"
                        label={intl.formatMessage({id: 'generic.domain.itinerary'})}
                        items={itineraries.getSelectItems()}
                        value={stretch.itineraryId}
                        error={errors.itineraryId}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button type="button" color="secondary" variant="contained" onClick={(e) => {props.onCancel()}}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" onClick={(e) => {e.preventDefault(); props.onSubmit()}} style={{marginLeft: '10px'}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
}

export default StreetStretchForm;