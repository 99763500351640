import React, { useEffect, useState } from 'react';
import { Redirect, Link as RouterLink, use } from 'react-router-dom';
import { useIntl } from 'react-intl';

import MUIAppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';

import Select from '../../../../components/form/Select';

import * as authService from '../../../../services/authService';
import * as agencyService from '../../../../services/domain/common/agencyService';
import { useAppContext } from '../../context/AppContext';


const FONT_SIZE = '14px';


const AppBar = (props) => {
    const intl = useIntl();

    const {agencyId, setAgencyId, user, notifications, setNotifications } = useAppContext();

    const [agencyName, setAgencyName] = useState("");
    const [items, setItems] = useState([]);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        const allowedAgencies = authService.getAgencies();
        const items = allowedAgencies.map(agency => {
            return {value: agency, label: agency};
        });
        setItems(items);

    }, []);

    useEffect(() => {
        if (agencyId) {
            setAgencyName("");
            const agencies = agencyService.getLSAgencies();
            if (agencies.length === 0) {
                //TODO:  La info de agencias debería venir en el token
                loadAgencies();
            } else {
                const userAgency = agencies.getObjectById(agencyId);
                setAgencyName(userAgency !== null ? userAgency.name : "");
            }
        } else {
            setAgencyName(intl.formatMessage({id: 'app.appbar.selectAgency'}));
        }
    }, [agencyId]);

    const loadAgencies = async () => {
        await agencyService.getAgencies()
            .then(response => {
                agencyService.setLSAgencies(response.data.agencies);
                const userAgency = response.data.agencies.getObjectById(agencyId);
                setAgencyName(userAgency !== null ? userAgency.name : "");
            });
    }

    const handleLogout = () => {
        authService.logOut();
    };
    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setAgencyId(value !== "" ? value : undefined);
        if (value === "") setNotifications([]);
        setRedirect(true);
    };

    const handleClickNotifications = (e) => {
        if (!agencyId) e.preventDefault();
    }

    if (redirect && window.location.pathname !== '/') return <Redirect to="/" />

    return (
        <MUIAppBar style={{height: '50px'}} position="relative">
            <Box display="flex" style={{paddingLeft: props.paddingLeft, paddingRight: '20px', justifyContent: 'space-between'}}>
                <Box display="flex" style={{alignItems: 'center', gap: '10px'}}>
                    <Box style={{color:'#FFF', fontSize:FONT_SIZE}}>
                        {intl.formatMessage({id: 'app.menu.agency'})}
                    </Box>
                    <Box style={{marginTop: '8px'}}>
                        <Select
                            variant='standard'
                            items={items}
                            value={agencyId}
                            onChange={handleChange}
                            style={{color:'#FFF', fontSize:FONT_SIZE, fontWeight:'bold', width:'75px', minWidth: '75px !important', textAlign:'center'}}
                            MenuProps={{style: {fontSize:FONT_SIZE}}}
                            width={100}
                        />
                    </Box>
                    <Box style={{float:'left', color:'#FFF', fontSize:FONT_SIZE}}>
                        {agencyName}
                    </Box>
                </Box>
                <Box style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <Box style={{color:'#FFF'}}>
                        <RouterLink to={{pathname: "/notifications"}} onClick={handleClickNotifications} style={{color: '#FFF'}}>
                            <Badge badgeContent={notifications.length} color="error"><NotificationsIcon /></Badge>
                        </RouterLink>
                    </Box>
                    <Box style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                        <Box style={{color:'#FFF'}}><PersonIcon /></Box>
                        <Box style={{color:'#FFF', fontSize:FONT_SIZE}}>{user.name}</Box>
                    </Box>
                    <ButtonBase onClick={handleLogout} style={{gap: '5px'}}>
                        <Box style={{color: '#FFF'}}><ExitToAppIcon /></Box>
                        <Box style={{color:'#FFF', fontSize:FONT_SIZE}}>{intl.formatMessage({id: 'app.logout'})}</Box>
                    </ButtonBase>
                </Box>
            </Box>
        </MUIAppBar>
    )
};

export default AppBar;
