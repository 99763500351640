import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';


const ExportIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><SystemUpdateAltIcon /></IconButton>
        );
    }
    return (<SystemUpdateAltIcon />);
};

export default ExportIcon;