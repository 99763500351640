import moment from 'moment';

/**
 * Return full address from all fields
 * @param address
 * @param zipCode
 * @param town
 * @param province
 * @returns {string}
 */
export const fullAddressFormatter = (address = null, zipCode = "", town = "", province = "") => {
    province = province ? province.toUpperCase() : "";
    zipCode = zipCode !== null ? zipCode : "";

    return address ? `${address}, ${zipCode} ${town} (${province})` : "";
};

export const truncStringFormatter = (str = "", length = 10) => {
    if (typeof str !== 'string') return str;
    return str.length > length ? str.slice(0, length) + "..." : str;
}

export const numberFormatter = (value, decimals = 0) => {
    return Number(value).toFixed(decimals).toLocaleString('es-ES');
};

export const priceFormatter = (value, currency = false) => {
    let result;
    if (value === null || typeof value === "undefined")
        result = "0 €";
    else
        result = currency ? Number(value).toFixed(2) + " €" : Number(value).toFixed(2);

    return result;
};

export const commissionFormatter = (value, currency = false) => {
    return currency ? Number(value).toFixed(4) + " €" : Number(value).toFixed(4);
};

export const dateFormatter = (value) => {
    return value !== "" && value !== null ? moment(value).format('DD/MM/YYYY') : "";
};
export const dateTimeFormatter = (value) => {
    return value !== "" && value !== null ? moment(value).format('DD/MM/YYYY HH:mm') : "";
};
export const dateApiFormatter = (value) => {
    return moment(value).format('YYYY-MM-DD');
};
export const dateTimeApiFormatter = (value) => {
    return moment(value, 'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD HH:mm:ss');
};
export const today = () => {
    return dateFormatter(moment());
}
export const now = () => {
    return dateTimeFormatter(moment());
}
