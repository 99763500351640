import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppContext } from '../../../core/context/AppContext';
import * as notificationService from "../../../../services/domain/warehouse/notificationService";

import { Grid, List, ListItem } from '@material-ui/core';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Loader from '../../../../components/Loader/Loader';
import NotificationListItem from './NotificationListItem';


export const NotificationList = () => {

    const intl = useIntl();
    const { notifications, isNotificationsLoading, refreshNotifications } = useAppContext();

    const sections = [
        {label: intl.formatMessage({id: 'generic.domain.notifications'})},
    ];

    const newNotificationsData = {
        [notificationService.NOTIFICATION_SCOPE.vehicles]: {},
        [notificationService.NOTIFICATION_SCOPE.employees]: {},
    }

    const [notificationData, setNotificationDate] = useState(newNotificationsData);

    useEffect(() => {
        refreshNotifications();
    }, []);

    useEffect(() => {
        const dataFormatted = newNotificationsData;
        if (notifications.length > 0) {
            notifications.map((notification) => {
                if (dataFormatted[notification.scope][notification.key] === undefined) {
                    dataFormatted[notification.scope] = {
                        ...dataFormatted[notification.scope],
                        [notification.key]: [notification]
                    }
                } else {
                    dataFormatted[notification.scope][notification.key].push(notification);
                }
            })
            setNotificationDate(dataFormatted);
        }
    }, [notifications])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <List>
                    <h3>{intl.formatMessage({id: 'generic.domain.vehicles'})}</h3>
                    {isNotificationsLoading && <Loader />}
                    {!isNotificationsLoading &&
                        Object.keys(notificationData[notificationService.NOTIFICATION_SCOPE.vehicles]).map((key, index) => (
                            <ListItem key={index}>
                                <NotificationListItem
                                    notificationKey={key}
                                    notificationData={notificationData[notificationService.NOTIFICATION_SCOPE.vehicles][key]}
                                />
                            </ListItem>
                        ))
                    }

                    <h3>{intl.formatMessage({id: 'generic.domain.employees'})}</h3>
                    {isNotificationsLoading && <Loader />}
                    {
                        !isNotificationsLoading &&
                        Object.keys(notificationData[notificationService.NOTIFICATION_SCOPE.employees]).map((key, index) => (
                            <ListItem key={index}>
                                <NotificationListItem
                                    notificationKey={key}
                                    notificationData={notificationData[notificationService.NOTIFICATION_SCOPE.employees][key]}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Grid>
        </Grid>
    )
}