import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Switch from '../../../../components/form/Switch';
import { RightBox } from '../../../../components/Box';


export const SaleChannelForm = (props) => {
    const {saleChannel, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="code"
                        label={intl.formatMessage({id: 'generic.domain.code'})}
                        value={saleChannel.code}
                        error={errors.code}
                        onChange={props.onChange}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="channel"
                        label={intl.formatMessage({id: 'generic.domain.name'})}
                        value={saleChannel.channel}
                        error={errors.channel}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Switch
                        name="applyGenericSupplements"
                        label={intl.formatMessage({id: 'generic.domain.price.supplement'})}
                        checked={saleChannel.applyGenericSupplements === 1}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Switch
                        name="enabled"
                        label={intl.formatMessage({id: 'generic.enabled'})}
                        checked={saleChannel.enabled === 1}
                        onChange={props.onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault();props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
};

export default SaleChannelForm;