import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import { RightBox } from '../../../../components/Box';



export const BankIncomeForm = (props) => {
    const {income, errors} = props;

    const intl = useIntl();

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Input
                    name="amount"
                    label={intl.formatMessage({id: "generic.domain.amount"})}
                    value={income.amount}
                    error={errors.amount}
                    onChange={props.onChange}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="office"
                    label={intl.formatMessage({id: "generic.domain.office"})}
                    value={income.office}
                    error={errors.office}
                    onChange={props.onChange}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <Input
                    type="date"
                    name="date"
                    label={intl.formatMessage({id: 'generic.date'})}
                    value={income.date}
                    error={errors.date}
                    onChange={props.onChange}
                    required
                />
            </Grid>

            <Grid item xs={12}>
                <RightBox>
                    <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                    <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                </RightBox>
            </Grid>
        </Grid>
    );
}

export default BankIncomeForm;
