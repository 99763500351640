import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import { RightBox } from '../../../../components/Box';
import Select from "../../../../components/form/Select";


export const SupplementForm = (props) => {
    const {supplement, errors, bonusTypes, products = []} = props;

    const intl = useIntl();

    return (
        <form>
            <Paper>
            <div style={{padding: '10px', backgroundColor: '#FAFAFA'}}>
                {intl.formatMessage({id: 'generic.domain.charges.withoutSupp'})}
                <Grid container spacing={1} p={2}>
                    <Grid item xs={3}>
                        <Select
                            name="productId"
                            label={intl.formatMessage({id: 'generic.domain.product'})}
                            items={products.getSelectItems()}
                            value={supplement.productId}
                            error={errors.productId}
                            onChange={props.onChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Input
                            name="charges"
                            label={intl.formatMessage({id: "generic.domain.charges"})}
                            value={supplement.charges}
                            error={errors.charges}
                            onChange={props.onChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            name="description"
                            label={intl.formatMessage({id: "generic.domain.desc"})}
                            value={supplement.description}
                            error={errors.description}
                            onChange={props.onChange}
                            required
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <RightBox>
                            <Button type="button" color="primary" variant="contained" style={{margin: '8px 0 0 10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                        </RightBox>
                    </Grid>
                </Grid>
            </div>
            </Paper>
        </form>
    )
}

export default SupplementForm;