import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const listPurchaseOrders = async (filter) => {
    return await apiService.get(`${URL}/purchase-orders`, filter);
};

export const addPurchaseOrder = async (data) => {
    return await apiService.post(`${URL}/purchase-orders`, data);
};

export const cancelPurchaseOrder = async (data) => {
    return await apiService.patch(`${URL}/purchase-orders/${data.id}/cancel`, {modifiedBy: 'tmp'});
};

export const downloadPurchaseOrders = (data) => {
    const fileName = "Compras " + data.number;
    apiService.downloadFile(`${URL}/purchase-orders/${data.id}/download`, {}, fileName);
};

export const listPurchaseOrdersInvoices = async (filter) => {
    return await apiService.get(`${URL}/purchase-orders/invoices`, filter);
};

export const downloadPurchaseOrdersInvoice = (data) => {
    const fileName = "Proforma compras " + data.number;
    apiService.downloadFile(`${URL}/purchase-orders/invoices/${data.id}/download`, {}, fileName);
};
