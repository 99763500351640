import React from 'react';

import MUIChip from '@material-ui/core/Chip';


const Chip = (props) => {
    return (
        <MUIChip {...props} />
    )
};

export default Chip;
