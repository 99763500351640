import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as deliveryNoteService from '../../../../services/domain/warehouse/deliveryNoteService';
import * as pointOfSaleService from '../../../../services/domain/warehouse/pointOfSaleService';
import * as validateService from "../../../../services/validateService";
import * as validator from "../../../../helpers/validator";
import {getValueFromField} from "../../../../helpers/form";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Button from '../../../../components/form/Button/index';
import Loader from "../../../../components/Loader/Loader";
import PrintButton from "../../../../components/form/Button/print";
import Table from '../../../../components/table';
import {RightBox} from "../../../../components/Box";

import DeliveryNotePosForm from "./DeliveryNotePosForm";



export const DeliveryNotePos = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.deliveryNotesPos'})}
    ];

    const emptyDeliveryNote = {
        id: null,
        number: null,
        posId: null,
        authorization: {id: null, number: null, vehicle: {id: null, plate: null}, driver: {id: null, name: null}},
        date: null,
        paid: false,
        invoiced: false,
        products: [],
        agencyId: null
    }

    const deliveryNoteValidations = {
        date: [{type: validateService.REQUIRED}],
        number: [{type: validateService.REQUIRED}],
        posId: [{type: validateService.REQUIRED}],
    }

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.containers"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, formatter: null, width: 50 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const [data, setData] = useState([]);
    const [deliveryNote, setDeliveryNote] = useState(emptyDeliveryNote);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [pos, setPos] = useState([]);

    useEffect(() => {
        const number = props.match.params.number;

        async function load() {
            setLoading(true);
            const responsePos = await pointOfSaleService.getPointOfSale();
            if (responsePos.status === 200) {
                setPos(responsePos.data.pointsOfSale.filter(pos => pos.enabled === 1));
                if (
                    props.location.state &&
                    props.location.state.deliveryNote &&
                    props.location.state.deliveryNote.number === number
                ) {
                    setDeliveryNote({
                        ...props.location.state.deliveryNote,
                        posId: props.location.state.deliveryNote.pointOfSale.id
                    });
                    setData(
                        props.location.state.deliveryNote.products.map(product => convertRowData(product))
                    );
                }
                setLoading(false);
            }
        }
        load();
    }, []);

    const convertRowData = item => {
        return {
            id1: item.product.name,
            id2: item.productSituation ? item.productSituation.type : "",
            id3: item.containers,
            id4: item.charges
        }
    }

    const handleChange = (e) => {
        setDeliveryNote({
            ...deliveryNote,
            [e.target.name]: getValueFromField(e)
        })
    }

    const handleSubmit = () => {
        const err = validateService.validate(deliveryNote, deliveryNoteValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            setLoading(true);
            const response = deliveryNoteService.update(deliveryNote);
            response
                .then(() => {
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                    console.error(err.response);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const handleDownload = () => {
        authorizationService.downloadAuthDeliveryNote({
            id: deliveryNote.id,
            number: deliveryNote.authorization.number
        });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <DeliveryNotePosForm
                    deliveryNotePos={deliveryNote}
                    pos={pos}
                    errors={errors}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <Table
                    headers={headers}
                    data={data}
                />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12}>
                <br/>
                <RightBox>
                    <Box display="flex">
                        <Box padding={1}>
                            <RouterLink to={{pathname: `/delivery-notes`}}>
                                <Button color="primary" variant="contained" size="small">{intl.formatMessage({id: 'generic.list'})}</Button>
                            </RouterLink>
                        </Box>
                        <Box padding={1}>
                            <PrintButton
                                onClick={handleDownload}
                            />
                        </Box>
                        <Box padding={1}>
                            <Button color="primary" variant="contained" size="small" onClick={handleSubmit} disabled={validator.isTrue(deliveryNote.invoiced)}>{intl.formatMessage({id: 'generic.save'})}</Button>
                        </Box>
                    </Box>
                </RightBox>
            </Grid>
        </Grid>
    )
}

export default withMessageBox(DeliveryNotePos);