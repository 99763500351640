import React from 'react';
import { useIntl } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Switch from '../../../../components/form/Switch';
import { RightBox } from '../../../../components/Box';


export const WarehouseForm = (props) => {
    const {warehouse, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="nif"
                        label={intl.formatMessage({id: 'app.employees.nif'})}
                        value={warehouse.nif}
                        error={errors.nif}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="name"
                        label={intl.formatMessage({id: 'app.employees.name'})}
                        value={warehouse.name}
                        error={errors.name}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "app.employees.address"})}/>
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="address"
                        label={intl.formatMessage({id: 'app.employees.address'})}
                        value={warehouse.address}
                        error={errors.address}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Input
                        name="zipCode"
                        label={intl.formatMessage({id: 'app.employees.zipCode'})}
                        value={warehouse.zipCode}
                        error={errors.zipCode}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Input
                        name="town"
                        label={intl.formatMessage({id: 'app.employees.town'})}
                        value={warehouse.town}
                        error={errors.town}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Input
                        name="province"
                        label={intl.formatMessage({id: 'app.employees.province'})}
                        value={warehouse.province}
                        error={errors.province}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        name="tf"
                        label={intl.formatMessage({id: 'app.warehouses.tf'})}
                        value={warehouse.tf}
                        error={errors.tf}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Input
                        name="email"
                        type="email"
                        label={intl.formatMessage({id: 'app.warehouses.email'})}
                        value={warehouse.email}
                        error={errors.email}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Switch
                        name="enabled"
                        label={intl.formatMessage({id: 'generic.enabled'})}
                        checked={warehouse.enabled === 1 || warehouse.enabled === true}
                        onChange={props.onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit(warehouse)}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
};

export default WarehouseForm;
