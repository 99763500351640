import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {getNameWithAlias} from "../../../../services/domain/warehouse/pointOfSaleService";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Checkbox from '../../../../components/form/Checkbox';
import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";



const DeliveryNotePosFilterForm = (props) => {
    const {filter, pos = []} = props;

    const intl = useIntl();

    const [posItems, setPosItems] = useState([]);

    useEffect(() => {
        setPosItems(
            pos.map(pos => {
                return {value: pos.id, label: getNameWithAlias(pos)};
            })
        );
    }, [pos]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box display="flex" width="100%">
                        <Box marginRight={1}>
                            <Select
                                name="posId"
                                label={intl.formatMessage({id: 'generic.domain.pos'})}
                                items={posItems}
                                value={filter.posId}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="startDate"
                                label={intl.formatMessage({id: 'generic.date.init'})}
                                value={filter.startDate}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="endDate"
                                label={intl.formatMessage({id: 'generic.date.init'})}
                                value={filter.endDate}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Checkbox
                                name="invoiced"
                                label={intl.formatMessage({id: 'generic.domain.invoiced'})}
                                onChange={props.onChange}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

export default DeliveryNotePosFilterForm;