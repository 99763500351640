import * as apiService from '../../apiService';
import * as bonusTypeService from './bonusTypeService';
import * as cageService from './cageService';
import * as saleChannelService from './saleChannelService';
import * as productLiquidationSupplementService from './productLiquidationSupplementService';
import * as productSituationService from './productSituationService';
import * as formatter from "../../../helpers/formatter";
import * as validator from "../../../helpers/validator";
import * as i18nService from "../../i18nService";

const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

const STATUS_AUTH = "Autorizada";
const STATUS_LIQ = "Liquidada";
const STATUS_CANCELED = "Anulada";
const STATUS_CANCELLATION = "Anulación";


/** API */
export const getAuthorizationByNumber = async (number) => {
    const response = await getAuthorizations({number: number});
    return response.data.authorizations.length > 0 ?
        response.data.authorizations[0] : null;
}
export const getAuthorizations = async (filter = {}) => {
    return await apiService.get(`${URL}/authorizations`, filter);
}

export const getAuthorizationItems = (authorizations = [], limit = 10) => {
    return authorizations.map(authorization => { return {value: authorization.id, label: authorization.number} });
}

export const authorize = async (data) => {
    const authorization = updateInfoForAuthorize(data);
    return await apiService.post(`${URL}/authorizations`, preSaveAuthorizationFormatter(authorization));
}
const updateInfoForAuthorize = (authorization) => {
    deleteInfoForRequest(authorization);
    return authorization;
}
export const liquidate = async (data) => {
    const authorization = updateInfoForLiquidate(data);
    return await apiService.post(`${URL}/authorizations/${data.id}/liquidate`, preSaveAuthorizationFormatter(authorization));
}
const updateInfoForLiquidate = (authorization) => {
    authorization.products = authorization.products.map(item => {
        return {
            ...item,
            productId: item.product.id
        }
    });
    authorization.cages = authorization.cages.map(item => {
        return {
            ...item,
            cageId: item.cage.id
        }
    });
    authorization.customerManagement = authorization.customerManagement.map(item => {
        return {
            ...item,
            productId: item.product ? item.product.id : item.productId,
            productSituationId: item.situation ? item.situation.id : item.productSituationId
        }
    });
    authorization.deliveryNotesPos = authorization.deliveryNotesPos.map(item => {
        const products = item.products.map(itemP => {
            return {
                ...itemP,
                productId: itemP.product ? itemP.product.id : itemP.productId,
                productSituationId: itemP.situation ? itemP.situation.id : itemP.productSituationId
            }
        });

        return {
            ...item,
            posId: item.pos ? item.pos.id : item.posId,
            products: products
        }
    });

    authorization = {
        ...authorization,
        cashIncome: authorization.cashAmount,
        creditCardIncome: authorization.creditCardAmount
    };
    deleteInfoForRequest(authorization);
    return authorization;
}
const deleteInfoForRequest = (authorization) => {
    //delete authorization.supplements; TODO: Descomentar cuandlo la API haga el cálculo
}

export const cancel = async (data) => {
    return await apiService.post(`${URL}/authorizations/${data.id}/cancel`);
}

export const download = (data) => {
    const fileName = "Autorizacion " + data.number;
    apiService.downloadFile(`${URL}/authorizations/${data.id}/download`, {}, fileName);
}

export const downloadList = (data) => {
    const fileName = "Autorizaciones";
    apiService.downloadFile(`${URL}/authorizations/download`, data, fileName);
}

export const downloadAuthLoad = (data) => {
    const fileName = "Carta de porte - autorizacion " + data.number;
    apiService.downloadFile(`${URL}/authorizations/${data.id}/download-auth-load`, {}, fileName);
}
export const downloadAuthDeliveryNote = (data) => {
    const fileName = "Albaran - autorizacion " + data.number;
    apiService.downloadFile(`${URL}/authorizations/download-delivery-note/${data.id}`, {}, fileName);
}

/** domain */
export const isAuthorization = (authorization) => {
    return authorization ? authorization.number === null : true;
};
export const isLiquidation = (authorization) => {
    return !isAuthorization(authorization) && !isLiquidated(authorization);
};
export const isLiquidated = (authorization) => {
    return authorization ? authorization.liqDate !== null : false;
}
export const isCancellation = (authorization) => {
    return authorization ? authorization.cancellation === true : false;
}
export const isCanceled = (authorization) => {
    return authorization ? authorization.canceled === true : false;
}

export const getStatus = (authorization) => {
    if (authorization.cancellation === true) return STATUS_CANCELLATION;
    if (authorization.canceled === true) return STATUS_CANCELED;
    if (isLiquidated(authorization)) return STATUS_LIQ;
    else return STATUS_AUTH;
}


export const isReadyForAut = (authorization, errors = [], warnings = []) => {
    if (authorization.warehouseId === null)
        errors.push({id: "app.error.domain.REQUIRED_WAREHOUSE"});
    if (authorization.vehicleId === null)
        errors.push({id: "app.error.domain.REQUIRED_VEHICLE"});
    if (authorization.driverId === null)
        errors.push({id: "app.error.domain.REQUIRED_DRIVER"});

    if (authorization.products.length === 0)
        errors.push({id: "app.error.domain.REQUIRED_PRODUCTS"});
    if (authorization.cages.length === 0)
        errors.push({id: "app.error.domain.REQUIRED_CAGES"});

    let containers = 0;
    authorization.products.map(product => {
        if (product.chargesAuth > product.containersAuth)
            errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CHARGES_AUT"});

        containers = containers + parseInt(product.containersAuth);
    });

    let cmContainers = 0;
    authorization.customerManagement.map(cm => {
        const productSituationId = cm.situation ? cm.situation.id : cm.productSituationId;
        switch (productSituationId) {
            case productSituationService.PRODUCT_SITUATION_ALTA:
            case productSituationService.PRODUCT_SITUATION_DMF_ALTA:
                cmContainers = parseInt(cmContainers) - parseInt(cm.containers);
                break;
            case productSituationService.PRODUCT_SITUATION_BAJA:
            case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
                cmContainers = parseInt(cmContainers) + parseInt(cm.containers);
                break;
        }
    });

    let cages = 0;
    authorization.cages.map(cage => {
        cages = cages + parseInt(cage.unitsAuth);
    });

    const cagesForContainers = cageService.getCagesForContainers(containers + cmContainers);
    if (cages < cagesForContainers -1 || cages > cagesForContainers + 1)
        errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CAGES_AUT"});


    checkEnoughProductAuthorized(authorization);

    return errors.length === 0;
};
//TODO: acabar.
export const isReadyForLiq = (authorization, errors = [], warnings = []) => {
    errors.length = 0;
    warnings.length = 0;

    authorization.products.map(product => {
        // errors /
        const  containersToReturn = getContainersToReturn(authorization, product);
        if (parseInt(product.containersReturned) !== containersToReturn) {
            errors.push({
                id: "app.error.domain.INCORRECT_VALUE_OF_CONTAINERS_RETURNED",
                vars: {
                    product: product.product.name,
                    containers: containersToReturn
                }
            });
        }
        const maxChargesToReturn = getMaxChargesToReturn(authorization, product);
        if (parseInt(product.chargesReturned) > parseInt(maxChargesToReturn)) {
            errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CHARGES_RETURNED"});
        }

        const chargesInPrepayments = getChargesInPrepayments(authorization, product);
        const chargesFromUnsubscribes = getChargesFromUnsubscribes(authorization, product);
        const chargesSold = parseInt(product.chargesAuth) + parseInt(chargesFromUnsubscribes) - parseInt(product.chargesReturned) - parseInt(product.chargesToPos);

        if (chargesInPrepayments > chargesSold) {
            errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_PREPAYMENTS"});
        }
        // warnings /
    });

    authorization.cages.map(cage => {
        if (parseInt(cage.unitsReturned) !== parseInt(cage.unitsAuth)) {
            errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CAGES_RETURNED"});
        }
    });

    checkEnoughProductAuthorized(authorization);

    if (authorization.supplementsAmount < 0) {
        errors.push({id: "app.error.code.NEGATIVE", vars: {field: i18nService.getMessage('generic.domain.supplements')}});
    }

    return errors.length === 0;
};

const preSaveAuthorizationFormatter = (authorization) => {
    authorization.deliveryNotesPos.map(dn => {
        dn.date = formatter.dateApiFormatter(dn.date);
        dn.posId = dn.pos ? dn.pos.id : dn.posId;
        dn.products.map(dnProduct => {
            dnProduct.productId = dnProduct.product ? dnProduct.product.id : dnProduct.productId;
            dnProduct.productSituationId = dnProduct.productSituation ? dnProduct.productSituation.id : dnProduct.productSituationId;
        })
    });
    return authorization;
}

const getChargesFromUnsubscribes = (authorization, product) => {
    let charges = 0;
    const cms = authorization.customerManagement.filter(
        cm => cm.productId === product.productId && cm.done && productSituationService.isBajaSituationById(cm.productSituationId)
    );
    cms.map(cm => charges += parseInt(cm.charges));
    return charges;
}

const getContainersToReturn = (authorization, product) => {
    const productId = product.product ? product.product.id : product.productId;
    let containersToReturn = parseInt(product.containersAuth) - parseInt(product.containersLost);
    const customerManagementDone = authorization.customerManagement.filter(cm => {
        const cmProductId = cm.product ? cm.product.id : cm.productId;
        return validator.isTrue(cm.done)  && cmProductId === productId;
    });
    customerManagementDone.map(cm => {
            const productSituationId = cm.situation ? cm.situation.id : cm.productSituationId;
            switch (productSituationId) {
                case productSituationService.PRODUCT_SITUATION_ALTA:
                case productSituationService.PRODUCT_SITUATION_DMF_ALTA:
                    containersToReturn = parseInt(containersToReturn) - parseInt(cm.containers);
                    break;
                case productSituationService.PRODUCT_SITUATION_BAJA:
                case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
                    containersToReturn = parseInt(containersToReturn) + parseInt(cm.containers);
                    break;
            }
    });

    const deliveryNotePosDone = authorization.deliveryNotesPos.filter(dn => {
        //return validator.isTrue(dn.done);
        return true;
    });
    deliveryNotePosDone.map(dn => {
        dn.products.map(dnProduct => {
            const dnProductId = dnProduct.product ? dnProduct.product.id : dnProduct.productId;
            if (dnProductId === productId) {
                const productSituationId = dnProduct.situation ? dnProduct.situation.id : dnProduct.productSituationId;
                switch (productSituationId) {
                    case productSituationService.PRODUCT_SITUATION_ALTA:
                    case productSituationService.PRODUCT_SITUATION_DMF_ALTA:
                        containersToReturn = parseInt(containersToReturn) - parseInt(dnProduct.containers);
                        break;
                    case productSituationService.PRODUCT_SITUATION_BAJA:
                    case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
                        containersToReturn = parseInt(containersToReturn) + parseInt(dnProduct.containers);
                        break;
                }
            }
        });
    });

    return containersToReturn;
}
const getMaxChargesToReturn = (authorization, product) => {
    const productId = product.product ? product.product.id : product.productId;
    let chargesToReturn = parseInt(product.chargesAuth);
    const customerManagementDone = authorization.customerManagement.filter(cm => {
        const cmProductId = cm.product ? cm.product.id : cm.productId;
        return validator.isTrue(cm.done) && cmProductId === productId && !validator.isTrue(cm.inPos);
    });

    customerManagementDone.map(cm => {
        const productSituationId = cm.situation ? cm.situation.id : cm.productSituationId;
        switch (productSituationId) {
            case productSituationService.PRODUCT_SITUATION_ALTA:
            case productSituationService.PRODUCT_SITUATION_DMF_ALTA:
                chargesToReturn = parseInt(chargesToReturn) - parseInt(cm.charges);
                break;
            case productSituationService.PRODUCT_SITUATION_BAJA:
            case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
                chargesToReturn = parseInt(chargesToReturn) + parseInt(cm.charges);
                break;
        }
    });

    const deliveryNotePosDone = authorization.deliveryNotesPos.filter(dn => {
        //return validator.isTrue(dn.done);
        return true;
    });
    deliveryNotePosDone.map(dn => {
        dn.products.map(dnProduct => {
            const dnProductId = dnProduct.product ? dnProduct.product.id : dnProduct.productId;
            if (dnProductId === productId) {
                const productSituationId = dnProduct.situation ? dnProduct.situation.id : dnProduct.productSituationId;
                switch (productSituationId) {
                    case productSituationService.PRODUCT_SITUATION_ALTA:
                    case productSituationService.PRODUCT_SITUATION_DMF_ALTA:
                        chargesToReturn = parseInt(chargesToReturn) - parseInt(dnProduct.charges);
                        break;
                    case productSituationService.PRODUCT_SITUATION_BAJA:
                    case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
                        chargesToReturn = parseInt(chargesToReturn) + parseInt(dnProduct.charges);
                        break;
                    default:
                        chargesToReturn = parseInt(chargesToReturn) - parseInt(dnProduct.charges);
                }
            }
        });
    });
    return chargesToReturn;
}

/** Returns product charges in prepayments where prepayment is not discount */
const getChargesInPrepayments = (authorization, product) => {
    const productId = product.product ? product.product.id : product.productId;
    let chargesToPrepayments = 0;
    authorization.bonusIncomes.map(prepayment => {
        if (prepayment.productId === productId && !bonusTypeService.isDiscount(prepayment.bonusTypeId)) {
            chargesToPrepayments += prepayment.charges ?? 0;
        }
    });
    return chargesToPrepayments;
}


const checkEnoughProductAuthorized = (authorization) => {
 //TODO: mirar si para customer management y pos hay suficiente producto autorizado

    return true;
}
const getProductToAuthorize = (authorization, product) => {
    let containersToAuthorize = 0;
    let chargesToAuthorize = 0;
    const authProduct = authorization.products.filter(item => {
        return product.id === item.id;
    });

    return {
        containers: containersToAuthorize,
        charges: chargesToAuthorize
    }
}

/** Return true if there is product authorized */
export const hasProductInAuthorization = (authorization, productId) => {
    const authProducts = authorization.products.filter(item => {
        const itemProductId = item.product ? item.product.id : item.productId;
        return itemProductId === productId;
    });
    return authProducts.length > 0;
}
export const isReadyForAddProductInDeliveryNote = (authorization, deliveryNoteProduct, errors = []) => {
    const authProducts = authorization.products.filter(item => {
        const productId = item.product ? item.product.id : item.productId;
        return productId === deliveryNoteProduct.productId;
    });
    if (authProducts.length === 0) {
        errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_PRODUCT"});
        return false;
    }

    if (parseInt(deliveryNoteProduct.charges) > parseInt(deliveryNoteProduct.containers)) {
        errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CONTAINERS_VS_CHARGES"});
        return false;
    }

    const productSituationId = deliveryNoteProduct.situation ? deliveryNoteProduct.situation.id : deliveryNoteProduct.productSituationId;
    switch (productSituationId) {
        case productSituationService.PRODUCT_SITUATION_BAJA:
        case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
            break;
        default:
            if (parseInt(deliveryNoteProduct.charges) !== parseInt(deliveryNoteProduct.containers)) {
                errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CONTAINERS_EQUAL_CHARGES"});
                return false;
            }
    }

    //TODO: això no està del tot bé. S'ha de calcular tenint en compte la resta de albarans de benzineres i altes / baixes de clients
    const chargesAvailable = parseInt(authProducts[0].chargesAuth) - parseInt(authProducts[0].chargesToPos) - parseInt(deliveryNoteProduct.charges);
    if (chargesAvailable < 0) {
        errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_NUM_CHARGES_AUT"});
    }
    const containersAvailable = parseInt(authProducts[0].containersAuth) - parseInt(authProducts[0].containersToPos) - parseInt(deliveryNoteProduct.containers);
    if (containersAvailable < 0) {
        errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_NUM_CONTAINERS_AUT"});
    }

    return errors.length === 0;
}

/** Calculate all product containers and charges from all POS */
export const calculateDeliveryNoteProduct = (authorization) => {
    authorization.products.map(product => {
        const total = getTotalProductInDeliveryNotes(authorization.deliveryNotesPos, product);
        product.containersToPos = total.containers;
        product.chargesToPos = total.charges;
    });
}

/** Return all containers and charges from all POS for 1 product
*** for unsubscribes don't calculate nothing */
const getTotalProductInDeliveryNotes = (deliveryNotes, product) => {
    let containers = 0;
    let charges = 0;
    const productId = product.product ? product.product.id : product.productId;
    deliveryNotes.map(deliveryNote => {
        deliveryNote.products.map(dnProduct => {
            const dnProductId = dnProduct.product ? dnProduct.product.id : dnProduct.productId;
            const productSituationId = dnProduct.situation ? dnProduct.situation.id : dnProduct.productSituationId;
            if (dnProductId === productId) {
                switch (productSituationId) {
                    case productSituationService.PRODUCT_SITUATION_BAJA:
                    case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
                        containers -= parseInt(dnProduct.containers);
                        charges -= parseInt(dnProduct.charges);
                        break;
                    case productSituationService.PRODUCT_SITUATION_ALTA:
                    case productSituationService.PRODUCT_SITUATION_DMF_ALTA:
                        containers += parseInt(dnProduct.containers);
                        charges += parseInt(dnProduct.charges);
                        break;
                    default:
                        charges += parseInt(dnProduct.charges);
                }

            }
        });
    });
    return {
        containers: containers,
        charges: charges
    }
}

export const isReadyForAddCustomerManagement = (authorization, cm, errors = []) => {
    if (!hasProductInAuthorization(authorization, cm.productId)) {
        errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_PRODUCT"});
    }
    if (parseInt(cm.charges) > parseInt(cm.containers)) {
        errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CONTAINERS_VS_CHARGES"});
        return false;
    }
    const productSituationId = cm.situation ? cm.situation.id : cm.productSituationId;
    switch (productSituationId) {
        case productSituationService.PRODUCT_SITUATION_BAJA:
        case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
            break;
        default:
            if (parseInt(cm.charges) !== parseInt(cm.containers)) {
                errors.push({id: "app.error.domain.INCORRECT_VALUE_OF_CONTAINERS_EQUAL_CHARGES"});
                return false;
            }
    }

    return errors.length === 0;
}

export const calculateContainersToReturn = (authorization) => {
    authorization.products = authorization.products.map(product => {
        return {
            ...product,
            containersToReturn: getContainersToReturn(authorization, product),
            maxChargesToReturn: getMaxChargesToReturn(authorization, product)
        }
    });
    return authorization;
}

/** Charges sold in venta domiciliaria */
const calculateChargesSold = (authorization, productId) => {
    let chargesToSell = 0;
    let chargesReturned = 0;
    authorization.products.map(product => {
        const pId = product.product ? product.product.id : product.productId;
        if (productId === pId) {
            chargesToSell = parseInt(product.chargesAuth);
            chargesReturned = parseInt(product.chargesReturned);

            const customerManagementDone = authorization.customerManagement.filter(cm => {
                const cmProductId = cm.product ? cm.product.id : cm.productId;
                return validator.isTrue(cm.done) && cmProductId === productId;
            });
            customerManagementDone.map(cm => {
                const productSituationId = cm.situation ? cm.situation.id : cm.productSituationId;
                if (
                    productSituationId === productSituationService.PRODUCT_SITUATION_BAJA ||
                    productSituationId === productSituationService.PRODUCT_SITUATION_DMF_BAJA
                ) {
                    chargesToSell += parseInt(cm.charges);
                }
            });
        }
    });
    return chargesToSell - chargesReturned;
}

/** Calculate all amounts of authorization */
export const calculateAmounts = (authorization) => {

    calculateContainersToReturn(authorization);

    if (!isLiquidated(authorization)) {
        authorization.authAmount = calculateAuthAmount(authorization);
        authorization.returnedAmount = calculateReturnedAmount(authorization);
        authorization.lostAmount = calculateLostAmount(authorization);
        authorization.posAmount = calculatePosAmount(authorization);
        authorization.supplementsAmount = calculateSupplementAmount(authorization);
        authorization.bankAmount = calculateBankIncomesAmount(authorization);
        authorization.bonusAmount = calculateBonusIncomesAmount(authorization);
    }

    authorization.totalAuthAmount =
        parseFloat(authorization.authAmount) -
        parseFloat(authorization.returnedAmount) +
        parseFloat(authorization.rentalAmount) +
        parseFloat(authorization.supplementsAmount) +
        parseFloat(authorization.lostAmount);

    authorization.totalIncomesAmount =
        parseFloat(authorization.bankAmount) +
        parseFloat(authorization.cashAmount) +
        parseFloat(authorization.creditCardAmount) +
        parseFloat(authorization.posAmount) +
        parseFloat(authorization.bonusAmount);

    authorization.totalLiqAmount = authorization.totalAuthAmount - authorization.totalIncomesAmount;
    authorization.totalAmount = parseFloat(authorization.incidenceBalance) + authorization.totalLiqAmount;

    return authorization;
}

const calculatePosAmount = (authorization) => {
    let amount = 0;
    const deliveryNotesDone = authorization.deliveryNotesPos.filter(deliveryNote => {return /*deliveryNote.done ===*/ true});

    deliveryNotesDone.map(deliveryNote => {
        if (validator.isFalse(deliveryNote.paid)) {
            deliveryNote.products.map(dnProduct => {
                authorization.products.map(product => {
                    const dnProductId = dnProduct.product ? dnProduct.product.id : dnProduct.productId;
                    const productId = product.product ? product.product.id : product.productId;
                    if (productId === dnProductId) {
                        amount = amount + (parseInt(dnProduct.charges) * parseFloat(product.basePrice));
                    }
                });
            });
        }
    });
    return amount;
}

const calculateSupplementAmount = (authorization) => {
    if (isAuthorization(authorization)) return 0;

    let amount = 0;
    authorization.supplements = authorization.supplements.filter(supplement => typeof supplement.uuid !== 'undefined');
    authorization.supplements.map((supplement, index) => {
        const genericSupplement = - productLiquidationSupplementService.getSupplement(authorization.supplementsToApply, supplement.productId, null, authorization.driverId);
        authorization.supplements[index] = {
            ...supplement,
            supplement: genericSupplement,
            supplementAmount: supplement.charges * genericSupplement
        }
        amount += supplement.charges * genericSupplement;
    });

    authorization.products.map(product => {
        if (product.chargesReturned !== null) {
            const productId = product.product ? product.product.id : product.productId;

            const chargesSold = calculateChargesSold(authorization, productId);

            /** POS */
            const saleChannelPos = authorization.saleChannels.getObjectByKeyValue('code', saleChannelService.SALE_CHANNEL_PUNTO_DE_VENTA);
            const supplementForPos = productLiquidationSupplementService.getSupplement(authorization.supplementsToApply, productId, saleChannelPos.id, authorization.driverId);
            const supplementsForProductPos = parseInt(product.chargesToPos) * supplementForPos;
            addSupplementInfo(authorization, productId, product.chargesToPos, saleChannelPos.id, supplementForPos);

            /** SaleChannels */
            const unitsPerSaleChannel = {};
            let supplementsForProductSaleChannels = 0;
            let totalUnitsPerProductSaleChannel = 0;
            authorization.bonusIncomes.map(bonus => {
                if (bonus.productId === productId && bonus.saleChannelId !== null) {
                    const saleChannel = authorization.saleChannels.getObjectById(bonus.saleChannelId);
                    if (!unitsPerSaleChannel.hasOwnProperty(saleChannel.code)) {
                        unitsPerSaleChannel[saleChannel.code] = {id: bonus.saleChannelId, charges: 0};
                    }
                    unitsPerSaleChannel[saleChannel.code].charges += bonus.charges;
                    totalUnitsPerProductSaleChannel += bonus.charges;
                }
            });
            Object.keys(unitsPerSaleChannel).forEach(scCode => {
                const supplementForSaleChannel = productLiquidationSupplementService.getSupplement(authorization.supplementsToApply, productId, unitsPerSaleChannel[scCode].id, authorization.driverId);
                supplementsForProductSaleChannels += supplementForSaleChannel * unitsPerSaleChannel[scCode].charges;
                addSupplementInfo(authorization, productId, unitsPerSaleChannel[scCode].charges,unitsPerSaleChannel[scCode].id, supplementForSaleChannel);
            });

            /** Sales */
            let saleChannelsSale = authorization.saleChannels.getObjectByKeyValue('code', saleChannelService.SALE_CHANNEL_VENTA_DOMICILIARIA);
            let supplementForSale = productLiquidationSupplementService.getSupplement(authorization.supplementsToApply, productId, saleChannelsSale.id, authorization.driverId);
            if (supplementForSale === 0) {
                saleChannelsSale = authorization.saleChannels.getObjectByKeyValue('code', saleChannelService.SALE_CHANNEL_VENTA_EN_RUTA);
                supplementForSale = productLiquidationSupplementService.getSupplement(authorization.supplementsToApply, productId, saleChannelsSale.id, authorization.driverId);
            }
            const qty = chargesSold - parseInt(product.chargesToPos) - totalUnitsPerProductSaleChannel;
            if (qty > 0) {
                const supplementForProductSale = qty * supplementForSale;
                addSupplementInfo(authorization, productId, qty, saleChannelsSale.id, supplementForSale);

                amount += supplementsForProductPos + supplementsForProductSaleChannels + supplementForProductSale;
            }
        }
    });

    return amount;
}
const addSupplementInfo = (authorization, productId, charges, saleChannelId, supplement) => {
    if (charges === 0 || supplement === 0) return;
    const supplementToAdd = {
        saleChannelId: saleChannelId,
        productId: productId,
        charges: parseInt(charges),
        supplement: supplement,
        supplementAmount: (parseInt(charges) * supplement).toFixed(2)
    };
    authorization.supplements.push(supplementToAdd);
}

const calculateAuthAmount = (authorization) => {
    let amount = 0;
    authorization.products.map(product => {
        let chargesAuth = 0;
        let chargesToAdd = 0;
        const customerManagementDone = authorization.customerManagement.filter(cm => {
            const cmProductId = cm.product ? cm.product.id : cm.productId;
            return validator.isTrue(cm.done)  && cmProductId === product.productId;
        });
        customerManagementDone.map(cm => {
            const productSituationId = cm.situation ? cm.situation.id : cm.productSituationId;
            switch (productSituationId) {
                case productSituationService.PRODUCT_SITUATION_BAJA:
                case productSituationService.PRODUCT_SITUATION_DMF_BAJA:
                    chargesToAdd += parseInt(cm.charges);
                    break;
                case productSituationService.PRODUCT_SITUATION_CANJE:
                    chargesToAdd += - parseInt(cm.charges);
                    break;
            }
        });
        chargesAuth = parseInt(product.chargesAuth) + chargesToAdd;
        amount += (chargesAuth * parseFloat(product.basePrice));
    });
    return amount;
}
const calculateReturnedAmount = (authorization) => {
    let amount = 0;
    authorization.products.map(product => {
        const chargesReturned = product.chargesReturned === null ? 0 : product.chargesReturned;
        amount += (parseInt(chargesReturned) * parseFloat(product.basePrice));
    });
    return amount;
}
//TODO: cambiar el maxPrice per lost price
const calculateLostAmount = (authorization) => {
    let amount = 0;
    authorization.products.map(product => {
        amount += (parseInt(product.containersLost) * parseFloat(product.maxPrice));
    });
    return amount;
}
/** bank and bonus incomes */
const calculateBankIncomesAmount = (authorization) => {
    let amount = 0;
    authorization.bankIncomes.map(income => {
        amount += parseFloat(income.amount);
    });
    return amount;
}
const calculateBonusIncomesAmount = (authorization) => {
    let amount = 0;
    authorization.bonusIncomes.map(income => {
        amount += parseFloat(income.amount);
    });
    return amount;
}

export const getPrePayments = (authorizations) => {
    const prepayments = {
        detail : [],
        summary : {
            byVehicle: {},
            byPrepayment: {}
        }
    }

    authorizations.map(authorization => {
        authorization.bonusIncomes.map(bonus => {
            const bonusType = bonus.bonusType.type;
            const vehicle = authorization.vehicle.plate;
            prepayments.detail.push({
                ...bonus,
                liquidation: authorization.number,
                vehicle: vehicle
            });

            /** byVehicle */
            if (vehicle in prepayments.summary.byVehicle) {} else {
                prepayments.summary.byVehicle[vehicle] = {}
            }
            addPrepaymentToSummary(prepayments.summary.byVehicle[vehicle], bonusType, bonus.amount);

            /** byPrepayment */
            addPrepaymentToSummary(prepayments.summary.byPrepayment, bonusType, bonus.amount);
        });
    });

    return prepayments;
}

const addPrepaymentToSummary = (prepaymentsList, bonusType, amount) => {
    if (bonusType in prepaymentsList) {
        prepaymentsList[bonusType].amount += amount;
    } else {
        prepaymentsList[bonusType] = {
            bonusType: bonusType,
            amount: amount
        };
    }
}

export const filterProductsInAuthorization = (authorization, products, bonusTypeId = null) => {
    return products.filter(product => {
        const authProducts = authorization.products.filter(productAuth => {
            return  productAuth.product ? productAuth.product.id === product.id : productAuth.productId === product.id;
        });

        if (bonusTypeId && bonusTypeService.isDiscount(bonusTypeId)) {
            const discountBonus = bonusTypeService.getBonusTypeById(bonusTypeId);
            const discountProduct = discountBonus.type.replace('Descuento ', '');
            if (!product.name.includes(discountProduct)) return false;
        }

        return authProducts.length > 0;
    });
}

export const removeProductReturned = (authorization, productId) => {
    authorization.products.map(authProduct => {
        if (authProduct.productId === productId) {
            authProduct.containersReturned = null;
            authProduct.chargesReturned = null;
        }
    });
}

