import moment from 'moment';
import * as authService from '../services/authService';


/** Date Time Formats */

/**
 * Date validation
 * [valid formats: dd/mm/yyyy or yyyy-mm-dd]
 * @param value
 * @returns {boolean}
 */
export const isDate = (value) => {
    const pattern1 =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    const pattern2 =/^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/;
    return pattern1.test(value) || pattern2.test(value);
};

/**
 * Time validation
 * [valid formats: hh:mm]
 * @param value
 * @returns {boolean}
 */
export const isTime = (value) => {
    const pattern =/^([0-9]{2})\:([0-9]{2})$/;
    return pattern.test(value);
};

export const isFutureDate = (value) => {
    const now = moment();
    return moment(value).isAfter(now); //TODO: comprobar
};
export const isPastDate = (value) => {
    const now = moment();
    return moment(value).isBefore(now); //TODO: comprobar
};
export const isToday = (value) => {
    const from = moment({ hour:0, minute:0 });
    const to = moment({ hour: 23, minute:59, second:59 });
    return moment(value).isBetween(from, to, undefined, '[]');
};
export const isSameDate = (value1, value2) => {
    return moment(value1).isSame(value2);
}



/** Number Formats */
export const isNumber = (value) => {
    return !isNaN(value);
};
export const isBetween = (value, values = [0,0]) => {
    return value >= values[0] && value <= values[1];
}
export const isEqualToZero = (value) => {
    return value === 0;
}
export const isGreaterOrEqualTo = (value, number) => {
    return isNumber(value) && toNumber(value) >= toNumber(number);
};
export const isGreaterOrEqualToZero = (value) => {
    return isGreaterOrEqualTo(value, 0);
};
export const isGreaterThan = (value, number) => {
    return isNumber(value) && toNumber(value) > toNumber(number);
}
export const isGreaterThanZero = (value) => {
    return isGreaterThan(value, 0);
};
export const isMultipleOf = (value, number) => {
    return value % number === 0;
}
export const isPair = (value) => {
    return isMultipleOf(value, 2);
}
export const isOdd = (value) => {
    return !isPair(value);
}

const toNumber = (value) => {
    return typeof value === 'string' ?
        value.indexOf('.') === -1 && value.indexOf(',') === -1 ? parseInt(value) : parseFloat(value)
        :
        value;
}



/** String Formats */

/**
 * Validate letter format
 * chars allowed: a-z, A-Z, -, [blank]
 * with accents: [`], [´], [¨], [^]
 * @param text
 * @returns {boolean}
 */
export const isLetter = (text) => {
    return (/^[a-zA-ZÄËÏÖÜäëïöüáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ \-]*$/.test(text));
};

export const isNif = (value) => {
    return true; //TODO: acabar
};
export const isValidNif = (value) => {
    return true; //TODO: acabar
}

/**
 * Validation email format
 * @param email
 * @returns {boolean}
 */
export const isEmail = (email) => {
    return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
};


/** boolean formats */
export const isTrue = (value) => {
    return value === true || value === 1;
}
export const isFalse = (value) => {
    return value === false || value === 0;
}

/** Business Formats */
export const isAgency = (value) => {
    return value === authService.getAgencyId();
}
export const isCP = (value) => {
    return (/^[0-5][0-9]{4}$/).test(value);
}
export const isTf = (value) => {
    return (/^[0-9]{9}$/).test(value);
}

/** Generic Formats */
/**
 * Validate is empty
 * @param obj
 * @returns {boolean}
 */
export const isEmpty = (obj) => {
    if (obj === null) return true;
    switch (typeof obj) {
        case "object":
            return Object.keys(obj).length === 0;

        case "string":
            return obj === "";

        default:
            return false;
    }
};

export const isNull = (obj) => {
    return obj === null || typeof obj === 'undefined';
}

/**
 * Compare two objects
 * @param obj1
 * @param obj2
 * @returns {boolean}
 */
export const equals = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

/**
 * Max length for numbers or strings
 * @param obj
 * @param length
 * @returns {boolean}
 */
export const isMaxLength = (obj, length) => {
    return isNumber(obj) ? obj.toString().length <= length : obj.length <= length;
}
