import * as apiService from "../../apiService";


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const getOrders = async (filter = {}) => {
    return await apiService.get(`${URL}/bwin/orders`, hydrateAgency(filter));
}

export const exportOrdersNace = (data) => {
    const fileName = "Pedidos " + (data.startDate === data.endDate ? data.startDate : data.startDate + ' a ' + data.endDate);

    return apiService.downloadFile(`${URL}/bwin/export-nace`, hydrateAgency(data), fileName);
};

//TODO: sustituir por agencyId: authService.getAgencyId(),
const hydrateAgency = (data) => {
    return {...data, agencyId: "08482"}
}
