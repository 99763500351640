import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import { RightBox } from '../../../../components/Box';
import Select from "../../../../components/form/Select";



export const BonusIncomeForm = (props) => {
    const {income, errors, bonusTypes, products = [], saleChannels = []} = props;

    const intl = useIntl();

    return (
        <form>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Select
                    name="bonusTypeId"
                    label={intl.formatMessage({id: 'generic.type'})}
                    items={bonusTypes.getSelectItems('type')}
                    value={income.bonusTypeId}
                    error={errors.bonusTypeId}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    name="saleChannelId"
                    label={intl.formatMessage({id: 'generic.domain.saleChannel'})}
                    items={saleChannels.getSelectItems('channel')}
                    value={income.saleChannelId}
                    error={errors.saleChannelId}
                    onChange={props.onChange}
                    disabled
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <Select
                    name="productId"
                    label={intl.formatMessage({id: 'generic.domain.product'})}
                    items={products.getSelectItems()}
                    value={income.productId}
                    error={errors.productId}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <Input
                    name="charges"
                    label={intl.formatMessage({id: "generic.domain.charges"})}
                    value={income.charges}
                    error={errors.charges}
                    onChange={props.onChange}
                />
            </Grid>
            <Grid item xs={2}>
                <Input
                    name="amount"
                    label={intl.formatMessage({id: "generic.domain.amount-i"})}
                    value={income.amount}
                    error={errors.amount}
                    onChange={props.onChange}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="referenceNumber"
                    label={intl.formatMessage({id: "generic.domain.referenceNumber"})}
                    value={income.referenceNumber}
                    error={errors.referenceNumber}
                    onChange={props.onChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    name="description"
                    label={intl.formatMessage({id: "generic.domain.desc"})}
                    value={income.description}
                    error={errors.description}
                    onChange={props.onChange}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <RightBox>
                    <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                    <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                </RightBox>
            </Grid>
        </Grid>
        </form>
    );
}

export default BonusIncomeForm;
