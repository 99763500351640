import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import App from './App';
import IntlProviderWrapper from './features/core/wrappers/IntlProviderWrapper';
import MessageProviderWrapper from './features/core/wrappers/MessageProviderWrapper';
import theme from './assets/themes/theme';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <React.StrictMode>
        <IntlProviderWrapper>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <MessageProviderWrapper>
                    <BrowserRouter
                        getUserConfirmation={() => {
                            /* Empty callback to block the default browser prompt */
                        }}
                    >
                        <App />
                    </BrowserRouter>
                </MessageProviderWrapper>
            </ThemeProvider>
        </IntlProviderWrapper>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
