import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAppContext } from '../../core/context/AppContext';
import LogoRepsolBG from '../../../assets/images/logo-Repsol-bg.jpg';

import Box from '@material-ui/core/Box';


const bgStyles = {
    backgroundImage: `url(${LogoRepsolBG})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: 'calc(100vh - 150px)',
    opacity: 0.2,
    marginTop: '-50px'
}

export const HomePage = () => {
    const intl = useIntl();
    const { agencyId, user, refreshNotifications } = useAppContext();

    useEffect(() => {
        refreshNotifications();
    }, [agencyId]);

    return (
        <Box>
            <h4>{intl.formatMessage({id: 'app.welcome'}, {userName: user.name})}</h4>
            {!agencyId && (<h5>{intl.formatMessage({id: 'app.welcome.text'})}</h5>)}
            <Box style={bgStyles}></Box>
        </Box>
    )
}

export default HomePage;