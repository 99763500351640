import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as formatter from '../../../../helpers/formatter';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Divider from '../../../../components/Divider';
import EditIcon from '../../../../components/icons/Edit';
import SearchIcon from '../../../../components/icons/Search';
import Input from '../../../../components/form/Input/Input';



export const LiqForm = (props) => {
    const {authorization} = props;

    const intl = useIntl();

    const disabled =
        authorizationService.isAuthorization(authorization) ||
        authorizationService.isLiquidated(authorization);

    return (
        <Grid container>
            <Grid item xs={3}>
                <Input
                    name="authAmount"
                    label={intl.formatMessage({id: 'generic.domain.authorization'})}
                    value={formatter.priceFormatter(authorization.authAmount, true)}
                    disabled
                />
                <Input
                    name="returnedAmount"
                    label={intl.formatMessage({id: 'generic.domain.return'})}
                    value={formatter.priceFormatter(authorization.returnedAmount, true)}
                    disabled
                />
                <Input
                    name="rentalAmount"
                    label={intl.formatMessage({id: 'generic.domain.rental'})}
                    value={formatter.priceFormatter(authorization.rentalAmount, true)}
                    disabled
                />
                <Input
                    name="supplementsAmount"
                    label={intl.formatMessage({id: 'generic.domain.supplements'})}
                    value={formatter.priceFormatter(authorization.supplementsAmount, true)}
                    disabled
                />
                <Input
                    name="lostAmount"
                    label={intl.formatMessage({id: 'generic.domain.containers.lost'})}
                    value={formatter.priceFormatter(authorization.lostAmount, true)}
                    disabled
                />
            </Grid>
            <Grid item xs={1} style={{paddingTop: '150px'}}><SearchIcon button onClick={props.handleOpenSupplementDetail} /></Grid>
            <Grid item xs={3}>
                <Input
                    name="bankAmount"
                    label={intl.formatMessage({id: 'generic.domain.bankIncomes'})}
                    value={formatter.priceFormatter(authorization.bankAmount)}
                    onChange={props.onChange}
                    disabled
                />
                <Input
                    name="creditCardAmount"
                    label={intl.formatMessage({id: 'generic.domain.creditCard'})}
                    value={authorization.creditCardAmount}
                    onChange={props.onChange}
                    disabled={disabled}
                />
                <Input
                    name="cashAmount"
                    label={intl.formatMessage({id: 'generic.domain.cash'})}
                    value={authorization.cashAmount}
                    onChange={props.onChange}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}><EditIcon button onClick={props.handleOpenBankIncomes} /></Grid>
            <Grid item xs={3}>
                <Input
                    name="posAmount"
                    label={intl.formatMessage({id: 'generic.domain.pos.plural'})}
                    value={formatter.priceFormatter(authorization.posAmount, true)}
                    disabled
                />
                <Input
                    name="bonusAmount"
                    label={intl.formatMessage({id: 'generic.domain.pre-paid'})}
                    value={formatter.priceFormatter(authorization.bonusAmount)}
                    onChange={props.onChange}
                    disabled
                />
            </Grid>
            <Grid item xs={1} style={{paddingTop: '55px'}}><EditIcon button onClick={props.handleOpenBonusIncomes} /></Grid>
            <Grid item xs={12}>
                <Divider label={intl.formatMessage({id: "generic.domain.liquidation"})}/>
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="totalAuthAmount"
                    label={intl.formatMessage({id: 'generic.domain.to.liquidate'})}
                    value={formatter.priceFormatter(authorization.totalAuthAmount, true)}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="totalIncomesAmount"
                    label={intl.formatMessage({id: 'generic.domain.incomes'})}
                    value={formatter.priceFormatter(authorization.totalIncomesAmount, true)}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="totalLiqAmount"
                    label={intl.formatMessage({id: 'generic.domain.total'})}
                    value={formatter.priceFormatter(authorization.totalLiqAmount, true)}
                    disabled
                />
            </Grid>

            <Grid item xs={12}>
                <Divider label={intl.formatMessage({id: "generic.domain.incidenceBalance"})}/>
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="incidenceBalance"
                    label={intl.formatMessage({id: 'generic.previous'})}
                    value={formatter.priceFormatter(authorization.incidenceBalance, true)}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="totalLiqAmount-si"
                    label={intl.formatMessage({id: 'generic.domain.liquidation'})}
                    value={formatter.priceFormatter(authorization.totalLiqAmount, true)}
                    disabled
                />
            </Grid>
            <Grid item xs={4}>
                <Input
                    name="newIncidenceBalance"
                    label={intl.formatMessage({id: 'generic.new.male'})}
                    value={formatter.priceFormatter(authorization.totalAmount, true)}
                    disabled
                />
            </Grid>
        </Grid>
    )
};

export default LiqForm;
