import { withStyles } from '@material-ui/core/styles';

import MUITablePagination from '@material-ui/core/TablePagination';


export const TablePagination = withStyles(theme => ({
    menuItem: {
        fontSize: '12px'
    }
}))(MUITablePagination);


const PaperProps = {
    style: {width: 60, padding: 0}
};
const MenuListProps = {
    style: {backgroundColor: '#FFF'},
    ListProps: {}
};
const MenuProps = {
    PaperProps: PaperProps,
    MenuListProps: MenuListProps
};
export const SelectProps = {
    MenuProps: MenuProps
};
