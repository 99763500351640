import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as formatter from '../../../../helpers/formatter';
import * as bonusTypeService from '../../../../services/domain/warehouse/bonusTypeService';
import {isNull} from "../../../../helpers/validator";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import DeleteIcon from "../../../../components/icons/Delete";
import EditIcon from "../../../../components/icons";
import Modal from "../../../../components/Modal/Modal";
import Table from '../../../../components/table';
import { CenterBox } from '../../../../components/Box';

import LiqForm from './LiqForm';
import BankIncomeForm from "./BankIncomeForm";
import BonusIncomeForm from "./BonusIncomeForm";
import SupplementForm from "./SupplementForm";
import {numberFormatter} from "../../../../helpers/formatter";



export const LiqTab = (props) => {
    const {authorization, products, saleChannels, refreshGrids, bankIncome, bonusIncome, supplement, errorsBankIncome, errorsBonusIncome, errorsSupplement} = props;

    const intl = useIntl();

    const [dataBankIncomes, setDataBankIncomes] = useState([]);
    const [dataBonusIncomes, setDataBonusIncomes] = useState([]);
    const [dataSupplementDetail, setDataSupplementDetail] = useState([]);
    const [footerSupplementDetail, setFooterSupplementDetail] = useState({});
    const [openBonusIncomes, setOpenBonusIncomes] = useState(false);
    const [openBankIncomes, setOpenBankIncomes] = useState(false);
    const [openSupplementDetail, setOpenSupplementDetail] = useState(false);

    useEffect(() => {
        setDataBankIncomes(authorization.bankIncomes.map(item => convertBankIncomeRowData(item)));
        setDataBonusIncomes(authorization.bonusIncomes.map(item => convertBonusIncomeRowData(item)));
        setDataSupplementDetail(authorization.supplements.map(item => convertSupplementDetailData(item)));
        let supplementAmount = 0;
        authorization.supplements.map(item => {
            const itemSupAmount = item.supplementAmount ? parseFloat(item.supplementAmount) : parseFloat(item.charges) * parseFloat(item.supplement);
            supplementAmount += itemSupAmount;
        });

        setFooterSupplementDetail({
            id3: intl.formatMessage({id:"generic.domain.total"}),
            id4: supplementAmount.toFixed(2)
        });
    }, [authorization, refreshGrids]);

    const handleClose = () => {
        setOpenBonusIncomes(false);
        setOpenBankIncomes(false);
        setOpenSupplementDetail(false);
    }
    const handleOpenBonusIncomes = () => {
        setOpenBonusIncomes(true);
    }
    const handleOpenBankIncomes = () => {
        setOpenBankIncomes(true);
    }
    const handleOpenSupplementDetail = () => {
        setOpenSupplementDetail(true);
    }

    const headersBankIncome = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.amount"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.office"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateFormatter },
        { id: 'actions', align: "center", width: 60 },
    ];
    const convertBankIncomeRowData = item => {
        return {
            id1: item.amount,
            id2: item.office,
            id3: item.date,
            actions: <DeleteIcon button onClick={() => props.handleDeleteBankIncome(item)} />
        }
    }

    const bonusTypeFormatter = (id) => {
        const bonusTypesItems = bonusTypeService.getBonusTypes();
        return bonusTypesItems.getKeyValueByObjectId(id, 'type');
    }
    const productFormatter = (id) => {
        return id !== null ? products.getKeyValueByObjectId(id, 'name') : "";
    }
    const saleChannelFormatter = (id) => {
        return id !== null ? saleChannels.getKeyValueByObjectId(id, 'channel') : "";
    }

    const headersBonusIncome = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: bonusTypeFormatter  },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: productFormatter },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.saleChannel"}), sublabel: null, formatter: saleChannelFormatter },
        { id: 'id4', align: "center",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, formatter: null, width: '50px' },
        { id: 'id5', align: "center",   label: intl.formatMessage({id:"generic.domain.amount-i"}), sublabel: null, formatter: null, width: '70px' },
        { id: 'id6', align: "left",   label: intl.formatMessage({id:"generic.domain.referenceNumber"}), sublabel: null, formatter: null },
        { id: 'id7', align: "left",   label: intl.formatMessage({id:"generic.domain.desc"}), sublabel: null, formatter: (value) => formatter.truncStringFormatter(value, 30) },
        { id: 'actions', align: "center", width: 60 },
    ];
    const convertBonusIncomeRowData = item => {
        return {
            id1: isNull(item.bonusTypeId) ? item.bonusType.id : item.bonusTypeId,
            id2: isNull(item.productId) ? (item.product ? item.product.id:"-") : item.productId,
            id3: isNull(item.saleChannelId) ? (item.saleChannel ? item.saleChannel.id : null) : item.saleChannelId,
            id4: item.units || item.charges,
            id5: item.amount,
            id6: item.referenceNumber,
            id7: item.description,
            actions:<><EditIcon button onClick={() => props.handleEditBonusIncome(item)} /><DeleteIcon button onClick={() => props.handleDeleteBonusIncome(item)} /></>
        }
    }

    const headersSupplementDetail = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: productFormatter, width: '100px' },
        { id: 'id2', align: "center",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, formatter: null, width: '50px' },
        { id: 'id3', align: "center",   label: intl.formatMessage({id:"generic.domain.supplement"}), sublabel: null, formatter: null, width: '70px' },
        { id: 'id4', align: "center",   label: intl.formatMessage({id:"generic.domain.amount-i"}), sublabel: null, formatter: (value) => numberFormatter(value, 2), width: '100px' },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.desc"}), sublabel: null, formatter: (value) => formatter.truncStringFormatter(value, 30)  },
        { id: 'actions' },
    ];
    const convertSupplementDetailData = item => {
        const saleChannelId = isNull(item.saleChannelId) ? (item.saleChannel ? item.saleChannel.id : null) : item.saleChannelId;
        const saleChannel = !isNull(saleChannelId) ? saleChannelFormatter(saleChannelId) : "";
        return {
            id1: isNull(item.productId) ? item.product.id : item.productId,
            id2: item.charges,
            id3: item.supplement,
            id4: item.supplementAmount ? item.supplementAmount : parseFloat(item.charges) * parseFloat(item.supplement),
            id5: item.description ? item.description : saleChannel,
            actions: item.uuid ? <DeleteIcon button onClick={() => props.handleDeleteSupplement(item)} /> : ""
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <CenterBox>
                    <Box width={600}>
                        <LiqForm
                            authorization={authorization}
                            onChange={props.handleChangeLiq}

                            handleOpenBonusIncomes={handleOpenBonusIncomes}
                            handleOpenBankIncomes={handleOpenBankIncomes}
                            handleOpenSupplementDetail={handleOpenSupplementDetail}
                        />
                    </Box>
                </CenterBox>
            </Grid>
            <Grid item xs={12}>
                <Modal open={openBankIncomes} title={intl.formatMessage({id: 'generic.domain.bankIncomes'})} onClose={handleClose} width={500} height={500}>
                    <BankIncomeForm
                        income={bankIncome}
                        errors={errorsBankIncome}
                        onChange={props.handleChangeBankIncome}
                        onSubmit={props.handleSubmitBankIncome}
                        onClose={handleClose}
                    />
                    <br/><br/>
                    <Table
                        headers={headersBankIncome}
                        data={dataBankIncomes}
                    />
                </Modal>
                <Modal open={openBonusIncomes} title={intl.formatMessage({id: 'generic.domain.pre-payments'})} onClose={handleClose} width={700} height={700}>
                    <BonusIncomeForm
                        income={bonusIncome}
                        errors={errorsBonusIncome}
                        bonusTypes={bonusTypeService.getBonusTypes()}
                        products={products}
                        saleChannels={saleChannels}
                        onChange={props.handleChangeBonusIncome}
                        onSubmit={props.handleSubmitBonusIncome}
                        onClose={handleClose}
                    />
                    <br/>
                    <Table
                        headers={headersBonusIncome}
                        data={dataBonusIncomes}
                    />
                </Modal>
                <Modal open={openSupplementDetail} title={intl.formatMessage({id: 'generic.domain.supplements'})} onClose={handleClose} width={700} height={700}>
                    <SupplementForm
                        supplement={supplement}
                        errors={errorsSupplement}
                        bonusTypes={bonusTypeService.getBonusTypes()}
                        products={products}
                        onChange={props.handleChangeSupplement}
                        onSubmit={props.handleSubmitSupplement}
                    />
                    <br/>
                    <Table
                        headers={headersSupplementDetail}
                        data={dataSupplementDetail}
                        footer={footerSupplementDetail}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
};

export default LiqTab;