import * as apiService from '../../apiService';
import { encrypt, decrypt} from '../../cryptService';

const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const getAgencies = async () => {
    return await apiService.get(`${URL}/agencies`);
}

export const getLSAgencies = () => {
    const agencies = localStorage.getItem('data_agencies');
    return agencies !== null ? decrypt(agencies) : [];
}
export const setLSAgencies = (agencies) => {
    const strAgencies = encrypt(agencies);
    localStorage.setItem('data_agencies', strAgencies);
}