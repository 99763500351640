import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import { CenterBox, RightBox } from '../../../../components/Box';



export const ProductPriceForm = (props) => {
    const {price, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <CenterBox>
                        <Input
                            name="basePrice"
                            label={intl.formatMessage({id: 'generic.domain.price.base'})}
                            value={price.basePrice}
                            error={errors.basePrice}
                            onChange={props.onChange}
                            required
                        />
                    </CenterBox>
                </Grid>
                <Grid item xs={4}>
                    <CenterBox>
                        <Input
                            name="authMaxPrice"
                            label={intl.formatMessage({id: 'generic.domain.price.maxLiq'})}
                            value={price.authMaxPrice}
                            error={errors.authMaxPrice}
                            onChange={props.onChange}
                            disabled
                            required
                        />
                    </CenterBox>
                </Grid>
                <Grid item xs={4}>
                    <CenterBox>
                        <Input
                            name="lostPrice"
                            label={intl.formatMessage({id: 'generic.domain.price.lost'})}
                            value={price.lostPrice}
                            error={errors.lostPrice}
                            onChange={props.onChange}
                            required
                        />
                    </CenterBox>
                </Grid>
                <Grid item xs={12}><br/><br/></Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            style={{marginLeft: '10px'}}
                            onClick={() => props.onClick()}
                        >
                            {intl.formatMessage({id: "generic.save"})}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
};

export default ProductPriceForm;
