import React from 'react';
import {withStyles} from "@material-ui/core/styles/index";

import Box from "@material-ui/core/Box";
import MUIDivider from "@material-ui/core/Divider";

import {TEXT_COLOR} from '../../assets/themes/theme';

const StyledDivider = withStyles({
    root: {
        backgroundColor: '#e1e1e4'
    }
})(MUIDivider);

export const Divider = (props) => {
    const {label} = props;

    if (!label) {
        return (
            <MUIDivider />
        )
    } else {
        return (
            <Box display="flex">
                <Box flexGrow={1} style={{paddingTop: 10}}>
                    <StyledDivider/>
                </Box>
                <Box
                    style={{paddingLeft: 10, paddingRight: 10, color: '#888888'}}
                >
                    {label}
                </Box>
                <Box flexGrow={1} style={{paddingTop: 10}}>
                    <StyledDivider/>
                </Box>
            </Box>
        )
    }

};

export default Divider;
