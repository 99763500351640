import {isNull} from "../../../helpers/validator";

export const PRODUCT_SITUATION_ALTA = 1;
export const PRODUCT_SITUATION_BAJA = 2;
export const PRODUCT_SITUATION_CANJE = 3;
export const PRODUCT_SITUATION_DMF_ALTA = 4;
export const PRODUCT_SITUATION_DMF_BAJA = 5;
export const PRODUCT_SITUATION_AUMENTO_STOCK = 6;
export const PRODUCT_SITUATION_REDUCCION_STOCK = 7;

const productSituations = [
    {id: PRODUCT_SITUATION_ALTA, type: "Alta"},
    {id: PRODUCT_SITUATION_BAJA, type: "Baja"},
    {id: PRODUCT_SITUATION_AUMENTO_STOCK, type: "Aumento stock"},
    {id: PRODUCT_SITUATION_REDUCCION_STOCK, type: "Reducción stock"},
    {id: PRODUCT_SITUATION_CANJE, type: "Canje S/C"},
    {id: PRODUCT_SITUATION_DMF_ALTA, type: "Alta DMF"},
    {id: PRODUCT_SITUATION_DMF_BAJA, type: "Baja DMF"},
];


//TODO: hacer la request
/**
 *
 * @param pos (PointOfSale)
 * @returns []
 */
export const getProductSituations = (pos = false) => {
    if (!pos) {
        delete productSituations[2];
        delete productSituations[3];
    } else {
        delete productSituations[4];
        delete productSituations[5];
        delete productSituations[6];
    }
    return productSituations;
};
export const getProductSituationItems = (pos = false) => {
    const productSituations = getProductSituations(pos);

    return productSituations.map(productSituation => {
        return {value: productSituation.id, label: productSituation.type}
    });
};

export const getProductSituationType = (id) => {
    if (isNull(id)) return "";
    const ps = productSituations.filter(productSituation => productSituation.id === id);
    return ps[0].type;
}

export const isAltaSituationById = (id) => {
    return PRODUCT_SITUATION_ALTA === id ||
        PRODUCT_SITUATION_AUMENTO_STOCK === id ||
        PRODUCT_SITUATION_DMF_ALTA === id;
}
export const isBajaSituationById = (id) => {
    return PRODUCT_SITUATION_BAJA === id ||
        PRODUCT_SITUATION_REDUCCION_STOCK === id ||
        PRODUCT_SITUATION_DMF_BAJA === id;
}
export const isCanjeSituationById = (id) => {
    return PRODUCT_SITUATION_CANJE === id;
}
export const isIntercambioSituationById = (id) => {
    return null === id;
}
