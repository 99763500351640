import React, { useEffect, useState }  from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import Switch from '../../../../components/form/Switch';
import { RightBox } from '../../../../components/Box';


const VehicleForm = (props) => {
    const {vehicle, companies, errors} = props;

    const intl = useIntl();

    //TODO: hidratar
    const transportCardAmbitItems = [
        {value: 1, label: "Nacional"},
        {value: 2, label: "Comarcal"},
        {value: 3, label: "Local"},
    ];

    const [companyItems, setCompanyItems] = useState([]);

    useEffect(() => {
        setCompanyItems(
            companies.map(company => {
                return {value: company.id, label: company.name}
            })
        );

    }, [companies]);

    const renderDocuments = (employee) => {
        if (typeof employee.documents === "undefined") return (<div></div>);

        return (
            <ul>
                {
                    employee.documents.map(doc => {
                        return (
                            <li>{doc.description}</li>
                        )
                    })
                }
            </ul>
        )
    };

    return (
        <form>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <Input
                    name="plate"
                    label={intl.formatMessage({id: 'app.vehicles.plate'})}
                    value={vehicle.plate}
                    error={errors.plate}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    type="date"
                    name="plateDate"
                    label={intl.formatMessage({id: 'app.vehicles.plateDate'})}
                    value={vehicle.plateDate}
                    error={errors.plateDate}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="brand"
                    label={intl.formatMessage({id: 'app.vehicles.brand'})}
                    value={vehicle.brand}
                    error={errors.brand}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="model"
                    label={intl.formatMessage({id: 'app.vehicles.model'})}
                    value={vehicle.model}
                    error={errors.model}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="pma"
                    label={intl.formatMessage({id: 'app.vehicles.pma'})}
                    value={vehicle.pma}
                    error={errors.pma}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="tara"
                    label={intl.formatMessage({id: 'app.vehicles.tara'})}
                    value={vehicle.tara}
                    error={errors.tara}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Select
                    name="companyId"
                    label={intl.formatMessage({id: 'app.companies.company'})}
                    items={companyItems}
                    value={vehicle.companyId}
                    error={errors.companyId}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Divider label={intl.formatMessage({id: "app.vehicles.itv"})}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    type="date"
                    name="itvExpiration"
                    label={intl.formatMessage({id: 'app.vehicles.itv'})}
                    value={vehicle.itvExpiration}
                    error={errors.itvExpiration}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Divider label={intl.formatMessage({id: "app.vehicles.assurance"})}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="assuranceCompany"
                    label={intl.formatMessage({id: 'app.vehicles.assuranceCompany'})}
                    value={vehicle.assuranceCompany}
                    error={errors.assuranceCompany}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="assuranceNumber"
                    label={intl.formatMessage({id: 'app.vehicles.assuranceNumber'})}
                    value={vehicle.assuranceNumber}
                    error={errors.assuranceNumber}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    type="date"
                    name="assuranceExpiration"
                    label={intl.formatMessage({id: 'app.vehicles.assuranceExpiration'})}
                    value={vehicle.assuranceExpiration}
                    error={errors.assuranceExpiration}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Divider label={intl.formatMessage({id: "app.vehicles.transportCardNumber"})}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="transportCardNumber"
                    label={intl.formatMessage({id: 'app.vehicles.transportCardNumber'})}
                    value={vehicle.transportCardNumber}
                    error={errors.transportCardNumber}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    name="transportCardClass"
                    label={intl.formatMessage({id: 'app.vehicles.transportCardClass'})}
                    value={vehicle.transportCardClass}
                    error={errors.transportCardClass}
                    onChange={props.onChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    type="date"
                    name="transportCardExpiration"
                    label={intl.formatMessage({id: 'app.vehicles.transportCardExpiration'})}
                    value={vehicle.transportCardExpiration}
                    error={errors.transportCardExpiration}
                    onChange={props.onChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Select
                    name="transportCardAmbit"
                    label={intl.formatMessage({id: 'app.vehicles.transportCardAmbit'})}
                    items={transportCardAmbitItems}
                    value={vehicle.transportCardAmbit}
                    error={errors.transportCardAmbit}
                    onChange={props.onChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Divider label={intl.formatMessage({id: "generic.domain.documents"})}/>
            </Grid>
            <Grid item xs={12}>
                {renderDocuments(vehicle)}
            </Grid>
            <Grid item xs={12}>
                <Divider label={intl.formatMessage({id: "app.system"})}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Switch
                    name="enabled"
                    label={intl.formatMessage({id: 'generic.enabled'})}
                    checked={vehicle.enabled === 1}
                    onChange={props.onChange}
                />
            </Grid>

            <Grid item xs={12}>
                <RightBox>
                    <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                    <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit(vehicle)}}>{intl.formatMessage({id: "generic.save"})}</Button>
                </RightBox>
            </Grid>
        </Grid>
        </form>
    )
};

export default VehicleForm;
