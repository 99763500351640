import LoginLayout from './layouts/login/LoginLayout';
import MainLayout from './layouts/main/Layout';

import AccessNotAllowed from "../../pages/AccessNotAllowed";
import HomePage from '../pages/HomePage';
import Login from './auth/Login';
import PageNotFound from '../../pages/PageNotFound';


const routes = [
    {
        exact: true,
        path: '/',
        layout: MainLayout,
        component: HomePage,
        private: true,
        menuSelectedItem: null
    },
    {
        exact: true,
        path: '/login',
        layout: LoginLayout,
        component: Login
    },
    {
        exact: true,
        path: '/access-not-allowed',
        layout: MainLayout,
        component: AccessNotAllowed
    },
    {
        layout: MainLayout,
        component: PageNotFound,
        private: true,
        menuSelectedItem: null
    }
];

export default routes;
