import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../../../../components/tabs/TabPanel';

import PointOfSaleForm from "./PointOfSaleForm";
import PointOfSaleDataForm from "./PointOfSaleDataForm";
import PointOfSaleStockTable from "./PointOfSaleStockTable";
import PointOfSaleCommissionTable from "./PointOfSaleCommissionTable";


const PointOfSale = (props) => {
    const {pos, posTypes, commissions, errors} = props;

    const intl = useIntl();

    const [activeTab, setActiveTab] = useState(0);

    const handleChangeTab = (e, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <PointOfSaleForm
                    pos={pos}
                    posTypes={posTypes}
                    errors={errors}
                    onChange={props.onChange}
                />
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" color="primary">
                    <Tabs
                        variant="fullWidth"
                        value={activeTab}
                        onChange={handleChangeTab}
                    >
                        <Tab label={intl.formatMessage({id: "generic.data.plural"})}/>
                        <Tab label={intl.formatMessage({id: "generic.domain.stock"})}/>
                        <Tab label={intl.formatMessage({id: "generic.domain.commissions"})}/>
                    </Tabs>
                </AppBar>
                <Paper style={{padding: '20px'}}>
                    <TabPanel index={0} value={activeTab}>
                        <PointOfSaleDataForm
                            pos={pos}
                            errors={errors}
                            onChange={props.onChange}
                            onClose={props.onClose}
                            onSubmit={props.onSubmit}
                        />
                    </TabPanel>
                    <TabPanel index={1} value={activeTab}>
                        <PointOfSaleStockTable
                            stock={pos.productStocks}
                        />
                    </TabPanel>
                    <TabPanel index={2} value={activeTab}>
                        <PointOfSaleCommissionTable
                            pos={pos}
                            commissions={commissions}
                            onChange={props.onChangeCommissions}
                            onSubmit={props.onSubmitCommissions}
                        />
                    </TabPanel>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default PointOfSale;