import React from 'react';
import { IntlProvider } from 'react-intl';

import * as i18nService from '../../../../services/i18nService';

class IntlProviderWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: "es"
        };
    }

    render = () => {
        const {children} = this.props;
        const {language} = this.state;

        const messages = i18nService.messages[language];

        return (
            <IntlProvider locale={language} messages={messages} defaultLocale="es">
                {children}
            </IntlProvider>
        )
    }
}

export default IntlProviderWrapper;
