import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const SALE_CHANNEL_PUNTO_DE_VENTA = "CHANNEL_PV";
export const SALE_CHANNEL_VENTA_DOMICILIARIA = "CHANNEL_DOM";
export const SALE_CHANNEL_VENTA_EN_RUTA = "CHANNEL_RUT";
export const SALE_CHANNEL_B24 = "CHANNEL_B24";
export const SALE_CHANNEL_PTB = "CHANNEL_PTB";
export const SALE_CHANNEL_FACTURABLE_POR_REPSOL = "CHANNEL_FAC_REPSOL";
export const SALE_CHANNEL_PEDIDOS_OFICINA_PREPAGADO = "CHANNEL_OFICINA_PREPAGADO";



export const getSaleChannels = async () => {
    return await apiService.get(`${URL}/sale-channels`);
};

export const addSaleChannel = async (data) => {
    return await apiService.post(`${URL}/sale-channels`, data);
};

export const updateSaleChannel = async (id, data) => {
    return await apiService.put(`${URL}/sale-channels/${id}`, data);
};
