import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MuiAddIcon from '@material-ui/icons/Add';


const AddIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><MuiAddIcon/></IconButton>
        );
    }
    return (<MuiAddIcon/>);
};

export default AddIcon;
