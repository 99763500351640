import MainLayout from '../core/layouts/main/Layout';

import {ROLE_ANY, ROLE_AGENCIA, ROLE_GERENTE, ROLE_ALMACEN} from '../../services/authService';

import EmployeesList from './management/employees/EmployeesList';
import VehiclesList from './management/vehicles/VehiclesList';
import CompaniesList from './management/companies/CompaniesList';
import WarehousesList from './management/warehouses/WarehousesList';
import PointsOfSellList from './management/pos/PointsOfSellList';
import ProductsList from './management/products/ProductsList';
import RoutesList from './management/routes/RoutesList';
import SaleChannelsList from './management/saleChannels/SaleChannelsList';
import AccountingAccount from "./management/accountingAccounts/AccountingAccounts";
import ProductLiquidationSupplementList from "./management/products/liquidationSupplements/ProductLiquidationSupplementsList";

import Authorization from './operations/authorizations/Authorization';
import AuthorizationList from './operations/authorizations/AuthorizationList';
import DeliveryConfirmationList from './operations/deliveryConfirmation/DeliveryConfirmationList';
import DeliveryNotePos from './operations/deliveryNotesPos/DeliveryNotePos';
import DeliveryNotePosList from './operations/deliveryNotesPos/DeliveryNotePosList';
import PrePaymentList from "./operations/authorizations/prePayments/PrePaymentList";
import PurchaseOrder from './operations/purchaseOrders/PurchaseOrder';
import PurchaseOrdersList from './operations/purchaseOrders/PurchaseOrdersList';
import WarehouseMovement from './operations/warehouseMovements/WarehouseMovement';
import WarehouseMovementList from './operations/warehouseMovements/WarehouseMovementList';
import WarehouseStock from './operations/warehouseStock/WarehouseStock';

import AccountingMovementList from "./accounting/movements/AccountingMovements";
import Payment from "./accounting/payments/Payment";

import DeliveryNoteInvoice from "./billing/deliveryNotes/DeliveryNoteInvoice";
import DeliveryNoteInvoiceList from "./billing/deliveryNotes/DeliveryNoteInvoiceList";
import PurchaseOrderInvoice from "./billing/purchaseOrders/PurchaseOrderInvoice";
import PurchaseOrderInvoiceList from "./billing/purchaseOrders/PurchaseOrderInvoiceList";

import Report from './report/Report';
import WarehouseStockCount from './report/warehouseStockCount/WarehouseStockCount';

import Stats from "./stats/sales/Stats";
import PurchaseStats from "./stats/purchases/PurchaseStats";
import UnsubscribeStats from "./stats/unsubscribes/UnsubscribeStats";

import Dashboard from './dashboard/Dashboard';


const routes = [
    {
        path: '/vehicles',
        layout: MainLayout,
        component: VehiclesList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.vehicles'
    },
    {
        path: '/employees',
        layout: MainLayout,
        component: EmployeesList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.employees'
    },
    {
        path: '/companies',
        layout: MainLayout,
        component: CompaniesList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.companies'
    },
    {
        path: '/warehouses',
        layout: MainLayout,
        component: WarehousesList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.warehouses'
    },
    {
        path: '/pos',
        layout: MainLayout,
        component: PointsOfSellList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.pos'
    },
    {
        path: '/products',
        layout: MainLayout,
        component: ProductsList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.products'
    },
    {
        path: '/routes',
        layout: MainLayout,
        component: RoutesList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.routes'
    },
    {
        path: '/accounting-accounts',
        layout: MainLayout,
        component: AccountingAccount,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.accountingAccounts'
    },
    {
        path: '/sale-channels',
        layout: MainLayout,
        component: SaleChannelsList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.saleChannels'
    },
    {
        path: '/product-liquidation-supplements',
        layout: MainLayout,
        component: ProductLiquidationSupplementList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.management.pls'
    },
    {
        path: '/delivery-notes/invoices',
        layout: MainLayout,
        component: DeliveryNoteInvoiceList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.billing.deliveryNotes'
    },
    {
        path: '/delivery-notes/invoices/:number',
        layout: MainLayout,
        component: DeliveryNoteInvoice,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.billing.deliveryNotes'
    },
    {
        path: '/purchase-orders/invoices',
        layout: MainLayout,
        component: PurchaseOrderInvoiceList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.billing.po'
    },
    {
        path: '/purchase-orders/invoices/:number',
        layout: MainLayout,
        component: PurchaseOrderInvoice,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.billing.po'
    },
    {
        path: '/purchase-orders',
        layout: MainLayout,
        component: PurchaseOrdersList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.po'
    },
    {
        path: '/purchase-orders/:number',
        layout: MainLayout,
        component: PurchaseOrder,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.po'
    },
    {
        path: '/authorizations',
        layout: MainLayout,
        component: AuthorizationList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.auth'
    },
    {
        path: '/authorizations/pre-payments',
        layout: MainLayout,
        component: PrePaymentList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.prePayments'
    },
    {
        path: '/authorizations/:number',
        layout: MainLayout,
        component: Authorization,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.auth'
    },
    {
        path: '/delivery-confirmations',
        layout: MainLayout,
        component: DeliveryConfirmationList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.deliveryConfirmation'
    },
    {
        path: '/delivery-notes',
        layout: MainLayout,
        component: DeliveryNotePosList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.deliveryNotesPos'
    },
    {
        path: '/delivery-notes/:number',
        layout: MainLayout,
        component: DeliveryNotePos,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.deliveryNotesPos'
    },
    {
        path: '/warehouse-movements',
        layout: MainLayout,
        component: WarehouseMovementList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.warehouse'
    },
    {
        path: '/warehouse-movements/:number',
        layout: MainLayout,
        component: WarehouseMovement,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.warehouse'
    },
    {
        path: '/stock',
        layout: MainLayout,
        component: WarehouseStock,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.movements.stock'
    },
    {
        path: '/accounting-movements',
        layout: MainLayout,
        component: AccountingMovementList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.accounting.movements'
    },
    {
        path: '/payments/new',
        layout: MainLayout,
        component: Payment,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.accounting.payments'
    },
    {
        path: '/reports/warehouse-stock-count',
        layout: MainLayout,
        component: WarehouseStockCount,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.report.stockCount'
    },
    {
        path: '/reports/closure',
        layout: MainLayout,
        component: Report,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.report.closure'
    },
    {
        path: '/stats/sales',
        layout: MainLayout,
        component: Stats,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.stats.sales'
    },
    {
        path: '/stats/purchases',
        layout: MainLayout,
        component: PurchaseStats,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.stats.purchases'
    },
    {
        path: '/stats/unsubscribes',
        layout: MainLayout,
        component: UnsubscribeStats,
        exact: true,
        private: true,
        menuSelectedItem: 'main.warehouse.stats.unsubscribes'
    },
    {
        path: '/dashboard',
        layout: MainLayout,
        component: Dashboard,
        exact: true,
        private: true,
        menuSelectedItem: 'main.dashboard',
        roles: []
    },
];

export default routes;
