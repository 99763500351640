import React from 'react';
import { withStyles } from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import MUIButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


const StyledButton = withStyles({
    root: {
        color: '#202323'
    }
})(MUIButton);


const Button = (props) => {
    const { children, float, startIcon, isLoading = false, disabled, ...other } = props;

    const isDisabled = isLoading === true || disabled === true;

    const colorStyle = (props.color === 'primary' || props.color === 'secondary') && !disabled ? {color: '#FFF'} : {};

    if (float) {
        return (<Fab disabled={isLoading || isDisabled} {...other} style={colorStyle}>{children}</Fab>)
    } else {
        return (
            <StyledButton
                startIcon={!isLoading && startIcon}
                disabled={isLoading || isDisabled}
                {...other}
            >
                <Box display="flex" width="100%">
                    <Box flexGrow={1} marginLeft="-10px" paddingRight="5px">
                        {isLoading && (
                            <CircularProgress size={22} color='inherit' style={{marginLeft: '10px', float: 'right', ...colorStyle}} />
                        )}
                    </Box>
                    <Box style={colorStyle}>
                        {children}
                    </Box>
                    <Box flexGrow={1}></Box>
                </Box>
            </StyledButton>
        )
    }

};

Button.propTypes = {
    disabled: PropTypes.bool,
    float: PropTypes.bool,
    isLoading: PropTypes.bool,
}

export default Button;
