import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as companyService from '../../../../services/domain/warehouse/companyService';
import * as validateService from "../../../../services/validateService";
import { getValueFromField } from '../../../../helpers/form';
import { fullAddressFormatter } from '../../../../helpers/formatter';

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from "../../../../components/icons/Enabled";
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";
import Modal from "../../../../components/Modal/Modal";
import CompanyForm from "./CompanyForm";



export const CompaniesList = (props) => {

    const intl = useIntl();

    const newCompany = {
        id: null,
        name: null,
        nif: null,
        address: null,
        town: null,
        province: null,
        zipCode: null,
        agencyOwner: 0,
        companyTypeId: null,
        enabled: 1
    };
    const validations = {
        nif: [{type: validateService.REQUIRED}],
        name: [{type: validateService.REQUIRED}],
        companyTypeId: [{type: validateService.REQUIRED}]
    };

    const [open, setOpen] = useState(false);
    const [company, setCompany] = useState(newCompany);
    const [companyTypeItems, setCompanyTypeItems] = useState([]);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});


    useEffect(() => {
        async function loadCompanies() {
            setLoading(true);
            const response = await companyService.getCompanies();
            if (response.status === 200) {
                setData(response.data.companies.map(item => convertRowData(item)));
                setCompanyTypeItems(companyService.getCompanyTypeItems());
            } else {

            }
            setLoading(false);
        }
        loadCompanies();
    }, [reload]);


    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.companies'})}
    ];

    const handleClose = () => {
        setOpen(false);
    };
    const handleNew = () => {
        setCompany(newCompany);
        setOpen(true);
    };
    const handleEdit = (item) => {
        setCompany({
            ...item,
            companyTypeId: item.companyType ? item.companyType.id : null
        });
        setOpen(true);
    };
    const handleChange = (e) => {
        e.preventDefault();
        setCompany({
            ...company,
            [e.target.name]: getValueFromField(e)
        })
    };

    const handleSubmit = () => {
        const err = validateService.validate(company, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            let response;
            if (company.id === null) {
                response = companyService.addCompany(company);
            } else {
                response = companyService.updateCompany(company.id, company);
            }
            response
                .then(() => {
                    setReload(reload + 1);
                    setOpen(false);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                });
        }
    };


    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"app.companies.name"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"app.companies.address"}), sublabel: null, formatter: null },
        { id: 'id3', align: "center",   label: intl.formatMessage({id:"generic.enabled"}), sublabel: null, formatter: (val) => <EnabledIcon value={val}/>, width: 80 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertRowData = item => {
        return {
            id1: item.name,
            id2: fullAddressFormatter(item.address, item.zipCode, item.town, item.province),
            id3: item.enabled,
            actions: <EditIcon button onClick={() => handleEdit(item)}/>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal open={open} title={intl.formatMessage({id: "app.companies.company"})} onClose={handleClose}>
                    <CompanyForm
                        company={company}
                        companyTypes={companyTypeItems}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
};

export default withMessageBox(CompaniesList);
