import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const getZones = async (filter) => {
    return await apiService.get(`${URL}/zones`, filter);
};

/**
 * Return array of zones not assigned to any vehicle from zones and vehicle arrays
 * @param zones
 * @param vehicles
 * @returns {*}
 */
export const getFreeZV = (zones, vehicles) => {
    return zones.filter(zone => {
        const result = vehicles.filter(vehicle => {
            if (vehicle.zones.length > 0) {
                const zns = vehicle.zones.filter(z => z.zone.name === zone.name);
                return zns.length > 0;
            } else return false;
        });

        return result.length === 0;
    });
};
/**
 * Return array of zones not assigned to any employee from zones and employee arrays
 * @param zones
 * @param vehicles
 * @returns {*}
 */
export const getFreeZE = (zones) => {
    return zones.filter(zone => {
        return zone.helpers.length === 0;
    });
};


export const toString = (zones) => {
    return zones.map(zone => zone.name);
}
