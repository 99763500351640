import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import DeleteIcon from '../../../../components/icons/Delete';
import EnabledIcon from "../../../../components/icons/Enabled";
import Table from '../../../../components/table';

import { priceFormatter } from "../../../../helpers/formatter";


export const ProductPriceTable = (props) => {
    const {supplements} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(supplements.map(item => convertRowData(item)));
    }, [supplements]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null },
        { id: 'id2', align: "right",   label: intl.formatMessage({id:"generic.domain.supplement"}), sublabel: null, formatter: priceFormatter },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.saleChannels"}), sublabel: null, formatter: null },
        { id: 'id4', align: "center",   label: intl.formatMessage({id:"app.routes.zone"}), sublabel: null, formatter: null },
        { id: 'id5', align: "center",   label: intl.formatMessage({id:"generic.from"}), sublabel: null, formatter: null },
        { id: 'id6', align: "center",   label: intl.formatMessage({id:"generic.to"}), sublabel: null, formatter: null },
        { id: 'id7', align: "center",   label: intl.formatMessage({id:"generic.weekend"}), sublabel: null, formatter: (val) => <EnabledIcon value={val}/> },
        { id: 'actions', align: "center", width: 50 },
    ];

    const convertRowData = item => {
        return {
            id1: item.supplementType.type,
            id2: item.supplementPrice,
            id3: item.saleChannel ? item.saleChannel.channel : "-",
            id4: item.zone ? item.zone.name : "-",
            id5: item.from ? item.from : "-",
            id6: item.to ? item.to : "-",
            id7: item.weekend,
            actions: <DeleteIcon button onClick={() => props.onClick(item.id)} />
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    totalRows
                />

            </Grid>
        </Grid>
    )
};

export default ProductPriceTable;
