import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Select from '../../../../components/form/Select';

import * as employeeService from '../../../../services/domain/warehouse/employeeService';



export const AssignDriverToVehicleForm = (props) => {
    const {assign, drivers, errors} = props;

    const intl = useIntl();

    const [driverItems, setDriverItems] = useState([]);

    useEffect(() => {
        setDriverItems(drivers.map(driver => {
            return {value: driver.id, label: employeeService.getFullName(driver)}
        }));
    }, [drivers]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <Select
                        name="driver"
                        label={intl.formatMessage({id: 'app.employees.driver'})}
                        items={driverItems}
                        value={assign.driver}
                        error={errors.driver}
                        onChange={props.onChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={3} style={{paddingTop: '13px'}}>
                    <Button type="button" color="primary" variant="contained" onClick={(e) => {e.preventDefault();props.onSubmit()}}>{intl.formatMessage({id: 'generic.assign'})}</Button>
                </Grid>
            </Grid>
        </form>
    )
};

export default AssignDriverToVehicleForm;