import * as apiService from '../../apiService';

const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const salesByVehicle = async (filter) => {
    return await apiService.get(`${URL}/stats/sales-by-vehicle`, filter);
}
export const salesByPos = async (filter) => {
    return await apiService.get(`${URL}/stats/sales-by-pos`, filter);
}

export const purchases = async (filter) => {
    return await apiService.get(`${URL}/stats/purchases`, filter);
}
export const unsubscribes = async (filter) => {
    return await apiService.get(`${URL}/stats/unsubscribes`, filter);
}
