import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button';
import Checkbox from '../../../../components/form/Checkbox';
import Divider from '../../../../components/Divider';
import HelperText from "../../../../components/form/HelperText";
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import { RightBox } from '../../../../components/Box';



export const ProductForm = (props) => {
    const {isAuthorization, product, products, errors} = props;

    const intl = useIntl();

    const [productItems, setProductItems] = useState([]);
    const [submitButtonLabel, setSubmitButtonLabel] = useState("");

    useEffect(() => {
        setProductItems(products.map((product) => {
            return {value: product.id, label: product.name};
        }))
    }, [products]);
    useEffect(() => {
        setSubmitButtonLabel(isAuthorization ? intl.formatMessage({id: "generic.add"}) : intl.formatMessage({id: "generic.save"}))
    }, [isAuthorization]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        name="productId"
                        label={intl.formatMessage({id: 'generic.domain.product'})}
                        items={productItems}
                        value={product.productId}
                        error={errors.productId}
                        onChange={props.onChange}
                        disabled={!isAuthorization}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.charge"})}/>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="containersAuth"
                        label={intl.formatMessage({id: 'generic.domain.containers'})}
                        value={product.containersAuth}
                        error={errors.containersAuth}
                        onChange={props.onChange}
                        disabled={!product.emptyContainers}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="chargesAuth"
                        label={intl.formatMessage({id: 'generic.domain.charges'})}
                        value={product.chargesAuth}
                        error={errors.chargesAuth}
                        onChange={props.onChange}
                        disabled={!isAuthorization}
                        required
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: '-20px'}}>
                    <Checkbox
                        name="emptyContainers"
                        label={intl.formatMessage({id: 'generic.domain.containers.empty'})}
                        value={product.emptyContainers}
                        disabled={!isAuthorization}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.return"})}/>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="containersReturned"
                        label={intl.formatMessage({id: 'generic.domain.containers'})}
                        value={product.containersReturned}
                        error={errors.containersReturned}
                        onChange={props.onChange}
                        disabled={isAuthorization}
                        required
                    />
                    <HelperText text={intl.formatMessage({id: 'generic.domain.containers.toReturn'}) + ": " + product.containersToReturn} />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="chargesReturned"
                        label={intl.formatMessage({id: 'generic.domain.charges'})}
                        value={product.chargesReturned}
                        error={errors.chargesReturned}
                        onChange={props.onChange}
                        disabled={isAuthorization}
                        required
                    />
                    <HelperText text={intl.formatMessage({id: 'generic.domain.charges.toReturnMax'}) + ": " + product.maxChargesToReturn} />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.lost"})}/>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="containersLost"
                        label={intl.formatMessage({id: 'generic.domain.containers'})}
                        value={product.containersLost}
                        error={errors.containersLost}
                        onChange={props.onChange}
                        disabled={isAuthorization}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>
                            {intl.formatMessage({id: "generic.cancel"})}
                        </Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>
                            {submitButtonLabel}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    );
};

export default ProductForm;
