import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { EnhancedTableHeader } from './EnhancedTableHeader';
import ExpandableTableRow from './ExpandableTableRow';
import { EnhancedTableFooter } from './EnhancedTableFooter';
import { SelectProps, TablePagination } from './TablePagination';
import {ExportButton} from "./ExportButton";

import {ORDER_ASC, getSorting, getToggleOrder, stableSort, exportToCSV, exportToXLSX} from './helper';


//TODO:
//import Loader from '../Loader';
const Loader = () => {return (<div>loading...</div>)};



export class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: this.props.customOrder ? this.props.customOrder : ORDER_ASC,
            orderBy: this.props.customOrderBy ? this.props.customOrderBy : "",
            selected: [],
            page: 0,
            rowsPerPage: 50,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            order: nextProps.customOrder ? nextProps.customOrder : this.state.order,
            orderBy: nextProps.customOrderBy ? nextProps.customOrderBy : this.state.orderBy,
            rowsPerPage: nextProps.rowsPerPage ? nextProps.rowsPerPage : this.state.rowsPerPage,
        })
    }

    handleRequestSort = (event, properties) => {
        const orderBy = properties.columnId;
        const isToggle = typeof properties.order === "undefined" || properties.order === "toggle";
        const order =
            orderBy !== this.state.orderBy ? ORDER_ASC :
                isToggle ? getToggleOrder(this.state.order) : properties.order;

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: this.props.data.map((n, index) => index) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            rowsPerPage: event.target.value,
            page: 0
        });
    };

    handleExport = () => {
        const {headers, data, footer, title} = this.props;
        const {order, orderBy} = this.state;
        const orderedData = stableSort(data, getSorting(order, orderBy));
        exportToXLSX(headers, orderedData, footer, title);
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes, headers, subHeaders, data, title, isChecked, noResultsText, intl, footer = {} } = this.props;
        const { isExpandable = false, fieldExpandable = "", headersExpandable = []} = this.props;
        const { isLoading = false, ordered = false, select = false, stripedRows = false, totalRows = false } = this.props;
        const { isExportable = false } = this.props;
        const { order, orderBy, selected, rowsPerPage, page } = this.state;
        const emptyRows = data.length === 0;

        return (
            <div className={classes.root} style={{width: '100%', overflow: 'auto', margin: '0'}}>
                <div style={{display: 'flex'}}>
                    <div style={{flexGrow: '1', paddingTop: '12px'}}>
                        { totalRows && (
                            <Chip
                                label={intl.formatMessage({id: "app.table.rows"}, {rows: data.length})}
                                disabled={true} variant="outlined"
                                style={{marginLeft: 20, color: 'gray', marginBottom: '10px'}}
                            />
                        )}
                        {
                            title && (<span style={{paddingLeft: '40px', color: 'gray', fontSize: '18px'}}>{title}</span>)
                        }
                    </div>
                    <div>
                        {isExportable && (<div style={{margin: '0'}}><ExportButton onClick={this.handleExport} /></div>)}
                    </div>
                </div>
                <div className={classes.tableWrapper} style={{minHeight: '90px', paddingBottom: '10px'}}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHeader
                            classes={classes}
                            numSelected={selected.length}
                            onRequestSort={this.handleRequestSort}
                            onSelectAllClick={this.handleSelectAllClick}
                            order={order}
                            orderBy={orderBy}
                            rowCount={data.length}
                            headers={headers}
                            subHeaders={subHeaders}
                            isChecked={isChecked}
                            ordered={ordered}
                        />
                        <TableBody>
                            { isLoading && (
                                <TableRow>
                                    <TableCell
                                        colSpan={headers.length}
                                    >
                                        <Loader/>
                                    </TableCell>
                                </TableRow>
                            )}
                            {
                                !isLoading &&
                                stableSort(data, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((n, index) => {
                                        const isSelected = this.isSelected(index) && select;
                                        const stripedRowClass = stripedRows ? ((index + 1) % 2 === 0 ? classes.tableStripedRow:''):classes.tableBorderedRow;

                                        if (isExpandable) {
                                            return (
                                                <ExpandableTableRow
                                                    hover
                                                    headers={headers}
                                                    rowData={n}
                                                    headersExpandable={headersExpandable}
                                                    fieldExpandable={fieldExpandable}
                                                    key={index} keyRow={index}
                                                />
                                            )

                                        } else {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={event => this.handleClick(event, index)}
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isSelected}
                                                    className={stripedRowClass}
                                                >
                                                    {
                                                        isChecked === 'true' ?
                                                            <TableCell padding="checkbox">
                                                                <Checkbox checked={isSelected}/>
                                                            </TableCell>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        headers.map((column, cellId) => {
                                                            return (
                                                                <TableCell
                                                                    key={cellId}
                                                                    component="td"
                                                                    scope="row"
                                                                    align={column.align}
                                                                    className={classes.tableCell}
                                                                >
                                                                    <div>{typeof column.formatter === 'function' ? column.formatter(n[column.id], n) : n[column.id]}</div>
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            );
                                        }
                                    })
                            }
                        </TableBody>
                        { Object.keys(footer).length > 0 && (
                            <EnhancedTableFooter
                                classes={classes}
                                headers={headers}
                                footer={footer}
                            />
                        )}
                    </Table>
                </div>
                { emptyRows && !isLoading && (
                    <Box display="flex" marginTop={10}>
                        <Box flexGrow={1}></Box>
                        <Box fontStyle="italic" color="#7b7b7f" mt={1}>{ noResultsText }</Box>
                        <Box flexGrow={1}></Box>
                    </Box>
                )}
                { ( !emptyRows && !isLoading && data.length > rowsPerPage ) && (
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        labelDisplayedRows={({from, to, count}) => intl.formatMessage({ id: "table.labelDisplayedRows" }, { from: from, to: to, count: count }) }
                        labelRowsPerPage={intl.formatMessage({ id: "table.labelRowsPerPage" })}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        SelectProps={SelectProps}
                    />
                )}
            </div>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array.isRequired,
    subHeaders: PropTypes.array,
    data: PropTypes.array.isRequired,
    footer: PropTypes.object,
    customOrderBy: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    customOrder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    isExpandable: PropTypes.bool,
    isExportable: PropTypes.bool,
    isLoading: PropTypes.bool,
    noResultsText: PropTypes.string,
    ordered: PropTypes.bool,
    title: PropTypes.string,
    totalRows: PropTypes.bool,
    rowsPerPage: PropTypes.number,
    select: PropTypes.bool,
    stripedRows: PropTypes.bool,
};

export default injectIntl(EnhancedTable);
