import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Prompt } from 'react-router-dom';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as array from '../../../../helpers/array';
import * as formatter from '../../../../helpers/formatter';
import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as bonusTypeService from '../../../../services/domain/warehouse/bonusTypeService';
import * as cageService from '../../../../services/domain/warehouse/cageService';
import * as employeeService from '../../../../services/domain/warehouse/employeeService';
import * as pointOfSaleService from '../../../../services/domain/warehouse/pointOfSaleService';
import * as productService from '../../../../services/domain/warehouse/productService';
import * as productSituationService from '../../../../services/domain/warehouse/productSituationService';
import * as productLiquidationSupplementService from '../../../../services/domain/warehouse/productLiquidationSupplementService';
import * as saleChannelService from '../../../../services/domain/warehouse/saleChannelService';
import * as validateService from '../../../../services/validateService';
import * as vehicleService from '../../../../services/domain/warehouse/vehicleService';
import * as warehouseService from '../../../../services/domain/warehouse/warehouseService';
import {getValueFromField, TYPE_INTEGER} from '../../../../helpers/form';
import {createUUID} from "../../../../helpers/utils";

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AlertDialog from "../../../../components/AlertDialog";
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Loader from '../../../../components/Loader/Loader';
import PrintButton from "../../../../components/form/Button/print";
import TabPanel from '../../../../components/tabs/TabPanel';
import { RightBox } from "../../../../components/Box/index";
import RouterPrompt from "../../../../components/RouterPrompt/RouterPrompt";

import AuthorizationForm from './AuthorizationForm';
import ChargeTab from './ChargeTab';
import DeliveryNotesPosTab from './DeliveryNotesPosTab';
import LiqTab from './LiqTab';
import ReturnTab from './ReturnTab';



export const Authorization = (props) => {

    const intl = useIntl();

    const newAut = {
        id: null,
        number: null,
        authDate: formatter.today(),
        liqDate: null,
        warehouseId: null,
        vehicleId: null,
        driverId: null,
        renting: false,
        zones: [],
        authAmount: 0,
        returnedAmount: 0,
        commissionA: 0,
        incidenceBalance: 0,
        containersLost: 0,
        rentalAmount: 0,
        supplementsAmount: 0,
        lostAmount: 0,
        bankAmount: 0,
        cashAmount: 0,
        creditCardAmount: 0,
        posAmount: 0,
        dmfAmount: 0,
        bonusAmount: 0,
        operationNumber: null,
        products: [],
        cages: [],
        deliveryNotesPos: [],
        customerManagement: [],
        bankIncomes: [],
        bonusIncomes: [],
        vehicle: null,
        driver: null,
        totalAuthAmount: 0,
        totalLiqAmount: 0,
        totalIncomesAmount: 0,
        totalAmount: 0,
        supplements : []
    };
    const authValidations = {
        authDate: [{type: validateService.REQUIRED}],
    };
    const newProduct = {
        id: null,
        productId: null,
        containersAuth: 0,
        chargesAuth: 0,
        containersToReturn: 0,
        chargesToReturn: 0,
        containersReturned: 0,
        chargesReturned: 0,
        containersToPos: 0,
        chargesToPos: 0,
        basePrice: 0,
        maxPrice: 0,
        containersLost: 0,
        containersFound: 0,
        order: 1,
        emptyContainers: false
    };
    const productAuthValidations = {
        productId: [{type: validateService.REQUIRED}],
        containersAuth: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        chargesAuth: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    };
    const productLiqValidations = {
        productId: [{type: validateService.REQUIRED}],
        containersReturned: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        chargesReturned: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        containersLost: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    };

    const newCage = {
        id: null,
        cageId: null,
        unitsAuth: 0,
        unitsReturned: 0
    }
    const cageAuthValidations = {
        cageId: [{type: validateService.REQUIRED}],
        unitsAuth: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_THAN_ZERO}],
    };
    const cageLiqValidations = {
        cageId: [{type: validateService.REQUIRED}],
        unitsReturned: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    };

    const newCustomerManagement = {
        id: null,
        productId: null,
        productSituationId: null,
        containers: 0,
        charges: 0,
        number: null,
        customer: null,
        done: false,
        inPos: false, //Altas de clientes que se dejan en gasolineras
        zone: "-" //TODO: ojo, cambiar por zoneId o buscarla por el num de póliza
    };
    const customerManagementValidations = {
        productId: [{type: validateService.REQUIRED}],
        productSituationId: [{type: validateService.REQUIRED}],
        containers: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_THAN_ZERO}],
        charges: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        number: [{type: validateService.MAX_LENGTH, value: 15}],
        customer: [{type: validateService.REQUIRED}],
        zone: [{type: validateService.REQUIRED}],
    };

    const newDeliveryNotePos = {
        uuid: null,
        id: null,
        number: null,
        posId: null,
        date: formatter.dateApiFormatter(),
        products: [],
        paid: false,
        done: true
    };
    const deliveryNotePosValidations = {
        posId: [{type: validateService.REQUIRED}],
        number: [{type: validateService.MAX_LENGTH, value: 15}],
    };

    const newDeliveryNotePosProduct = {
        productId: null,
        productSituationId: null,
        containers: 0,
        charges: 0
    };
    const deliveryNotePosProductValidations = {
        productId: [{type: validateService.REQUIRED}],
        containers: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        charges: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    };

    const newBankIncome = {
        id: null,
        office: null,
        amount: 0,
        date: null
    }
    const bankIncomeValidations = {
        office: [{type: validateService.REQUIRED}],
        amount: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_THAN_ZERO}],
        date: [{type: validateService.REQUIRED}, {type: validateService.DATE_FORMAT}],
    }
    const newBonusIncome = {
        id: null,
        bonusTypeId: null,
        saleChannelId: null,
        productId: null,
        referenceNumber: null,
        description: null,
        charges: null,
        amount: 0,
        uuid: createUUID()
    }
    const bonusIncomeValidations = {
        charges: [{type: validateService.NUMBER_GREATER_THAN_ZERO}],
        amount: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_NON_ZERO}],
        bonusTypeId: [{type: validateService.REQUIRED}],
        productId: [{type: validateService.REQUIRED}],
    }

    const newSupplement = {
        productId: null,
        charges: 0,
        supplement: 0,
        supplementAmount: 0,
        description: null,
        uuid: createUUID()
    }
    const supplementValidations = {
        productId: [{type: validateService.REQUIRED}],
        charges: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_NON_ZERO}],
        description: [{type: validateService.REQUIRED}],
    }

    const [activeTab, setActiveTab] = useState(0);
    const [authorization, setAuthorization] = useState(newAut);
    const [bankIncome, setBankIncome] = useState(newBankIncome);
    const [bonusIncome, setBonusIncome] = useState(newBonusIncome);
    const [cage, setCage] = useState(newCage);
    const [cages, setCages] = useState([]);
    const [customerManagement, setCustomerManagement] = useState(newCustomerManagement);
    const [customerManagementIndex, setCustomerManagementIndex] = useState(-1);
    const [deliveryNotePos, setDeliveryNotePos] = useState(newDeliveryNotePos);
    const [deliveryNotePosProduct, setDeliveryNotePosProduct] = useState(newDeliveryNotePosProduct);
    const [drivers, setDrivers] = useState([]);
    const [errorsForm, setErrorsForm] = useState({});
    const [errorsProduct, setErrorsProduct] = useState({});
    const [errorsCage, setErrorsCage] = useState({});
    const [errorsCustomerManagement, setErrorsCustomerManagement] = useState({});
    const [errorsDeliveryNotePos, setErrorsDeliveryNotePos] = useState({});
    const [errorsDeliveryNotePosProduct, setErrorsDeliveryNotePosProduct] = useState({});
    const [errorsBankIncome, setErrorsBankIncome] = useState({});
    const [errorsBonusIncome, setErrorsBonusIncome] = useState({});
    const [errorsSupplement, setErrorsSupplement] = useState({});
    const [loading, setLoading] = useState(true);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openCageModal, setOpenCageModal] = useState(false);
    const [openProductModal, setOpenProductModal] = useState(false);
    const [pos, setPos] = useState([]);
    const [product, setProduct] = useState(newProduct);
    const [products, setProducts] = useState([]);
    const [readOnly, setReadOnly] = useState(false);
    const [refreshGrids, setRefreshGrids] = useState(0);
    const [saleChannels, setSaleChannels] = useState([]);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [supplement, setSupplement] = useState(newSupplement);
    const [vehicles, setVehicles] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        const number = props.match.params.number;

        async function loadAut() {}
        async function load() {
            const responseC = await cageService.getCages();
            const drivers = await employeeService.getDrivers();
            const responseP = await productService.getProducts();
            const responsePOS = await pointOfSaleService.getPointOfSale();
            const responseSC = await saleChannelService.getSaleChannels();
            const responseV = await vehicleService.getVehicles();
            const responseW = await warehouseService.listWarehouses();
            if (
                responseP &&
                responseC.status === 200 &&
                responseP.status === 200 &&
                responsePOS.status === 200 &&
                responseSC.status === 200 &&
                responseV.status === 200 &&
                responseW.status === 200
            ) {
                setDrivers(drivers);
                setCages(responseC.data.cages);
                setPos(responsePOS.data.pointsOfSale.filter(pos => pos.enabled === 1).sortBy('name')); //TODO: poner filtro en la request
                setProducts(responseP.data.products.sortBy('name', 'DESC'));
                setSaleChannels(responseSC.data.saleChannels.sortBy('channel'));
                setVehicles(responseV.data.vehicles.filter(vehicle => vehicle.enabled === 1)); //TODO: poner filtro en la request
                setWarehouses(responseW.data.warehouses.filter(w => w.enabled === true)); //TODO: poner filtro en la request
                setLoading(false);
                if (number === 'new') {
                    setAuthorization(newAut);
                } else if (
                    props.location.state &&
                    props.location.state.authorization &&
                    props.location.state.authorization.number === number
                ) {
                    //TODO: nomenclatura diferente en la API. Arreglarlo bien
                    let objAuth = props.location.state.authorization;
                    let supplementsToApply;
                    let bonusIncomes = [];

                    if (authorizationService.isLiquidation(objAuth)) {
                        const responsePLS = await productLiquidationSupplementService.getSupplements(null, null, objAuth.driver.id);
                        if (responsePLS.status === 200) {
                            supplementsToApply = productLiquidationSupplementService.hydrate(responsePLS.data);
                        }
                        objAuth.deliveryNotesPos.map(item => item.uuid = createUUID());
                        objAuth.cages.map(cage => cage.unitsReturned = cage.unitsAuth);
                    }
                    if (authorizationService.isLiquidated(objAuth)) {
                        setSaveButtonDisabled(true);
                        bonusIncomes = objAuth.bonusIncomes.map(bonus => {
                            const productDetail = objAuth.productDetails.filter(pd => pd.bonusIncomeId === bonus.id);
                            if (productDetail.length === 0) return bonus;
                            return {
                                ...bonus,
                                saleChannelId: productDetail[0].saleChannel ? productDetail[0].saleChannel.id : null,
                                productId: productDetail[0].product.id,
                                referenceNumber: productDetail[0].referenceNumber,
                                description: productDetail[0].obs
                            }
                        });
                    }
                    objAuth = {
                        ...objAuth,
                        vehicleId: objAuth.vehicle.id,
                        warehouseId: objAuth.warehouse.id,
                        driverId: objAuth.driver.id,
                        driver: employeeService.getFullName(objAuth.driver),
                        saleChannels: responseSC.data.saleChannels,
                        bonusIncomes: bonusIncomes,
                        supplementsToApply: supplementsToApply,
                        lostAmount: 0 //TODO: no viene en el GET. Modificar el response para que venga en las liq. ya liquidadas
                    }

                    authorizationService.calculateDeliveryNoteProduct(objAuth);
                    authorizationService.calculateAmounts(objAuth);
                    setAuthorization(objAuth);
                } else {
                    loadAut(); //TODO:
                }
            } else {
            }
        }
        load();
    }, []);


    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.aut-liq'})}
    ];

    const handleChangeTab = (e, newValue) => {
        setActiveTab(newValue);
    };

    const handleNew = () => {
        setAuthorization(newAut);
        setProduct(newProduct);
        setCage(newCage);
        setCustomerManagement(newCustomerManagement);
        setDeliveryNotePos(newDeliveryNotePos);
        setDeliveryNotePosProduct(newDeliveryNotePosProduct);
        setBankIncome(newBankIncome);
        setBonusIncome(newBonusIncome);
        setSaveButtonDisabled(false);
        setActiveTab(0);
        props.history.push('/authorizations/new');
    }
    const handleChange = (e) => {
        hidrate(e);
    };
    const hidrate = (e) => {
        const value = getValueFromField(e);
        let vehicle;
        switch (e.target.name) {
            case 'vehicleId':
                vehicle = array.getObjectById(vehicles, value);
                setAuthorization({
                    ...authorization,
                    vehicleId: value,
                    vehicle: vehicle,
                    driverId: vehicle.drivers.length > 0 ? vehicle.drivers[0].id : null,
                    zones: vehicleService.getAssignedZonesFromVehicle(vehicle, false),
                    incidenceBalance: vehicle.drivers.length > 0 ? vehicle.drivers[0].incidenceBalanceGas : 0,
                });
                break;

            case 'driverId':
                vehicle = employeeService.getVehicleFromDriverId(drivers, value);
                const driver = array.getObjectById(drivers, value);
                setAuthorization({
                    ...authorization,
                    driverId: value,
                    vehicleId: vehicle ? vehicle.id : null,
                    vehicle: vehicle,
                    zones: vehicle ? vehicleService.getAssignedZonesFromVehicle(vehicle, false) : [],
                    incidenceBalance: driver ? driver.incidenceBalanceGas : 0,
                });
                break;

            default:
                setAuthorization({
                    ...authorization,
                    [e.target.name]: value
                });
        }
    };
    const handleSubmit = () => {
        const errors = [];
        let response;
        if (authorizationService.isAuthorization(authorization)) {
            if (authorizationService.isReadyForAut(authorization, errors)) {
                setLoading(true);
                response = authorizationService.authorize(authorization);
            } else {
                props.errorMessage(intl.formatMessage({id: errors[0].id}, errors[0].vars));
                return null;
            }
        } else {
            if (authorizationService.isReadyForLiq(authorization, errors)) {
                setLoading(true);
                response = authorizationService.liquidate(authorization);
            } else {
                props.errorMessage(intl.formatMessage({id: errors[0].id}, errors[0].vars));
                return null;
            }
        }
        response
            .then(resp => {
                if (resp) {
                    if (authorizationService.isAuthorization(authorization)) {
                        setAuthorization({
                            ...authorization,
                            id: resp.data.id,
                            number: resp.data.number
                        });
                    }
                    if (authorizationService.isLiquidation(authorization)) {
                        setAuthorization({
                            ...authorization,
                            liqDate: resp.data.liqDate
                        });
                    }
                    setSaveButtonDisabled(true);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                } else {
                    props.errorMessage(intl.formatMessage({id: "app.error.code.ECONNABORTED"}));
                }
            })
            .catch(err => {
                let message = "Error";
                if (err.response && err.response.data) message = err.response.data.message;
                props.errorMessage(message);
                console.error('Error: ', err);
            })
            .finally(() => setLoading(false));
    };

    const handleDownload = () => {
        authorizationService.download(authorization);
    }
    const handleDownloadLoadDoc = () => {
        authorizationService.downloadAuthLoad(authorization);
    }

    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }
    const handleCancel = () => {
        setOpenConfirmation(false);
        setLoading(true);
        const response = authorizationService.cancel(authorization);
        response
            .then(resp => {
                props.successMessage(intl.formatMessage({id: "generic.canceled.confirmation"}));
            })
            .catch(err => {
                let message = "Error";
                if (err.response && err.response.data) message = err.response.data.message;
                props.errorMessage(message);
                console.error('Error: ', err.response);
            })
            .finally(() => setLoading(false));
    }

    /** product */
    const existProduct = () => {
        return authorization.products.filter(item => {
            return item.productId === product.productId;
        }).length > 0;
    };

    const handleSubmitProduct = () => {
        let err;
        if (authorizationService.isAuthorization(authorization)) {
            err = validateService.validate(product, productAuthValidations);
            setErrorsProduct(err);
            if (!existProduct() && !validateService.hasErrors(err)) {
                authorization.products.push(product);
                setAuthorization(authorizationService.calculateAmounts(authorization));
                setProduct(newProduct);
                setRefreshGrids(refreshGrids + 1);
                setOpenProductModal(false);
            }
        } else {
            err = validateService.validate(product, productLiqValidations);
            setErrorsProduct(err);
            if (!validateService.hasErrors(err)) {
                const index = authorization.products.getIndexObjectByKeyValue('id', product.id);
                authorization.products[index] = product;
                setAuthorization(authorizationService.calculateAmounts(authorization));
                setRefreshGrids(refreshGrids + 1);
                setOpenProductModal(false);
            }
        }
    };
    const handleNewProduct = () => {
        setOpenProductModal(true);
        setProduct(newProduct);
    };
    const handleEditProduct = (item) => {
        setOpenProductModal(true);
        setProduct({
            ...item,
            productId: item.product.id
        });
    };
    const handleDeleteProduct = (item) => {
        authorization.products = authorization.products.filter(product => {
            return product.productId !== item.productId;
        });
        setRefreshGrids(refreshGrids + 1);
    };
    const handleChangeProduct = (e) => {
        hidrateProduct(e);
    };
    const hidrateProduct = (e) => {
        const value = getValueFromField(e, newProduct);
        switch (e.target.name) {
            case 'productId':
                const prod = array.getObjectById(products, value);
                setProduct({
                    ...product,
                    [e.target.name]: value,
                    basePrice: parseFloat(prod.productPrice.basePrice),
                    maxPrice: parseFloat(prod.productPrice.authMaxPrice)
                });
                break;

            case 'chargesAuth':
                setProduct({
                    ...product,
                    containersAuth: value,
                    chargesAuth: value,
                });
                break;

            default:
                setProduct({
                    ...product,
                    [e.target.name]: value
                });
        }
    };

    /** cage */
    const existCage = () => {
        return authorization.cages.filter(item => {
            return item.cageId === cage.cageId;
        }).length > 0;
    };
    const handleSubmitCage = () => {
        let err;
        if (authorizationService.isAuthorization(authorization)) {
            err = validateService.validate(cage, cageAuthValidations);
            setErrorsProduct(err);
            if (!existCage() && !validateService.hasErrors(err)) {
                authorization.cages.push(cage);
                setAuthorization(authorizationService.calculateAmounts(authorization));
                setCage(newCage);
                setRefreshGrids(refreshGrids + 1);
                setOpenCageModal(false);
            }
        } else {
            err = validateService.validate(cage, cageLiqValidations);
            setErrorsProduct(err);
            if (!validateService.hasErrors(err)) {
                const index = authorization.cages.getIndexObjectByKeyValue('id', cage.id);
                authorization.cages[index] = cage;
                setAuthorization(authorizationService.calculateAmounts(authorization));
                setRefreshGrids(refreshGrids + 1);
                setOpenCageModal(false);
            }
        }
    }
    const handleNewCage = () => {
        setOpenCageModal(true);
        setCage(newCage);
    }
    const handleEditCage = (item) => {
        setOpenCageModal(true);
        setCage({
            ...item,
            cageId: item.cage.id
        });
    }
    const handleDeleteCage = (item) => {
        authorization.cages = authorization.cages.filter(cage => {
            return cage.cageId !== item.cageId;
        });
        setRefreshGrids(refreshGrids + 1);
    }
    const handleChangeCage = (e) => {
        const value = getValueFromField(e, newCage);
        setCage({
            ...cage,
            [e.target.name]: value
        });
    }

    /** customer management */
    const handleSubmitCustomerManagement = () => { //TODO: quan es retoca, netejar les devolucions
        let err;
        if (!authorizationService.isLiquidated(authorization)) {
            err = validateService.validate(customerManagement, customerManagementValidations);
            setErrorsCustomerManagement(err);
            if (!validateService.hasErrors(err)) {
                authorizationService.removeProductReturned(authorization, customerManagement.productId);
                if (
                    !authorizationService.hasProductInAuthorization(authorization, customerManagement.productId) &&
                    productSituationService.isBajaSituationById(customerManagement.productSituationId)
                ) {
                    const prod = array.getObjectById(products, customerManagement.productId);
                    const productToAdd = {
                        ...newProduct,
                        productId: customerManagement.productId,
                        product: prod,
                        basePrice: parseFloat(prod.productPrice.basePrice),
                        maxPrice: parseFloat(prod.productPrice.authMaxPrice),
                        containersReturned: null,
                        chargesReturned: null
                    }
                    authorization.products.push(productToAdd);
                    setRefreshGrids(refreshGrids + 1);
                }

                const errors = [];
                if (authorizationService.isReadyForAddCustomerManagement(authorization, customerManagement, errors)) {
                    if (customerManagementIndex === -1)
                        authorization.customerManagement.push(customerManagement);
                    else {
                        authorization.customerManagement[customerManagementIndex] = customerManagement;
                        setCustomerManagementIndex(-1);
                    }
                    setCustomerManagement(newCustomerManagement);
                    setRefreshGrids(refreshGrids + 1);
                    authorizationService.calculateAmounts(authorization);
                } else {
                    props.errorMessage(intl.formatMessage({id: errors[0].id}));
                    return null;
                }
            }
        }
    };
    const handleNewCustomerManagement = () => {
        setCustomerManagementIndex(-1);
        setCustomerManagement(newCustomerManagement);
    };

    const handleEditCustomerManagement = (item) => {
        const customerManagementRows = authorization.customerManagement.filter(customerManagement => {
            return (
                customerManagement.productId === item.productId &&
                customerManagement.productSituationId === item.productSituationId &&
                customerManagement.customer === item.customer
            )
        });
        const index = authorization.customerManagement.getIndexObject(customerManagementRows[0]);
        setCustomerManagementIndex(index);
        setCustomerManagement({
            ...customerManagementRows[0],
            productId: customerManagementRows[0].product ? customerManagementRows[0].product.id : customerManagementRows[0].productId,
            productSituationId: customerManagementRows[0].situation ? customerManagementRows[0].situation.id : customerManagementRows[0].productSituationId
        });
    }
    const handleDeleteCustomerManagement = (item) => {
        authorization.customerManagement = authorization.customerManagement.filter(customerManagement => {
            const itemProductId = item.product ? item.product.id : item.productId;
            const itemProductSituationId = item.situation ? item.situation.id : item.productSituationId;
            const cmProductId = customerManagement.product ? customerManagement.product.id : customerManagement.productId;
            const cmProductSituationId = customerManagement.situation ? customerManagement.situation.id : customerManagement.productSituationId;
            return !(
                cmProductId === itemProductId &&
                cmProductSituationId === itemProductSituationId &&
                customerManagement.customer === item.customer &&
                customerManagement.zone === item.zone
            );
        });
        setRefreshGrids(refreshGrids + 1);
        authorizationService.calculateAmounts(authorization);
    };
    const handleChangeCustomerManagement = (e) => {
        const value = getValueFromField(e);
        switch (e.target.name) {
            case 'containers':
                if (!productSituationService.isBajaSituationById(customerManagement.productSituationId)) {
                    setCustomerManagement({
                        ...customerManagement,
                        containers: value,
                        charges: value
                    });
                } else {
                    setCustomerManagement({
                        ...customerManagement,
                        containers: value,
                        charges: 0
                    });
                }
                break;
            case 'charges':
                if (!productSituationService.isBajaSituationById(customerManagement.productSituationId)) {
                    setCustomerManagement({
                        ...customerManagement,
                        charges: value,
                        containers: value
                    });
                } else {
                    setCustomerManagement({
                        ...customerManagement,
                        charges: value
                    });
                }
                break;
            default:
                setCustomerManagement({
                    ...customerManagement,
                    [e.target.name]: value
                });
        }
    };
    const handleToggleCustomerManagement = (item, field) => {
        authorization.customerManagement.map(cm => {
            if (
                cm.productId === item.productId &&
                cm.productSituationId === item.productSituationId &&
                cm.customer === item.customer
            ) {
                cm[field] = !cm[field];
                setRefreshGrids(refreshGrids + 1);
            }
        });
        authorizationService.calculateAmounts(authorization);
    }

    /** delivery notes */
    const getIndexDeliveryPos = (item) => {
        for (let i = 0; i < authorization.deliveryNotesPos.length; i++) {
            if (item.uuid === authorization.deliveryNotesPos[i].uuid) return i;
        }
        return -1;
    }
    const handleSubmitDeliveryNotePos = () => {
        const err = validateService.validate(deliveryNotePos, deliveryNotePosValidations);
        setErrorsDeliveryNotePos(err);
        if (!validateService.hasErrors(err) && deliveryNotePos.products.length > 0) {
            const index = getIndexDeliveryPos(deliveryNotePos);
            if (index === -1)
                authorization.deliveryNotesPos.push({
                    ...deliveryNotePos,
                    uuid: createUUID()
                });
            else
                authorization.deliveryNotesPos[index] = {...deliveryNotePos};
            setDeliveryNotePos(newDeliveryNotePos);
            setDeliveryNotePosProduct(newDeliveryNotePosProduct);
            setRefreshGrids(refreshGrids + 1);
            authorizationService.calculateDeliveryNoteProduct(authorization);
            authorizationService.calculateAmounts(authorization);
        }
    };
    const handleNewDeliveryNotePos = () => {
        setDeliveryNotePosProduct(newDeliveryNotePosProduct);
        setDeliveryNotePos(newDeliveryNotePos);
    }
    const handleEditDeliveryNotePos = (item) => {
        setDeliveryNotePos({
            ...item,
            posId: item.pos ? item.pos.id : item.posId
        });
        authorizationService.calculateDeliveryNoteProduct(authorization);
    }

    const handleDeleteDeliveryNotePos = (item) => {
        authorization.deliveryNotesPos = authorization.deliveryNotesPos.filter(deliveryNotePos => {
            return deliveryNotePos.posId !== item.posId;
        });
        setRefreshGrids(refreshGrids + 1);
        authorizationService.calculateDeliveryNoteProduct(authorization);
        authorizationService.calculateAmounts(authorization);
    };
    const handleChangeDeliveryNotePos = (e) => {
        setDeliveryNotePos({
            ...deliveryNotePos,
            [e.target.name]: getValueFromField(e, authorization)
        });
    };

    const handlePrintDeliveryNotePos = (item) => {
        if (item.id) {
            authorizationService.downloadAuthDeliveryNote(item);
        }
    }

    /** delivery notes - product */
    const existPosProduct = () => {
        return deliveryNotePos.products.filter(item => {
            return item.productId === deliveryNotePosProduct.productId &&
                item.productSituationId === deliveryNotePosProduct.productSituationId
        }).length > 0;
    };
    const handleSubmitDeliveryNotePosProduct = () => {
        const err = validateService.validate(deliveryNotePosProduct, deliveryNotePosProductValidations);
        setErrorsDeliveryNotePosProduct(err);
        if (!validateService.hasErrors(err) && !existPosProduct()) {
            const errors = [];
            if (authorizationService.isReadyForAddProductInDeliveryNote(authorization, deliveryNotePosProduct, errors)) {
                deliveryNotePos.products.push(deliveryNotePosProduct);
                setDeliveryNotePosProduct(newDeliveryNotePosProduct);
                setRefreshGrids(refreshGrids + 1);
            } else {
                props.errorMessage(intl.formatMessage({id: errors[0].id}));
                return null;
            }
        }
    };
    const handleDeleteDeliveryNotePosProduct = (item) => {
        deliveryNotePos.products = deliveryNotePos.products.filter(product => {
            return !(product.productId === item.productId &&
                product.productSituationId === item.productSituationId)
        });
        setRefreshGrids(refreshGrids + 1);
    };
    const handleChangeDeliveryNotePosProduct = (e) => {
        const value = getValueFromField(e);
        switch (e.target.name) {
            case 'containers':
                if (!productSituationService.isBajaSituationById(deliveryNotePosProduct.productSituationId)) {
                    setDeliveryNotePosProduct({
                        ...deliveryNotePosProduct,
                        containers: value,
                        charges: value
                    });
                } else {
                    setDeliveryNotePosProduct({
                        ...deliveryNotePosProduct,
                        containers: value,
                        charges: 0
                    });
                }
                break;
            case 'charges':
                if (!productSituationService.isBajaSituationById(deliveryNotePosProduct.productSituationId)) {
                    setDeliveryNotePosProduct({
                        ...deliveryNotePosProduct,
                        containers: value,
                        charges: value
                    });
                } else {
                    setDeliveryNotePosProduct({
                        ...deliveryNotePosProduct,
                        charges: value
                    });
                }
                break;
            default:
                setDeliveryNotePosProduct({
                    ...deliveryNotePosProduct,
                    [e.target.name]: value
                });
        }
    };

    /** Return */
    const handleCloseReturnModals = () => {
        setOpenProductModal(false);
        setOpenCageModal(false);
    }

    /** liq */
    const handleChangeLiq = (e) => {
        let authorizationUpdated = {
            ...authorization,
            [e.target.name]: getValueFromField(e, null, "float")
        }
        authorizationUpdated = authorizationService.calculateAmounts(authorizationUpdated);
        setAuthorization(authorizationUpdated);
    }

    /** Bank income */
    const handleSubmitBankIncome = () => {
        let err = validateService.validate(bankIncome, bankIncomeValidations);
        setErrorsBankIncome(err);
        if (!validateService.hasErrors(err)) {
            authorization.bankIncomes.push(bankIncome);
            setBankIncome(newBankIncome);
            setRefreshGrids(refreshGrids + 1);
            authorizationService.calculateAmounts(authorization);
        }
    }
    const handleNewBankIncome = () => {
        setBankIncome(newBankIncome);
    }
    const handleEditBankIncome = (item) => {}
    const handleDeleteBankIncome = (item) => {
        const index = authorization.bankIncomes.getIndexObject(item);
        authorization.bankIncomes.splice(index, 1);
        setRefreshGrids(refreshGrids + 1);
        authorizationService.calculateAmounts(authorization);
    }
    const handleChangeBankIncome = (e) => {
        setBankIncome({
            ...bankIncome,
            [e.target.name]: getValueFromField(e, newBankIncome)
        });
    }
    /** Bonus income */
    const handleSubmitBonusIncome = () => {
        if (bonusIncome.referenceNumber && authorization.bonusIncomes.keyValueExists('referenceNumber', bonusIncome.referenceNumber)) {
            props.errorMessage(intl.formatMessage(
                {id: 'app.error.code.FIELD_ALREADY_EXISTS'},
                {field: intl.formatMessage({id: 'generic.domain.referenceNumber'})}
                ));
            return;
        }
        let validations = {...bonusIncomeValidations};
        if (bonusTypeService.isDiscount(bonusIncome.bonusTypeId)) {
            validations = {
                ...validations,
                charges: [{type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
            }
        }
        if (bonusTypeService.referenceNumberRequired(bonusIncome.bonusTypeId)) {
            validations = {
                ...validations,
                referenceNumber: [{type: validateService.REQUIRED}]
            }
        }
        let err = validateService.validate(bonusIncome, validations);
        setErrorsBonusIncome(err);
        if (!validateService.hasErrors(err)) {
            const index = authorization.bonusIncomes.getIndexObjectByKeyValue('uuid', bonusIncome.uuid);
            if (index === -1) authorization.bonusIncomes.push(bonusIncome);
            else authorization.bonusIncomes[index] = {...bonusIncome};
            setBonusIncome(newBonusIncome);
            setRefreshGrids(refreshGrids + 1);
            authorizationService.calculateAmounts(authorization);
        }
    }
    const handleNewBonusIncome = () => {
        setBonusIncome(newBonusIncome);
    }
    const handleEditBonusIncome = (item) => {
        const bonusIncomes = authorization.bonusIncomes.filter(bi => bi.uuid === item.uuid);
        setBonusIncome(bonusIncomes[0]);
    }
    const handleDeleteBonusIncome = (item) => {
        const index = authorization.bonusIncomes.getIndexObject(item);
        authorization.bonusIncomes.splice(index, 1);
        setRefreshGrids(refreshGrids + 1);
        authorizationService.calculateAmounts(authorization);
    }
    const handleChangeBonusIncome = (e) => {
        switch (e.target.name) {
            case "bonusTypeId":
                const bonusTypeId = getValueFromField(e, newBonusIncome);
                const saleChannel = bonusTypeService.getSaleChannel(bonusTypeId, saleChannels);
                setBonusIncome({
                    ...bonusIncome,
                    bonusTypeId: bonusTypeId,
                    saleChannelId: saleChannel !== null ? saleChannel.id : null,
                    productId: bonusTypeService.isDiscount(bonusTypeId) ? bonusTypeService.getProductIdFromDiscount(bonusTypeId, products) : null
                });
                break;
            case "charges":
                const charges = getValueFromField(e, null, TYPE_INTEGER);
                if (bonusIncome.productId !== null) {
                    const product = authorization.products.filter(authProd => {
                        return authProd.productId === bonusIncome.productId || authProd.product.id === bonusIncome.productId
                    })[0];
                    const amount = charges !== null && product ? charges * product.basePrice : 0;

                    setBonusIncome({
                        ...bonusIncome,
                        charges: charges,
                        amount: amount.toFixed(2)
                    });
                } else {
                    setBonusIncome({
                        ...bonusIncome,
                        charges: charges
                    });
                }
                break;

            case "amount":
                setBonusIncome({
                    ...bonusIncome,
                    amount: getValueFromField(e, newBonusIncome)
                });
                break;

            default:
                setBonusIncome({
                    ...bonusIncome,
                    [e.target.name]: getValueFromField(e, newBonusIncome)
                });
        }
    }

    /** Supplements */
    const handleSubmitSupplement = () => {
        let err = validateService.validate(supplement, supplementValidations);
        setErrorsSupplement(err);
        if (!validateService.hasErrors(err)) {
            authorization.supplements.push(supplement);
            setSupplement(newSupplement);
            setRefreshGrids(refreshGrids + 1);
            authorizationService.calculateAmounts(authorization);
        }
    }
    const handleNewSupplement = () => {
        setSupplement(newSupplement);
    }
    const handleEditSupplement = (item) => {}
    const handleDeleteSupplement = (item) => {
        const index = authorization.supplements.getIndexObjectByKeyValue('uuid', item.uuid);
        authorization.supplements.splice(index, 1);
        setRefreshGrids(refreshGrids + 1);
        authorizationService.calculateAmounts(authorization);
    }
    const handleChangeSupplement = (e) => {
        setSupplement({
            ...supplement,
            [e.target.name]: getValueFromField(e, newSupplement)
        });
    }

    return (
        <Grid container>
            <RouterPrompt
                when={!saveButtonDisabled}
                title={intl.formatMessage({id: 'generic.domain.authorization'})}
                message="No ha guardado la Autorización/Liquidación. ¿Desea salir?"
                state={{back: true}}
            />
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            {loading && (
                <Grid item xs={12}><Loader/></Grid>
            )}
            {!loading && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <AuthorizationForm
                            authorization={authorization}
                            drivers={drivers}
                            vehicles={vehicles}
                            warehouses={warehouses}
                            errors={errorsForm}
                            loading={loading}
                            readOnly={readOnly}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Grid item xs={12} style={{padding : '20px 0 0 0'}}>
                        <Paper>
                            <AppBar position="static" color="primary">
                                <Tabs
                                    variant="fullWidth"
                                    value={activeTab}
                                    onChange={handleChangeTab}
                                >
                                    <Tab label={intl.formatMessage({id: 'generic.domain.charge'})} />
                                    <Tab label={intl.formatMessage({id: 'generic.domain.return'})} />
                                    <Tab label={intl.formatMessage({id: 'generic.domain.pos.plural'})} />
                                    <Tab label={intl.formatMessage({id: 'generic.domain.liquidation'})} />
                                </Tabs>
                            </AppBar>
                            <TabPanel index={0} value={activeTab}>
                                <ChargeTab
                                    authorization={authorization}
                                    customerManagement={customerManagement}
                                    product={product}
                                    products={products}
                                    cage={cage}
                                    cages={cages}
                                    errors={errorsProduct}
                                    errorsCage={errorsCage}
                                    errorsCustomerManagement={errorsCustomerManagement}
                                    readOnly={readOnly}
                                    refreshGrids={refreshGrids}

                                    handleChangeProduct={handleChangeProduct}
                                    handleSubmitProduct={handleSubmitProduct}
                                    handleNewProduct={handleNewProduct}
                                    handleDeleteProduct={handleDeleteProduct}

                                    handleChangeCage={handleChangeCage}
                                    handleSubmitCage={handleSubmitCage}
                                    handleNewCage={handleNewCage}
                                    handleDeleteCage={handleDeleteCage}

                                    handleChangeCustomerManagement={handleChangeCustomerManagement}
                                    handleToggleCustomerManagement={handleToggleCustomerManagement}
                                    handleSubmitCustomerManagement={handleSubmitCustomerManagement}
                                    handleNewCustomerManagement={handleNewCustomerManagement}
                                    handleEditCustomerManagement={handleEditCustomerManagement}
                                    handleDeleteCustomerManagement={handleDeleteCustomerManagement}
                                />
                            </TabPanel>
                            <TabPanel index={1} value={activeTab}>
                                <ReturnTab
                                    openProductModal={openProductModal}
                                    openCageModal={openCageModal}
                                    authorization={authorization}
                                    product={product}
                                    products={products}
                                    errors={errorsProduct}
                                    cage={cage}
                                    cages={cages}
                                    errorsCage={errorsCage}
                                    readOnly={readOnly}
                                    refreshGrids={refreshGrids}

                                    handleChangeProduct={handleChangeProduct}
                                    handleSubmitProduct={handleSubmitProduct}
                                    handleEditProduct={handleEditProduct}

                                    handleChangeCage={handleChangeCage}
                                    handleSubmitCage={handleSubmitCage}
                                    handleEditCage={handleEditCage}

                                    onCloseModal={handleCloseReturnModals}
                                />
                            </TabPanel>
                            <TabPanel index={3} value={activeTab}>
                                <LiqTab
                                    authorization={authorization}
                                    authProducts={authorizationService.filterProductsInAuthorization(authorization, products, bonusIncome.bonusTypeId)}
                                    products={products}
                                    saleChannels={saleChannels}
                                    handleChangeLiq={handleChangeLiq}
                                    refreshGrids={refreshGrids}

                                    bankIncome={bankIncome}
                                    errorsBankIncome={errorsBankIncome}
                                    handleChangeBankIncome={handleChangeBankIncome}
                                    handleSubmitBankIncome={handleSubmitBankIncome}
                                    handleNewBankIncome={handleNewBankIncome}
                                    handleEditBankIncome={handleEditBankIncome}
                                    handleDeleteBankIncome={handleDeleteBankIncome}

                                    bonusIncome={bonusIncome}
                                    errorsBonusIncome={errorsBonusIncome}
                                    handleChangeBonusIncome={handleChangeBonusIncome}
                                    handleSubmitBonusIncome={handleSubmitBonusIncome}
                                    handleNewBonusIncome={handleNewBonusIncome}
                                    handleEditBonusIncome={handleEditBonusIncome}
                                    handleDeleteBonusIncome={handleDeleteBonusIncome}

                                    supplement={supplement}
                                    errorsSupplement={errorsSupplement}
                                    handleChangeSupplement={handleChangeSupplement}
                                    handleSubmitSupplement={handleSubmitSupplement}
                                    handleNewSupplement={handleNewSupplement}
                                    handleEditSupplement={handleEditSupplement}
                                    handleDeleteSupplement={handleDeleteSupplement}
                                />
                            </TabPanel>
                            <TabPanel index={2} value={activeTab}>
                                <DeliveryNotesPosTab
                                    authorization={authorization}
                                    deliveryNotePos={deliveryNotePos}
                                    deliveryNotePosProduct={deliveryNotePosProduct}
                                    errorsDeliveryNotePos={errorsDeliveryNotePos}
                                    errorsDeliveryNotePosProduct={errorsDeliveryNotePosProduct}
                                    pos={pos}
                                    products={products}
                                    readOnly={readOnly}
                                    refreshGrids={refreshGrids}

                                    handleChangeDeliveryNotePosProduct={handleChangeDeliveryNotePosProduct}
                                    handleSubmitDeliveryNotePosProduct={handleSubmitDeliveryNotePosProduct}
                                    handleDeleteDeliveryNotePosProduct={handleDeleteDeliveryNotePosProduct}

                                    handleNewDeliveryNotePos={handleNewDeliveryNotePos}
                                    handleChangeDeliveryNotePos={handleChangeDeliveryNotePos}
                                    handleSubmitDeliveryNotePos={handleSubmitDeliveryNotePos}
                                    handleEditDeliveryNotePos={handleEditDeliveryNotePos}
                                    handleDeleteDeliveryNotePos={handleDeleteDeliveryNotePos}
                                    handlePrintDeliveryNotePos={handlePrintDeliveryNotePos}
                                />
                            </TabPanel>
                            <TabPanel index={4} value={activeTab}>Contabilidad</TabPanel>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} style={{padding : '20px 0'}}>
                        <RightBox>
                            <RouterLink to={{pathname: `/authorizations`, state: {back: true}}}>
                                <Button>
                                    {intl.formatMessage({id: 'generic.back'})}
                                </Button>
                            </RouterLink>
                            <PrintButton
                                onClick={handleDownload}
                                disabled={authorization.id === null}
                                isLoading={loading}
                            />
                            <PrintButton
                                label={intl.formatMessage({id: "generic.domain.authorizationLoadDoc"})}
                                onClick={handleDownloadLoadDoc}
                                disabled={authorization.id === null}
                                isLoading={loading}
                            />
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleOpenConfirmation}
                                disabled={authorizationService.isAuthorization(authorization) || authorizationService.isCanceled(authorization) || authorizationService.isCancellation(authorization)}
                            >
                                {intl.formatMessage({id: "generic.cancel2.verb"})}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{marginLeft: '10px'}}
                                onClick={handleNew}
                            >
                                {intl.formatMessage({id: "generic.new.fem"})}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{marginLeft: '10px'}}
                                onClick={(e) => {e.preventDefault(); handleSubmit();}}
                                isLoading={loading}
                                disabled={saveButtonDisabled}
                            >
                                {intl.formatMessage({id: "generic.save"})}
                            </Button>
                        </RightBox>
                        <AlertDialog
                            open={openConfirmation}
                            title={intl.formatMessage({id: "generic.domain.liquidation"})}
                            text={intl.formatMessage({id: "generic.domain.authorization.cancel.confirmation"}, {br: <br key="br" />})}
                            onYes={handleCancel}
                            onNo={handleCloseConfirmation}
                        />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    )
};

export default withMessageBox(Authorization);
