import React from 'react';
import { useIntl } from 'react-intl';

import '../../../../assets/styles/index.css';

import Paper from "@material-ui/core/Paper";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ZoneChip from "../../../warehouse/management/routes/ZoneChip";


export const StreetItem = (props) => {
    const {street} = props;

    const intl = useIntl();

    return (
         <Paper style={{padding: '10px', margin: '10px 0'}}>
            <div style={{display: 'flex', gap: '50px'}}>
                <div className="vertical-align" style={{width: '400px'}}>{street.streetType.name} {street.name} ({street.town.name})</div>
                <div  className="vertical-align" style={{width: '120px'}}>
                    {street.stretches.odds.map((partial, index) => <div key={"odd"+index} style={{margin: '1px 0'}}><ZoneChip  label={partial.initialNumber + " - " + partial.finalNumber}/></div>)}
                </div>
                <div className="vertical-align" style={{width: '120px'}}>
                    {street.stretches.pairs.map((partial, index) => <div key={"pair"+index} style={{margin: '1px 0'}}><ZoneChip key={"odd"+index} label={partial.initialNumber + " - " + partial.finalNumber}/></div>)}
                </div>
             </div>
        </Paper>
    )
}

export default StreetItem;