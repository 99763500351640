import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Box from "@material-ui/core/Box";

import Button from "../form/Button";
import Modal from "../Modal/Modal";
import {RightBox} from "../Box";


/**
 * from https://medium.com/nerd-for-tech/custom-react-router-prompt-d325538b4d2b
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const RouterPrompt = (props) => {
    const { when, onOK = () => true, onCancel, title, message = "", state = null } = props;

    const history = useHistory();
    const intl = useIntl();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => {});
                history.push({
                    pathname: currentPath,
                    state: state
                });
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <Modal
            title={title}
            open={showPrompt}
            onClose={handleCancel}
            height={150}
            width={500}
        >
            <Box style={{padding: '0 0 10px 0'}}>
                {typeof message === 'string' && (<>{message}</>)}
                {typeof message !== 'string' && (<>{message.map(line => <p>{line}</p>)}</>)}
            </Box>
            <RightBox>
                <Button
                    onClick={(e) => {e.preventDefault(); handleCancel();}}
                >
                    {intl.formatMessage({id: 'generic.cancel'})}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {e.preventDefault(); handleOK();}}
                >
                    {intl.formatMessage({id: 'generic.accept'})}
                </Button>
            </RightBox>
        </Modal>
    ) : null;
}

export default RouterPrompt;

RouterPrompt.propTypes = {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    state: PropTypes.object,
    when: PropTypes.bool.isRequired,
    onOk: PropTypes.func,
    onCancel: PropTypes.func
}
