export const createHeaderFromData = (
    data = [],
    labelField = "",
    withFirstColBlank = true,
    withActions = false
) => {
    const header = [];
    let headerCol;

    if (withFirstColBlank) {
        header.push({id: 'id0',align: "left"});
    }
    
    for (let i = 0; i < data.length; i++) {
        headerCol = {
            id: "id" + (i+1),
            align: "left",
            label: data[i][labelField]
        }  
        header.push(headerCol);
    }
    if (withActions) {
        header.push({id: "actions", align: "center", width: 80});
    }
    return header;
}