import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import { RightBox } from '../../../../components/Box';
import Button from "../../../../components/form/Button";



export const AccountingAccountForm = (props) => {
    const {accounts, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.account.treasury"})}/>
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bank"
                        label={intl.formatMessage({id: 'generic.domain.account.bank'})}
                        value={accounts.bank}
                        error={errors.bank}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="cash"
                        label={intl.formatMessage({id: 'generic.domain.account.cash'})}
                        value={accounts.cash}
                        error={errors.cash}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="creditCard"
                        label={intl.formatMessage({id: 'generic.domain.account.creditCard'})}
                        value={accounts.creditCard}
                        error={errors.creditCard}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}><br/></Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.payments"})}/>
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="payments"
                        label={intl.formatMessage({id: 'generic.domain.payments'})}
                        value={accounts.payments}
                        error={errors.payments}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}><br/></Grid>
                {false && ( <React.Fragment>
                    <Grid item xs={12}>
                        <Divider label={intl.formatMessage({id: "generic.domain.account.vat"})}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Input
                            name="vatS"
                            label={intl.formatMessage({id: 'generic.domain.account.vatS'})}
                            value={accounts.vatS}
                            error={errors.vatS}
                            onChange={props.onChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Input
                            name="vatR"
                            label={intl.formatMessage({id: 'generic.domain.account.vatR'})}
                            value={accounts.vatR}
                            error={errors.vatR}
                            onChange={props.onChange}
                            fullWidth
                        />
                    </Grid>
                </React.Fragment>)}
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.pre-payments"})}/>
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusPO"
                        label={intl.formatMessage({id: 'generic.domain.pre-payments.po'})}
                        value={accounts.bonusPO}
                        error={errors.bonusPO}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusPT"
                        label={intl.formatMessage({id: 'generic.domain.pre-payments.pt'})}
                        value={accounts.bonusPT}
                        error={errors.bonusPT}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusPTB"
                        label={intl.formatMessage({id: 'generic.domain.pre-payments.ptb'})}
                        value={accounts.bonusPTB}
                        error={errors.bonusPTB}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusB24"
                        label="Butano 24"
                        value={accounts.bonusB24}
                        error={errors.bonusB24}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusArmy"
                        label={intl.formatMessage({id: 'generic.domain.pre-payments.army'})}
                        value={accounts.bonusArmy}
                        error={errors.bonusArmy}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusSup"
                        label={intl.formatMessage({id: 'generic.domain.supplements'})}
                        value={accounts.bonusSup}
                        error={errors.bonusSup}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusDiscI350"
                        label={intl.formatMessage({id: 'generic.domain.pre-payments.discI350'})}
                        value={accounts.bonusDiscI350}
                        error={errors.bonusDiscI350}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusDiscUD110"
                        label={intl.formatMessage({id: 'generic.domain.pre-payments.discUD110'})}
                        value={accounts.bonusDiscUD110}
                        error={errors.bonusDiscUD110}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusDMF"
                        label={intl.formatMessage({id: 'generic.domain.dmf'})}
                        value={accounts.bonusDMF}
                        error={errors.bonusDMF}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="bonusPagadoARepsol"
                        label={intl.formatMessage({id: 'generic.domain.pre-payments.par'})}
                        value={accounts.bonusPagadoARepsol}
                        error={errors.bonusPagadoARepsol}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}><br/></Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.products.gas"})}/>
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="products"
                        label={intl.formatMessage({id: 'generic.domain.merchandise.plural'})}
                        value={accounts.products}
                        error={errors.products}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="chargesSupplement"
                        label={intl.formatMessage({id: 'generic.domain.supplements'})}
                        value={accounts.chargesSupplement}
                        error={errors.chargesSupplement}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="gasRenting"
                        label={intl.formatMessage({id: 'generic.domain.rental'})}
                        value={accounts.gasRenting}
                        error={errors.gasRenting}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={2}>
                    <Input
                        name="gasSubscribes"
                        label={intl.formatMessage({id: 'generic.domain.subscribe.plural'})}
                        value={accounts.gasSubscribes}
                        error={errors.gasSubscribes}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="gasUnsubscribes"
                        label={intl.formatMessage({id: 'generic.domain.unsubscribe.plural'})}
                        value={accounts.gasUnsubscribes}
                        error={errors.gasUnsubscribes}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}><br/></Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.incidenceBalance"})}/>
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="gasIncidences"
                        label={intl.formatMessage({id: 'generic.domain.gas'})}
                        value={accounts.gasIncidences}
                        error={errors.gasIncidences}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault();props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
}

export default AccountingAccountForm;
