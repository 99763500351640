import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';



export const Loader = (props) => {
    return (
        <CircularProgress size={24} />
    )
};

export default Loader;
