import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import { RightBox } from '../../../../components/Box';



export const ItineraryForm = (props) => {
    const {itinerary, routes = [], errors, loading} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Input
                        name="id"
                        label="Id"
                        value={itinerary.id}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="name"
                        label={intl.formatMessage({id: 'generic.domain.name'})}
                        value={itinerary.name}
                        error={errors.name}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        name="routeId"
                        label={intl.formatMessage({id: 'generic.domain.route'})}
                        items={routes.getSelectItems()}
                        value={itinerary.routeId}
                        error={errors.routeId}
                        loading={loading}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>
                            {intl.formatMessage({id: "generic.cancel"})}
                        </Button>
                        <Button
                            disabled //TODO: eliminar cuando el BWIN esté en la nube
                            type="button"
                            color="primary"
                            variant="contained"
                            style={{marginLeft: '10px'}}
                            onClick={(e) => {e.preventDefault();props.onSubmit()}}
                        >
                            {intl.formatMessage({id: "generic.save"})}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    );
}

export default ItineraryForm;