import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MuiIcon from '@material-ui/icons/GroupAdd';


const GroupAddIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><MuiIcon/></IconButton>
        );
    }
    return (<MuiIcon/>);
};

export default GroupAddIcon;
