import React from 'react';
import { useIntl } from 'react-intl';

import SearchIcon from '@material-ui/icons/Search';

import Button from "./index";


const SearchButton = (props) => {
    const intl = useIntl();

    return (
        <Button
            startIcon={<SearchIcon />}
            {...props}
        >
            {intl.formatMessage({id: 'generic.search'})}
        </Button>
    )
}

export default SearchButton;