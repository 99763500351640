import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../../components/form/Button/index';
import Input from '../../../../../components/form/Input/Input';
import Select from '../../../../../components/form/Select';
import {RightBox} from "../../../../../components/Box";


export const DefaultSaleChannelSupplementForm = (props) => {
    const {index, supplement, products, saleChannels, errors} = props;

    const intl = useIntl();

    const [productItems, setProductItems] = useState([]);
    const [saleChannelItems, setSaleChannelItems] = useState([]);

    useEffect(() => {
        setProductItems(
            products
                .filter(product => {return product.enabled === 1})
                .map(product => {
                    return {value: product.id, label: product.name};
                })
        );
    }, [products]);
    useEffect(() => {
        setSaleChannelItems(
            saleChannels
                .filter(channel => {return channel.enabled === 1})
                .map(channel => {
                    return {value: channel.id, label: channel.channel};
                })
        );
    }, [saleChannels]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        name="saleChannelId"
                        label={intl.formatMessage({id: 'generic.domain.saleChannel'})}
                        items={saleChannelItems}
                        value={supplement.saleChannelId}
                        error={errors.saleChannelId}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        name="productId"
                        label={intl.formatMessage({id: 'generic.domain.product'})}
                        items={productItems}
                        value={supplement.productId}
                        error={errors.productId}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="supplement"
                        label={intl.formatMessage({id: 'generic.domain.supplement'})}
                        value={supplement.supplement}
                        error={errors.supplement}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={() => props.onClear()}
                        >
                            {intl.formatMessage({id: "generic.clear"})}
                        </Button>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            style={{marginLeft: '5px'}}
                            onClick={() => props.onSubmit()}
                        >
                            {index == null && intl.formatMessage({id: "generic.add"})}
                            {index != null && intl.formatMessage({id: "generic.update"})}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
}

export default DefaultSaleChannelSupplementForm;