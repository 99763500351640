import ExcelJS from 'exceljs';
import {download, FILE_TYPE_XLSX} from "../services/apiService";
import {isNull} from "./validator";



export const exportToXLSX = async (header = [], rows = [], hasFooter = false, title = null) => {
    if (rows.length === 0) return;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(null);

    worksheet.addRows(header);
    worksheet.addRows(rows);

    const font = {name: 'Arial', size: 8};
    for (let i = 1; i <= worksheet.rowCount; i++) {
        worksheet.getRow(i).font = font;
    }
    for (let i = 1; i <= worksheet.columnCount ; i++) {
        worksheet.getRow(1).getCell(i).font = {...font, bold: true, color: {argb: '00FFFFFF'}}
        worksheet.getRow(1).getCell(i).alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.getRow(1).getCell(i).fill = {type: 'pattern', pattern: 'darkGray' }
        if (header.length > 1) { //Subheader
            worksheet.getRow(2).getCell(i).font = {...font, size: 6, color: {argb: '00FFFFFF'}}
            worksheet.getRow(2).getCell(i).alignment = { vertical: 'middle', horizontal: 'center' }
            worksheet.getRow(2).getCell(i).fill = {type: 'pattern', pattern: 'mediumGray' }
        }
        if (hasFooter) {
            worksheet.getRow(header.length + rows.length).getCell(i).font = {...font, bold: true}
        }
    }

    if (!isNull(title)) {
        worksheet.insertRow(1);
        worksheet.mergeCells(1, 1, 1, worksheet.columnCount);
        worksheet.getCell(1,1).value = title;
        worksheet.getRow(1).getCell(1).font = {...font, bold: true}
        worksheet.getRow(1).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {type: FILE_TYPE_XLSX});
    download(blob, 'datos.xlsx');
}
