import React from 'react';
import PropTypes from 'prop-types';

import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';



const gray = "#7b7b7f";

const StyledBox = ({children, ...otherProps}) => {
    return (
        <Box
            fontSize={14}
            {...otherProps}
        >
            {children}
        </Box>
    )
};

const StyledLink = ({href, children}) => {
    return (
        <Link
            href={href}
            underline="none"
            color="textSecondary"
        >
            {children}
        </Link>
    )
};

const Breadcrumbs = (props) => {
        const {sections, separator = '>'} = props;

        return (
            <MUIBreadcrumbs separator={separator}>
                <StyledLink href="/"><StyledBox>Home</StyledBox></StyledLink>
                {
                    sections.map((section, index) => {
                        return (
                            typeof section.link === "undefined" ?
                                <StyledBox
                                    key={index}
                                    fontWeight={index + 1 === sections.length ? 700 : 400}
                                    color={index + 1 === sections.length ? '#464650' : gray}
                                >
                                    {section.label}
                                </StyledBox>
                                :
                                <StyledLink key={index} to={section.link}>{section.label}</StyledLink>
                        )
                    })
                }
            </MUIBreadcrumbs>
        )

}

Breadcrumbs.propTypes = {
    sections: PropTypes.array.isRequired
};

export default Breadcrumbs;
