import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import Switch from '../../../../components/form/Switch';
import { RightBox } from '../../../../components/Box';



export const PaymentForm = (props) => {
    const {movement, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <RightBox>
                                <Input
                                    name="date"
                                    label={intl.formatMessage({id: 'generic.date'})}
                                    value={movement.date}
                                    disabled
                                />
                            </RightBox>
                        </Grid>
                        <Grid item xs={3}>
                            <Input
                                name="amount"
                                label={intl.formatMessage({id: 'generic.domain.amount'})}
                                value={movement.amount}
                                error={errors.amount}
                                onChange={props.onChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Input
                                name="description"
                                label={intl.formatMessage({id: 'generic.domain.desc'})}
                                value={movement.description}
                                error={errors.description}
                                onChange={props.onChange}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RightBox>
                                <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault();props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                            </RightBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default PaymentForm;
