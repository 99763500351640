import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import { listWarehouses, addWarehouse, updateWarehouse } from '../../../../services/domain/warehouse/warehouseService'

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from "../../../../components/icons/Enabled";
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Table from '../../../../components/table';
import { RightBox } from '../../../../components/Box/index';
import {fullAddressFormatter} from '../../../../helpers/formatter';
import WarehouseForm from './WarehouseForm';
import Modal from "../../../../components/Modal/Modal";
import {getValueFromField} from "../../../../helpers/form";
import * as validateService from "../../../../services/validateService";


export const WarehousesList = (props) => {

    const intl = useIntl();

    const newWarehouse = {
        id: null,
        name: null,
        nif: null,
        address: null,
        town: null,
        province: null,
        zipCode: null,
        tf: null,
        email: null,
        enabled: 1
    };
    const validations = {
        name: [{type: validateService.REQUIRED}],
        email: [{type: validateService.EMAIL_FORMAT}],
    };

    const [open, setOpen] = useState(false);
    const [warehouse, setWarehouse] = useState(newWarehouse);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});


    useEffect(() => {
        async function loadWarehouses() {
            setLoading(true);
            const response = await listWarehouses();
            if (response.status === 200) {
                setData(response.data.warehouses.map(item => convertRowData(item)));
            }
            setLoading(false);
        }
        loadWarehouses();
    }, [reload]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.warehouses'})}
    ];

    const handleClose = () => {
        setOpen(false);
    };
    const handleNew = () => {
        setWarehouse(newWarehouse);
        setOpen(true);
    };
    const handleEdit = (item) => {
        setWarehouse(item);
        setOpen(true);
    };
    const handleChange = (e) => {
        setWarehouse({
            ...warehouse,
            [e.target.name]: getValueFromField(e)
        })
    };
    const handleSubmit = () => {
        const err = validateService.validate(warehouse, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            let response;
            if (warehouse.id === null) {
                response = addWarehouse(warehouse);
            } else {
                response = updateWarehouse(warehouse.id, warehouse);
            }
            response
                .then(() => {
                    setReload(reload + 1);
                    setOpen(false);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                });
        }
    };


    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"app.warehouses.name"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"app.warehouses.address"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"app.warehouses.tf"}), sublabel: null, formatter: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"app.warehouses.email"}), sublabel: null, formatter: null },
        { id: 'id5', align: "center",   label: intl.formatMessage({id:"generic.enabled"}), sublabel: null, formatter: (val) => <EnabledIcon value={val}/>, width: 80 },
        { id: 'actions', align: "center", width: 80 },
    ];

    const convertRowData = item => {
        return {
            id1: item.name,
            id2: fullAddressFormatter(item.address, item.zipCode, item.town, item.province),
            id3: item.tf,
            id4: item.email,
            id5: item.enabled,
            actions: <EditIcon button onClick={() => handleEdit(item)}/>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal open={open} title={intl.formatMessage({id: "app.warehouses.warehouse"})} onClose={handleClose}>
                    <WarehouseForm
                        warehouse={warehouse}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
};

export default withMessageBox(WarehousesList);
