import React from 'react';

import routes from './routes';
import { AppProvider } from './features/core/context/AppContext';

function App() {

    return (
        <AppProvider>
            <div className="App">
                {routes}
            </div>
        </AppProvider>
    );
}

export default App;
