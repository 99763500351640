import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Input from "../../../../components/form/Input/Input";
import Table from '../../../../components/table';



export const WarehouseStockCountTableProduct = (props) => {
    const {stockCount, refresh, isLoading} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(stockCount.products.map(product => convertRowData(product)));
    }, [stockCount, refresh]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.containers"}), formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.charges"}), formatter: null, width: 100 },
    ];

    const convertRowData = item => {
        return {
            id1: item.product.name,
            id2: <Input name="containers" value={item.containers} onChange={(e) => props.onChange(e, item)} />,
            id3: <Input name="charges" value={item.charges} onChange={(e) => props.onChange(e, item)} />,
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{minHeight: '200px'}}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default WarehouseStockCountTableProduct;