import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as employeeService from '../../../../services/domain/warehouse/employeeService';
import * as zoneService from '../../../../services/domain/warehouse/zoneService';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Alert from '../../../../components/Alert/Alert';

import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import ZoneChip from '../../management/routes/ZoneChip';



export const AuthorizationForm = (props) => {
    const {authorization, drivers = [], vehicles, warehouses, errors, loading, readOnly = false} = props;

    const intl = useIntl();

    const [driverItems, setDriverItems] = useState([]);
    const [vehicleItems, setVehicleItems] = useState([]);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const isAuthorization = authorizationService.isAuthorization(authorization);

    useEffect(() => {
        setDriverItems(
            drivers.map(driver => {
                return {value: driver.id, label: employeeService.getFullName(driver)};
            })
        );
    }, [drivers]);
    useEffect(() => {
        setVehicleItems(
            vehicles.map(vehicle => {
                return {value: vehicle.id, label: vehicle.plate};
            })
        );
    }, [vehicles]);
    useEffect(() => {
        setWarehouseItems(
            warehouses.map(warehouse => {
                return {value: warehouse.id, label: warehouse.name};
            })
        );
    }, [warehouses]);
    useEffect(() => {
        if (!authorizationService.isLiquidated(authorization)) {
            let vehicleNotifications = [];
            let driverNotifications = [];
            if (authorization.vehicleId) {
                vehicleNotifications =
                    vehicles
                        .find(vehicle => vehicle.id === authorization.vehicleId).notifications
                        .map((notification, index) => ({id: `v${index}`, ...notification}))

            }
            if (authorization.driverId) {
                const driver = drivers.find(driver => driver.id === authorization.driverId);
                driverNotifications =
                    driver.notifications ?
                        driver.notifications.map((notification, index) => ({id: `d${index}`, ...notification})) : [];
            }
            setNotifications([...vehicleNotifications, ...driverNotifications]);
        }
    }, [authorization]);

    const handleDeleteNotification = (id) => {
        setNotifications(notifications.filter(notification => notification.id !== id));
    }

    const renderRoute = () => {
        return authorization.zones ?
            zoneService.toString(authorization.zones).map((zone, index) => <ZoneChip label={zone} key={index}/>) : null
    };

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Input
                        name="number"
                        label={intl.formatMessage({id: 'generic.domain.number'})}
                        value={authorization.number}
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    {authorizationService.isCancellation(authorization) && (
                        <div style={{color: 'white', backgroundColor: 'red', marginTop: '9px', padding: '8px'}}>ANULACIÓN de {authorization.relatedAuth}</div>
                    )}
                    {authorizationService.isCanceled(authorization) && (
                        <div style={{color: 'white', backgroundColor: 'red', marginTop: '9px', padding: '8px'}}>ANULADA por {authorization.relatedAuth}</div>
                    )}
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={2}>
                    <Input
                        name="authDate"
                        label={intl.formatMessage({id: 'generic.domain.authorizationDate'})}
                        value={authorization.authDate}
                        disabled
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="liqDate"
                        label={intl.formatMessage({id: 'generic.domain.liquidationDate'})}
                        value={authorization.liqDate}
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        name="warehouseId"
                        label={intl.formatMessage({id: 'generic.domain.warehouse'})}
                        items={warehouseItems}
                        value={authorization.warehouseId}
                        error={errors.warehouseId}
                        loading={loading}
                        onChange={props.onChange}
                        disabled={readOnly || !isAuthorization}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.vehicle"})}/>
                </Grid>
                <Grid item xs={3}>
                    <Select
                        name="vehicleId"
                        label={intl.formatMessage({id: 'generic.domain.vehicle'})}
                        items={vehicleItems}
                        value={authorization.vehicleId}
                        error={errors.vehicleId}
                        loading={loading}
                        onChange={props.onChange}
                        disabled={readOnly || !isAuthorization}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={7}>
                    <Select
                        name="driverId"
                        label={intl.formatMessage({id: 'generic.domain.driver'})}
                        items={driverItems}
                        value={authorization.driverId}
                        error={errors.driverId}
                        loading={loading}
                        onChange={props.onChange}
                        disabled={readOnly || !isAuthorization}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    {
                        notifications.map(notification => (
                            <Alert
                                severity={notification.variant.toLowerCase()}
                                onClose={() => handleDeleteNotification(notification.id)}
                                style={{marginBottom: '5px'}}
                            >
                                {notification.message}
                            </Alert>
                        ))
                    }
                </Grid>
                <Grid item xs={6}>
                    <Box paddingTop="15px">
                        {intl.formatMessage({id: 'generic.domain.zones'})}: &nbsp;
                        {renderRoute()}
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
};

export default AuthorizationForm;
