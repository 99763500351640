import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import { CenterBox, RightBox } from '../../../../components/Box';



export const ProductExtraForm = (props) => {
    const {product, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <CenterBox>
                        <Input
                            name="registerRights"
                            label={intl.formatMessage({id: 'generic.domain.registerRights'})}
                            value={product.registerRights}
                            error={errors.registerRights}
                            onChange={props.onChange}
                            disabled
                            required
                        />
                    </CenterBox>
                </Grid>
                <Grid item xs={4}>
                    <CenterBox>
                        <Input
                            name="bond"
                            label={intl.formatMessage({id: 'generic.domain.bond'})}
                            value={product.bond}
                            error={errors.bond}
                            onChange={props.onChange}
                            disabled
                            required
                        />
                    </CenterBox>
                </Grid>
                <Grid item xs={12}><br/><br/></Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            style={{marginLeft: '10px'}}
                            onClick={() => props.onClick()}
                            disabled
                        >
                            {intl.formatMessage({id: "generic.save"})}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
};

export default ProductExtraForm;
