import React from 'react';

import BubbleChartIcon from '@material-ui/icons/BubbleChart';


const WarehouseIcon = (props) => {
    
    return (
        <BubbleChartIcon style={{color: "#FFF"}} />
    )        
}

export default WarehouseIcon;
