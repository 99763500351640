import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {getNameWithAlias} from "../../../../services/domain/warehouse/pointOfSaleService";
import * as formatter from "../../../../helpers/formatter";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Input from "../../../../components/form/Input/Input";


const DeliveryNoteInvoiceForm = (props) => {
    const {invoice} = props;

    const intl = useIntl();


    useEffect(() => {

    }, );

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Input
                        name="number"
                        label={intl.formatMessage({id: 'generic.domain.number'})}
                        value={invoice.number}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="date"
                        label={intl.formatMessage({id: 'generic.date'})}
                        value={formatter.dateTimeFormatter(invoice.createdAt)}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="pos"
                        label={intl.formatMessage({id: 'generic.domain.pos'})}
                        value={getNameWithAlias(invoice.pointOfSale)}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="startDate"
                        label={intl.formatMessage({id: 'generic.date.init'})}
                        value={formatter.dateFormatter(invoice.dateFrom)}
                        disabled
                    />
                    &nbsp;
                    <Input
                        name="endDate"
                        label={intl.formatMessage({id: 'generic.date.end'})}
                        value={formatter.dateFormatter(invoice.dateTo)}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box paddingTop="15px">
                        {intl.formatMessage({id: 'generic.domain.deliveryNotesInvoiced'})}: &nbsp;
                        {invoice.deliveryNotes ? invoice.deliveryNotes.map(item => item.number).join(', ') : ""}
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

export default DeliveryNoteInvoiceForm;
