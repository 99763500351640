import React from 'react';
import { useIntl } from 'react-intl';

import PrintIcon from '@material-ui/icons/Print';

import Button from "./index";


const PrintButton = (props) => {
    const intl = useIntl();
    const label = props.label ? props.label : intl.formatMessage({id: 'generic.print'});

    return (
        <Button
            startIcon={<PrintIcon />}
            {...props}
        >
            {label}
        </Button>
    )
}

export default PrintButton;