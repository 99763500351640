import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Select from '../../../../components/form/Select';



const AssignZonesToEmployeesForm = (props) => {
    const {assign, employees, zones} = props;

    const intl = useIntl();

    const [employeeItems, setEmployeeItems] = useState([]);
    const [zoneItems, setZoneItems] = useState([]);

    useEffect(() => {
        setEmployeeItems(employees.map(employee => {
            return {value: employee.id, label: employee.name + " " + employee.surname}}
        ));
    }, [employees]);

    useEffect(() => {
        setZoneItems(zones.map(zone => {
            return {value: zone.id, label: zone.name}}
        ));
    }, [zones]);

    return (
        <form onSubmit={(e) => {e.preventDefault();props.onSubmit()}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth required>
                        <Select
                            name="employee"
                            label={intl.formatMessage({id: 'app.employees.employee'})}
                            items={employeeItems}
                            value={assign.employee}
                            onChange={props.onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth required>
                        <Select
                            name="zone"
                            label={intl.formatMessage({id: 'generic.domain.itinerary'})}
                            items={zoneItems}
                            value={assign.zone}
                            onChange={props.onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" color="primary" variant="contained">{intl.formatMessage({id: 'generic.assign'})}</Button>
                </Grid>
            </Grid>
        </form>
    )
};

export default AssignZonesToEmployeesForm;
