import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const get = async (warehouseId, date) => {
    return await apiService.get(`${URL}/warehouses/${warehouseId}/stockCount`, {date: date});
};
export const create = async (data) => {
    return await apiService.post(`${URL}/warehouses/${data.warehouseId}/stockCount`, data);
};
export const download = (data) => {
    const fileName = "Recuento existencias " + data.date;
    apiService.downloadFile(`${URL}/warehouses/${data.warehouseId}/stockCount/download`, data, fileName);
};
