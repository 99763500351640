import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as streetMapService from '../../../../services/domain/streetMap/streetMapService';
import * as validateService from "../../../../services/validateService";
import {getValueFromField} from "../../../../helpers/form";
import {isNull} from "../../../../helpers/validator";

import Grid from "@material-ui/core/Grid";

import AddIcon from "../../../../components/icons/Add";
import AlertDialog from "../../../../components/AlertDialog";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Button from '../../../../components/form/Button/index';
import DeleteIcon from "../../../../components/icons/Delete";
import EditIcon from "../../../../components/icons";
import Modal from "../../../../components/Modal/Modal";
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";
import ZoneChip from "../../../warehouse/management/routes/ZoneChip";
import RouteForm from "./RouteForm";



export const RouteList = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.streetMap'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management.routes'})}
    ];

    const itinerariesFormatter = (value) => {
        const itineraries = value.split(', ');
        return itineraries.map((it, index) => {
            return it !== "" ? <ZoneChip label={it} key={index}/> : ""
        });
    }

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id: 'generic.domain.route'}), sublabel: null, formatter: null, width: 200 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id: 'generic.domain.itineraries'}), sublabel: null, formatter: itinerariesFormatter },
        { id: 'actions', align: "center", width: 120 },
    ];

    const newRoute = {
        id: null,
        name: null
    }
    const validations = {
        name: [{type: validateService.REQUIRED}],
    }

    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [refresh, setRefresh] = useState(null);
    const [route, setRoute] = useState(newRoute);


    useEffect(() => {
        async function load() {
            setLoading(true);
            const response = await streetMapService.getRoutes();
            if (response.status === 200) {
                setData(response.data.routes.map(item => convertRowData(item)));
                setLoading(false);
            }
        }
        load();
    }, [refresh]);

    const convertRowData = item => {
        const itineraries = item.itineraries.sortBy('name').map(it => it.name);
        return {
            id1: item.name,
            id2: itineraries.join(', '),
            actions: <React.Fragment>
                        <EditIcon button onClick={() => handleEdit(item)}/>
                        <DeleteIcon button onClick={() => handleOpenConfirmation(item)}/>
                    </React.Fragment>
        }
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleNew = () => {
        setRoute(newRoute);
        setOpen(true);
    };
    const handleEdit = (route) => {
        setRoute(route);
        setOpen(true);
    };
    const handleChange = (e) => {
        e.preventDefault();
        setRoute({
            ...route,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSubmit = () => {
        const err = validateService.validate(route, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            setLoading(true);
            let response;
            if (route.id === null) {
                response = streetMapService.addRoute(route);
            } else {
                response = streetMapService.updateRoute(route.id, route);
            }
            response
                .then(() => {
                    setRefresh(new Date());
                    setOpen(false);
                    props.successMessage(intl.formatMessage({id: 'generic.saved'}));
                })
                .catch(err => {
                    if (err.response.status === 400)
                        props.errorMessage(intl.formatMessage({id: 'app.error.code.ALREADY_EXISTS'}))
                    else
                        props.errorMessage('Error');
                    console.error(err.response)
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }
    const handleDelete = () => {
        if (!isNull(route.id)) {
            const response = streetMapService.deleteRoute(route.id);
            setOpenConfirmation(false);
            response
                .then(() => {
                    setRefresh(new Date());
                    setRoute(newRoute);
                    props.successMessage(intl.formatMessage({id: 'generic.deleted'}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleOpenConfirmation = (route) => {
        setRoute(route);
        setOpenConfirmation(true);
    }
    const handleCloseConfirmation = () => {
        setRoute(newRoute);
        setOpenConfirmation(false);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
            <Grid item xs={12}>
                <Modal
                    open={open}
                    title={intl.formatMessage({id: 'generic.domain.route'})}
                    onClose={handleClose}
                    height={240}
                    width={500}
                >
                    <RouteForm
                        route={route}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
                <AlertDialog
                    open={openConfirmation}
                    title={intl.formatMessage({id: 'generic.domain.route'})}
                    text={intl.formatMessage({id: 'generic.questions.sure'})}
                    onYes={handleDelete}
                    onNo={handleCloseConfirmation}
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(RouteList);