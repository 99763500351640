import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

//TODO: hacer la request
export const getPaymentTypeItems = () => {
    return [
        {value: 1, label: "Efectivo"},
        {value: 2, label: "Tarjeta"},
        {value: 3, label: "Pagado por la Web"},
        {value: 4, label: "A facturar"},
        {value: 5, label: "Bono"},
    ]
}