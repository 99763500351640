import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Table from '../../../../components/table';



const PointOfSaleStockTable = (props) => {
    const {stock} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(stock.map(item => convertRowData(item)));
    }, [stock]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "right",   label: intl.formatMessage({id:"generic.domain.containers"}), sublabel: null, formatter: null, width: 90 },
    ];

    const convertRowData = item => {
        return {
            id1: item.product ? item.product.name : "",
            id2: item.containers
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
                <Table
                    headers={headers}
                    data={data}
                />
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    )
}

export default PointOfSaleStockTable;