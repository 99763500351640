import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';


/** Documentation notistack: https://openbase.io/js/notistack#documentation */

const SnackbarCloseButton = ({snackbarKey}) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton
            color="inherit"
            onClick={() => closeSnackbar(snackbarKey)}
        >
            <ClearIcon/>
        </IconButton>
    )
}

export const MessageProviderWrapper = ({children}) => {
    return (
        <SnackbarProvider
            anchorOrigin = {{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            preventDuplicate
            maxSnack = {3}
            autoHideDuration={5000}
            action={key => <SnackbarCloseButton snackbarKey={key} />}
        >
            { children }
        </SnackbarProvider>
    )
}

export default MessageProviderWrapper;
