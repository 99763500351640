import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {getNameWithAlias} from "../../../../services/domain/warehouse/pointOfSaleService";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Button from "../../../../components/form/Button";
import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";
import {RightBox} from "../../../../components/Box";


const DeliveryNoteInvoiceCreateForm = (props) => {
    const {create, pos} = props;

    const intl = useIntl();

    const [posItems, setPosItems] = useState([]);

    useEffect(() => {
        setPosItems(
            pos.map(pos => {
                return {value: pos.id, label: getNameWithAlias(pos)};
            })
        );
    }, [pos]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box display="flex" width="100%">
                        <Box marginRight={1}>
                            <Select
                                name="posId"
                                label={intl.formatMessage({id: 'generic.domain.pos'})}
                                items={posItems}
                                value={create.posId}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="startDate"
                                label={intl.formatMessage({id: 'generic.date.init'})}
                                value={create.startDate}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="endDate"
                                label={intl.formatMessage({id: 'generic.date.end'})}
                                value={create.endDate}
                                onChange={props.onChange}
                            />
                        </Box>
                    </Box>
                    <Grid item xs={12}>
                        <RightBox marginTop="10px">
                            <Button color="primary" variant="contained"  onClick={props.onSubmit}>{intl.formatMessage({id: 'generic.generate'})}</Button>
                        </RightBox>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default DeliveryNoteInvoiceCreateForm;
