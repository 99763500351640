import React, { useEffect, useState } from 'react';

import { Box, Card } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

import { useTheme } from '@material-ui/core';

export const NotificationListItem = (props) => {
    const { notificationKey, notificationData = [] } = props;

    const theme = useTheme();

    const [variant, setVariant] = useState('info');
    const [color, setColor] = useState('#FFF');
    const [bgColor, setBGColor] = useState('#FFF');


    useEffect(() => {
        let maxVariant;
        notificationData.map(notification => {
            switch (notification.variant.toLowerCase()) {
                case 'error':
                    maxVariant = 'error';
                    break
                case 'warning':
                    if (maxVariant !== 'error') maxVariant = 'warning';
                    break;
                case 'info':
                    if (maxVariant !== 'error' && maxVariant !== 'warning') maxVariant = 'info';
                    break;
            }
        })
        setVariant(maxVariant);
        setColor(theme.palette[maxVariant].main);
        switch (maxVariant) {
            case 'error':
                setBGColor('#FDEDED');
                break;
            case 'warning':
                setBGColor('#FFF4E5');
                break;
            case 'info':
                setBGColor('#E5F6FD');
                break;
            case 'success':
                setBGColor('#EDF7ED');
                break;
        }

    }, [notificationData])

    return (
        <Card>
            <Box style={{display: 'flex', minWidth: '500px', backgroundColor:  bgColor}}>
                <Box style={{width: '5px', backgroundColor: color}}></Box>
                <Box style={{padding: '0 20px', marginTop: 'auto',  marginBottom: 'auto'}}>
                    { variant === 'warning' && <WarningIcon  style={{color: color}} fontSize={'large'} />}
                    { variant === 'error' && <ErrorIcon  style={{color: color}} fontSize={'large'} />}
                </Box>
                <Box style={{padding: '20px 20px 20px 0'}}>
                    <strong>{notificationKey}</strong><br/>
                    {notificationData.map((notification, index) => (
                        <React.Fragment key={index}>{notification.message}<br/></React.Fragment>
                    ))}
                </Box>
            </Box>
        </Card>
    )
}

export default NotificationListItem;