import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiCheckbox from "@material-ui/core/Checkbox";



const Checkbox = (props) => {
    const {label, value, error, required, ...other} = props;

    const [checked, setChecked] = useState(false);
    const [helperText, setHelperText] = useState("");

    useEffect(() => {
        switch (typeof value) {
            case "number":
                setChecked(value === 1);
                break;
            case 'boolean':
                setChecked(value)
                break;
            default:
                // error
        }
    }, [value]);
    useEffect(() => {
        setHelperText(props.helperText);
    }, [props.helperText]);

    return (
        <FormControl margin="dense">
            <FormControlLabel
                control={
                    <MuiCheckbox
                        checked={checked}
                        color="default"
                        size="small"
                        {...other}
                    />
                }
                label={label}
            />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    )
}

Checkbox.propTypes = {
    helperText: PropTypes.string,
}

export default Checkbox;
