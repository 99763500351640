import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';



//TODO: hacer la request
export const getCages = async () => {
    return await getCagesMock();
};

const getCagesMock = () => {
    return new Promise((resolve, reject) => {
        resolve({
            status: 200,
            data: {
                cages: [
                    {id: 1, code: "JD1", name: "Jaula de 35 uds", order: 1},
                    {id: 2, code: "J1", name: "Jaula para Propano 35 Kg", order: 2},
                ]
            }
        })
    });
};

/**
 * Retorna el num de jaulas que se necesitan para el transporte de envases
 * @param containers
 * @returns {number}
 */
export const getCagesForContainers = (containers) => {
    let cages = parseInt(containers / 35);
    if (containers % 35 > 0) cages ++;
    return cages;
}
