import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Divider from '../../../../components/Divider';
import Input from '../../../../components/form/Input/Input';
import Switch from '../../../../components/form/Switch';
import { RightBox } from '../../../../components/Box';


export const PointOfSaleDataForm = (props) => {
    const {pos, errors} = props;

    const intl = useIntl();

    return (
        <form onSubmit={(e) => {e.preventDefault();props.onSubmit()}}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "app.employees.address"})}/>
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="address"
                        label={intl.formatMessage({id: 'app.employees.address'})}
                        value={pos.address}
                        error={errors.address}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Input
                        name="zipCode"
                        label={intl.formatMessage({id: 'app.employees.zipCode'})}
                        value={pos.zipCode}
                        error={errors.zipCode}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Input
                        name="town"
                        label={intl.formatMessage({id: 'app.employees.town'})}
                        value={pos.town}
                        error={errors.town}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Input
                        name="province"
                        label={intl.formatMessage({id: 'app.employees.province'})}
                        value={pos.province}
                        error={errors.province}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        name="tf"
                        label={intl.formatMessage({id: 'app.warehouses.tf'})}
                        value={pos.tf}
                        error={errors.tf}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Input
                        name="email"
                        type="email"
                        label={intl.formatMessage({id: 'app.warehouses.email'})}
                        value={pos.email}
                        error={errors.email}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider label={intl.formatMessage({id: "generic.domain.accounting.accounts"})}/>
                </Grid>
                <Grid item xs={4}>
                    <Input
                        name="accountingAccountDebtor"
                        label={intl.formatMessage({id: 'generic.domain.account.debtor'})}
                        value={pos.accountingAccountDebtor}
                        error={errors.accountingAccountDebtor}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <Input
                        name="accountingAccountCreditor"
                        label={intl.formatMessage({id: 'generic.domain.account.creditor'})}
                        value={pos.accountingAccountCreditor}
                        error={errors.accountingAccountCreditor}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Switch
                        name="enabled"
                        label={intl.formatMessage({id: 'generic.enabled'})}
                        checked={pos.enabled === 1}
                        onChange={props.onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault();props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )

};

export default PointOfSaleDataForm;