import * as XLSX from "../../helpers/xlsx";

export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';


/** order */
export function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy) {
    return order === ORDER_DESC ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function getToggleOrder(order) {
    return order === ORDER_ASC ? ORDER_DESC : ORDER_ASC;
}


/** export CSV */
export const exportToCSV = (
    header = [],
    body = [],
    footer = null
) => {
    const filteredHeader = header.filter(col => typeof col.label !== 'undefined' && col.id !== 'actions');

    const ids = filteredHeader.map(col => col.id);
    const headerRaw = filteredHeader.map(col => col.label);

    let csvContent = csvProcessHeader(headerRaw);

    if (hasHeaderSubLabels(filteredHeader)) {
        const headerSublabelRaw = filteredHeader.map(col => typeof col.sublabel !== "undefined" && col.sublabel !== null ? col.sublabel :  "");
        csvContent += csvProcessHeader(headerSublabelRaw);
    }

    for (let i = 0; i < body.length; i++) {
        csvContent += csvProcessRow(body[i], ids, header);
    }
    if (footer !== null) {
        csvContent += csvProcessRow(footer, ids, header);
    }

    const filename = "datos.csv"
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const hasHeaderSubLabels = (header = []) => {
    let hasSublabels = false;
    header.map(col => {
        if (
            typeof col.sublabel !== "undefined" &&
            col.sublabel !== null &&
            col.sublabel !== ""
        ) hasSublabels = true;
    });
    return hasSublabels;
}
const csvProcessHeader = (header = []) => {
    return header.join(';') + '\n';
}
const csvProcessRow = (row = [], ids = [], header = []) => {
    const processedRow = processRow(row, ids, header);
    return processedRow.length > 0 ? processedRow.join(';') + '\n' : "";
}
const processRow = (row = [], ids = [], header = []) => {
    let processedRow = [];

    ids.map(id => {
        const headerRow = header.filter(hr => hr.id === id);
        const value = typeof headerRow[0].formatter === 'function' ? headerRow[0].formatter(row[id], row) : row[id];

        let processedValue;
        switch (typeof value) {
            case 'boolean':
            case 'string':
                processedValue = value;
                break;
            case 'number':
                processedValue = value.toString();
                break;
            case 'object':
                processedValue = typeof row[id] !== 'object' ? row[id] : "";
                break;
            case 'undefined':
            default:
                processedValue = "";
        }
        processedRow.push(processedValue);
    });

    return processedRow;
}


export const exportToXLSX = (
    header = [],
    body = [],
    footer = null,
    title = null
) => {
    const filteredHeader = header.filter(col => typeof col.label !== 'undefined' && col.id !== 'actions');
    const ids = filteredHeader.map(col => col.id);

    const headerData = [];
    const headerRaw = filteredHeader.map(col => col.label);
    headerData.push(headerRaw);
    if (hasHeaderSubLabels(filteredHeader)) {
        const subheaderRaw = filteredHeader.map(col => typeof col.sublabel !== "undefined" && col.sublabel !== null ? col.sublabel :  "");
        headerData.push(subheaderRaw);
    }

    const data = [];
    for (let i = 0; i < body.length; i++) {
        data.push(xlsxProcessRow(body[i], ids, header));
    }

    if (footer) {
        data.push(xlsxProcessRow(footer, ids, header));
    }

    XLSX.exportToXLSX(headerData, data, footer != null, title);
}
const xlsxProcessRow = (row = [], ids = [], header = []) => {
    return processRow(row, ids, header);
}