import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as employeeService from '../../../../services/domain/warehouse/employeeService';
import * as vehicleService from '../../../../services/domain/warehouse/vehicleService';
import * as filterService from '../../../../services/filterService';
import * as formatter from '../../../../helpers/formatter';
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Filter from '../../../../components/Filter/Filter';
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";
import AuthorizationFilterForm from "./AuthorizationFilterForm";



export const AuthorizationList = (props) => {

    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.aut'})}
    ];

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.number"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id11', align: "left",   label: intl.formatMessage({id:"generic.status"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.warehouse"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.vehicle"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.driver"}), sublabel: null, formatter: null },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.authorization"}), sublabel: null, formatter: formatter.dateTimeFormatter, width: 50 },
        { id: 'id6', align: "left",   label: intl.formatMessage({id:"generic.domain.liquidation"}), sublabel: null, formatter: formatter.dateTimeFormatter, width: 50 },
        { id: 'id7', align: "center",   label: intl.formatMessage({id:"generic.domain.pos.plural"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id8', align: "left",   label: intl.formatMessage({id:"generic.related.fem"}), sublabel: null, formatter: null, width: 50 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const newFilter = {
        number : null,
        warehouseId: null,
        vehicleId: null,
        startDate: null,
        endDate: null,
        liquidated: false
    }

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    async function loadAuthorizations() {
        setLoading(true);
        const response = await authorizationService.getAuthorizations(filter);
        if (response.status === 200) {
            setData(response.data.authorizations.map(item => convertRowData(item)));
        } else {
            console.error(response);
        }
        setLoading(false);
    }

    useEffect(() => {
        async function load() {
            const response = await vehicleService.getVehicles();
            if (response.status === 200) {
                setVehicles(response.data.vehicles);
            }
        }

        if (props.location.state && props.location.state.back) {
            const filterCache = filterService.loadFilter();
            if (filterCache && filterCache.filter) {
                setFilter(filterCache.filter);
                setVehicles(filterCache.data.vehicles);
                setRefresh(Date.now());
            } else load();
        } else load();

    }, []);
    useEffect(() => {
        if (refresh != null) handleSearch();
    }, [refresh]);

    const convertRowData = item => {
        return {
            id1: item.number,
            id11: authorizationService.getStatus(item),
            id2: item.warehouse.name,
            id3: item.vehicle.plate,
            id4: employeeService.getFullName(item.driver),
            id5: item.authDate,
            id6: item.liqDate,
            id7: item.deliveryNotesPos.length,
            id8: item.relatedAuth,
            actions: <RouterLink to={{pathname: `authorizations/${item.number}`, state: {authorization: item}}}><EditIcon button /></RouterLink>
        }
    }

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        switch (e.target.name) {
            case "liquidated":
                setFilter({
                    ...filter,
                    number: null,
                    startDate: filter.startDate === null ? formatter.dateApiFormatter() : filter.startDate,
                    liquidated: getValueFromField(e)
                });
                break;

            case "number":
                setFilter({
                    ...newFilter,
                    number: getValueFromField(e),
                    liquidated: null
                });
                break;

            default:
                setFilter({
                    ...filter,
                    number: null,
                    [e.target.name]: getValueFromField(e)
                });
        }
    }

    const handleSearch = () => {
        loadAuthorizations();
        filterService.saveFilter(filter, {vehicles: vehicles});
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={loading}>
                    <AuthorizationFilterForm
                        filter={filter}
                        vehicles={vehicles}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <RouterLink to={{pathname: `authorizations/new`}}><Button color="primary" float size="small"><AddIcon color="white"/></Button></RouterLink>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
        </Grid>
    )
};

export default AuthorizationList;