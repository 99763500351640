import React, {useEffect, useState} from 'react';



const InputNative = (props) => {
    const {type = 'text', name, value, formatter, onChange, ...others} = props;

    const inputRef = React.createRef();

    const [edit, setEdit] = useState(false);
    const [formattedValue, setFormattedValue] = useState("");

    useEffect(() => {
        setFormattedValue(
            typeof formatter === 'function' ? formatter(value) : value
        );
    }, [value]);

    useEffect(() => {
        if (edit) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [edit]);

    const handleFocus = () => {
        setEdit(true);
    }
    const handleBlur = () => {
        setEdit(false);
    }
    return (
        <React.Fragment>
            {!edit && (
                <input
                    type={type}
                    value={formattedValue}
                    onClick={handleFocus}
                    onFocus={handleFocus}
                    {...others}
                />
            )}
            {edit && (
                <input
                    type={type}
                    name={name}
                    ref={inputRef}
                    value={value}
                    onBlur={handleBlur}
                    onChange={onChange}
                    {...others}
                />
            )}
        </React.Fragment>
    )
}

export default InputNative;