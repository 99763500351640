import MainLayout from '../core/layouts/main/Layout';

import OrderList from './OrderList';


const routes = [
    {
        path: '/orders',
        layout: MainLayout,
        component: OrderList,
        private: true,
        menuSelectedItem: 'main.orders'
    }
];

export default routes;
