import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from "../../../../hocs/withMessageBox";

import * as formatter from "../../../../helpers/formatter";
import * as validator from "../../../../helpers/validator";
import * as statsService from '../../../../services/domain/warehouse/statsService';
import * as vehicleService from "../../../../services/domain/warehouse/vehicleService";

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import Filter from "../../../../components/Filter/Filter";
import TabPanel from '../../../../components/tabs/TabPanel';
import {getValueFromField} from "../../../../helpers/form";

import SaleStatsFilterForm from "./SaleStatsFilterForm";
import SalesByVehicleTab from "./SalesByVehicleTab";
import SalesByPointOfSaleTab from "./SalesByPointOfSaleTab";



export const Stats = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.stats'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.stats.sales'})}
    ];

    const newFilter = {
        vehicleId: null,
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter()
    }

    const [activeTab, setActiveTab] = useState(0);
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(false);
    const [salesByVehicle, setSalesByVehicle] = useState([]);
    const [salesByPointOfSale, setSalesByPointOfSale] = useState([]);
    const [title, setTitle] = useState(null);
    const [vehicles, setVehicles] = useState([]);


    useEffect(() => {
        async function load() {
            const response = await vehicleService.getVehicles();
            if (response && response.status === 200) {
                setVehicles(response.data.vehicles);
            }
        }
        load();
    }, []);

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);

        let response;
        switch (activeTab) {
            case 0:
                response = statsService.salesByVehicle(filter);
                break;
            case 1:
                response = statsService.salesByPos(filter);
                break;
        }
        response
            .then((res) => {
                if (res && res.data) {
                    switch (activeTab) {
                        case 0:
                            setSalesByVehicle(res.data.sales);
                            break;
                        case 1:
                            setSalesByPointOfSale(res.data.sales);
                            break;
                    }
                    const vehicle = filter.vehicleId === null ? "" : " " + vehicles.getObjectById(filter.vehicleId).plate;
                    const dates = validator.isSameDate(filter.startDate, filter.endDate) ?
                        formatter.dateFormatter(filter.startDate) :
                        formatter.dateFormatter(filter.startDate) + " - " + formatter.dateFormatter(filter.endDate);
                    setTitle(intl.formatMessage({id: 'generic.domain.sales'}) + vehicle + " " + dates);
                } else console.error('Error resolving promise')
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)})
            .finally(() => setIsLoading(false));
    }


    const handleChangeTab = (e, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={isLoading}>
                    <SaleStatsFilterForm
                        filter={filter}
                        vehicles={vehicles}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" color="primary">
                    <Tabs
                        variant="fullWidth"
                        value={activeTab}
                        onChange={handleChangeTab}
                    >
                        <Tab label={intl.formatMessage({id: "generic.domain.sales.byVehicle"})}/>
                        <Tab label={intl.formatMessage({id: "generic.domain.sales.byPos"})}/>
                    </Tabs>
                </AppBar>
                <Paper style={{padding: '20px'}}>
                    <TabPanel index={0} value={activeTab}>
                        <SalesByVehicleTab
                            sales={salesByVehicle}
                            title={title}
                            isLoading={isLoading}
                        />
                    </TabPanel>
                    <TabPanel index={1} value={activeTab}>
                        <SalesByPointOfSaleTab
                            sales={salesByPointOfSale}
                            title={title}
                            isLoading={isLoading}
                        />
                    </TabPanel>
                </Paper>

            </Grid>
        </Grid>
    )
}

export default withMessageBox(Stats);
