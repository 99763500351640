import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as deliveryConfirmationService from '../../../../services/domain/warehouse/deliveryConfirmationService';
import * as employeeService from '../../../../services/domain/warehouse/employeeService';
import * as productService from '../../../../services/domain/warehouse/productService';
import * as saleChannelService from '../../../../services/domain/warehouse/saleChannelService';
import * as validateService from "../../../../services/validateService";
import * as formatter from "../../../../helpers/formatter";
import {getObjectById} from "../../../../helpers/array";
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import DeleteIcon from '../../../../components/icons/Delete';
import EditIcon from '../../../../components/icons/Edit';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Filter from '../../../../components/Filter/Filter';
import Modal from "../../../../components/Modal/Modal";
import Table from '../../../../components/table';
import {RightBox} from "../../../../components/Box";
import DeliveryConfirmationFilterForm from "./DeliveryConfirmationFilterForm";
import DeliveryConfirmationForm from "./DeliveryConfirmationForm";



export const DeliveryConfirmationList = (props) => {

    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.deliveryConfirmation'})}
    ];

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.authorization"}), sublabel: null, width: 150 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateFormatter, width: 150 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.saleChannel"}), sublabel: null },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.reference"}), sublabel: null },
        { id: 'actions', width: 60 },
    ];

    const newDeliveryConfirmation = {
        id: null,
        authCustomerManagementId: null,
        authorizationId: null,
        authorizationNumber: null,
        productId: null,
        charges: 0,
        subscribes: 0,
        unsubscribes: 0,
        changes: 0,
        dmf: 0,
        employeeId: null,
        bonusNumber: null,
        observations: null,
        orderNumber: null,
        paymentTypeId: null,
        referenceNumber: null,
        saleChannelId: null,
        ticketId: null,
        customerSign: null,
        zoneId: null
    }
    const validations = {
        authorizationId: [{type: validateService.REQUIRED}],
        employeeId: [{type: validateService.REQUIRED}],
        productId: [{type: validateService.REQUIRED}],
        saleChannelId: [{type: validateService.REQUIRED}],
        charges: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    }
    const newFilter = {
        authorizationNumber : null,
        referenceNumber: null,
        startDate: null,
        endDate: null
    }

    const [authorizations, setAuthorizations] = useState([]);
    const [data, setData] = useState([]);
    const [deliveryConfirmation, setDeliveryConfirmation] = useState(newDeliveryConfirmation);
    const [employees, setEmployees] = useState([]);
    const [errors, setErrors] = useState({});
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [reload, setReload] = useState(null);
    const [saleChannels, setSaleChannels] = useState([]);

    useEffect(() => {
        async function load() {
            const drivers = await employeeService.getDrivers();
            const responseP = await productService.getProducts();
            const responseS = await saleChannelService.getSaleChannels();

            if (responseP.status === 200 && responseS.status === 200) {
                setEmployees(drivers);
                setProducts(responseP.data.products.sortBy('name', 'DESC'));
                setSaleChannels(responseS.data.saleChannels);
                setIsLoading(false);
            }
        }
        load();
    }, []);
    useEffect(() => {

    }, [reload]);

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        const response = deliveryConfirmationService.list(filter);
        response
            .then(res => {
                setData(
                    res.data.map(item => convertRowData(item))
                );
            })
            .catch(err => {})
            .finally(() => setIsLoading(false));
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleNew = () => {
        setDeliveryConfirmation(newDeliveryConfirmation);
        setOpen(true);
    };
    const handleEdit = (item) => {
        setDeliveryConfirmation({
            ...item,
        });
        setAuthorizations([{id: item.authorizationId, number: item.authorizationNumber}]);
        setOpen(true);
    }
    const handleDelete = (item) => {
        setIsLoading(true)
        const response = deliveryConfirmationService.del(item);
        response
            .then(() => {
                props.successMessage(intl.formatMessage({id: "generic.deleted"}));
                handleSearch();
            })
            .catch(err => {
                props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                console.error(err.response.data)
            })
            .finally(() => setIsLoading(false));
    }
    const handleChange = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = getValueFromField(e);

        const authorizationId = name === "employeeId" ?
            null :
            name === "authorizationId" ? getValueFromField(e) : deliveryConfirmation.authorizationId;

        setDeliveryConfirmation({
            ...deliveryConfirmation,
            [e.target.name]: value,
            authorizationId: authorizationId
        });
        if (name === "employeeId") {
            setIsLoading(true);
            const employee = getObjectById(employees, value);
            if (employee && employee.vehicle) {
                const vehicleId = employee.vehicle.id;
                const response = authorizationService.getAuthorizations({vehicleId: vehicleId});
                response
                    .then(res => {
                        setAuthorizations(res.data.authorizations);
                    })
                    .catch(err => {})
                    .finally(() => setIsLoading(false));
            } else {
                setAuthorizations([]);
            }
        }
    };
    const handleSubmit = () => {
        const err = validateService.validate(deliveryConfirmation, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            let response;
            if (deliveryConfirmation.id === null) {
                response = deliveryConfirmationService.add(deliveryConfirmation);
            } else {
                response = deliveryConfirmationService.update(deliveryConfirmation);
            }
            response
                .then(() => {
                    handleClose();
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                    handleSearch();
                })
                .catch(err => {
                    props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                    console.error(err.response)
                });
        }
    };

    const convertRowData = item => {
        item = {
            ...item,
            employeeId: item.employee ? item.employee.id : null,
            productId: item.product ? item.product.id : null,
            saleChannelId: item.saleChannel ? item.saleChannel.id : null,
            paymentTypeId: item.paymentType ? item.paymentType.id : null
        }
        return ({
            id1: item.authorizationNumber,
            id2: item.createdAt,
            id3: item.product ? item.product.name : "",
            id4: item.saleChannel ? item.saleChannel.channel : "",
            id5: item.referenceNumber,
            actions: <div><EditIcon button onClick={() => handleEdit(item)}/><DeleteIcon button onClick={() => handleDelete(item)} /></div>
        });
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={isLoading}>
                    <DeliveryConfirmationFilterForm
                        filter={filter}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12} style={{visibility: 'hidden'}}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew} disabled={isLoading}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal
                    open={open}
                    height={400} width={600}
                    title={intl.formatMessage({id: "generic.domain.deliveryConfirmation"})}
                    onClose={handleClose}
                >
                    <DeliveryConfirmationForm
                        deliveryConfirmation={deliveryConfirmation}
                        products={products}
                        employees={employees}
                        saleChannels={saleChannels}
                        authorizations={authorizations}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
}

export default withMessageBox(DeliveryConfirmationList);