import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as accountingService from "../../../../services/domain/warehouse/accountingService";
import * as validateService from "../../../../services/validateService";
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../../../../components/Breadcrumbs/index';

import AccountingAccountForm from "./AccountingAccountsForm";
import withMessageBox from "../../../../hocs/withMessageBox";



export const AccountingAccount = (props) => {
    const intl = useIntl();

    const newAccounts = {
        bank: null,
        cash: null,
        creditCard: null,
        payments: null,
        //vatR: null,
        //vatS: null,
        products: null,
        bonusPO: null,
        bonusPT: null,
        bonusPTB: null,
        bonusB24: null,
        bonusArmy: null,
        bonusSup: null,
        bonusDiscI350: null,
        bonusDiscUD110: null,
        bonusDMF: null,
        bonusPagadoARepsol: null,
        gasIncidences: null,
        chargesSupplement: null,
        gasSubscribes: null,
        gasUnsubscribes: null,
        gasRenting: null,
    }
    const validations = {
        cash: [{type: validateService.REQUIRED}],
    }

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.accountingAccounts'})}
    ];

    const [accounts, setAccounts] = useState(newAccounts);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        async function load() {
            setLoading(true);
            const response = await accountingService.getAccountingAccounts();
            if (response.status === 200) {
                response.data.accounts.map(account => {
                    //TODO: refactor
                    switch (account.accountType.code) {
                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_CASH:
                            accounts.cash = account.account;
                            break;
                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_BANK:
                            accounts.bank = account.account;
                            break;
                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_TPV:
                            accounts.creditCard = account.account;
                            break;
                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_PAYMENTS:
                            accounts.payments = account.account;
                            break;

                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_BONUS:
                            switch (account.codeId) {
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_PO:
                                    accounts.bonusPO = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_PT:
                                    accounts.bonusPT = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_PTB:
                                    accounts.bonusPTB = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_B24:
                                    accounts.bonusB24 = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_ARMY:
                                    accounts.bonusArmy = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_SUP:
                                    accounts.bonusSup = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_DISC_I350:
                                    accounts.bonusDiscI350 = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_DISC_UD110:
                                    accounts.bonusDiscUD110 = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_DMF:
                                    accounts.bonusDMF = account.account;
                                    break;
                                case accountingService.ACCOUNTING_ACCOUNT_CODE_BONUS_PAID_REPSOL:
                                    accounts.bonusPagadoARepsol = account.account;
                                    break;
                            }
                            break;

                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_MERCHANDASING:
                            accounts.products = account.account;
                            break;

                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_SUPPLEMENT:
                            accounts.bonusSup = account.account;
                            break;

                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_RENTING_GAS:
                            accounts.gasRenting = account.account;
                            break;

                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_SUBS_GAS:
                            accounts.gasSubscribes = account.account;
                            break;
                        case accountingService.ACCOUNTING_ACCOUNT_TYPE_UNSUBS_GAS:
                            accounts.gasUnsubscribes = account.account;
                            break;

                            case accountingService.ACCOUNTING_ACCOUNT_TYPE_INCIDENCE_GAS:
                            accounts.gasIncidences = account.account;
                            break;
                    }
                });

            } else {}
            setLoading(false);
        }
        load();
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        setAccounts({
            ...accounts,
            [e.target.name]: getValueFromField(e)
        });
    }
    const handleSubmit = () => {
        setLoading(true);
        const response = accountingService.updateDefaultAccountingAccounts(accounts);
        response
            .then(() => {
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {
                props.errorMessage('Error');
                console.error(err.response)
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}><br/><br/></Grid>
            <Grid item xs={12}>
                <AccountingAccountForm
                    accounts={accounts}
                    errors={errors}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(AccountingAccount);