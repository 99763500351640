import React from 'react';
import PropTypes from 'prop-types';

import { CenterBox } from "../Box";


const TabPanel = (props) => {
    const {value, index, overflow = 'visible', padding = '20px 10px', children} = props;

    return (
        <React.Fragment>
            {
                value === index && (
                    <CenterBox overflow={overflow} display="block" padding={padding}>
                        {children}
                    </CenterBox>
                )
            }
        </React.Fragment>
    )
};

TabPanel.propTypes = {
    index: PropTypes.number.isRequired,
    overflow: PropTypes.oneOf(['visible', 'hidden']),
    padding: PropTypes.string,
    value: PropTypes.number.isRequired
};

export default TabPanel;
