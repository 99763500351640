import MainLayout from "../core/layouts/main/Layout";

import ItineraryList from "./management/itineraries/ItineraryList";
import RouteList from "./management/routes/RouteList";
import StreetList from "./management/streets/StreetList";
import Street from "./management/streets/Street";

import QStreetList from "./queries/streets/StreetList";

import {ROLE_CALLEJERO, ROLE_GERENTE} from "../../services/authService";


const routes = [
    {
        path: '/street-map/itineraries',
        layout: MainLayout,
        component: ItineraryList,
        exact: true,
        private: true,
        roles: [ROLE_CALLEJERO, ROLE_GERENTE],
        menuSelectedItem: 'main.streetMap.management.itineraries',
    },
    {
        path: '/street-map/routes',
        layout: MainLayout,
        component: RouteList,
        exact: true,
        private: true,
        roles: [ROLE_CALLEJERO, ROLE_GERENTE],
        menuSelectedItem: 'main.streetMap.management.routes'
    },
    {
        path: '/street-map/streets',
        layout: MainLayout,
        component: StreetList,
        exact: true,
        private: true,
        menuSelectedItem: 'main.streetMap.management.streets'
    },
    {
        path: '/street-map/streets/:id',
        layout: MainLayout,
        component: Street,
        exact: true,
        private: true,
        roles: [ROLE_CALLEJERO, ROLE_GERENTE],
        menuSelectedItem: 'main.streetMap.management.streets'
    },
    {
        path: '/street-map/streets-by-itinerary',
        layout: MainLayout,
        component: QStreetList,
        exact: true,
        private: true,
        roles: [ROLE_CALLEJERO, ROLE_GERENTE],
        menuSelectedItem: 'main.streetMap.queries.streetsByItinerary'
    }
];

export default routes;
