import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import * as filterService from "../../../../services/filterService";
import * as streetMapService from '../../../../services/domain/streetMap/streetMapService';
import {getValueFromField} from "../../../../helpers/form";
import {isEmpty} from "../../../../helpers/validator";

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from "../../../../components/icons/Enabled";
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Filter from '../../../../components/Filter/Filter';
import Table from '../../../../components/table';
import {RightBox} from "../../../../components/Box";
import StreetFilterForm from "./StreetFilterForm";



export const StreetList = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.streetMap'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management.streets'})}
    ];

    const headers = [
        { id: 'id0', align: "left",   label: "Id", sublabel: null, formatter: null, width: 100 },
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.streetType"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.street"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.town"}), sublabel: null, formatter: null, width: 250 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.province"}), sublabel: null, formatter: null, width: 175 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const newFilter = {
        streetTypeId: null,
        name: null,
        raw: null,
        townId: null,
        provinceId: null,
        startBy: false
    }

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [loading, setLoading] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [streetTypes, setStreetTypes] = useState([]);
    const [towns, setTowns] = useState([]);


    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseP = await streetMapService.getProvinces();
            const responseST = await streetMapService.getStreetTypes();
            if (responseP.status === 200) {
                setProvinces(responseP.data.provinces);
            }
            if (responseST.status === 200) {
                setStreetTypes(responseST.data.streetTypes);
            }
            if (responseP.status === 200 && responseST.status === 200) {
                setLoading(false);
            }
        }

        if (props.location.state && props.location.state.back) {
            const filterCache = filterService.loadFilter();
            if (filterCache.filter) {
                setFilter(filterCache.filter);
                setStreetTypes(filterCache.data.streetTypes);
                setProvinces(filterCache.data.provinces);
                setTowns(filterCache.data.towns);
                setRefresh(Date.now());
            } else load();
        } else load();
    }, []);

    useEffect(() => {
        if (refresh != null) handleSearch();
    }, [refresh]);

    async function loadTowns(provinceId) {
        setLoading(true);
        const response = await streetMapService.getTowns({provinceId: provinceId});
        if (response.status === 200) {
            setTowns(response.data.towns);
        } else {
            console.error(response);
        }
        setLoading(false);
    }
    async function loadStreets() {
        setLoading(true);
        const response = await streetMapService.getStreets(filter);
        if (response && response.status === 200) {
            setData(response.data.streets.map(item => convertRowData(item)));
        } else {
            if (response) console.error(response);
        }
        setLoading(false);
    }

    const convertRowData = item => {
        return {
            id0: item.id,
            id1: item.streetType.name,
            id2: item.name,
            id3: item.town.name,
            id4: item.town.province.name,
            actions: <RouterLink to={{pathname: `/street-map/streets/${item.id}`, state: {street: item, streetTypes: streetTypes, provinces: provinces}}}><EditIcon button /></RouterLink>
        }
    }

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        const value = getValueFromField(e);
        setFilter({
            ...filter,
            [e.target.name]: value
        });

        if (e.target.name === 'provinceId') {
            if (isEmpty(value))
                setTowns([]);
            else
                loadTowns(value);
        }
    }

    const handleSearch = () => {
        loadStreets();
        filterService.saveFilter(filter, {streetTypes: streetTypes, provinces: provinces, towns: towns});
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={loading} defaultExpanded>
                    <StreetFilterForm
                        filter={filter}
                        streetTypes={streetTypes}
                        towns={towns}
                        provinces={provinces}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <RouterLink to={{pathname: `/street-map/streets/new`}}><Button color="primary" float size="small"><AddIcon color="white"/></Button></RouterLink>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default StreetList;