import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';

import {getVehicleItems} from "../../../../services/domain/warehouse/vehicleService";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Input from "../../../../components/form/Input/Input";
import Select from "../../../../components/form/Select";



const SaleStatsFilterForm = (props) => {
    const {filter, vehicles} = props;

    const intl = useIntl();

    const [vehicleItems, setVehicleItems] = useState([]);

    useEffect(() => {
        setVehicleItems(getVehicleItems(vehicles));
    }, [vehicles]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box display="flex" width="100%">
                        <Box marginRight={1}>
                            <Select
                                name="vehicleId"
                                label={intl.formatMessage({id: 'generic.domain.vehicle'})}
                                items={vehicleItems}
                                value={filter.vehicleId}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="startDate"
                                label={intl.formatMessage({id: 'generic.date.init'})}
                                value={filter.startDate}
                                onChange={props.onChange}
                            />
                        </Box>
                        <Box marginRight={1}>
                            <Input
                                type="date"
                                name="endDate"
                                label={intl.formatMessage({id: 'generic.date.end'})}
                                value={filter.endDate}
                                onChange={props.onChange}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

export default SaleStatsFilterForm;
