import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as deliveyNoteService from  '../../../../services/domain/warehouse/deliveryNoteService';
import * as pointOfSaleService from  '../../../../services/domain/warehouse/pointOfSaleService';
import {getValueFromField} from "../../../../helpers/form";
import * as formatter from "../../../../helpers/formatter";

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import AlertDialog from "../../../../components/AlertDialog";
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Button from '../../../../components/form/Button/index';
import Filter from '../../../../components/Filter/Filter';
import Modal from '../../../../components/Modal/Modal';
import PrintIcon from '../../../../components/icons/Print';
import SearchIcon from '../../../../components/icons/Search';
import Table from '../../../../components/table';
import {RightBox} from "../../../../components/Box";

import DeliveryNoteInvoiceFilterForm from "./DeliveryNoteInvoiceFilterForm";
import DeliveryNoteInvoiceCreateForm from "./DeliveryNoteInvoiceCreateForm";



export const DeliveryNoteInvoiceList = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing.deliveryNotes'})}
    ];

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.number"}), sublabel: null, width: 150 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateFormatter, width: 150 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.pos"}), sublabel: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.deliveryNotesInvoiced"}), sublabel: null },
        { id: 'actions', align: "center", width: 60 },
    ];

    const newCreate = {
        posId: null,
        startDate: null,
        endDate: null
    }
    const newFilter = {
        posId: null,
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter()
    }

    const [create, setCreate] = useState(newCreate);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [reload, setReload] = useState(null);
    const [pos, setPos] = useState([]);

    useEffect(() => {
        async function load() {
            setIsLoading(true);
            const responseP = await pointOfSaleService.getPointOfSale();

            if (responseP.status === 200) {
                setPos(
                    responseP.data.pointsOfSale.filter(item =>
                        item.pointOfSaleType.code === pointOfSaleService.POS_TYPE_CODE_SUBAGENTE &&
                        item.enabled === 1
                    ).sortBy('name')
                );
                setIsLoading(false);
            }
        }
        load();
    }, []);

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        const response = deliveyNoteService.listInvoices(filter);
        response
            .then((res) => {
                setData(
                    res.data.deliveryNoteInvoices.map(item => convertRowData(item))
                )
            })
            .catch(err => {})
            .finally(() => setIsLoading(false));
    }

    const handleDownload = (item) => {
        deliveyNoteService.downloadInvoice(item);
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleFormChange = (e) => {
        setCreate({
            ...create,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSubmit = () => {
        handleCloseConfirmation();
        handleClose();
        setIsLoading(true);
        const response = deliveyNoteService.createInvoices(create);
        response
            .then(response => {
                props.successMessage(intl.formatMessage({id: "generic.domain.deliveryNoteInvoice.created"}));
                handleSearch();
            })
            .catch(error => {
                if (error.response.status === 404) {
                    props.warningMessage(intl.formatMessage({id: "generic.domain.deliveryNoteInvoice.empty"}))
                } else {
                    props.errorMessage('Error');
                }
            })
            .finally(() => setIsLoading(false));
    }
    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }



    const convertRowData = item => {
        const deliveryNotesNumber = item.deliveryNotes.map(item => item.number);
        return ({
            id1: item.number,
            id2: item.date,
            id3: pointOfSaleService.getNameWithAlias(item.pointOfSale),
            id4: deliveryNotesNumber.join(', '),
            actions: <React.Fragment>
                <RouterLink to={{pathname: `/delivery-notes/invoices/${item.number}`, state: {invoice: item}}}><SearchIcon button /></RouterLink>
                <PrintIcon button onClick={() => handleDownload(item)} />
            </React.Fragment>
        });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Filter onSearch={handleSearch} onClear={handleFilterClear}>
                    <DeliveryNoteInvoiceFilterForm
                        filter={filter}
                        pos={pos}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleOpen}>
                        <AddIcon />
                    </Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    isExportable
                />
                <Modal
                    open={open}
                    title=""
                    height="200px"
                    width="600px"
                    onClose={handleClose}
                >
                    <DeliveryNoteInvoiceCreateForm
                        create={create}
                        pos={pos}
                        onChange={handleFormChange}
                        onSubmit={handleOpenConfirmation}
                    />
                </Modal>
                <AlertDialog
                    open={openConfirmation}
                    title={intl.formatMessage({id: 'generic.domain.deliveryNote.bill'})}
                    text={intl.formatMessage({id: 'generic.questions.sure'})}
                    onYes={handleSubmit}
                    onNo={handleCloseConfirmation}
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(DeliveryNoteInvoiceList);