export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';

export const OBJ_NOT_EXISTS = -1;


Array.prototype.clone = (array = []) => {
    return JSON.parse(JSON.stringify(array));
}

Array.prototype.getObjectById = function (id) {
    const result = this.filter(obj => {return obj.id === id});
    return result.length > 0 ? result[0] : null;
};

Array.prototype.getKeyValueByObjectId = function (id, key) {
    const obj = this.getObjectById(id);
    return obj !== null ? obj[key] : null;
};

/**
 * Return an object from an array where object.key = value
 * @param key
 * @param value
 * @returns {null}
 */
Array.prototype.getObjectByKeyValue = function (key, value) {
    const result = this.filter(obj => {return obj[key] === value});
    return result.length > 0 ? result[0] : null;
};

/**
 * Return the index of an object by key/value in array
 * @param key
 * @param value
 * @returns {number}
 */
Array.prototype.getIndexObjectByKeyValue = function (key, value) {
    let idx = OBJ_NOT_EXISTS;
    this.forEach((obj, index) => {
        if (obj[key] === value) idx = index;
    });
    return idx;
};
/**
 * Return the index of an object in array
 * @param object
 * @returns {number}
 */
Array.prototype.getIndexObject = function (object) {
    let idx = OBJ_NOT_EXISTS;
    this.forEach((obj, index) => {
        let equal = true;
        for (let key in obj) {
            if (obj[key] !== object[key]) equal = false;
        }
        if (equal) idx = index;
    });
    return idx;
}

/**
 * Object exists in array
 * @param object
 * @returns {boolean}
 */
Array.prototype.objectExists = function (object) {
    return this.getIndexObject(object) !== OBJ_NOT_EXISTS;
}

/**
 * Object with key = value exists in array
 * @param key
 * @param value
 * @returns {boolean}
 */
Array.prototype.keyValueExists = function (key, value) {
    return this.getIndexObjectByKeyValue(key, value) !== OBJ_NOT_EXISTS;
}

/** sort */
Array.prototype.sortBy = function (field, order = ORDER_ASC) {
    return this.slice(0).sort(function(a,b) {
        return order === ORDER_ASC ?
            (a[field] > b[field]) ? 1 : (a[field] < b[field]) ? -1 : 0 :
            (a[field] < b[field]) ? 1 : (a[field] > b[field]) ? -1 : 0;
    });
};

/**
 *
 * @param fieldToLabel
 * @param fieldToValue
 * @returns {{label: *, value: *}[]}
 */
Array.prototype.getSelectItems = function (fieldToLabel = "name", fieldToValue = "id") {
    return this.map(item => { return {value: item[fieldToValue], label: item[fieldToLabel]} });
}


/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Deprecated !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Return an object from an array where object.id = id
 * //TODO: cambiar por Array.prototype.getObjectById
 * @param array
 * @param id
 * @returns {null}
 */
export const getObjectById = (array = [], id) => {
    const result = array.filter(obj => {return obj.id === id});
    return result.length > 0 ? result[0] : null;
};

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Deprecated !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Return an object from an array where object.key = value
 * //TODO: cambiar por Array.prototype.getObjectByKeyValue
 * @param array
 * @param key
 * @param value
 * @returns {null}
 */
export const getObjectByKey = (array = [], key, value) => {
    const result = array.filter(obj => {return obj[key] === value});
    return result.length > 0 ? result[0] : null;
};
