import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

export class EnhancedTableFooter extends React.Component {

    render() {
        const { classes, headers, footer } = this.props;

        return (
            <TableFooter>
                <TableRow>
                    {
                        headers.map((column, cellId) => {
                            return (
                                <TableCell
                                    key={cellId}
                                    component="td"
                                    scope="row"
                                    align={column.align}
                                    className={classes.tableSummaryCell}
                                >
                                    <div>{typeof column.formatter === 'function' ? column.formatter(footer[column.id], footer) : footer[column.id]}</div>
                                </TableCell>
                            )
                        })
                    }
                </TableRow>
            </TableFooter>
        )
    }
}

EnhancedTableFooter.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array.isRequired,
    footer: PropTypes.object.isRequired
};
