import React from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';


export const PointOfSaleForm = (props) => {
    const {pos, posTypes, errors} = props;

    const intl = useIntl();

    return (
        <form onSubmit={(e) => {e.preventDefault();props.onSubmit()}}>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={6}>
                    <Select
                        name="pointOfSaleTypeId"
                        label={intl.formatMessage({id: 'generic.type'})}
                        items={posTypes}
                        value={pos.pointOfSaleTypeId}
                        error={errors.pointOfSaleTypeId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        name="nif"
                        label={intl.formatMessage({id: 'app.employees.nif'})}
                        value={pos.nif}
                        error={errors.nif}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="name"
                        label={intl.formatMessage({id: 'app.employees.name'})}
                        value={pos.name}
                        error={errors.name}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="alias"
                        label={intl.formatMessage({id: 'generic.domain.alias'})}
                        value={pos.alias}
                        error={errors.alias}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    )

};

export default PointOfSaleForm;