import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as accountingService from '../../../../services/domain/warehouse/accountingService';
import * as formatter from "../../../../helpers/formatter";
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import Filter from "../../../../components/Filter/Filter";
import ExportButton from "../../../../components/form/Button/export";
import PrintButton from '../../../../components/form/Button/print';
import Table from '../../../../components/table';
import {RightBox} from "../../../../components/Box";

import AccountingMovementsFilterForm from "./AccountingMovementsFilterForm";



export const AccountingMovementList = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.accounting'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.accounting.movements'})}
    ];

    const newFilter = {
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter()
    }

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [footer, setFooter] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateFormatter, width: 100 },
        { id: 'id2', align: "center",   label: intl.formatMessage({id:"generic.domain.accounting.account"}), sublabel: null, formatter: null, width: 120 },
        { id: 'id3', align: "right",   label: intl.formatMessage({id:"generic.domain.accounting.d"}), sublabel: null, formatter: (value) => formatter.numberFormatter(value, 2), width: 90 },
        { id: 'id4', align: "right",   label: intl.formatMessage({id:"generic.domain.accounting.h"}), sublabel: null, formatter: (value) => formatter.numberFormatter(value, 2), width: 90 },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.opNumber"}), sublabel: null, formatter: null, width: 120 },
        { id: 'id6', align: "left",   label: intl.formatMessage({id:"generic.domain.desc"}), sublabel: null, formatter: null },
    ];


    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        setFooter({});
        const response = accountingService.listAccountingMovements(filter);
        response
            .then((res) => {
                let debe = 0;
                let haber = 0;
                setData(
                    res.data.accountingMovements.map(item => {
                        debe = (parseFloat(debe) + parseFloat(item.debe)).toFixed(2);
                        haber = (parseFloat(haber) + parseFloat(item.haber)).toFixed(2);

                        return convertRowData(item);
                    })
                );
                setFooter({
                    id1: "",
                    id2: "Total",
                    id3: debe,
                    id4: haber
                });
            })
            .catch(err => {})
            .finally(() => setIsLoading(false));
    }

    const handleDownload = () => {
        accountingService.downloadAccountingMovements(filter);
    }
    const handleExport = async () => {
        setIsExporting(true);
        const response = await accountingService.exportAccountingMovements(filter);
        if (response === true) setIsExporting(false);
    }

    const convertRowData = item => {
        return ({
            id1: item.date,
            id2: item.account,
            id3: item.debe,
            id4: item.haber,
            id5: item.operationNumber,
            id6: item.description,
        });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={isLoading}>
                    <AccountingMovementsFilterForm
                        filter={filter}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <PrintButton onClick={handleDownload} />
                    <ExportButton isLoading={isExporting} onClick={handleExport} />
                </RightBox>
                <br/>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    footer={footer}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(AccountingMovementList);