import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '../../../components/form/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import * as apiService from '../../../services/apiService';
import * as authService from '../../../services/authService';
import * as cryptService from '../../../services/cryptService';


const Login = () => {
    const [user, setUser] = useState({username: null, password: null});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const intl = useIntl();

    const handleChange = (event) => {
        setUser({...user, [event.target.name]: event.target.value});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        logIn();
    };

    const logIn = async () => {
        try {
            const response = await apiService.post(
                process.env.REACT_APP_API_SERVER + '/api/v1/login',
                {username: user.username, password: cryptService.encryptPassword(user.password)}
            );
            authService.setLogInEnvironment(response.data.token);
            window.location = "/";
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError(intl.formatMessage({id: 'app.login.incorrect'}));
                } else {
                    setError(error.response.data.message)
                }
            } else {
                console.error(error);
            }
        } finally {
            setLoading(false);
        }
    };

    const env = process.env.REACT_APP_ENV === 'production' ? "" : "[" + process.env.REACT_APP_ENV + "]";

    return (
        <Paper style={{width: '100%', marginTop: '150px', padding:'25px', borderRadius: '25px'}}>
            <Box fontSize={20} paddingLeft="10px">ButaApp {env}</Box>
            { error && (
                <Box padding="10px"><Alert severity="error" style={{padding: '10px'}}>{error}</Alert></Box>
            )}
            <LoginForm
                loading={loading}
                onChange={handleChange}
                onSubmit={handleSubmit}
            />
        </Paper>
    )
};


const LoginForm = ({ onChange, onSubmit, loading}) => {
    const intl = useIntl();
    return (
        <div>
            <form onSubmit={e => onSubmit(e)}>
                <div style={{padding: 10}}>
                    <FormControl fullWidth>
                        <TextField
                            required
                            name="username"
                            label={intl.formatMessage({id: "app.login.form.user"})}
                            variant="outlined"
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </div>
                <div style={{padding: 10}}>
                    <FormControl fullWidth>
                        <TextField
                            required
                            name="password"
                            label="Password"
                            type="Password"
                            variant="outlined"
                            onChange={e => onChange(e)}
                        />
                    </FormControl>
                </div>
                <div style={{padding: 10}}>
                    <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        isLoading={loading}
                        fullWidth
                    >
                        Login
                    </Button>
                </div>
            </form>

        </div>
    )
};

export default Login;
