import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MuiCloseIcon from '@material-ui/icons/Close';


const CloseIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><MuiCloseIcon/></IconButton>
        );
    }
    return (<MuiCloseIcon/>);
};

export default CloseIcon;
