import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import * as formatter from "../../../../helpers/formatter";

import Grid from "@material-ui/core/Grid";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import Button from "../../../../components/form/Button";
import Table from '../../../../components/table';
import {RightBox} from "../../../../components/Box";

import DeliveryNoteInvoiceForm from "./DeliveryNoteInvoiceForm";



export const DeliveryNoteInvoice = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing.deliveryNotes'})}
    ];

    const headers = [
        { id: 'id01', align: "left",   label: intl.formatMessage({id:"generic.domain.deliveryNote"}), sublabel: null, width: 150 },
        { id: 'id02', align: "left",   label: intl.formatMessage({id:"generic.domain.deliveryDate"}), sublabel: null, width: 150 },
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, width: 100 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null },
        { id: 'id3', align: "right",   label: intl.formatMessage({id:"generic.domain.price.unit"}), sublabel: null, formatter: formatter.priceFormatter, width: 100 },
        { id: 'id4', align: "right",   label: intl.formatMessage({id:"generic.domain.price"}), sublabel: null, formatter: formatter.priceFormatter, width: 100 },
        { id: 'id5', align: "right",   label: intl.formatMessage({id:"generic.domain.commission.unit"}), sublabel: null, formatter: formatter.commissionFormatter, width: 100 },
        { id: 'id6', align: "right",   label: intl.formatMessage({id:"generic.domain.commission"}), sublabel: null, formatter: formatter.commissionFormatter,width: 100 },
    ];
    const headersC = [
        { id: 'id1', align: "right",   label: intl.formatMessage({id:"generic.domain.total.sub"}),formatter: formatter.priceFormatter, width: 100 },
        { id: 'id2', align: "right",   label: intl.formatMessage({id:"generic.domain.vat"}),formatter: formatter.priceFormatter, width: 100 },
        { id: 'id3', align: "right",   label: intl.formatMessage({id:"generic.domain.total"}),formatter: formatter.priceFormatter, width: 100 },
    ];
    const headersT = [
        { id: 'id1', align: "right",   label: intl.formatMessage({id:"generic.domain.total.sub"}),formatter: formatter.priceFormatter, width: 100 },
        { id: 'id2', align: "right",   label: intl.formatMessage({id:"generic.domain.commissions"}),formatter: formatter.priceFormatter, width: 100 },
        { id: 'id3', align: "right",   label: intl.formatMessage({id:"generic.domain.total"}),formatter: formatter.priceFormatter, width: 100 },
    ];

    const [data, setData] = useState([]);
    const [dataC, setDataC] = useState([]);
    const [dataT, setDataT] = useState([]);
    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        setInvoice(props.location.state.invoice);
    }, []);

    useEffect(() => {
        if (Object.keys(invoice).length > 0) {
            setData(invoice.lines.map(line => convertRowData(line)));
            setDataC([convertRowDataC(invoice)]);
            setDataT([convertRowDataT(invoice)]);
        }
    }, [invoice]);

    const convertRowData = item => {
        return ({
            id01: item.deliveryNoteNumber,
            id02: item.deliveryDate,
            id1: item.units,
            id2: item.product.name,
            id3: item.priceU,
            id4: item.priceU * item.units,
            id5: item.commissionU,
            id6: item.commissionU * item.units
        })
    }

    const convertRowDataC = item => {
        return ({
            id1: item.commission,
            id2: item.vatAmount,
            id3: item.commission + item.vatAmount
        })
    }
    const convertRowDataT = item => {
        const commissionAmount = item.commission + item.vatAmount;

        return ({
            id1: item.amount,
            id2: -commissionAmount,
            id3: item.amount - commissionAmount
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <DeliveryNoteInvoiceForm
                    invoice={invoice}
                />
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Table
                    headers={headers}
                    data={data}
                />
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <br/><br/>
                    <h4>{intl.formatMessage({id:"generic.domain.commissions"})}</h4>
                    <Table
                        headers={headersC}
                        data={dataC}
                    />
                    <h4>{intl.formatMessage({id:"generic.domain.total"})}</h4>
                    <Table
                        headers={headersT}
                        data={dataT}
                    />
                </RightBox>
                <RightBox>
                    <RouterLink to={{pathname: `/delivery-notes/invoices`}}>
                        <Button
                            color="primary"
                            variant="contained"
                        >
                            {intl.formatMessage({id: 'generic.list'})}
                        </Button>
                    </RouterLink>
                </RightBox>
                <br/><br/>
            </Grid>
        </Grid>
    )
}

export default DeliveryNoteInvoice