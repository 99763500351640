import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as productSituationService from '../../../../services/domain/warehouse/productSituationService';
import * as arrayHelper from "../../../../helpers/array";
import {priceFormatter} from '../../../../helpers/formatter';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import Button from '../../../../components/form/Button/index';
import DeleteIcon from '../../../../components/icons/Delete';
import EditIcon from "../../../../components/icons";
import EnabledIcon from '../../../../components/icons/Enabled';
import Modal from "../../../../components/Modal/Modal";
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";

import ProductForm from './ProductForm';
import CageForm from './CageForm';
import CustomerProductForm from './CustomerProductForm';



export const ChargeTab = (props) => {
    const {authorization, product, products, errors, cage, cages, errorsCage, customerManagement, errorsCustomerManagement, refreshGrids} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);
    const [dataC, setDataC] = useState([]);
    const [dataCP, setDataCP] = useState([]);
    const [openProduct, setOpenProduct] = useState(false);
    const [openCage, setOpenCage] = useState(false);
    const [openCustomerManagement, setOpenCustomerManagement] = useState(false);

    useEffect(() => {
        setData(authorization.products.map(item => convertProductRowData(item)));
        setDataC(authorization.cages.map(item => convertCageRowData(item)));
        setDataCP(authorization.customerManagement.map(item => convertCustomerManagementRowData(item)));
    }, [authorization, refreshGrids]);

    const handleClose = () => {
        setOpenProduct(false);
        setOpenCage(false);
        setOpenCustomerManagement(false);
    };

    const handleOpenProduct = () => {
        props.handleNewProduct();
        setOpenProduct(true);
    };
    const handleOpenCage = () => {
        props.handleNewCage();
        setOpenCage(true);
    };
    const handleEditCustomerManagement = (item) => {
        props.handleEditCustomerManagement(item);
        setOpenCustomerManagement(true);
    }
    const handleOpenCustomerManagement = () => {
        setOpenCustomerManagement(true);
    };

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.containers"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.price"}), sublabel: null, formatter: priceFormatter, width: 100 },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.price.max"}), sublabel: null, formatter: priceFormatter, width: 100 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertProductRowData = item => {
        const productId = item.product ? item.product.id : item.productId;
        const product = arrayHelper.getObjectById(products, productId);
        return {
            id1: product ? product.name : "",
            id2: item.containersAuth,
            id3: item.chargesAuth,
            id4: item.basePrice,
            id5: item.maxPrice,
            actions: authorizationService.isAuthorization(authorization) ?
                <DeleteIcon button onClick={() => props.handleDeleteProduct(item)} /> :
                <div></div>
        }
    };

    const headersCages = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.cage"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.units"}), sublabel: null, formatter: null, width: 100 },
        { id: 'actions', align: "center", width: 60 },
    ];
    const convertCageRowData = item => {
        const cageId = item.cage ? item.cage.id : item.cageId;
        const cage = arrayHelper.getObjectById(cages, cageId);
        return {
            id1: cage ? cage.name : "",
            id2: item.unitsAuth,
            actions: authorizationService.isAuthorization(authorization) ?
                <DeleteIcon button onClick={() => props.handleDeleteCage(item)} /> :
                <div></div>
        }
    }


    const headersCP = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null, width: 150 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.containers"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.customer"}), sublabel: null, formatter: null, width: 200 },
        { id: 'id6', align: "left",   label: intl.formatMessage({id:"generic.domain.zone"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id65', align: "left",   label: intl.formatMessage({id:"generic.domain.pos"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id7', align: "left",   label: intl.formatMessage({id:"generic.done"}), sublabel: null, formatter: null, width: 50 },
        { id: 'actions', align: "center", width: 120 },
    ];

    const convertCustomerManagementRowData = item => {
        const productId = item.product ? item.product.id : item.productId;
        const product = arrayHelper.getObjectById(products, productId);
        const type = item.situation ?
            item.situation.type :
            arrayHelper.getObjectById(productSituationService.getProductSituations(), item.productSituationId).type;
        return {
            id1: product ? product.name : "",
            id2: type,
            id3: item.containers,
            id4: item.charges,
            id5: item.customer,
            id6: item.zone,
            id65: <EnabledIcon button value={item.inPos} onClick={() => props.handleToggleCustomerManagement(item, 'inPos')} />,
            id7: <EnabledIcon button value={item.done} onClick={() => props.handleToggleCustomerManagement(item, 'done')} />,
            actions: !authorizationService.isLiquidated(authorization) ?
                <div>
                    <EditIcon button onClick={() => handleEditCustomerManagement(item)} />
                    <DeleteIcon button onClick={() => props.handleDeleteCustomerManagement(item)} />
                </div>
                 :
                <div></div>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <RightBox>
                    <Button disabled={!authorizationService.isAuthorization(authorization)}  color="primary" float size="small" onClick={handleOpenProduct}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12} style={{minHeight: '200px'}}>
                <Table
                    headers={headers}
                    data={data}
                />
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button disabled={!authorizationService.isAuthorization(authorization)}  color="primary" float size="small" onClick={handleOpenCage}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12} style={{minHeight: '100px'}}>
                <Table
                    headers={headersCages}
                    data={dataC}
                />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex">
                    <Box flexGrow={1}><h2>{intl.formatMessage({id:'generic.domain.subscribe.unsubscribe.plural'})}</h2></Box>
                    <Box style={{paddingTop: '20px'}}><Button disabled={authorizationService.isLiquidated(authorization)} color="primary" float size="small" onClick={handleOpenCustomerManagement}><AddIcon color="white"/></Button></Box>
                </Box>
            </Grid>
            <Grid item xs={12} style={{minHeight: '100px'}}>
                <Table
                    headers={headersCP}
                    data={dataCP}
                />
            </Grid>
            <Grid item xs={12}>
                <Modal open={openProduct} title={intl.formatMessage({id: 'generic.domain.product'})} onClose={handleClose} width={420} height={480}>
                    <ProductForm
                        isAuthorization={authorizationService.isAuthorization(authorization)}
                        product={product}
                        products={products}
                        errors={errors}
                        onChange={props.handleChangeProduct}
                        onSubmit={props.handleSubmitProduct}
                        onClose={handleClose}
                    />
                </Modal>
                <Modal open={openCage} title={intl.formatMessage({id: 'generic.domain.cage'})} onClose={handleClose} width={400} height={360}>
                    <CageForm
                        isAuthorization={authorizationService.isAuthorization(authorization)}
                        cage={cage}
                        cages={cages}
                        errors={errorsCage}
                        onChange={props.handleChangeCage}
                        onSubmit={props.handleSubmitCage}
                        onClose={handleClose}
                    />
                </Modal>
            </Grid>
            <Grid item xs={12}>
                <Modal open={openCustomerManagement} title={intl.formatMessage({id: 'generic.domain.product'})} onClose={handleClose} width={400} height={470}>
                    <CustomerProductForm
                        product={customerManagement}
                        products={products}
                        productSituationItems={productSituationService.getProductSituationItems()}
                        errors={errorsCustomerManagement}
                        onChange={props.handleChangeCustomerManagement}
                        onSubmit={props.handleSubmitCustomerManagement}
                        onClose={handleClose}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
};

export default ChargeTab;