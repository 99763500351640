import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {numberFormatter} from '../../../helpers/formatter';

import Grid from '@material-ui/core/Grid';

import ErrorIcon from "../../../components/icons/Error";
import Table from '../../../components/table';



export const ReportTableProduct = (props) => {
    const {report, isLoading} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(report.closureCages.map(cage => convertRowData(cage)));
    }, [report]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.cage"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.lastDay"}), formatter: null },
        { id: 'id4', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.purchase.plural"}), formatter: null },
        //{ id: 'id6', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.sell.plural"}), formatter: null },
        //{ id: 'id8', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.subscribe.plural"}), formatter: null },
        //{ id: 'id10', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.unsubscribe.plural"}), formatter: null },
        { id: 'id11', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.movements"}), formatter: null },
        { id: 'id12', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.movements.pos.ab"}), formatter: null },
        { id: 'id13', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: "Stock " + intl.formatMessage({id:"generic.domain.warehouse"}), formatter: null },
        { id: 'id14', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.onVehicle"}), formatter: null },
        //{ id: 'id16', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.domain.pos.plural"}), formatter: null },
        { id: 'id18', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.units"}), label: intl.formatMessage({id:"generic.total"}), formatter: numberFormatter },
        { id: 'error', align: "left", label: intl.formatMessage({id:"generic.error"}), sublabel: "Stock", formatter: value => { return value === true ? <ErrorIcon/> : ""} },
    ];

    const convertRowData = item => {

        const total = item.unitsStock + item.unitsOnVehicle + item.unitsInPos;

        return {
            id1: item.cage.name,
            id2: item.unitsLastStock,
            id4: item.unitsPurchased,
            //id6: "-",
            //id8: item.unitsSubscribed,
            //id10: item.unitsUnsubscribed,
            id11: item.unitsMoved,
            id12: item.unitsPosMoved,
            id13: item.unitsStock,
            id14: item.unitsOnVehicle,
            id15: item.chargesOnVehicle,
            //id16: item.unitsInPos,
            id17: "-",
            id18: total,
            error: item.stockError
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{minHeight: '200px'}}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    stripedRows
                    isExportable
                />
            </Grid>
        </Grid>
    )
};

export default ReportTableProduct;
