import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {isNull} from "../../../helpers/validator";

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';



const Input = (props) => {
    const {value, fullWidth = false, width, helperText, error, ...other} = props;

    const intl = useIntl();

    const [hasError, setHasError] = useState(false);
    const [style, setStyle] = useState({});
    const [text, setText] = useState("");

    const inputLabelProps = props.type === "date" ? {shrink: true} : {};

    useEffect(() => {
        if (!isNull(error)) {
            setHasError(true);
            if (typeof error === "string")
                setText(intl.formatMessage({id: error}));
            else
                setText(intl.formatMessage({id: error.id}, error));
        } else {
            setHasError(false);
            setText(helperText);
        }
    }, [error]);
    useEffect(() => {
        const stl = {...style};
        if (typeof width !== 'undefined') {
            stl.width = width;
        } else {
            delete stl['width'];
        }
        setStyle(stl);
    }, [width]);


    return (
        <FormControl fullWidth={fullWidth}>
            <TextField
                variant="outlined"
                margin="dense"
                InputLabelProps={inputLabelProps}
                value={value !== null ? value : ""}
                style={style}
                error={hasError}
                helperText={text}
                {...other}
            />
        </FormControl>
    )
};

Input.propTypes = {
    error: PropTypes.string, /** error code */
    fullwidth: PropTypes.bool,
    helperText: PropTypes.string,
    width: PropTypes.number
};

export default Input;
