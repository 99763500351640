import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import Table from '../../../../components/table';
import ZoneChip from './ZoneChip';

import * as employeeService from '../../../../services/domain/warehouse/employeeService';


const EmployeesRoutesTable = (props) => {
    const {loading, employees} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(employees.map(item => convertRowData(item)));
    }, [employees]);

    const headers = [
        { id: 'id1', align: "left", label: intl.formatMessage({id: 'app.employees.employee'}), width: 300 },
        { id: 'id2', align: "left", label: intl.formatMessage({id: 'generic.type'}), width: 100 },
        { id: 'id3', align: "left", label: intl.formatMessage({id: 'app.routes.route'}), sortable: false },
    ];

    const convertRowData = item => {
        const Icon = employeeService.isDriver(item) ? LocalShippingIcon : ShoppingCartIcon;
        return {
            id1: item.name + " " + item.surname,
            id2: <Icon/>,
            id3: item.zones.map((zone, index) => <ZoneChip key={index} label={zone.name} onDelete={() => props.onDeleteZone(item, zone)}/>)
        }
    };

    return (
        <div >
            <Table
                headers={headers}
                data={data}
                isLoading={loading}
                totalRows
                ordered
                isExportable
            />
        </div>
    )
};

export default EmployeesRoutesTable;
