import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox/index';

import * as cageService from '../../../../services/domain/warehouse/cageService';
import * as productService from '../../../../services/domain/warehouse/productService';
import * as validateService from "../../../../services/validateService";
import * as warehouseService from '../../../../services/domain/warehouse/warehouseService';
import * as warehouseStockCountService from '../../../../services/domain/warehouse/warehouseStockCountService';
import * as formatter from '../../../../helpers/formatter';
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import {RightBox} from "../../../../components/Box";

import WarehouseStockCountForm from "./WarehouseStockCountForm";
import WarehouseStockCountTableProduct from "./WarehouseStockCountTableProduct";
import WarehouseStockCountTableCage from "./WarehouseStockCountTableCage";




export const WarehouseStockCount = (props) => {
    const intl = useIntl();

    const newStockCount = {
        warehouseId: null,
        date: formatter.dateApiFormatter(),
        products: [],
        cages: []
    }
    const stockCountValidations = {
        warehouseId: [{type: validateService.REQUIRED}]
    }
    const productValidations = {
        containers: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        charges: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}]
    }
    const cageValidations = {
        units: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}]
    }

    const [cages, setCages] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [stockCount, setStockCount] = useState(newStockCount);
    const [warehouses, setWarehouses] = useState([]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.report'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.report.stockCount'})}
    ];

    useEffect(() => {
        async function load() {
            const responseC = await cageService.getCages();
            const responseP = await productService.getProducts();
            const responseW = await warehouseService.listWarehouses();
            if (responseC.status === 200 && responseP.status === 200 && responseW.status === 200) {
                setCages(responseC.data.cages);
                setProducts(responseP.data.products);
                setWarehouses(responseW.data.warehouses);
                stockCount.products = responseP.data.products.map(product => {
                    return {productId: product.id, containers: 0, charges: 0, product: product, errors: {}}
                });
                stockCount.cages = responseC.data.cages.map(cage => {
                    return {cageId: cage.id, units: 0, cage: cage}
                });
                setRefresh(Date.now());
            } else {
                console.error(responseW);
            }
        }
        load();
    }, []);

    const clearStockCount = () => {
        setStockCount({
            ...newStockCount,
            warehouseId: stockCount.warehouseId,
            date: stockCount.date,
            products: products.map(product => {
                return {productId: product.id, containers: 0, charges: 0, product: product, errors: {}}
            }),
            cages: cages.map(cage => {
                return {cageId: cage.id, units: 0, cage: cage}
            })
        });
    }
    const loadStockCount = async () => {
        setIsLoading(true);
        const response = warehouseStockCountService.get(stockCount.warehouseId, stockCount.date);
        response
            .then((res) => {
                setStockCount({
                    ...res.data,
                    warehouseId: res.data.warehouse.id
                });
                setIsLoading(false);
            })
            .catch(err => {
                if (err.response.status !== 404) {
                    props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                    console.error(err.response);
                } else {
                    props.warningMessage(intl.formatMessage({id: "app.error.code.NOT_FOUND"}))
                }
                clearStockCount();
                setIsLoading(false);
            });
    }
    const saveStockCount = async () => {
        setIsLoading(true);
        const response = warehouseStockCountService.create(stockCount);
        response
            .then((res) => {
                setIsLoading(false);
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {
                setIsLoading(false);
                props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                console.error(err.response);
            });
    }

    const handleClear = () => {
        clearStockCount();
    }
    const handleChange = (e) => {
        setStockCount({
            ...stockCount,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSearch = () => {
        const err = validateService.validate(stockCount, stockCountValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            loadStockCount();
        }
    };
    const handleSubmit = () => {
        const err = validateService.validate(stockCount, stockCountValidations);
        setErrors(err);
        if (!validateService.hasErrors(err) && isReadyForSave()) {
            saveStockCount();
        }
    };

    const isReadyForSave = () => {
        let errP;
        let errC;
        for (let i = 0; i < stockCount.products.length; i++) {
            errP = validateService.validate(stockCount.products[i], productValidations);
            if (validateService.hasErrors(errP)) {
                const product = stockCount.products[i].product.name;
                props.errorMessage(intl.formatMessage({id: 'app.error.domain.INCORRECT_VALUE_OF_STOCK_COUNT_PRODUCT'}, {product: product}));
                return false;
            }
        }
        for (let i = 0; i < stockCount.cages.length; i++) {
            errC = validateService.validate(stockCount.cages[i], cageValidations);
            if (validateService.hasErrors(errC)) {
                const cage = stockCount.cages[i].cage.name;
                props.errorMessage(intl.formatMessage({id: 'app.error.domain.INCORRECT_VALUE_OF_STOCK_COUNT_CAGE'}, {cage: cage}));
                return false;
            }
        }
        return true;
    }

    const handleChangeProducts = (e, item) => {
        const value = getValueFromField(e);
        for (let i = 0; i < stockCount.products.length; i++) {
            if (stockCount.products[i].productId === item.productId) {
                stockCount.products[i][e.target.name] = value;
                setRefresh(Date.now());
            }
        }
    }

    const handleChangeCages = (e, item) => {
        const value = getValueFromField(e);
        for (let i = 0; i < stockCount.cages.length; i++) {
            if (stockCount.cages[i].cageId === item.cageId) {
                stockCount.cages[i][e.target.name] = value;
                setRefresh(Date.now());
            }
        }
    }

    const handleDownload = () => {
        const err = validateService.validate(stockCount, stockCountValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            const params = {...stockCount};
            delete params.products;
            delete params.cages;
            warehouseStockCountService.download(params);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <WarehouseStockCountForm
                    stockCount={stockCount}
                    warehouses={warehouses}
                    errors={errors}
                    onChange={handleChange}
                    onSubmit={handleSearch}
                    onDownload={handleDownload}
                />
                <br/><br/>
            </Grid>
            <Grid item xs={6}>
                <WarehouseStockCountTableProduct
                    stockCount={stockCount}
                    refresh={refresh}
                    isLoading={isLoading}
                    onChange={handleChangeProducts}
                />
                <br/>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
                <WarehouseStockCountTableCage
                    stockCount={stockCount}
                    refresh={refresh}
                    isLoading={isLoading}
                    onChange={handleChangeCages}
                />
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button type="button" color="secondary" variant="contained" style={{marginLeft: '10px', marginTop: '8px'}} onClick={handleClear}>
                        {intl.formatMessage({id: "generic.clear"})}
                    </Button>
                    <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px', marginTop: '8px'}} onClick={handleSubmit}>
                        {intl.formatMessage({id: "generic.save"})}
                    </Button>
                </RightBox>
            </Grid>
        </Grid>
    )

}

export default withMessageBox(WarehouseStockCount);
