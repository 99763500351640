import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const getAgencyStock = async (warehouseId) => {
    return await apiService.get(`${URL}/warehouses/agency-stock`, {warehouseId: warehouseId});
};

export const getGroupStock = async (warehouseId) => {
    return await apiService.get(`${URL}/warehouses/group-stock`, {warehouseId: warehouseId});
};


export const getStock = () => {
    return mockWs;
}

const mockWs = [
    {
        agency:  {id: "08000", name: "Agency 08000"},
        warehouse: {id: 1, name: "w1"},
        products: [
            {
                product: {id: 1, name: "UD125"},
                stock: {containers: 10, charges: 11}
            },
            {
                product: {id: 2, name: "UD120L"},
                stock: {containers: 20, charges: 21}
            },
            {
                product: {id: 3, name: "UD110"},
                stock: {containers: 30, charges: 31}
            }
        ],
        cages: [
            {
                cage: {id: 1, name: "J1"},
                stock: 15
            },
            {
                cage: {id: 2, name: "J2"},
                stock: 5
            }
        ]
    },
    {
        agency:  {id: "08000", name: "Agency 08000"},
        warehouse: {id: 2, name: "w2"},
        products: [
            {
                product: {id: 1, name: "UD125"},
                stock: {containers: 100, charges: 110}
            },
            {
                product: {id: 2, name: "UD120L"},
                stock: {containers: 200, charges: 210}
            },
            {
                product: {id: 3, name: "UD110"},
                stock: {containers: 300, charges: 310}
            }
        ],
        cages: [
            {
                cage: {id: 1, name: "J1"},
                stock: 15
            },
            {
                cage: {id: 2, name: "J2"},
                stock: 5
            }
        ]
    },
    {
        agency:  {id: "08000", name: "Agency 08000"},
        warehouse: {id: 3, name: "w3"},
        products: [
            {
                product: {id: 1, name: "UD125"},
                stock: {containers: 1000, charges: 1100}
            },
            {
                product: {id: 2, name: "UD120L"},
                stock: {containers: 2000, charges: 2100}
            },
            {
                product: {id: 3, name: "UD110"},
                stock: {containers: 3000, charges: 3100}
            }
        ],
        cages: [
            {
                cage: {id: 1, name: "J1"},
                stock: 15
            },
            {
                cage: {id: 2, name: "J2"},
                stock: 5
            }
        ]
    }
];

const mockAgs = [
    {
        agency:  {id: "08000", name: "Agency 08000"},
        warehouse: {id: 1, name: "w1"},
        products: [
            {
                product: {id: 1, name: "UD125"},
                stock: {containers: 10, charges: 10}
            },
            {
                product: {id: 2, name: "UD120L"},
                stock: {containers: 20, charges: 20}
            },
            {
                product: {id: 3, name: "UD110"},
                stock: {containers: 30, charges: 30}
            }
        ],
        cages: [
            {
                cage: {id: 1, name: "J1"},
                stock: 15
            },
            {
                cage: {id: 2, name: "J2"},
                stock: 5
            }
        ]
    },
    {
        agency:  {id: "08111", name: "Agency 08111"},
        warehouse: {id: 2, name: "w2"},
        products: [
            {
                product: {id: 1, name: "UD125"},
                stock: {containers: 10, charges: 10}
            },
            {
                product: {id: 2, name: "UD120L"},
                stock: {containers: 20, charges: 20}
            },
            {
                product: {id: 3, name: "UD110"},
                stock: {containers: 30, charges: 30}
            }
        ],
        cages: [
            {
                cage: {id: 1, name: "J1"},
                stock: 15
            },
            {
                cage: {id: 2, name: "J2"},
                stock: 5
            }
        ]
    }
];
