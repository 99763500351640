import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { EnhancedTableHeader } from './EnhancedTableHeader';
const rowHoverColor = 'red';
//import { rowHoverColor } from '../../features/core/styles/theme';
//import { expandableTableRowsStyles } from './styles.css';

const styles = (theme) => ({
    expansionPanelRoot: {
        backgroundColor: 'transparent',
        padding: 0,
        width: '100%'
    },
    expansionPanelHover: {
        backgroundColor: rowHoverColor,
    },
    expansionPanelSummary: {
        padding: 0,
    },
    expansionTableSummary: {
        height: '40px'
    },
    tableCell: {
        border: 'none'
    },
    tableOneCell: {
        padding: '0px !important'
    }
});

export class ExpandableTableRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
        }
    }

    renderTableSummary() {
        const { headers, rowData, index } = this.props;
        const { classes, customOrderBy = "", order = "asc" } = this.props;

        return (
            <Table className={classes.expansionTableSummary}>
                <EnhancedTableHeader
                    classes={classes}
                    numSelected={0}
                    onRequestSort={()=>{}}
                    onSelectAllClick={()=>{}}
                    order={order}
                    orderBy={customOrderBy}
                    rowCount={0}
                    headers={headers}
                    withLabels={false}
                />
                <TableBody>
                    <TableRow
                        key={index}
                    >
                        {
                            headers.map((column, cellId) => {
                                return (
                                    <TableCell
                                        component="td"
                                        scope="row"
                                        align={column.align}
                                        className={classes.tableCell}
                                        key={cellId}
                                    >
                                        {rowData[column.id]}
                                    </TableCell>
                                );
                            })
                        }
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
    
    renderTableDetails() {
        const { fieldExpandable, headersExpandable, rowData, keyRow } = this.props;
        const { classes, customOrderBy = "", order = "asc", hover = false, selected = false } = this.props;

        return (
            <Table className="expansionTableDetails">
                <EnhancedTableHeader
                    classes={classes}
                    numSelected={0}
                    onRequestSort={()=>{}}
                    onSelectAllClick={()=>{}}
                    order={order}
                    orderBy={customOrderBy}
                    rowCount={0}
                    headers={headersExpandable}
                    withLabels={false}
                />
                <TableBody>
                    {
                        rowData[fieldExpandable].map((row, rowId) => {
                            return (
                                <TableRow
                                    hover={hover}
                                    selected={selected}
                                    key={keyRow.toString() + rowId.toString()}
                                >
                                    {
                                        headersExpandable.map((column, colId) => {
                                            return (
                                                <TableCell
                                                    className={classes.tableCell}
                                                    key={keyRow.toString() + rowId.toString() + colId.toString()}
                                                >
                                                    <div dangerouslySetInnerHTML={{__html: row[column.id]}}></div>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        )    
    }

    onMouseOverHandler() {
        this.setState({hover: this.props.hover});
    }

    onMouseOutHandler() {
        this.setState({hover: false});
    }

    render() {
        const { classes, headers } = this.props;
        const { selected = false, keyRow } = this.props;
        const { hover } = this.state;
        const hoverClass = hover ? classes.expansionPanelHover : "";

        return (
            <TableRow
                selected={selected}
                key={keyRow}
            >
                <TableCell
                    colSpan={headers.length}
                    key={keyRow}
                    className={classes.tableOneCell}
                >
                    <ExpansionPanel className={classes.expansionPanelRoot} elevation={0}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={classes.expansionPanelSummary + " " + hoverClass}
                            onMouseOver={()=>this.onMouseOverHandler()}
                            onMouseOut={()=>this.onMouseOutHandler()}
                        >
                            {this.renderTableSummary()}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.renderTableDetails()}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </TableCell>
            </TableRow>
        );
    }
}

ExpandableTableRow.propTypes = {
    headers: PropTypes.array.isRequired,
    rowData: PropTypes.object.isRequired,
    headersExpandable: PropTypes.array.isRequired,
    fieldExpandable: PropTypes.string.isRequired,
    keyRow: PropTypes.number.isRequired
};

export default withStyles(styles)(ExpandableTableRow);
