import * as apiService from '../../apiService';
import {isNull} from "../../../helpers/validator";


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const POS_TYPE_SUBAGENTE = "Subagente";
export const POS_TYPE_CODE_SUBAGENTE = "SUBAGENTE";
export const POS_TYPE_POS = "Punto de Venta";


export const getPointOfSale = async () => {
    return await apiService.get(`${URL}/points-of-sale`);
};

export const addPointOfSale = async (data) => {
    return await apiService.post(`${URL}/points-of-sale`, data);
};

export const updatePointOfSale = async (id, data) => {
    return await apiService.put(`${URL}/points-of-sale/${id}`, data);
};

//TODO: hacer la request
export const getPointOfSaleTypeItems = () => {
    return [
        {value: 1, label: POS_TYPE_SUBAGENTE},
        {value: 2, label: POS_TYPE_POS},
    ]
};

export const updateCommissions = async (id, data) => {
    return await apiService.put(`${URL}/points-of-sale/${id}/product-commissions`, data);
}

export const getNameWithAlias = (pointOfSale) => {
    if (isNull(pointOfSale)) return "";

    const alias = !isNull(pointOfSale.alias) && pointOfSale.alias !== "" ? " (" + pointOfSale.alias + ")" : "";
    return pointOfSale.name + alias;
}
