import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from "../../../../hocs/withMessageBox";

import * as saleChannelService from '../../../../services/domain/warehouse/saleChannelService';
import * as validateService from "../../../../services/validateService";
import { getValueFromField } from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from '../../../../components/icons/Enabled';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Table from '../../../../components/table';
import Modal from '../../../../components/Modal/Modal';
import SaleChannelForm from './SaleChannelForm';


export const SaleChannelsList = (props) => {

    const intl = useIntl();

    const newSaleChannel = {
        id: null,
        code: null,
        channel: null,
        applyGenericSupplements: 0,
        enabled: 1
    };
    const validations = {
        channel: [{type: validateService.REQUIRED}],
    };

    const [open, setOpen] = useState(false);
    const [saleChannel, setSaleChannel] = useState(newSaleChannel);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        async function load() {
            setLoading(true);
            const response = await saleChannelService.getSaleChannels();
            if (response.status === 200) {
                setData(response.data.saleChannels.map(item => convertRowData(item)));
            } else {}
            setLoading(false);
        }

        load();
    }, [reload]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.saleChannels'})}
    ];

    const handleClose = () => {
        setOpen(false);
    };

    const handleNew = () => {
        setSaleChannel(newSaleChannel);
        setOpen(true);
    };
    const handleEdit = (item) => {
        setSaleChannel(item);
        setOpen(true);
    };
    const handleChange = (e) => {
        e.preventDefault();
        setSaleChannel({
            ...saleChannel,
            [e.target.name]: getValueFromField(e)
        })
    };
    const handleSubmit = () => {
        const err = validateService.validate(saleChannel, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            let response;
            if (saleChannel.id === null) {
                //TODO: insert
            } else {
                response = saleChannelService.updateSaleChannel(saleChannel.id, saleChannel);
            }
            response
                .then(() => {
                    setReload(reload + 1);
                    setOpen(false);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                });
        }
    };


    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.saleChannel"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.price.supplementsApply"}), sublabel: null, formatter: (val) => <EnabledIcon value={val}/>, width: 80 },
        { id: 'id3', align: "center",   label: intl.formatMessage({id:"generic.enabled"}), sublabel: null, formatter: (val) => <EnabledIcon value={val}/>, width: 80 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertRowData = item => {
        return {
            id1: item.channel,
            id2: item.applyGenericSupplements,
            id3: item.enabled,
            actions: <EditIcon button onClick={() => handleEdit(item)}/>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal open={open} title={intl.formatMessage({id: "generic.domain.saleChannel"})} onClose={handleClose}>
                    <SaleChannelForm
                        saleChannel={saleChannel}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
};


export default withMessageBox(SaleChannelsList);