import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Checkbox from "../../../../components/form/Checkbox";
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import Switch from '../../../../components/form/Switch';
import { RightBox } from '../../../../components/Box';



export const CustomerProductForm = (props) => {
    const {product, products, productSituationItems, errors} = props;

    const intl = useIntl();

    const [productItems, setProductItems] = useState([]);

    useEffect(() => {
        setProductItems(products.map((product) => {
            return {value: product.id, label: product.name};
        }))
    }, [products]);


    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        name="productId"
                        label={intl.formatMessage({id: 'generic.domain.product'})}
                        items={productItems}
                        value={product.productId}
                        error={errors.productId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        name="productSituationId"
                        label={intl.formatMessage({id: 'generic.type'})}
                        items={productSituationItems}
                        value={product.productSituationId}
                        error={errors.productSituationId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="containers"
                        label={intl.formatMessage({id: 'generic.domain.containers'})}
                        value={product.containers}
                        error={errors.containers}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="charges"
                        label={intl.formatMessage({id: 'generic.domain.charges'})}
                        value={product.charges}
                        error={errors.charges}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="number"
                        label={intl.formatMessage({id: 'generic.domain.number'})}
                        value={product.number}
                        error={errors.number}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="customer"
                        label={intl.formatMessage({id: 'generic.domain.customer'})}
                        value={product.customer}
                        error={errors.customer}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        name="zone"
                        label={intl.formatMessage({id: 'generic.domain.zone'})}
                        value={product.zone}
                        error={errors.zone}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Checkbox
                        name="inPos"
                        label={intl.formatMessage({id: 'generic.domain.pos'})}
                        value={product.inPos}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Switch
                        name="done"
                        label={intl.formatMessage({id: 'generic.done'})}
                        checked={product.done === 1 || product.done === true}
                        onChange={props.onChange}
                    /></Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    );
};

export default CustomerProductForm;
