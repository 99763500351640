import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';


const EnabledIcon = (props) => {
    const {button, value, hideOnFalse = false,  ...other} = props;

    const Icon = value === true || value === 1  ? CheckIcon : ClearIcon;

    if (hideOnFalse) return <></>;

    if (button) {
        return (<IconButton {...other}><Icon/></IconButton>);
    } else {
        return <Icon />;
    }
};

export default EnabledIcon;
