import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from "@material-ui/core/Grid";

import Input from "../../../../components/form/Input/Input";
import Table from "../../../../components/table";



export const WarehouseStockCountTableCage = (props) => {
    const {stockCount, refresh, isLoading} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(stockCount.cages.map(cage => convertRowData(cage)));
    }, [stockCount, refresh]);


    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.cage"}), formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.units"}), formatter: null, width: 100 },
    ];

    const convertRowData = item => {
        return {
            id1: item.cage.name,
            id2: <Input name="units" value={item.units} onChange={(e) => props.onChange(e, item)} />,
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{minHeight: '200px'}}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default WarehouseStockCountTableCage;