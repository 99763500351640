import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import Switch from '../../../../components/form/Switch';
import {RightBox} from "../../../../components/Box";



export const ProductSupplementPriceForm = (props) => {
    const {saleChannels, supplementTypes, supplement, zones, errors} = props;

    const intl = useIntl();

    const [saleChannelItems, setSaleChannelItems] = useState([]);
    const [zoneItems, setZoneItems] = useState([]);

    useEffect(() => {
        setSaleChannelItems(
            saleChannels
                .filter(channel => {return channel.applyGenericSupplements === 1})
                .map(channel => {
                    return {value: channel.id, label: channel.channel};
            })
        );
    }, [saleChannels]);
    useEffect(() => {
        setZoneItems(
            zones.map(zone => {
                return {value: zone.id, label: zone.name};
            })
        );
    }, [zones]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={4}>
                    <Select
                        name="supplementTypeId"
                        label={intl.formatMessage({id: 'generic.type'})}
                        items={supplementTypes}
                        value={supplement.supplementTypeId}
                        error={errors.supplementTypeId}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <Select
                        name="channelId"
                        label={intl.formatMessage({id: 'generic.domain.saleChannel'})}
                        items={saleChannelItems}
                        value={supplement.channelId}
                        error={errors.channelId}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <Select
                        name="zoneId"
                        label={intl.formatMessage({id: 'app.routes.zone'})}
                        items={zoneItems}
                        value={supplement.zoneId}
                        error={errors.zoneId}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        name="from"
                        label={intl.formatMessage({id: 'generic.from'}) + " (hh:mm)"}
                        value={supplement.from}
                        error={errors.from}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        name="to"
                        label={intl.formatMessage({id: 'generic.to'}) + " (hh:mm)"}
                        value={supplement.to}
                        error={errors.to}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Switch
                        name="weekend"
                        label={intl.formatMessage({id: 'generic.weekend'})}
                        checked={supplement.weekend === 1}
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Input
                        name="supplementPrice"
                        label={intl.formatMessage({id: 'generic.domain.price.withoutVat'})}
                        value={supplement.supplementPrice}
                        error={errors.supplementPrice}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            style={{marginLeft: '10px'}}
                            onClick={() => props.onClick()}
                        >
                            {intl.formatMessage({id: "generic.save"})}
                        </Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
};

export default ProductSupplementPriceForm;
