import AgencyIcon from '../../icons/Agency';
import DashboardIcon from '../../icons/Dashboard';
import OrderIcon from '../../icons/Order';
import StreetIcon from '../../icons/Street';
import WarehouseIcon from '../../icons/Warehouse';
import {
    ROLE_AGENCIA,
    ROLE_ALMACEN,
    ROLE_AYUDANTE,
    ROLE_CALLEJERO,
    ROLE_GERENTE,
    ROLE_GESTOR,
    ROLE_REPARTIDOR,
    ROLE_USUARIO,
    ROLE_ANY
} from '../../../services/authService';


const menu = {
    MainMenu: [
        {
            id: "main.dashboard",
            path: "/dashboard",
            icon: DashboardIcon,
            label: "app.menu.dashboard",
            roles: [],
            children: []
        },
        {
            id: "main.agency",
            path: "",
            icon: AgencyIcon,
            label: "app.menu.agency",
            roles: [ROLE_AGENCIA, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
            children: [
                {
                    id: "main.agency.contracts",
                    path: "",
                    icon: null,
                    label: "app.menu.agency.contracts",
                    roles: [ROLE_AGENCIA, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                    children: [
                        {
                            id: "main.agency.contracts.contracts",
                            path: "",
                            icon: null,
                            label: "app.menu.agency.contracts",
                            roles: [ROLE_AGENCIA, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                    ]
                },
                {
                    id: "main.agency.revisions",
                    path: "",
                    icon: null,
                    label: "app.menu.agency.revisions",
                    roles: [ROLE_AGENCIA, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                    children: [
                        {
                            id: "main.agency.revisions.revisions",
                            path: "",
                            icon: null,
                            label: "app.menu.agency.revisions",
                            roles: [ROLE_AGENCIA, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                    ]
                },
            ]
        },
        {
            id: "main.warehouse",
            path: "",
            icon: WarehouseIcon,
            label: "app.menu.warehouse",
            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
            children: [
                {
                    id: "main.warehouse.management",
                    path: "",
                    icon: null,
                    label: "app.menu.warehouse.management",
                    roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                    children: [
                        {
                            id: "main.warehouse.management.warehouses",
                            path: "/warehouses",
                            icon: null,
                            label: "app.menu.warehouse.management.warehouses",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.companies",
                            path: "/companies",
                            icon: null,
                            label: "app.menu.warehouse.management.companies",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.vehicles",
                            path: "/vehicles",
                            icon: null,
                            label: "app.menu.warehouse.management.vehicles",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.employees",
                            path: "/employees",
                            icon: null,
                            label: "app.menu.warehouse.management.employees",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.saleChannels",
                            path: "/sale-channels",
                            icon: null,
                            label: "app.menu.warehouse.management.saleChannels",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.products",
                            path: "/products",
                            icon: null,
                            label: "app.menu.warehouse.management.products",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.pos",
                            path: "/pos",
                            icon: null,
                            label: "app.menu.warehouse.management.pos",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.routes",
                            path: "/routes",
                            icon: null,
                            label: "app.menu.warehouse.management.routes",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.pls",
                            path: "/product-liquidation-supplements",
                            icon: null,
                            label: "app.menu.warehouse.management.pls",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.management.accountingAccounts",
                            path: "/accounting-accounts",
                            icon: null,
                            label: "app.menu.warehouse.management.accountingAccounts",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        }
                    ]    
                },
                {
                    id: "main.warehouse.movements",
                    path: "",
                    icon: null,
                    label: "app.menu.warehouse.movements",
                    roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                    children: [
                        {
                            id: "main.warehouse.movements.auth",
                            path: "/authorizations",
                            icon: null,
                            label: "app.menu.warehouse.movements.aut",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.movements.deliveryConfirmation",
                            path: "/delivery-confirmations",
                            icon: null,
                            label: "app.menu.warehouse.movements.deliveryConfirmation",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.movements.deliveryNotesPos",
                            path: "/delivery-notes",
                            icon: null,
                            label: "app.menu.warehouse.movements.deliveryNotesPos",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.movements.prePayments",
                            path: "/authorizations/pre-payments",
                            icon: null,
                            label: "app.menu.warehouse.movements.prePayments",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.movements.po",
                            path: "/purchase-orders",
                            icon: null,
                            label: "app.menu.warehouse.movements.po",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.movements.warehouse",
                            path: "/warehouse-movements",
                            icon: null,
                            label: "app.menu.warehouse.movements.warehouse",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.movements.stock",
                            path: "/stock",
                            icon: null,
                            label: "app.menu.warehouse.movements.stock",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        }
                    ]
                },
                {
                    id: "main.warehouse.billing",
                    path: "",
                    icon: null,
                    label: "app.menu.warehouse.billing",
                    roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                    children: [
                        {
                            id: "main.warehouse.billing.deliveryNotes",
                            path: "/delivery-notes/invoices",
                            icon: null,
                            label: "app.menu.warehouse.billing.deliveryNotes",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.billing.po",
                            path: "/purchase-orders/invoices",
                            icon: null,
                            label: "app.menu.warehouse.billing.po",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        }
                    ]
                },
                {
                    id: "main.warehouse.accounting",
                    path: "",
                    icon: null,
                    label: "app.menu.warehouse.accounting",
                    roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                    children: [
                        {
                            id: "main.warehouse.accounting.movements",
                            path: "/accounting-movements",
                            icon: null,
                            label: "app.menu.warehouse.accounting.movements",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        },
                        {
                            id: "main.warehouse.accounting.payments",
                            path: "/payments/new",
                            icon: null,
                            label: "app.menu.warehouse.accounting.payments",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR, ROLE_USUARIO],
                            children: []
                        }
                    ]
                },
                {
                    id: "main.warehouse.report",
                    path: "",
                    icon: null,
                    label: "app.menu.warehouse.report",
                    roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                    children: [
                        {
                            id: "main.warehouse.report.stockCount",
                            path: "/reports/warehouse-stock-count",
                            icon: null,
                            label: "app.menu.warehouse.report.stockCount",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.report.closure",
                            path: "/reports/closure",
                            icon: null,
                            label: "app.menu.warehouse.report.closure",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        }
                    ]
                },
                {
                    id: "main.warehouse.stats",
                    path: "",
                    icon: null,
                    label: "app.menu.warehouse.stats",
                    roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                    children: [
                        {
                            id: "main.warehouse.stats.sales",
                            path: "/stats/sales",
                            icon: null,
                            label: "app.menu.warehouse.stats.sales",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.stats.purchases",
                            path: "/stats/purchases",
                            icon: null,
                            label: "app.menu.warehouse.stats.purchases",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                        {
                            id: "main.warehouse.stats.unsubscribes",
                            path: "/stats/unsubscribes",
                            icon: null,
                            label: "app.menu.warehouse.stats.unsubscribes",
                            roles: [ROLE_ALMACEN, ROLE_GERENTE, ROLE_GESTOR],
                            children: []
                        },
                    ]
                }
            ]
        },
        {
            id: "main.orders",
            path: "/orders",
            icon: OrderIcon,
            label: "app.menu.orders",
            roles: [ROLE_ANY],
            children: []
        },
        {
            id: "main.streetMap",
            path: "",
            icon: StreetIcon,
            label: "app.menu.streetMap",
            roles: [ROLE_ANY],
            children: [
                {
                    id: "main.streetMap.management",
                    path: "",
                    icon: null,
                    label: "app.menu.streetMap.management",
                    roles: [ROLE_ANY],
                    children: [
                        {
                            id: "main.streetMap.management.streets",
                            path: "/street-map/streets",
                            icon: null,
                            label: "app.menu.streetMap.management.streets",
                            roles: [ROLE_ANY],
                            children: []
                        },
                        {
                            id: "main.streetMap.management.itineraries",
                            path: "/street-map/itineraries",
                            icon: null,
                            label: "app.menu.streetMap.management.itineraries",
                            roles: [ROLE_CALLEJERO, ROLE_GERENTE],
                            children: []
                        },
                        {
                            id: "main.streetMap.management.routes",
                            path: "/street-map/routes",
                            icon: null,
                            label: "app.menu.streetMap.management.routes",
                            roles: [ROLE_CALLEJERO, ROLE_GERENTE],
                            children: []
                        },
                    ]
                },
                {
                    id: "main.streetMap.queries",
                    path: "",
                    icon: null,
                    label: "app.menu.streetMap.queries",
                    roles: [ROLE_ANY],
                    children: [
                        {
                            id: "main.streetMap.queries.streetsByItinerary",
                            path: "/street-map/streets-by-itinerary",
                            icon: null,
                            label: "app.menu.streetMap.queries.streetsByItinerary",
                            roles: [ROLE_ANY],
                            children: []
                        },
                    ]
                }
            ]
        },
    ]
}

export default menu;
