import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {numberFormatter} from '../../../helpers/formatter';

import Grid from '@material-ui/core/Grid';

import ErrorIcon from "../../../components/icons/Error";
import Table from '../../../components/table';



export const ReportTableProduct = (props) => {
    const {report, isLoading} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(report.closureProducts.map(product => convertRowData(product)));
    }, [report]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.lastDay"}), formatter: null },
        { id: 'id3', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.lastDay"}), formatter: null },
        { id: 'id4', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.purchase.plural"}), formatter: null },
        { id: 'id5', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.purchase.plural"}), formatter: null },
        { id: 'id6', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.sell.plural"}), formatter: null },
        { id: 'id7', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.sell.plural"}), formatter: null },
        { id: 'id8', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.subscribe.plural"}), formatter: null },
        //{ id: 'id9', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.subscribe.plural"}), formatter: null },
        { id: 'id10', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.unsubscribe.plural"}), formatter: null },
        { id: 'id11', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.unsubscribe.plural"}), formatter: null },
        { id: 'id111', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.movements.ab"}), formatter: null },
        { id: 'id112', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.movements.ab"}), formatter: null },
        { id: 'id113', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.movements.pos.ab"}), formatter: null },
        { id: 'id12', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: "Stock " + intl.formatMessage({id:"generic.domain.warehouse"}), formatter: null },
        { id: 'id13', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: "Stock " + intl.formatMessage({id:"generic.domain.warehouse"}), formatter: null },
        { id: 'id14', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.onVehicle"}), formatter: null },
        { id: 'id15', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.onVehicle"}), formatter: null },
        //{ id: 'id16', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.domain.pos.plural"}), formatter: null },
        //{ id: 'id17', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.domain.pos.plural"}), formatter: null },
        { id: 'id18', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.containers"}), label: intl.formatMessage({id:"generic.total"}), formatter: numberFormatter },
        { id: 'id19', align: "left",   sublabel: intl.formatMessage({id:"generic.domain.charges"}), label: intl.formatMessage({id:"generic.total"}), formatter: numberFormatter },
        { id: 'error', align: "left", label: intl.formatMessage({id:"generic.error"}), sublabel: "Stock", formatter: value => { return value === true ? <ErrorIcon/> : ""} },
    ];

    const convertRowData = item => {
        const totalContainers = item.containersStock + item.containersOnVehicle + item.containersInPos;
        const totalCharges = item.chargesStock + item.chargesOnVehicle;

        return {
            id1: item.product.name,
            id2: item.containersLastStock,
            id3: item.chargesLastStock,
            id4: item.containersPurchased,
            id5: item.chargesPurchased,
            id6: item.containersSold,
            id7: item.chargesSold,
            id8: item.containersSubscribed,
            //id9: item.chargesSubscribed,
            id10: item.containersUnsubscribed,
            id11: item.chargesUnsubscribed,
            id111: item.containersMoved,
            id112: item.chargesMoved,
            id113: item.containersPosMoved,
            id12: item.containersStock,
            id13: item.chargesStock,
            id14: item.containersOnVehicle,
            id15: item.chargesOnVehicle,
            //id16: item.containersInPos,
            //id17: "-",
            id18: totalContainers,
            id19: totalCharges,
            error: item.stockError
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{minHeight: '200px'}}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    stripedRows
                    isExportable
                />
            </Grid>
        </Grid>
    )
};

export default ReportTableProduct;
