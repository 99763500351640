import * as apiService from '../../apiService';

const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const list = async (filter) => {
        return await apiService.get(`${URL}/warehouses/movements`, filter);
};
export const create = async (data) => {
    return await apiService.post(`${URL}/warehouses/${data.warehouseId}/movements`, data);
};

export const download = (data) => {
    const fileName = "Mov almace, " + data.number;
    apiService.downloadFile(`${URL}/warehouses/movements/${data.id}/download`, {}, fileName);
}

export const listTypes = async () => {
    return await apiService.get(`${URL}/warehouses/movement-types`);
};