import React, { useEffect, useState } from 'react';
import {Link as RouterLink} from "react-router-dom";
import { useIntl } from 'react-intl';

import withMessageBox from '../../../hocs/withMessageBox/index';

import * as accountingService from "../../../services/domain/warehouse/accountingService";
import * as authorizationService from "../../../services/domain/warehouse/authorizationService";
import * as productService from '../../../services/domain/warehouse/productService';
import * as proceedingService from '../../../services/domain/warehouse/proceedingService';
import * as validateService from "../../../services/validateService";
import * as warehouseService from '../../../services/domain/warehouse/warehouseService';
import * as formatter from '../../../helpers/formatter';
import * as validator from '../../../helpers/validator';
import {getValueFromField} from "../../../helpers/form";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../../../components/Breadcrumbs/index';
import Button from '../../../components/form/Button/index';
import PrintButton from '../../../components/form/Button/print';

import ReportForm from './ReportForm';
import ReportTableProduct from './ReportTableProduct';
import ReportTableCage from './ReportTableCage';



export const Report = (props) => {

    const intl = useIntl();

    const newReport = {
        warehouseId: null,
        date: formatter.dateApiFormatter(),
        closureProducts: [],
        closureCages: []
    };
    const reportValidations = {
        warehouseId: [{type: validateService.REQUIRED}],
        date: [{type: validateService.REQUIRED}, {type: validateService.DATE_PAST_OR_TODAY}],
    };

    const [errors, setErrors] = useState({});
    const [isCalculating, setIsCalculating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [products, setProducts] = useState([]);
    const [report, setReport] = useState(newReport);
    const [reportState, setReportState] = useState(intl.formatMessage({id: 'generic.opened'}));
    const [calcButtonDisabled, setCalcButtonDisabled] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [warehouses, setWarehouses] = useState([]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.report'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.report.closure'})}
    ];

    useEffect(() => {
        async function load() {
            const responseP = await productService.getProducts();
            const responseW = await warehouseService.listWarehouses();
            if (responseW.status === 200) {
                setProducts(responseP.data.products);
                setWarehouses(responseW.data.warehouses);
            } else {
                console.error(responseW);
            }
        }
        load();
    }, []);
    useEffect(() => {
        if (report.date && validator.isToday(report.date) && report.warehouseId) {
            //setCalcButtonDisabled(false);
            setSubmitButtonDisabled(report.stockError === true);
        } else {
            //setCalcButtonDisabled(true);
            setSubmitButtonDisabled(true);
        }
    }, [report]);


    const clearReport = () => {
        setReport(newReport);
        setReportState(intl.formatMessage({id: 'generic.opened'}));
    }
    const loadReport = async () => {
        setIsLoading(true);
        setIsSearching(true);
        const params = {
            ...report
        };
        delete params.closureProducts;
        delete params.closureCages;
        const response = proceedingService.getProceeding(params);
        response
            .then((res) => {
                setReport({
                    ...report,
                    ...res.data
                });
                setReportState(intl.formatMessage({id: 'generic.closed'}));
            })
            .catch(err => {
                props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                setReportState(intl.formatMessage({id: 'generic.opened'}));
                console.error(err.response);
            })
            .finally(() => {
                setIsLoading(false);
                setIsSearching(false);
            });
    };
    const calculateReport = async () => {
        setIsLoading(true);
        setIsCalculating(true);
        const params = {
            ...report
        };
        delete params.closureProducts;
        delete params.closureCages;
        const response = proceedingService.getCalculateProceeding(params);
        setReportState("-");
        response
            .then((res) => {
                setReport({
                    ...report,
                    ...res.data
                });
            })
            .catch(err => {
                props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                console.error(err.response);
            })
            .finally(() => {
                setIsLoading(false);
                setIsCalculating(false);
            });
    };
    const saveReport = async () => {
        setIsLoading(true);
        const response = proceedingService.close(report);
        response
            .then((res) => {
                setIsLoading(false);
                props.successMessage(intl.formatMessage({id: "generic.domain.report.closed"}));
                setReportState(intl.formatMessage({id: 'generic.closed'}));
                setSubmitButtonDisabled(true);
            })
            .catch(err => {
                setIsLoading(false);
                props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                console.error(err.response);
            });
    }

    const handleChange = (e) => {
        setReport({
            ...report,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSearch = () => {
        const err = validateService.validate(report, reportValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            loadReport();
        }
    };
    const handleCalculate = () => {
        const err = validateService.validate(report, reportValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            calculateReport();
        }
    };
    const handleSubmit = () => {
        const err = validateService.validate(report, reportValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            saveReport();
        }
    };

    const handleDownload = () => {
        const err = validateService.validate(report, reportValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            const params = {...report};
            delete params.closureProducts;
            delete params.closureCages;
            proceedingService.downloadVerification(params);
        }
    }

    const handleDownloadOnVehicle = () => {
        const params = {
            liquidated: false,
            warehouseId: report.warehouseId
        }
        authorizationService.downloadList(params);
    }

    const handleDownloadLiquidations = () => {
        const params = {
            liquidated: true,
            warehouseId: report.warehouseId,
            startDate: report.date,
            endDate: report.date
        }
        authorizationService.downloadList(params);
    }

    const handleDownloadOperationsDiary = () => {
        accountingService.downloadAccountingMovements({
            startDate: report.date,
            endDate: report.date
        });
    }

    const handleDownloadPaymentsRelation = () => {
        accountingService.downloadAccountingMovements({
            startDate: report.date,
            endDate: report.date,
            payments: true
        });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <ReportForm
                    report={report}
                    warehouses={warehouses}
                    errors={errors}
                    isLoading={isLoading}
                    isCalculating={isCalculating}
                    isSearching={isSearching}
                    onChange={handleChange}
                    onSubmit={handleSearch}
                    onCalculate={handleCalculate}
                />
            </Grid>
            <Grid item xs={12}>
                Estado: <Box fontSize={18} fontWeight="fontWeightBold">{reportState}</Box>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <ReportTableProduct
                    report={report}
                    isLoading={isLoading}
                />
                <br/>
                <ReportTableCage
                    report={report}
                    isLoading={isLoading}
                />
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" width="100%">
                    <Box>
                        <PrintButton
                            label={intl.formatMessage({id: 'generic.domain.generalChecking'})}
                            style={{marginLeft: '10px', marginTop: '8px'}}
                            onClick={handleDownload}
                        />
                        <PrintButton
                            label={intl.formatMessage({id: 'generic.domain.operationsDiary'})}
                            style={{marginLeft: '10px', marginTop: '8px'}}
                            onClick={handleDownloadOperationsDiary}
                        />
                        <PrintButton
                            label={intl.formatMessage({id: 'generic.domain.paymentsRelation'})}
                            style={{marginLeft: '10px', marginTop: '8px'}}
                            onClick={handleDownloadPaymentsRelation}
                        />
                        <PrintButton
                            label={intl.formatMessage({id: 'generic.domain.onVehicle'})}
                            style={{marginLeft: '10px', marginTop: '8px'}}
                            onClick={handleDownloadOnVehicle}
                        />
                        <PrintButton
                            label={intl.formatMessage({id: 'generic.domain.liquidations'})}
                            style={{marginLeft: '10px', marginTop: '8px'}}
                            onClick={handleDownloadLiquidations}
                        />
                    </Box>
                    <Box padding={1}>
                        <RouterLink to={{pathname: `/authorizations/pre-payments`}}>
                            <Button color="primary" variant="contained" size="small">{intl.formatMessage({id: 'generic.domain.pre-payments'})}</Button>
                        </RouterLink>
                    </Box>
                    <Box flexGrow={1}></Box>
                    <Box>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            style={{marginLeft: '10px', marginTop: '8px'}}
                            onClick={handleSubmit}
                            disabled={submitButtonDisabled}
                        >
                            {intl.formatMessage({id: "generic.domain.report.close"})}
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
};

export default withMessageBox(Report);