import React from 'react';
import { useIntl } from 'react-intl';

import LockIcon from '@material-ui/icons/Lock';


const AccessNotAllowed = () => {
    const intl = useIntl();

    return (
        <div style={{display: 'flex', alignItems: 'center', height: '70vh', justifyContent: 'center', gap: 10}}>
            <LockIcon />
            {intl.formatMessage({id: 'app.error.code.ACCESS_NOT_ALLOWED'})}
        </div>
    )
}

export default AccessNotAllowed;
