import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MuiEditIcon from '@material-ui/icons/Edit';


const EditIcon = (props) => {
    const {button, ...other} = props;
    if (button) {
        return (
            <IconButton {...other}><MuiEditIcon/></IconButton>
        );
    }
    return (<MuiEditIcon/>);
};

export default EditIcon;
