import React, {useEffect, useState} from "react";
import { useIntl } from 'react-intl';
import withMessageBox from '../../../../hocs/withMessageBox';

import * as statsService from '../../../../services/domain/warehouse/statsService';
import * as formatter from "../../../../helpers/formatter";
import * as validator from "../../../../helpers/validator";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import Filter from "../../../../components/Filter/Filter";
import Table from '../../../../components/table';
import UnsubscribeStatsFilterForm from "./UnsubscribeStatsFilterForm";
import {getValueFromField} from "../../../../helpers/form";
import {TEXT_COLOR} from "../../../../assets/themes/theme";


export const UnsubscribeStats = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.stats'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.stats.unsubscribes'})}
    ];
    const newFilter = {
        warehouseId: null,
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter()
    }
    const newHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.vehicle"}), sublabel: null, formatter: null },
    ];

    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState(newHeaders);
    const [filter, setFilter] = useState(newFilter);
    const [footer, setFooter] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(null);


    useEffect(() => {
        if (data.length > 0) generateFooter();
    }, [data]);


    const generateHeaders = item => {
        let index = 1;
        const vehicleHeader = [...newHeaders];
        const productHeaders = item.products.map(item => {
            index ++;
            return { id: 'id' + (index), align: "center",   label: item.product.name, formatter: cellFormatter };
        });
        return vehicleHeader.concat(productHeaders);
    }
    const cellFormatter = (value) => {
        const color = value > 0 ? TEXT_COLOR : 'silver';
        return <Box color={color}>{value}</Box>;
    }
    const convertRowData = (item, generatedHeaders) => {
        const row = {id1: item.vehicle ? item.vehicle.plate : ""};
        let id;
        item.products.map(productItem => {
            id = getHeaderId(generatedHeaders, productItem.product.name);
            row[id] = productItem.containers;
        });
        return row;
    }
    const getHeaderId = (headers, label) => {
        for (let i = 0; i < headers.length; i++) {
            if (label === headers[i].label) return headers[i].id;
        }
        return "undefined";
    }
    const generateFooter = () => {
        let footer = {}
        headers.map(col => {
            footer = {
                ...footer,
                [col.id]: col.id === 'id1' ? intl.formatMessage({id: 'generic.domain.total'}).toUpperCase() : 0
            }
        });
        data.map(row => {
            for (const key in row) {
                if (key !== 'id1') footer[key] = parseInt(footer[key]) + row[key];
            }
        })
        setFooter(footer);
    }

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        const response = statsService.unsubscribes(filter);
        response
            .then((res) => {
                const generatedHeaders = generateHeaders(res.data.unsubscribes[0]);
                const generatedData = res.data.unsubscribes.map(item => convertRowData(item, generatedHeaders));
                setHeaders(generatedHeaders);
                setData(generatedData);

                const dates = validator.isSameDate(filter.startDate, filter.endDate) ?
                    formatter.dateFormatter(filter.startDate) :
                    formatter.dateFormatter(filter.startDate) + " - " + formatter.dateFormatter(filter.endDate);
                setTitle(intl.formatMessage({id: 'generic.domain.unsubscribe.plural'}) + " " + dates);
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)})
            .finally(() => setIsLoading(false));
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Filter onSearch={handleSearch} onClear={handleFilterClear}>
                    <UnsubscribeStatsFilterForm
                        filter={filter}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Table
                    title={title}
                    headers={headers}
                    data={data}
                    footer={footer}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(UnsubscribeStats);