import * as apiService from '../../apiService';
import * as authService from "../../authService";


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

const DRIVER_CODE = "DRIVER";
const HELPER_CODE = "HELPER";


export const getEmployees = async () => {
    return await apiService.get(`${URL}/employees`);
};

export const getDrivers = async () => {
    try {
        let employees = [];
        const response = await apiService.get(`${URL}/employees`);
        if (response.status === 200) {
            employees = response.data.employees.filter(employee => {
                return isDriver(employee);
            })
        }
        return employees;
    } catch (e) {
        console.error(e);
    }
};
export const getHelpers = async () => {
    let employees = [];
    const response = await apiService.get(`${URL}/employees`);
    if (response.status === 200) {
        employees = response.data.employees.filter(employee => {
            return !isDriver(employee);
        })
    }
    return employees;
};

/**
 * Return helpers from employees. If free is true, returns only the unassigned helpers
 * @param employees
 * @param free
 * @returns {*}
 */
export const getHelpersFromEmployees = (employees = [], free = false) => {
    return employees.filter(employee => {
        if (free) return isHelper(employee) && employee.driver === null;
        else return isHelper(employee);
    })
};

export const getFullName = (employee) => {
    return employee.name + " " + employee.surname;
};
export const isDriver = (employee) => {
    return employee.employeeType.code === DRIVER_CODE;
};
export const isHelper = (employee) => {
    return employee.employeeType.code === HELPER_CODE;
};


export const assignHelper = async (employeeId, helperId) => {
    return await apiService.post(
        `${URL}/employees/` + employeeId + `/helpers/` + helperId + `/enable`,
        {agencyId: authService.getAgencyId(), createdBy: authService.getUser().userName}
    );
};
export const unassignHelper = async (employeeId, helperId) => {
    return await apiService.patch(
        `${URL}/employees/` + employeeId + `/helpers/` + helperId + `/disable`,
        {agencyId: authService.getAgencyId(), updatedBy: authService.getUser().userName}
    );
};

/** VEHICLES */
export const getVehicleFromDriverId = (drivers, driverId) => {
    const filteredDrivers = drivers.filter(driver => {
        return driver.id === driverId
    })

    return filteredDrivers.length > 0 ? filteredDrivers[0].vehicle : null;
}
//TODO: cuando vengan los employees con vehículo, esto ya no tendrá sentido
export const getUnassignedDriversFromVehicles = (vehicles, drivers) => {
    let assignedDriversId = [];
    vehicles.map(vehicle => {
        vehicle.drivers.map(driver => assignedDriversId.push(driver.id));
    });

    return drivers.filter(driver => {
        return assignedDriversId.indexOf(driver.id) === -1;
    });
};

/** ZONES */
//TODO: cuando vengan las zonas con los employees, esto ya no tendrá sentido
export const getEmployeesFromZones = async (zones) => {
    let employees = [];
    const response = await apiService.get(`${URL}/employees`);
    if (response.status === 200) {
        employees = response.data.employees.map(employee => {
            return {...employee, zones: getZonesAssigned(zones, employee)}
        })
    }
    return employees;
};
export const getZonesAssigned = (zones, employee) => {
    return zones.filter(zone => {
        const result = zone.helpers.filter(helper => {
            return employee.id === helper.id
        });
        return result.length > 0;
    })
};

export const assignZone = async (employeeId, zoneId) => {
    return await apiService.post(
        `${URL}/employees/` + employeeId + `/zones/` + zoneId + `/enable`,
        {agencyId: authService.getAgencyId(), createdBy: authService.getUser().userName}
    );
};
export const unassignZone = async (employeeId, zoneId) => {
    return await apiService.patch(
        `${URL}/employees/` + employeeId + `/zones/` + zoneId + `/disable`,
        {agencyId: authService.getAgencyId(), updatedBy: authService.getUser().userName}
    );
};


