import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from "../../../../hocs/withMessageBox";

import * as purchaseOrderService from "../../../../services/domain/warehouse/purchaseOrderService";
import * as warehouseService from "../../../../services/domain/warehouse/warehouseService";
import {getValueFromField} from "../../../../helpers/form";
import * as formatter from "../../../../helpers/formatter";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import Filter from '../../../../components/Filter/Filter';
import PrintIcon from '../../../../components/icons/Print';
import Table from '../../../../components/table';

import PurchaseOrderInvoiceFilterForm from './PurchaseOrderInvoiceFilterForm';



export const PurchaseOrderInvoiceList = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing.po'})}
    ];

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.number"}), sublabel: null, width: 150 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateFormatter, width: 150 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.warehouse"}), sublabel: null },
        { id: 'actions', align: "center", width: 60 },
    ];

    const newFilter = {
        warehouseId: null,
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter()
    }

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(null);
    const [warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        async function load() {
            setIsLoading(true);
            const responseW = await warehouseService.listWarehouses();
            if (responseW.status === 200) {
                setWarehouses(responseW.data.warehouses);
                setIsLoading(false);
            }
        }
        load();
    }, []);

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        const response = purchaseOrderService.listPurchaseOrdersInvoices(filter);
        response
            .then((res) => {
                setData(
                    res.data.purchaseOrderInvoices.map(item => convertRowData(item))
                )
            })
            .catch(err => {})
            .finally(() => setIsLoading(false));
    }
    const handleDownload = (item) => {
        purchaseOrderService.downloadPurchaseOrdersInvoice(item);
    }

    const convertRowData = item => {
        return ({
            id1: item.number,
            id2: item.date,
            id3: item.warehouse.name,
            actions: <PrintIcon button onClick={() => handleDownload(item)} />
        });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Filter onSearch={handleSearch} onClear={handleFilterClear}>
                    <PurchaseOrderInvoiceFilterForm
                        filter={filter}
                        warehouses={warehouses}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default PurchaseOrderInvoiceList;