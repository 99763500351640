import React from 'react';

import Grid from '@material-ui/core/Grid';

import BackgroundImage from '../../../../assets/images/background.jpg';

class LoginLayout extends React.Component {
    render() {
        const {children} = this.props;

        return (
            <Grid container style={{backgroundColor: 'orange', height: '100vh', backgroundImage: `url(${BackgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    {children}
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
        )
    }
}

export default LoginLayout;