import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as streetMapService from '../../../../services/domain/streetMap/streetMapService';
import * as validateService from '../../../../services/validateService';
import {getValueFromField} from "../../../../helpers/form";
import {isTrue, isOdd, isPair, isNull, isEmpty, equals} from "../../../../helpers/validator";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';

import AddIcon from "../../../../components/icons/Add";
import AlertDialog from "../../../../components/AlertDialog";
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from "../../../../components/form/Button";
import DeleteIcon from "../../../../components/icons/Delete";
import EditIcon from '../../../../components/icons/Edit';
import Modal from "../../../../components/Modal/Modal";
import Table from '../../../../components/table';
import {RightBox} from "../../../../components/Box";
import StreetForm from "./StreetForm";
import StreetStretchForm from "./StreetStretchForm";
import StreetCPStretchForm from "./StreetCPStretchForm";



export const Street = (props) => {

    const intl = useIntl();
    const id = props.match.params.id;

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.streetMap'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.management.streets'})}
    ];

    const headersS = [
        { id: 'id0', align: "left", width: 50 },
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.numI"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.numF"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.parity"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.itinerary"}), sublabel: null, formatter: null, width: 200 },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.route"}), sublabel: null, formatter: null, width: 200 },
        { id: 'actions', align: "center", width: 120 },
    ];
    const headersSCP = [
        { id: 'id0', align: "left", width: 50 },
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.numI"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.numF"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.parity"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.cp"}), sublabel: null, formatter: null, width: 200 },
        { id: 'actions', align: "center", width: 120 },
    ];

    const newStreet = {
        id: null,
        streetTypeId: null,
        streetType: null,
        name: null,
        townId: null,
        town: {zipCode: null},
        provinceId: null,
        stretches: {odds: [], pairs: []},
        stretchesZipCode: {odds: [], pairs: []}
    }
    const streetValidations = {
        streetTypeId: [{type: validateService.REQUIRED}],
        name: [{type: validateService.REQUIRED}],
        provinceId: [{type: validateService.REQUIRED}],
        townId: [{type: validateService.REQUIRED}],
    }
    const newStretch = {
        initialNumber: null,
        finalNumber: null,
        parityId: null,
        itineraryId: null,
        route: null,
        allStreet: false
    }
    const stretchValidations = {
        initialNumber: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_BETWEEN, value: [0, 9999]}],
        finalNumber: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_BETWEEN, value: [0, 9999]}],
        parityId: [{type: validateService.REQUIRED}],
        itineraryId: [{type: validateService.REQUIRED}],
    }
    const newStretchCP = {
        initialNumber: null,
        finalNumber: null,
        parityId: null,
        zipCode: null,
        allStreet: false
    }
    const stretchCPValidations = {
        initialNumber: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_BETWEEN, value: [0, 9999]}],
        finalNumber: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_BETWEEN, value: [0, 9999]}],
        parityId: [{type: validateService.REQUIRED}],
        zipCode: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
    }
    const newStretchIndex = -1;

    const [dataS, setDataS] = useState([]);
    const [dataSCP, setDataSCP] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorsS, setErrorsS] = useState({});
    const [errorsSCP, setErrorsSCP] = useState({});
    const [itineraries, setItineraries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [openS, setOpenS] = useState(false);
    const [openSCP, setOpenSCP] = useState(false);
    const [parity, setParity] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [street, setStreet] = useState(newStreet);
    const [streetLoaded, setStreetLoaded] = useState(false);
    const [streetTypes, setStreetTypes] = useState([]);
    const [stretch, setStretch] = useState(newStretch);
    const [stretchCP, setStretchCP] = useState(newStretchCP);
    const [stretchIndex, setStretchIndex] = useState(newStretchIndex);
    const [towns, setTowns] = useState([]);


    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseI = await streetMapService.getItineraries();
            const responseP = await streetMapService.getProvinces();
            const responseST = await streetMapService.getStreetTypes();
            if (responseP.status === 200 && responseST.status === 200 && responseI.status === 200) {
                setProvinces(responseP.data.provinces);
                setStreetTypes(responseST.data.streetTypes);
                setItineraries(responseI.data.itineraries);
                setLoading(false);
            }
        }
        setParity(streetMapService.getParity());

        if (props.location.state) {
            if (!isEmpty(props.location.state.streetTypes)) {
                setStreetTypes(props.location.state.streetTypes);
            }
            if (!isEmpty(props.location.state.provinces)) {
                setProvinces(props.location.state.provinces);
            }
        }
        if (id === "new") {
            load();
        }
    }, []);
    useEffect( () => {
        if (
            provinces.length !==0 &&
            streetTypes.length !== 0 &&
            props.location.state &&
            props.location.state.street &&
            props.location.state.street.id === id
        ) {
            loadTowns(props.location.state.street.town.province.id);
        }
    }, [provinces, streetTypes]);
    useEffect( () => {
        if (
            towns.length !==0 &&
            props.location.state &&
            props.location.state.street &&
            props.location.state.street.id === id &&
            !streetLoaded
        ) {
            loadItineraries();
            setStreet(streetMapService.hydrateStreet(props.location.state.street));
            setRefresh(new Date());
            setStreetLoaded(true);
        }
    }, [towns]);

    useEffect(() => {
        const pairs = street.stretches.pairs.map(item => convertRowDataS(item));
        const odds = street.stretches.odds.map(item => convertRowDataS(item));
        setDataS([
            ...pairs,
            ...odds
        ]);
        if (!isNull(street.stretchesZipCode)) {
            const pairsCP = street.stretchesZipCode.pairs.map(item => convertRowDataSCP(item));
            const oddsCP = street.stretchesZipCode.odds.map(item => convertRowDataSCP(item));
            setDataSCP([
                ...pairsCP,
                ...oddsCP
            ]);
        }
    }, [refresh]);

    const convertRowDataS = item => {
        const itinerary = item.itinerary && item.itineraryId &&  item.itinerary.id !== item.itineraryId ?
            itineraries.getObjectById(item.itineraryId) :
            item.itinerary ?
                item.itinerary :
                item.itineraryId ? itineraries.getObjectById(item.itineraryId) : null
        ;
        return {
            id0: <div title={item.id}><LocationOnIcon/></div>,
            id1: item.initialNumber,
            id2: item.finalNumber,
            id3: item.parityId,
            id4: itinerary ? itinerary.name : "-",
            id5: itinerary && itinerary.route ? itinerary.route.name : "",
            actions: <div><EditIcon button onClick={() => handleEditStretch(item)}/><DeleteIcon button onClick={() => handleDeleteStretch(item)} /></div>
        }
    }
    const convertRowDataSCP = item => {
        return {
            id0: <div title={item.id}><MailIcon/></div>,
            id1: item.initialNumber,
            id2: item.finalNumber,
            id3: item.parityId,
            id4: item.zipCode,
            actions: <div><EditIcon button onClick={() => handleEditStretchCP(item)}/><DeleteIcon button onClick={() => handleDeleteStretchCP(item)} /></div>
        }
    }

    async function loadTowns(provinceId) {
        setLoading(true);
        const response = await streetMapService.getTowns({provinceId: provinceId});
        if (response.status === 200) {
            setTowns(response.data.towns);
        } else {
            console.error(response);
        }
        setLoading(false);
    }
    async function loadItineraries() {
        setLoading(true);
        const response = await streetMapService.getItineraries();
        if (response.status === 200) {
            setItineraries(response.data.itineraries);
        } else {
            console.error(response);
        }
        setLoading(false);
    }

    const handleOpenS = () => {
        setOpenS(true);
    }
    const handleOpenSCP = () => {
        setOpenSCP(true);
    }
    const handleClose = () => {
        setOpenS(false);
        setOpenSCP(false);
    }
    const handleNew = () => {
        setStreet(newStreet);
        setTowns([]);
        setStretch(newStretch);
        setStretchCP(newStretchCP);
        setRefresh(new Date());
        props.history.push('/street-map/streets/new');
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = getValueFromField(e, newStreet);

        switch (e.target.name) {
            case 'provinceId':
                if (isNull(value))
                    setTowns([]);
                else
                    loadTowns(value);

                setStreet({
                    ...street,
                    townId: null,
                    [name]: value
                });
                break;

            case 'townId':
                const town = towns.getObjectById(value);
                setStreet({
                    ...street,
                    [name]: value,
                    town: town
                });
                break;

            default:
                setStreet({
                    ...street,
                    [name]: value
                });
        }
    }

    const handleSubmit = () => {
        const err = validateService.validate(street, streetValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            if (hasAllStretches(street.stretches)) {
                let response;
                if (street.id === null) response = streetMapService.addStreet(street);
                else response = streetMapService.updateStreet(street.id, street);
                response
                    .then(resp => {
                        setStreet({
                            ...street,
                            id: resp.data.id,
                        });
                        props.successMessage(intl.formatMessage({id: "generic.saved"}));
                    })
                    .catch(err => {
                        if (err.response.status && err.response.status === 400)
                            props.errorMessage(intl.formatMessage({id: 'app.error.code.ALREADY_EXISTS'}));
                        else
                            props.errorMessage('Error');
                        console.error(err.response)
                    });
            } else {
                props.errorMessage(intl.formatMessage({id: 'app.error.domain.streetMap.INCORRECT_STRETCHES'}));
            }
        }
    }

    const handleDeleteStreet = () => {
        if (!isNull(street.id)) {
            const response = streetMapService.deleteStreet(street.id);
            setLoading(true);
            setOpenConfirmation(false);
            response
                .then(() => {
                    setStreet(newStreet);
                    setRefresh(new Date());
                    props.successMessage(intl.formatMessage({id: 'generic.deleted'}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const hasAllStretches = (stretches) => {
        return true;
        //TODO: Comprobar. No funciona bien
        if (stretches.odds.length === 0 || stretches.pairs === 0) return false;
        if (stretches.odds[stretches.odds.length - 1] !== streetMapService.ODDS_NUM_F) return false;
        if (stretches.pairs[stretches.pairs.length - 1] !== streetMapService.PAIRS_NUM_F) return false;

        let res = true;
        let num = 1;

        stretches.odds.forEach(row => {
            if (num !== row.initialNumber) res = false;
            num = row.finalNumber + 2;
        });
        stretches.pairs.forEach((row, index) => {
            if (index === 0) {
                if (row.initialNumber !== 0 && row.initialNumber !== 2) res = false;
            } else {
                if (num !== row.initialNumber) res = false;
            }
            num = row.finalNumber + 2;
        });
        return res;
    }

    const handleChangeStretch = (e) => {
        const value = getValueFromField(e, newStretch);
        switch (e.target.name) {
            case 'allStreet':
                if (isTrue(value)) {
                    if (stretch.parityId === 'P') {
                        setStretch({
                            ...stretch,
                            initialNumber: 0,
                            finalNumber: 9998,
                            [e.target.name]: value
                        });
                    } else {
                        setStretch({
                            ...stretch,
                            initialNumber: 1,
                            finalNumber: 9999,
                            [e.target.name]: value
                        });
                    }
                }
                break;

            case 'parityId':
                setStretch({
                    ...stretch,
                    initialNumber: null,
                    finalNumber: null,
                    allStreet: false,
                    [e.target.name]: value
                });
                break;

            case 'initialNumber':
            case 'finalNumber':
                setStretch({
                    ...stretch,
                    allStreet: false,
                    [e.target.name]: parseInt(value)
                });
                break;

            case 'itineraryId':


            default:
                setStretch({
                    ...stretch,
                    [e.target.name]: value
                });
        }
    }
    const handleDeleteStretch = (item) => {
        if (isOdd(item.initialNumber))
            street.stretches.odds = street.stretches.odds.filter(stretch => stretch.initialNumber !== item.initialNumber);
        else
            street.stretches.pairs = street.stretches.pairs.filter(stretch => stretch.initialNumber !== item.initialNumber);
        setRefresh(new Date());
    }
    const handleEditStretch = (item) => {
        for (let i = 0; i < street.stretches.pairs.length; i++) {
            if (equals(street.stretches.pairs[i], item)) {
                setStretch(item);
                setStretchIndex(i);
            }
        }
        for (let i = 0; i < street.stretches.odds.length; i++) {
            if (equals(street.stretches.odds[i], item)) {
                setStretch(item);
                setStretchIndex(i);
            }
        }
        setOpenS(true);
    }
    const handleSubmitStretch = () => {
        const validations = {...stretchValidations};
        if (stretch.parityId === 'P') {
            validations.initialNumber.push({type: validateService.NUMBER_PAIR});
            validations.finalNumber.push({type: validateService.NUMBER_PAIR});
        } else {
            validations.initialNumber.push({type: validateService.NUMBER_ODD});
            validations.finalNumber.push({type: validateService.NUMBER_ODD});
        }
        validations.finalNumber.push({type: validateService.NUMBER_GREATER_OR_EQUAL_TO, value: stretch.initialNumber});
        const err = validateService.validate(stretch, validations);
        setErrorsS(err);
        if (!validateService.hasErrors(err)) {
            if (stretchIndex === newStretchIndex) { //Add
                if (isValidStretchForAdd(street.stretches, stretch)) {
                    if (isOdd(stretch.initialNumber)) {
                        const odds = street.stretches.odds;
                        odds.push(stretch);
                        street.stretches.odds = odds.sortBy('initialNumber');
                    } else {
                        const pairs = street.stretches.pairs;
                        pairs.push(stretch);
                        street.stretches.pairs = pairs.sortBy('initialNumber');
                    }
                } else {
                    props.errorMessage('Hay otro tramo que contiene algún número de este');
                }
            } else { //Update
                if (isOdd(stretch.initialNumber)) {
                    street.stretches.odds[stretchIndex] = stretch;
                } else {
                    street.stretches.pairs[stretchIndex] = stretch;
                }
                setStretchIndex(newStretchIndex);
            }
            setRefresh(new Date());
            setStretch(newStretch);
            handleClose();
        }
    }

    const handleChangeStretchCP = (e) => {
        const value = getValueFromField(e, newStretchCP);
        switch (e.target.name) {
            case 'allStreet':
                if (isTrue(value)) {
                    if (stretchCP.parityId === 'P') {
                        setStretchCP({
                            ...stretchCP,
                            initialNumber: 0,
                            finalNumber: 9998,
                            [e.target.name]: value
                        });
                    } else {
                        setStretchCP({
                            ...stretchCP,
                            initialNumber: 1,
                            finalNumber: 9999,
                            [e.target.name]: value
                        });
                    }
                }
                break;

            case 'parityId':
                setStretchCP({
                    ...stretchCP,
                    initialNumber: null,
                    finalNumber: null,
                    allStreet: false,
                    [e.target.name]: value
                });
                break;

            case 'initialNumber':
            case 'finalNumber':
                setStretchCP({
                    ...stretchCP,
                    allStreet: false,
                    [e.target.name]: parseInt(value)
                });
                break;

            default:
                setStretchCP({
                    ...stretchCP,
                    [e.target.name]: value
                });
        }
    }
    const handleDeleteStretchCP = (item) => {
        if (isOdd(item.initialNumber))
            street.stretchesZipCode.odds = street.stretchesZipCode.odds.filter(stretch => stretch.initialNumber !== item.initialNumber);
        else
            street.stretchesZipCode.pairs = street.stretchesZipCode.pairs.filter(stretch => stretch.initialNumber !== item.initialNumber);
        setRefresh(new Date());
    }
    const handleEditStretchCP = (item) => {
        for (let i = 0; i < street.stretchesZipCode.pairs.length; i++) {
            if (equals(street.stretchesZipCode.pairs[i], item)) {
                setStretchCP(item);
                setStretchIndex(i);
            }
        }
        for (let i = 0; i < street.stretchesZipCode.odds.length; i++) {
            if (equals(street.stretchesZipCode.odds[i], item)) {
                setStretchCP(item);
                setStretchIndex(i);
            }
        }
        setOpenSCP(true);
    }
    const handleSubmitStretchCP = () => {
        const validations = {...stretchCPValidations};
        if (stretchCP.parityId === 'P') {
            validations.initialNumber.push({type: validateService.NUMBER_PAIR});
            validations.finalNumber.push({type: validateService.NUMBER_PAIR});
        } else {
            validations.initialNumber.push({type: validateService.NUMBER_ODD});
            validations.finalNumber.push({type: validateService.NUMBER_ODD});
        }
        validations.finalNumber.push({type: validateService.NUMBER_GREATER_OR_EQUAL_TO, value: stretchCP.initialNumber});
        const err = validateService.validate(stretchCP, validations);

        setErrorsSCP(err);
        if (!validateService.hasErrors(err)) {
            if (stretchIndex === newStretchIndex) { //Add
                if (isValidStretchForAdd(street.stretchesZipCode, stretchCP)) {
                    if (isOdd(stretchCP.initialNumber)) {
                        const odds = street.stretchesZipCode.odds;
                        odds.push(stretchCP);
                        street.stretchesZipCode.odds = odds.sortBy('initialNumber');
                    } else {
                        const pairs = street.stretchesZipCode.pairs;
                        pairs.push(stretchCP);
                        street.stretchesZipCode.pairs = pairs.sortBy('initialNumber');
                    }
                } else {
                    props.errorMessage('Hay otro tramo que contiene algún número de este');
                }
            } else { //Update
                if (isOdd(stretchCP.initialNumber)) {
                    street.stretchesZipCode.odds[stretchIndex] = stretchCP;
                } else {
                    street.stretchesZipCode.pairs[stretchIndex] = stretchCP;
                }
                setStretchIndex(newStretchIndex);
            }
            setRefresh(new Date());
            setStretchCP(newStretchCP);
            handleClose();
        }
    }

    const handleCancel = () => {
        setOpenS(false);
        setOpenSCP(false);
        setStretch(newStretch);
        setStretchCP(newStretchCP);
        setErrorsS({});
        setErrorsSCP({});
        setOpenConfirmation(false);
    }
    const handleOpenConfirmation = () => {
        setOpenConfirmation(true);
    }
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }

    const isValidStretchForAdd = (stretches, stretch) => {
        let res = true;
        if (isOdd(stretch.initialNumber))
            stretches.odds.forEach(row => {
                if (stretch.initialNumber >= row.initialNumber && stretch.initialNumber <= row.finalNumber) res = false;
                if (stretch.finalNumber >= row.initialNumber && stretch.finalNumber <= row.finalNumber) res = false;
            });
        if (isPair(stretch.initialNumber))
            stretches.pairs.forEach(row => {
                if (stretch.initialNumber >= row.initialNumber && stretch.initialNumber <= row.finalNumber) res = false;
                if (stretch.finalNumber >= row.initialNumber && stretch.finalNumber <= row.finalNumber) res = false;
            });
        return res;
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
                <br/><br/>
            </Grid>
            <Grid item xs={10}>
                <StreetForm
                    street={street}
                    streetTypes={streetTypes}
                    towns={towns}
                    provinces={provinces}
                    errors={errors}
                    loading={loading}
                    onChange={handleChange}
                />
                <br/><br/>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={12}>
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <b>{intl.formatMessage({id: 'generic.domain.streetStretch.plural'})}</b>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid item xs={11}>
                            <Table
                                headers={headersS}
                                data={dataS}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button color="primary" float size="small" onClick={handleOpenS}><AddIcon color="white"/></Button>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <b>{intl.formatMessage({id:"generic.domain.cp.plural"})}</b>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid item xs={11}>
                            <Table
                                headers={headersSCP}
                                data={dataSCP}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button color="primary" float size="small" onClick={handleOpenSCP} disabled={!isNull(street.town.zipCode)}><AddIcon color="white"/></Button>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <br/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <RouterLink to={{pathname: "/street-map/streets", state: {back: true}}}>
                        <Button
                        >
                            {intl.formatMessage({id: "generic.back"})}
                        </Button>
                    </RouterLink>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleOpenConfirmation}
                        disabled={street.id === null}
                    >
                        {intl.formatMessage({id: "generic.delete"})}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{marginLeft: '10px'}}
                        onClick={handleNew}
                    >
                        {intl.formatMessage({id: "generic.new.fem"})}
                    </Button>
                    <Button
                        disabled //TODO: eliminar cuando el BWIN esté en la nube
                        color="primary"
                        variant="contained"
                        style={{marginLeft: '10px'}}
                        onClick={(e) => {e.preventDefault(); handleSubmit();}}
                    >
                        {intl.formatMessage({id: "generic.save"})}
                    </Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Modal
                    open={openS}
                    onClose={handleClose}
                    width={400}
                    height={400}
                >
                    <StreetStretchForm
                        stretch={stretch}
                        parity={parity}
                        itineraries={itineraries}
                        errors={errorsS}
                        onChange={handleChangeStretch}
                        onSubmit={handleSubmitStretch}
                        onCancel={handleCancel}
                    />
                </Modal>
                <Modal
                    open={openSCP}
                    onClose={handleClose}
                    width={400}
                    height={400}
                >
                    <StreetCPStretchForm
                        stretch={stretchCP}
                        parity={parity}
                        errors={errorsSCP}
                        onChange={handleChangeStretchCP}
                        onSubmit={handleSubmitStretchCP}
                        onCancel={handleCancel}
                    />
                </Modal>
                <AlertDialog
                    open={openConfirmation}
                    title={intl.formatMessage({id: "generic.domain.street"})}
                    text={intl.formatMessage({id: "generic.delete.confirmation"})}
                    onYes={handleDeleteStreet}
                    onNo={handleCloseConfirmation}
                />
            </Grid>
        </Grid>
    )

}

export default withMessageBox(Street);