import React from 'react';

import StreetviewIcon from '@material-ui/icons/Streetview';


const StreetIcon = (props) => {
    
    return (
        <StreetviewIcon style={{color: "#FFF"}} />
    )        
}

export default StreetIcon;