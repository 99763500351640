import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {isNull} from "../../../helpers/validator";

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';


const Select = (props) => {
    const {label, items = [], value, required = false, variant = "outlined", fullWidth = false, width, helperText, error, disabled = false, loading = false, ...other} = props;

    const intl = useIntl();

    const [hasError, setHasError] = useState(false);
    const [text, setText] = useState("");

    const labelId = 'select_' + Math.floor((Math.random() * 1000) + 1);

    const widthStyle = fullWidth ? {} :
        width ? {minWidth: width + 'px'} : {minWidth: '200px'};

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: '225px'
            }
        }
    }

    useEffect(() => {
        if (!isNull(error)) {
            setHasError(true);
            if (typeof error === "string")
                setText(intl.formatMessage({id: error}));
            else
                setText(intl.formatMessage({id: error.id}, error));
        } else {
            setHasError(false);
            setText(helperText);
        }
    }, [error]);

    const handleChange = (e) => {
        e.target.type = "select";
        props.onChange(e);
    }


    return (
        <FormControl variant={variant} margin="dense" required={required} fullWidth={fullWidth} error={hasError} style={widthStyle}>
            {label && (<InputLabel id={labelId}>{label}</InputLabel>)}
            <MUISelect
                labelId={labelId}
                label={label}
                value={value !== null ? value : ""}
                variant={variant}
                disabled={disabled || loading}
                onChange={handleChange}
                MenuProps={MenuProps}
                {...other}
            >
                <MenuItem value=""><em>{intl.formatMessage({id: "app.form.select.none"})}</em></MenuItem>
                {
                    items.map((item, index) => {
                        return (
                            <MenuItem value={item.value} key={index}>
                                {item.label}
                            </MenuItem>
                        )
                    })
                }
            </MUISelect>
            <FormHelperText>{text}</FormHelperText>
        </FormControl>
    )

};

Select.propTypes = {
    error: PropTypes.string,
    fullwidth: PropTypes.bool,
    helperText: PropTypes.string,
    items: PropTypes.array.isRequired,
    required: PropTypes.bool,
    variant: PropTypes.string,
    width: PropTypes.number,
};

export default Select;
