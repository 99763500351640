const CryptoJS = require('crypto-js');

const keyBase64 = "o9szYIOq1rRMiouNhNvaq96lqUvCek";
const key = CryptoJS.enc.Base64.parse(keyBase64);

export const encryptPassword = (str) => {
    const day = new Date().getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

    // keyBase64 32 dig
    const key = CryptoJS.enc.Base64.parse(keyBase64 + day);
    const srcs = CryptoJS.enc.Utf8.parse(str);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});

    return encrypted.toString();
}

// ??
export const decryptPassword = (str) => {
    const key = CryptoJS.enc.Base64.parse(keyBase64);
    const decrypt = CryptoJS.AES.decrypt(str, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});

    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export const encrypt = (str) => {
    return CryptoJS.AES.encrypt(JSON.stringify(str), keyBase64);
}
export const decrypt = (encriptedStr) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encriptedStr, keyBase64);
    return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
}



