import jwt from 'jsonwebtoken';
import moment from 'moment';

export const KEY_ACCESS_TOKEN = "token";
export const KEY_AGENCY_ID = "agencyId";

export const CLAIM_USERNAME = "sub";
export const CLAIM_NAME = "firstName";
export const CLAIM_LASTNAME = "lastName";
export const CLAIM_EMAIL = "email";
export const CLAIM_AGENCIES = "agencies";
export const CLAIM_ROLES = "roles";
export const CLAIM_VALID_UNTIL = "exp";

export const ROLE_ADMIN = "ADMIN";
export const ROLE_CALLEJERO = "GERENTE";
export const ROLE_GERENTE = "GERENTE";
export const ROLE_ALMACEN = "ALMACEN";
export const ROLE_AGENCIA = "AGENCIA";
export const ROLE_REPARTIDOR = "REPARTIDOR";
export const ROLE_AYUDANTE = "AYUDANTE";
export const ROLE_GESTOR = "GESTOR";
export const ROLE_USUARIO = "USUARIO";
export const ROLE_ANY = "ANY";



/** token */
export const getAccessToken = () => {
    return localStorage.getItem(KEY_ACCESS_TOKEN);
};
export const setAccessToken = (value) => {
    return localStorage.setItem(KEY_ACCESS_TOKEN, value.replace("Bearer ", ""));
};
const isValidToken = () => {
    const token = getAccessToken();

    if (!token) return false;

    const validUntil = getClaims(CLAIM_VALID_UNTIL);
    const now = moment().unix();

    return validUntil >= now;
};

/** logIn */
export const setLogInEnvironment = (token) => {
    setAccessToken(token);
    const agencyIdSetted = getAgencyId();
    if (
        isAdmin() ||
        (agencyIdSetted && !userHasAgencyAuthorization(agencyIdSetted))
    ) {
        deleteAgencyId();
    }
};
export const isLoggedIn = () => {
    return isValidToken();
};
/** logOut */
export const logOut = () => {
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem('drawerOptions');
    window.location = "/login";
};

/** jwt **/
const getClaims = (key = null) => {
    const token = getAccessToken();

    if (!token) return null;

    const claims = jwt.decode(token);

    return key ? claims[key] : claims;
};

/** user */
export const getUser = () => {
    const agencies = getClaims(CLAIM_AGENCIES);
    const roles = getClaims(CLAIM_ROLES);
    
    return {
        name: getClaims(CLAIM_NAME),
        firstName: getClaims(CLAIM_NAME),
        lastName: getClaims(CLAIM_LASTNAME),
        userName: getClaims(CLAIM_USERNAME),
        email: getClaims(CLAIM_EMAIL),
        roles: roles ? roles : [],
        agencies: agencies ? agencies : []
    }
};
export const getUserName = () => {
    return getClaims(CLAIM_USERNAME);
};

export const isAdmin = () => {
    const userRoles = getClaims(CLAIM_ROLES);
    return userRoles !== null ?  userRoles.includes(ROLE_ADMIN) : false;
};

/**
 * Return true if user has any role in roles parameter or user is ADMIN or role is ANY
 * @param roles {array}
 * @returns {boolean}
 */
export const userHasRole = (roles = []) => {
    const userRoles = getClaims(CLAIM_ROLES);

    if (userRoles === null) return false;
    if (isAdmin() || roles.includes(ROLE_ANY)) return true;

    return  roles.some((role) => userRoles.includes(role));
};

/**
 *
 * @param agencyId
 * @returns {boolean | *}
 */
export const userHasAgencyAuthorization = (agencyId) => {
    if (isAdmin()) return true;

    const userAgencies = getClaims(CLAIM_AGENCIES);

    return userAgencies.includes(agencyId);
};

/** agency */
export const getAgencyId = () => {
    return localStorage.getItem(KEY_AGENCY_ID);
};
export const setAgencyId = (value) => {
    if (value !== undefined) {
        localStorage.setItem(KEY_AGENCY_ID, value);
    } else {
        deleteAgencyId();
    }
};
export const getAgencies = () => {
    return getClaims(CLAIM_AGENCIES);
};
const deleteAgencyId = () => {
    localStorage.removeItem(KEY_AGENCY_ID);
};
