import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const listWarehouses = async () => {
    return await apiService.get(`${URL}/warehouses`);
};

export const addWarehouse = async (data) => {
    return await apiService.post(`${URL}/warehouses`, data);
};

export const updateWarehouse = async (id, data) => {
    return await apiService.put(`${URL}/warehouses/${id}`, data);
};

