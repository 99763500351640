import React from 'react';

import Box from '@material-ui/core/Box';


export const CenterBox = (props) => {
    const {children, ...other} = props;

    return (
        <Box display="flex" width="100%" {...other}>
            <Box flexGrow={1}></Box>
            <Box>{children}</Box>
            <Box flexGrow={1}></Box>
        </Box>
    )
};

export const RightBox = (props) => {
    const {children, ...other} = props;

    return (
        <Box display="flex" width="100%" {...other}>
            <Box flexGrow={1}></Box>
            <Box>{children}</Box>
        </Box>
    )
};

export default CenterBox;
