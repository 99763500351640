import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Box from "@material-ui/core/Box";

import Table from '../../../../components/table';
import {TEXT_COLOR} from "../../../../assets/themes/theme";



export const SalesByVehicleTab = (props) => {
    const {sales, title, isLoading} = props;

    const intl = useIntl();

    const newHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.vehicle"}), sublabel: null, formatter: null },
    ];

    const [headers, setHeaders] = useState(newHeaders);
    const [data, setData] = useState([]);
    const [footer, setFooter] = useState({});

    useEffect(() => {
        if (data.length > 0) generateFooter();
    }, [data]);
    useEffect(() => {
        if (sales.length > 0) {
            const generatedHeaders = generateHeaders(sales[0]);
            const generatedData = sales.map(item => convertRowData(item, generatedHeaders));

            setHeaders(generatedHeaders);
            setData(generatedData);
        }
    }, [sales]);
    useEffect(() => {
        if (isLoading) setFooter({});
    }, [isLoading]);

    const generateHeaders = item => {
        let index = 1;
        const vehicleHeader = [{ id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.vehicle"}), sublabel: null, formatter: null }];

        const productSaleHeaders = item.saleProducts.map(item => {
            index ++;
            return { id: 'id' + (index), align: "center",   label: item.product.name, sublabel: "Ventas", formatter: cellFormatter };
        });
        index ++;
        const productSaleEquivHeader = [{ id: 'id' + index, align: "center",   label: "Equiv.", sublabel: "Ventas", formatter: cellFormatter }];

        const productPosHeaders = item.saleProducts.map(item => {
            index ++;
            return { id: 'id' + (index), align: "center",   label: item.product.name, sublabel: "PV", formatter: cellFormatter };
        });
        index ++;
        const productPosEquivHeader = [{ id: 'id' + index, align: "center",   label: "Equiv.", sublabel: "PV", formatter: cellFormatter }];


        const productTotalHeaders = item.saleProducts.map(item => {
            index ++;
            return { id: 'id' + (index), align: "center",   label: item.product.name, sublabel: "Total", formatter: cellFormatter };
        });
        index ++;
        const productTotalEquivHeader = [{ id: 'id' + index, align: "center",   label: "Equiv.", sublabel: "Total", formatter: cellFormatter }];

        return vehicleHeader
            .concat(productSaleHeaders)
            .concat(productSaleEquivHeader)
            .concat(productPosHeaders)
            .concat(productPosEquivHeader)
            .concat(productTotalHeaders)
            .concat(productTotalEquivHeader);
    }

    const generateFooter = () => {
        let footer = {}
        const equivCols = [];
        headers.map(col => {
            footer = {
                ...footer,
                [col.id]: col.id === 'id1' ? intl.formatMessage({id: 'generic.domain.total'}).toUpperCase() : 0
            }
            if (col.label.search('Equiv') !== -1) equivCols.push(col.id);
        });
        data.map(row => {
            for (const key in row) {
                if (key !== 'id1') {
                    if (equivCols.indexOf(key) !== -1) footer[key] = (parseFloat(footer[key]) + parseFloat(row[key])).toFixed(1);
                    else footer[key] = parseInt(footer[key]) + parseInt(row[key]);
                }
            }
        })
        setFooter(footer);
    }

    const getHeaderId = (headers, label, subLabel) => {
        for (let i = 0; i < headers.length; i++) {
            if (label === headers[i].label && subLabel === headers[i].sublabel) return headers[i].id;
        }
        return "undefined";
    }

    const convertRowData = (item, generatedHeaders) => {
        const row = {id1: item.vehicle ? item.vehicle.plate : ""};
        let saleEquiv = 0;
        let posEquiv = 0;
        let id;

        item.saleProducts.forEach(saleProduct => {
            id = getHeaderId(generatedHeaders, saleProduct.product.name, "Ventas");
            row[id] = saleProduct.charges;
            saleEquiv += parseFloat(saleProduct.charges) * parseFloat(saleProduct.equivalence);
            id = getHeaderId(generatedHeaders, saleProduct.product.name, "PV");
            row[id] = saleProduct.chargesToPos;
            posEquiv += parseFloat(saleProduct.chargesToPos) * parseFloat(saleProduct.equivalence);

            id = getHeaderId(generatedHeaders, saleProduct.product.name, "Total");
            row[id] = saleProduct.totalCharges;
        });
        const totalEquiv = saleEquiv + posEquiv;

        id = getHeaderId(generatedHeaders, "Equiv.", "Ventas");
        row[id] = saleEquiv;
        id = getHeaderId(generatedHeaders, "Equiv.", "PV");
        row[id] = posEquiv;
        id = getHeaderId(generatedHeaders, "Equiv.", "Total");
        row[id] = totalEquiv;

        return row;
    }

    const cellFormatter = (value) => {
        const color = value > 0 ? TEXT_COLOR : 'silver';
        return <Box color={color}>{value}</Box>;
    }

    return (
        <Table
            title={title}
            headers={headers}
            data={data}
            footer={footer}
            isLoading={isLoading}
            isExportable
        />
    )
}

export default SalesByVehicleTab;