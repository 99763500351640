import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import withMessageBox from "../../../../hocs/withMessageBox";

import * as filterService from '../../../../services/filterService';
import * as formatter from '../../../../helpers/formatter';
import * as warehouseService from "../../../../services/domain/warehouse/warehouseService";
import * as warehouseMovementsService from "../../../../services/domain/warehouse/warehouseMovementsService";
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import {RightBox} from "../../../../components/Box";
import Button from "../../../../components/form/Button";
import Filter from "../../../../components/Filter/Filter";
import AddIcon from "../../../../components/icons/Add";
import EditIcon from "../../../../components/icons";
import EnabledIcon from "../../../../components/icons/Enabled";
import Table from '../../../../components/table'

import WarehouseMovementFilterForm from "./WarehouseMovementFilterForm";



export const WarehouseMovementList = (props) => {

    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.warehouse'})}
    ];

    const newFilter = {
        warehouseId: null,
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter()
    }

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(null);
    const [warehouses, setWarehouses] = useState([]);



    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseW = await warehouseService.listWarehouses();
            if (responseW.status === 200) {
                setWarehouses(responseW.data.warehouses);
            }
            setLoading(false);
        }

        if (props.location?.state?.back) {
            const filterCache = filterService.loadFilter();
            if (filterCache.filter) {
                setFilter(filterCache.filter);
                setWarehouses(filterCache.data.warehouses);
                setRefresh(Date.now());
            } else load();
        } else load();
    }, []);
    useEffect(() => {
        if (refresh != null) handleSearch();
    }, [refresh]);

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.number"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateFormatter },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.warehouse"}), sublabel: null, formatter: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null },
        { id: 'id5', align: "center",   label: intl.formatMessage({id:"generic.canceled.male"}), sublabel: null, formatter: (val) => <EnabledIcon value={val}/>, width: 80 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertRowData = item => {
        return {
            id1: item.number,
            id2: item.date,
            id3: item.warehouse.name,
            id4: item.movementType ? item.movementType.type : "",
            id5: item.canceled,
            actions: <RouterLink to={{pathname: `warehouse-movements/${item.number}`, state: {item: item}}}><EditIcon button /></RouterLink>
        }
    }

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const handleSearch = () => {
        setLoading(true);
        filterService.saveFilter(filter, {warehouses: warehouses});
        const response = warehouseMovementsService.list(filter);
        response
            .then((res) => {
                setData(res.data.warehouseMovements.map(item => convertRowData(item)));
            })
            .catch(err => {})
            .finally(() => setLoading(false));
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={loading}>
                    <WarehouseMovementFilterForm
                        filter={filter}
                        warehouses={warehouses}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <RouterLink to={{pathname: `warehouse-movements/new`}}><Button color="primary" float size="small" ><AddIcon color="white"/></Button></RouterLink>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(WarehouseMovementList);