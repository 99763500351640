import React from 'react';
import { useIntl } from 'react-intl';
import Icon from '@material-ui/icons/Search';

const PageNotFound = () => {
    const intl = useIntl();

    return (
        <div style={{display: 'flex', alignItems: 'center', height: '70vh', justifyContent: 'center', gap: 10}}>
            <Icon />
            <h2>404</h2>
            {intl.formatMessage({id: 'app.error.code.PAGE_NOT_FOUND'})}
        </div>
    )
}

export default PageNotFound;
