import {createIntl} from 'react-intl';
import messages_es from '../assets/translations/es.json';

/**
 * Languages availables
 * @type {string[]}
 */
export const languages = ['es'];

/**
 * Application texts in each language
 * @type {{es}}
 */
export const messages = {
    es: messages_es
};

export const getMessage = (id) => {
    return messages['es'][id];
}

/**
 * navigator language without region code
 * @type {string}
 */
export const navLanguage = navigator.language.split(/[-_]/)[0];

export const intl = createIntl({locale: 'es', messages: messages_es});
