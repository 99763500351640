import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const AlertDialog = (props) => {
    const intl = useIntl();
    const {title, text, labelNoButton, labelYesButton, onNo, onYes, ...otherProps} = props;

    return (
        <Dialog {...otherProps}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onNo}>{labelNoButton ? labelNoButton : intl.formatMessage({id: 'generic.no'})}</Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onYes}
                    autoFocus
                >{labelYesButton ? labelYesButton : intl.formatMessage({id: 'generic.yes'})}</Button>
            </DialogActions>
        </Dialog>
    )
}

AlertDialog.propTypes = {
    labelNoButton: PropTypes.string,
    labelYesButton: PropTypes.string,
    onNo: PropTypes.func,
    onYes: PropTypes.func,
    text: PropTypes.oneOfType([
        PropTypes.string, PropTypes.array
    ]),
    title: PropTypes.string,
    severity: PropTypes.oneOf(['question', '']),
}

export default AlertDialog;