import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Button from '../../../../components/form/Button/index';
import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import { RightBox } from '../../../../components/Box';


export const CageForm = (props) => {
    const {cage, cages, errors} = props;

    const intl = useIntl();

    const [cageItems, setCageItems] = useState([]);

    useEffect(() => {
        setCageItems(cages.map(cage => {
            return {value: cage.id, label: cage.name};
        }));
    }, [cages]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Select
                        name="cageId"
                        label={intl.formatMessage({id: 'generic.domain.cage'})}
                        items={cageItems}
                        value={cage.cageId}
                        error={errors.cageId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        name="units"
                        label={intl.formatMessage({id: 'generic.domain.units'})}
                        value={cage.units}
                        error={errors.units}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}><Box fontSize="9px" color="gray" marginTop="-5px" marginBottom="10px">* Entradas stock en positivo. Salidas stock en negativo.</Box></Grid>
                <Grid item xs={12}>
                    <RightBox>
                        <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                        <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit(cage)}}>{intl.formatMessage({id: "generic.add"})}</Button>
                    </RightBox>
                </Grid>
            </Grid>
        </form>
    )
}

export default CageForm;