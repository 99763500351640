import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as productSituationService from "../../../../services/domain/warehouse/productSituationService";
import * as arrayHelper from "../../../../helpers/array";
import {isNull} from "../../../../helpers/validator";

import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

import AddIcon from '../../../../components/icons/Add';
import Button from '../../../../components/form/Button/index';
import DeleteIcon from '../../../../components/icons/Delete';
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";

import DeliveryNotePosForm from './DeliveryNotePosForm';
import DeliveryNotePosProductForm from './DeliveryNotePosProductForm';





export const DeliveryNotePos = (props) => {
    const {deliveryNotePos, deliveryNotePosProduct, errorsDeliveryNotePos, errorsDeliveryNotePosProduct, pos, products, refreshGrids} = props;
    const intl = useIntl();

    const [data, setData] = useState([]);
    const [openProduct, setOpenProduct] = useState(false);
    const [timeout, setTimeout] = useState(0);

    useEffect(() => {
        setData(deliveryNotePos.products.map(product => convertRowData(product)));
    }, [deliveryNotePos, refreshGrids]);

    const handleCloseProduct = () => {
        setOpenProduct(false);
        setTimeout(500);
    };
    const handleOpenProduct = () => {
        setOpenProduct(true);
    };
    const handleSubmitProduct = () => {
        props.handleSubmitDeliveryNotePosProduct();
    };

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.containers"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, formatter: null, width: 50 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertRowData = item => {
        const productId = item.product ? item.product.id : item.productId;
        const product = arrayHelper.getObjectById(products, productId);
        const type = item.situation ?
            item.situation.type :
            isNull(item.productSituationId) && isNull(item.situation) ?
                "" :
                arrayHelper.getObjectById(productSituationService.getProductSituations(true), item.productSituationId).type;
        return {
            id1: product.name,
            id2: type,
            id3: item.containers,
            id4: item.charges,
            actions: <DeleteIcon button onClick={() => props.handleDeleteDeliveryNotePosProduct(item)} />
        }
    };

    return (
        <React.Fragment>
            { !openProduct && (
                <Slide direction="right" in={!openProduct} timeout={timeout}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DeliveryNotePosForm
                            deliveryNotePos={deliveryNotePos}
                            errors={errorsDeliveryNotePos}
                            pos={pos}
                            onChange={props.handleChangeDeliveryNotePos}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RightBox>
                            <Button color="primary" float size="small" onClick={handleOpenProduct}><AddIcon color="white"/></Button>
                        </RightBox>
                    </Grid>
                    <Grid item xs={12} style={{minHeight: '150px'}}>
                        <Table
                            headers={headers}
                            data={data}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <RightBox>
                            <Button color="secondary" variant="contained" onClick={props.onClose}>{intl.formatMessage({id: "generic.cancel"})}</Button>
                            <Button type="button" color="primary" variant="contained" style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); props.onSubmit()}}>{intl.formatMessage({id: "generic.save"})}</Button>
                        </RightBox>
                    </Grid>
                </Grid>
                </Slide>
            )}
            { openProduct && (
                <Slide direction="left" in={openProduct} timeout={500}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <DeliveryNotePosProductForm
                                product={deliveryNotePosProduct}
                                products={products}
                                productSituationItems={productSituationService.getProductSituationItems(true)}
                                errors={errorsDeliveryNotePosProduct}

                                onClose={handleCloseProduct}
                                onChange={props.handleChangeDeliveryNotePosProduct}
                                onSubmit={handleSubmitProduct}
                            />
                        </Grid>
                    </Grid>
                </Slide>
            )}
        </React.Fragment>
    )
};

export default DeliveryNotePos;