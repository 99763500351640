import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';

import * as streetMapService from "../../../../services/domain/streetMap/streetMapService";
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Filter from '../../../../components/Filter/Filter';

import StreetListFilterForm from "./StreetListFilterForm.jsx";
import StreetItem from "./StreetItem";



export const StreetList = () => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.streetMap'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.queries'})},
        {label: intl.formatMessage({id: 'app.menu.streetMap.queries.streetsByItinerary'})}
    ];

    const newFilter = {
        itineraryId: null
    }

    const [filter, setFilter] = useState(newFilter);
    const [itineraries, setItineraries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [streets, setStreets] = useState([]);

    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseI = await streetMapService.getItineraries();
            if (responseI.status === 200) {
                setItineraries(responseI.data.itineraries);
            }
            setLoading(false);
        }
        load();
    }, []);

    async function loadStreets() {
        setLoading(true);
        const response = await streetMapService.getStreetsByItinerary(filter.itineraryId);
        if (response.status === 200) {
            setStreets(response.data.streets);
        } else {
            console.error(response);
        }
        setLoading(false);
    }

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        const value = getValueFromField(e);
        setFilter({
            ...filter,
            [e.target.name]: value
        });
    }
    const handleSearch = () => {
        loadStreets();
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections} />
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={loading} defaultExpanded>
                    <StreetListFilterForm
                        filter={filter}
                        itineraries={itineraries}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                {streets.map((street, index) => {
                    return <StreetItem street={street} key={index} />
                })}
            </Grid>
        </Grid>
    )
}

export default StreetList;