import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import * as authService from './authService';

import AccessNotAllowed from '../pages/AccessNotAllowed';


/**
 * Turns an object whose values are different arrays of routes
 * definitions objects (RDO), into a switch (react-router-dom) component.
 *
 * The available fields of RDO are:
 *   - path: string | string[]
 *   - exact: bool
 *   - strict: bool
 *   - weight: int
 *
 *   - layout: React component [for layout]
 *   - component: React component [to render]
 *   - menuSelectedItem: int [menu index item that has to be selected] //TODO: treure si no es fa servir
 *   - private: bool [if route is private or not]
 *
 * @param {Object} routes An object whose values correspond to different
 * arrays of route definition objects.
 *
 * @returns {Component} A switch component with all the routes added.
 */
export function combineRoutes(routes) {
    const routeKeys = Object.keys(routes);
    const combined = [];

    for (let i = 0; i < routeKeys.length; i++) {
        routes[routeKeys[i]].map((r) => combined.push(r));
    }

    //combined.sort(sortByWeight); TODO:

    const allRoutes = combined.map((r, k) =>
        r.private ?
            <PrivateRouteLayout
                key={k}
                path={r.path}
                exact={r.exact}
                strict={r.strict}
                component={r.component}
                layout={r.layout}
                roles={r.roles ? r.roles : null}
                menuSelectedItem={r.menuSelectedItem ? r.menuSelectedItem : null}
            />
            :
            <RouteLayout
                key={k}
                path={r.path}
                exact={r.exact}
                strict={r.strict}
                component={r.component}
                layout={r.layout}
                menuSelectedItem={r.menuSelectedItem ? r.menuSelectedItem : null}
            />
    );

    return (
        <Switch>
            {allRoutes}
        </Switch>
    )
}


/**
 * Public routes. If user is logged in and path is '/login' it redirects to '/', else it renders the component
 * @param Layout
 * @param Component
 * @param other
 * @returns {*}
 * @constructor
 */
const RouteLayout = ({ layout: Layout, component: Component, ...other }) => {
    const loginLoggedIn = other.path === '/login' && authService.isLoggedIn();

    if (loginLoggedIn) return <Redirect to={{pathname: "/"}} />;

    return (
            <Route
                {...other}
                render={
                    matchProps => (
                        <Layout menuSelectedItem={other.menuSelectedItem}>
                            <Component {...matchProps} />
                        </Layout>
                    )
                }
            />
    )
};

/**
 * Private routes. If user is logged in it renders the component, else it redirects to '/login'
 * @param Layout
 * @param Component
 * @param [] roles
 * @param other
 * @returns {*}
 * @constructor
 */
const PrivateRouteLayout = ({ layout: Layout, component: Component, roles: roles, ...other }) => {

    if (roles && !authService.userHasRole(roles)) {
        //return <Redirect to={{pathname: "/access-not-allowed"}} />
        return <Layout menuSelectedItem={undefined}><AccessNotAllowed /></Layout>
    }

    return (
        <Route
            {...other}
            render={
                matchProps => (
                    authService.isLoggedIn() ?
                        <Layout menuSelectedItem={other.menuSelectedItem}>
                            <Component {...matchProps} />
                        </Layout>
                        :
                        <Redirect to={{pathname: "/login"}} />
                )
            }
        />
    )
};
