import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as arrayHelper from '../../../../helpers/array';
import * as cageService from '../../../../services/domain/warehouse/cageService';
import * as productService from '../../../../services/domain/warehouse/productService';
import * as productSituationService from '../../../../services/domain/warehouse/productSituationService';
import * as purchaseOrderService from '../../../../services/domain/warehouse/purchaseOrderService';
import * as validateService from "../../../../services/validateService";
import * as validator from "../../../../helpers/validator";
import * as factoryService from '../../../../services/domain/warehouse/factoryService';
import * as warehouseService from '../../../../services/domain/warehouse/warehouseService';
import { getValueFromField } from '../../../../helpers/form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import DeleteIcon from '../../../../components/icons/Delete';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Loader from '../../../../components/Loader/Loader';
import Modal from "../../../../components/Modal/Modal";
import PrintButton from "../../../../components/form/Button/print";
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";

import PurchaseOrderForm from './PurchaseOrderForm';
import AddProductForm from '../../management/products/AddProductForm';
import CageForm from "./CageForm";



export const PurchaseOrder = (props) => {

    const intl = useIntl();

    const newPO = {
        id: null,
        number: null,
        reference: null,
        factoryId: null,
        date: moment().format('YYYY-MM-DD'),
        warehouseId: null,
        agencyId: null,
        obs: null,
        canceled: 0,
        products: [],
        cages: [],
        agencyProducts: [],
        createdBy: "tmp" //TODO: eliminar cuando el created by se coja del token
    };
    const poValidations = {
        reference: [{type: validateService.REQUIRED}],
        agencyId: [{type: validateService.REQUIRED}, {type: validateService.AGENCY}],
        warehouseId: [{type: validateService.REQUIRED}],
    };
    const newProduct = {
        productId: null,
        productSituationId: null,
        containersGiven: 0,
        containersReceived: 0,
    };
    const productValidations = {
        productId: [{type: validateService.REQUIRED}],
        containersGiven: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        containersReceived: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    };
    const newCage = {
        cageId: null,
        unitsGiven: 0,
        unitsReceived: 0
    };
    const cageValidations = {
        cageId: [{type: validateService.REQUIRED}],
        unitsGiven: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        unitsReceived: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    };


    const [errors, setErrors] = useState({});
    const [errorsC, setErrorsC] = useState({});
    const [productErrors, setProductErrors] = useState({});
    const [dataC, setDataC] = useState([]);
    const [dataP, setDataP] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [openC, setOpenC] = useState(false);
    const [po, setPO] = useState(newPO);
    const [readOnly, setReadOnly] = useState(false); //TODO: mirar si el expe esta cerrado
    const [refreshGrids, setRefreshGrids] = useState(0);
    const [product, setProduct] = useState(newProduct);
    const [products, setProducts] = useState([]);
    const [productSituations, setProductSituations] = useState(productSituationService.getProductSituations());
    const [warehouses, setWarehouses] = useState([]);
    const [factories, setFactories] = useState([]);
    const [cage, setCage] = useState(newCage);
    const [cages, setCages] = useState([]);



    useEffect(() => {
        const number = props.match.params.number;

        //TODO: hacer get para traer sólo la PO que se consulta
        async function loadPO() {
            setLoading(true);
            const response = await purchaseOrderService.listPurchaseOrders();
            if (response.status === 200) {
                const po = arrayHelper.getObjectByKey(response.data.purchaseOrders, 'number', number);
                if (po !== null) {
                    po.warehouseId = po.warehouse.id;
                    po.factoryId = po.factory != null ? po.factory.id : null;
                    setPO(po);
                    setReadOnly(!validator.isToday(po.date) || validator.isTrue(po.canceled));
                } else {
                    setPO(newPO);
                    props.errorMessage(intl.formatMessage({id: 'app.error.code.NOT_FOUND'}));
                    props.history.push('/purchase-orders/new');
                }
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
        async function load() {
            const responseC = await cageService.getCages();
            const responseP = await productService.getProducts();
            const responseF = await factoryService.listFactories();
            const responseW = await warehouseService.listWarehouses();
            if (responseC.status === 200 && responseP.status === 200 && responseF.status === 200 && responseW.status === 200) {
                setCages(responseC.data.cages);
                setProducts(responseP.data.products);
                setFactories(responseF.data.factories);
                setWarehouses(responseW.data.warehouses);
                setLoading(false);
                if (number === 'new') {
                    setPO(newPO);
                } else if (props.location.state && props.location.state.po && props.location.state.po.number === number) {
                    const po = props.location.state.po;
                    po.warehouseId = po.warehouse.id;
                    po.factoryId = po.factory != null ? po.factory.id : null;
                    setPO(po);
                    setReadOnly(!validator.isToday(props.location.state.po.date) || validator.isTrue(props.location.state.po.canceled));
                } else {
                    loadPO();
                }
            } else {}
        }
        load();
    }, []);

    useEffect(() => {
        setDataP(po.products.map(item => convertProductRowData(item)));
        setDataC(po.cages.map(item => convertCageRowData(item)));
    }, [po, refreshGrids]);


    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.po'})}
    ];

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    /** po */
    const handleNew = () => {
        setPO(newPO);
        setReadOnly(false);
        props.history.push('/purchase-orders/new');
    };
    const handleDelete = () => {
        const response = purchaseOrderService.cancelPurchaseOrder(po);
        response
            .then(() => {
                setReadOnly(true);
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {
                props.errorMessage('Error');
                console.error(err.response)
            });
    };
    const handleChange = (e) => {
        setPO({
            ...po,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSubmit = () => {
        const err = validateService.validate(po, poValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            if (isReadyForSave()) {
                if (po.id === null) {
                    const response = purchaseOrderService.addPurchaseOrder(po);
                    setLoading(true);

                    response
                        .then(resp => {
                            setPO({
                                ...po,
                                id: resp.data.id,
                                number: resp.data.number
                            });
                            setReadOnly(true);
                            props.successMessage(intl.formatMessage({id: "generic.saved"}));
                        })
                        .catch(err => {
                            props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                            console.error(err.response);
                        })
                        .finally(() => setLoading(false));
                }
            }
        }
    };

    const handleDownload = () => {
        purchaseOrderService.downloadPurchaseOrders(po);
    }

    const isReadyForSave = () => {
        if (po.products.length === 0) {
            const items = intl.formatMessage({id: "generic.domain.products"});
            props.warningMessage(intl.formatMessage({id: "app.error.code.EMPTY"}, {items: items}));
            return false;
        }
        if (po.cages.length === 0) {
            const items = intl.formatMessage({id: "generic.domain.cages"});
            props.warningMessage(intl.formatMessage({id: "app.error.code.EMPTY"}, {items: items}));
            return false;
        }
        let hasError = false;
        po.products.map(product => {
            const isIntercambio = productSituationService.isIntercambioSituationById(product.productSituationId);
            const isAlta = productSituationService.isAltaSituationById(product.productSituationId);
            const isBaja = productSituationService.isBajaSituationById(product.productSituationId);
            const isCanje = productSituationService.isCanjeSituationById(product.productSituationId);
            if (
                //(isIntercambio && product.containersReceived !== product.containersGiven) ||
                (isAlta && product.containersGiven !== 0) ||
                //(isCanje && product.containersReceived !== 0) ||
                (isBaja && product.containersReceived !== 0)
            ) {
                props.errorMessage(intl.formatMessage({id: "app.error.domain.INCORRECT_VALUE_OF_CONTAINERS_GIVEN_OR_RECEIVED"}));
                hasError = true;
            }
        });
        return !hasError;
    }

    /** products */
    const existProduct = () => {
        return po.products.filter(item => {
            const sameProduct = item.productId === product.productId;
            const sameSituation = item.productSituationId === product.productSituationId;
            return sameProduct && sameSituation;
        }).length > 0;
    };
    const handleAddProduct = () => {
        if (!existProduct()) {
            po.products.push(product);
            setProduct(newProduct);
            setRefreshGrids(refreshGrids + 1);
        }
    };
    const handleChangeProduct = (e) => {
        const value = getValueFromField(e);
        switch (e.target.name) {
            case "productSituationId":
                setProduct({
                    ...product,
                    containersGiven: 0,
                    containersReceived: 0,
                    productSituationId: value
                });
                break;
            case "containersGiven":
                if (
                    productSituationService.isIntercambioSituationById(product.productSituationId) ||
                    productSituationService.isCanjeSituationById(product.productSituationId)
                )
                    setProduct({
                        ...product,
                        containersGiven: value,
                        containersReceived: value,
                    });
                else if (
                    productSituationService.isBajaSituationById(product.productSituationId)
                )
                    setProduct({
                        ...product,
                        containersGiven: value,
                        containersReceived: 0,
                    });
                break;
            case "containersReceived":
                if (
                    productSituationService.isIntercambioSituationById(product.productSituationId)
                )
                    setProduct({
                        ...product,
                        containersGiven: value,
                        containersReceived: value,
                    });
                else
                if (
                    productSituationService.isAltaSituationById(product.productSituationId)
                )
                    setProduct({
                        ...product,
                        containersGiven: 0,
                        containersReceived: value,
                    });
                break;
            default:
                setProduct({
                    ...product,
                    [e.target.name]: value
                });
        }
    };
    const handleSubmitProduct = () => {
        const err = validateService.validate(product, productValidations);
        setProductErrors(err);
        if (!validateService.hasErrors(err)) {
            handleAddProduct();
        }
    };

    const handleDeleteProduct = (item) => {
        po.products = po.products.filter(product => {
            const sameProduct = item.productId === product.productId;
            const itemProductSituation = item.productSituation ? item.productSituation.id : null;
            const productProductSituation = product.productSituation ? product.productSituation.id : null;

            return !sameProduct || (sameProduct && itemProductSituation !== productProductSituation);
        });
        setRefreshGrids(refreshGrids + 1);
    };

    /** cages */
    const handleOpenC = () => {
        setOpenC(true);
    }
    const handleCloseC = () => {
        setOpenC(false);
    }

    const existsCage = () => {
        return po.cages.filter(item => {
            return item.cageId === cage.cageId
        }).length > 0;
    }
    const handleChangeCage = (e) => {
        switch (e.target.name) {
            case 'unitsGiven':
                setCage({
                    ...cage,
                    unitsGiven: getValueFromField(e),
                    unitsReceived: getValueFromField(e)
                });
                break;
            default:
                setCage({
                    ...cage,
                    [e.target.name]: getValueFromField(e)
                });
        }
    }
    const handleSubmitCage = () => {
        const err = validateService.validate(cage, cageValidations);
        setErrorsC(err);
        if (!validateService.hasErrors(err)) {
            if (!existsCage()) {
                po.cages.push(cage);
                setCage(newCage);
                setRefreshGrids(refreshGrids + 1);
            }
        }
    }
    const handleDeleteCage = (item) => {
        po.cages = po.cages.filter(cage => {
            return cage.cageId !== item.cageId
        });
        setRefreshGrids(refreshGrids + 1);
    }

    const pHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.containers.given"}), sublabel: null, formatter: null, width: 200 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.containers.received"}), sublabel: null, formatter: null, width: 200 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null, width: 200 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertProductRowData = item => {
        const product = arrayHelper.getObjectById(products, item.productId);
        const ps = item.productSituation ?
            item.productSituation.type :
            item.productSituationId  ?
                item.productSituationId !== null ?
                    arrayHelper.getObjectById(productSituations, item.productSituationId).type :
                    ""
            : "";
        return {
            id1: product.name,
            id2: item.containersGiven,
            id3: item.containersReceived,
            id4: ps,
            actions: <DeleteIcon button onClick={() => handleDeleteProduct(item)} />
        }
    };

    const convertCageRowData = item => {
        const id = item.cage ? item.cage.id : item.cageId;
        const cage = cages.getObjectById(id);

        return {
            id1: item.cage ? item.cage.name : cage.name,
            id2: item.unitsGiven,
            id3: item.unitsReceived,
            actions: <DeleteIcon button onClick={() => handleDeleteCage(item)} />
        }
    };

    const cHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.cage"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.given.plural.fem"}), sublabel: null, formatter: null, width: 200 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.received.plural.fem"}), sublabel: null, formatter: null, width: 200 },
        { id: 'actions', align: "center", width: 60 },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            {loading && (
                <Grid item xs={12}><Loader/></Grid>
            )}
            {!loading && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <PurchaseOrderForm
                            po={po}
                            warehouses={warehouses}
                            factories={factories}
                            errors={errors}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <RightBox>
                            <Button color="primary" float size="small" onClick={handleOpen} disabled={readOnly}><AddIcon color="white"/></Button>
                        </RightBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            headers={pHeaders}
                            data={dataP}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <RightBox>
                            <Button color="primary" float size="small" onClick={handleOpenC} disabled={readOnly}><AddIcon color="white"/></Button>
                        </RightBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            headers={cHeaders}
                            data={dataC}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <RightBox>
                            <Box display="flex">
                                <Box padding={1}>
                                    <RouterLink to={{pathname: `/purchase-orders`, state: {back: true}}}>
                                        <Button color="primary" variant="contained" size="small">{intl.formatMessage({id: 'generic.back'})}</Button>
                                    </RouterLink>
                                </Box>
                                <Box padding={1}>
                                    <PrintButton
                                        disabled={po.id === null}
                                        onClick={handleDownload}
                                    />
                                </Box>
                                <Box padding={1}>
                                    <Button color="primary" variant="contained" size="small" onClick={handleNew}>{intl.formatMessage({id: 'generic.new.fem'})}</Button>
                                </Box>
                                <Box padding={1}>
                                    <Button color="primary" variant="contained" size="small" onClick={handleDelete} disabled={readOnly || po.id === null}>{intl.formatMessage({id: 'generic.delete'})}</Button>
                                </Box>
                                <Box padding={1}>
                                    <Button color="primary" variant="contained" size="small" onClick={handleSubmit} disabled={readOnly}>{intl.formatMessage({id: 'generic.save'})}</Button>
                                </Box>
                            </Box>
                        </RightBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Modal open={open} title={intl.formatMessage({id: 'generic.domain.product'})} onClose={handleClose} width={400} height={350}>
                            <AddProductForm
                                product={product}
                                products={products}
                                productSituationItems={productSituationService.getProductSituationItems()}
                                errors={productErrors}
                                onClose={handleClose}
                                onChange={handleChangeProduct}
                                onSubmit={handleSubmitProduct}
                            />
                        </Modal>
                        <Modal open={openC} title={intl.formatMessage({id: 'generic.domain.cage'})} onClose={handleCloseC} width={400} height={250}>
                            <CageForm
                                cage={cage}
                                cages={cages}
                                errors={errorsC}
                                onClose={handleCloseC}
                                onChange={handleChangeCage}
                                onSubmit={handleSubmitCage}
                            />
                        </Modal>
                    </Grid>
                </React.Fragment>
        )}
        </Grid>
    )
};

export default withMessageBox(PurchaseOrder);
