import React from 'react';
import { withSnackbar } from 'notistack';


const withMessageBox = WrappedComponent => {

    class withMessageBox extends React.Component {
        constructor(props) {
            super(props);

            this.message = this.message.bind(this);
            this.successMessage = this.successMessage.bind(this);
            this.infoMessage = this.infoMessage.bind(this);
            this.warningMessage = this.warningMessage.bind(this);
            this.errorMessage = this.errorMessage.bind(this);
        }

        message(message, persist = false) {
            this.enqueue(message, 'default', persist);
        }

        successMessage(message, persist = false) {
            this.enqueue(message, 'success', persist);
        }

        infoMessage(message, persist = false) {
            this.enqueue(message, 'info', persist);
        }

        warningMessage(message, persist = false) {
            this.enqueue(message, 'warning', persist);
        }

        errorMessage(message, persist = false) {
            this.enqueue(message, 'error', persist);
        }

        enqueue(message, variant, persist = false) {
            this.props.enqueueSnackbar(
                message, {
                    variant: variant,
                    persist: persist
                });
        }

        render() {
            return (
                <WrappedComponent
                    message={this.message}
                    successMessage={this.successMessage}
                    infoMessage={this.infoMessage}
                    warningMessage={this.warningMessage}
                    errorMessage={this.errorMessage}
                    {...this.props}
                />
            )
        }
    }

    return withSnackbar(withMessageBox);
};

export default withMessageBox;
