import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';


export const getCompanies = async () => {
    return await apiService.get(`${URL}/companies`);
};

export const addCompany = async (data) => {
    return await apiService.post(`${URL}/companies`, data);
};

export const updateCompany = async (id, data) => {
    return await apiService.put(`${URL}/companies/${id}`, data);
};

//TODO: hacer la request
export const getCompanyTypeItems = () => {
    return [
        {value: 1, label: "Autónomo"},
        {value: 2, label: "Cooperativa"},
        {value: 3, label: "Sociedad Limitada"},
    ]
};
