import React from 'react';

import LocationOnIcon from '@material-ui/icons/LocationOn';

import Chip from '../../../../components/Chip';


const ZoneChip = (props) => {
    return (
        <Chip
            icon={<LocationOnIcon/>}
            label={props.label}
            onDelete={props.onDelete}
            variant="outlined"
        />
    )
};

export default ZoneChip;
