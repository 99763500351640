import React from 'react';
import PropTypes from 'prop-types';

import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';



const Alert = (props) => {
    const {title = null, children, ...otherProps} = props;

    return (
        <MuiAlert {...otherProps}>
            {title !== null && (<MuiAlertTitle>{title}</MuiAlertTitle>)}
            {children}
        </MuiAlert>
    )
};

Alert.propTypes = {
    severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    title: PropTypes.string,
};

export default Alert;
