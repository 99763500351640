import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Loader from "../../../../components/Loader/Loader";
import Table from '../../../../components/table';



export const WarehouseStockTab = (props) => {
    const {stock, isLoading} = props;

    const intl = useIntl();

    const [headersP, setHeadersP] = useState([]);
    const [dataP, setDataP] = useState([]);
    const [headersC, setHeadersC] = useState([]);
    const [dataC, setDataC] = useState([]);

    useEffect(() => {
        buildHeadersP();
        buildHeadersC();
        let index = 1;
        const products = [];
        const cages = [];
        const rowsP = [];
        const rowsC = [];

        stock.map(ws => {
            let indexP = 0;
            ws.products.map(wsp => {
                if (!products[wsp.product.name]) {
                    products[wsp.product.name] = {containers: wsp.stock.containers, charges : wsp.stock.charges};
                    const row = {id1: wsp.product.name, id2: wsp.stock.containers, id3: wsp.stock.charges};
                    if (stock.length === 1) row['id4'] = wsp.stock.containers - wsp.stock.charges;
                    rowsP.push(row);
                } else {
                    products[wsp.product.name].containers += wsp.stock.containers;
                    products[wsp.product.name].charges += wsp.stock.charges;
                    let keys = Object.keys(rowsP[indexP]).length;
                    rowsP[indexP]["id"+(keys+1)] = wsp.stock.containers;
                    rowsP[indexP]["id"+(keys+2)] = wsp.stock.charges;
                }
                indexP ++;
            });

            let indexC = 0;
            ws.cages.map(wsc => {
                const key = wsc.cage.name.replace(" ", "");
                if (!cages[key]) {
                    cages[key] = {units: wsc.stock.units};
                    rowsC.push({id1: wsc.cage.name, id2: wsc.stock.units});
                } else {
                    cages[key].units += wsc.stock.units;
                    let keys = Object.keys(rowsC[indexC]).length;
                    rowsC[indexC]["id"+(keys+1)] = wsc.stock.units;
                }
                indexC ++;
            })
            index ++;
        });

        for (let i = 0; i < rowsP.length; i++) {
            let productName = rowsP[i].id1;
            let keys = Object.keys(rowsP[i]).length;
            rowsP[i]["id"+(keys+1)] = products[productName].containers;
            rowsP[i]["id"+(keys+2)] = products[productName].charges;
        }
        for (let i = 0; i < rowsC.length; i++) {
            let name = rowsC[i].id1.replace(" ", "");
            let keys = Object.keys(rowsC[i]).length;
            rowsC[i]["id"+(keys+1)] = cages[name].units;
        }
        setDataP(rowsP);
        setDataC(rowsC);
    }, [stock]);

    const buildHeadersP = () => {
        let label;
        const bHeaders = [
            { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), formatter: null },
        ];
        let index = 1;
        for (let i = 0; i < stock.length; i++) {
            label = stock[i].warehouse.name + " [" + stock[i].agency.id + "]"
            index ++;
            bHeaders.push({ id: 'id' + index, align: "left", label: label , sublabel: intl.formatMessage({id:"generic.domain.containers"}), formatter: null });
            index ++;
            bHeaders.push({ id: 'id' + index, align: "left", label: label , sublabel: intl.formatMessage({id:"generic.domain.charges"}), formatter: null });
        }
        if (stock.length === 1) {
            index ++;
            bHeaders.push({ id: 'id' + index, align: "left", label: label  , sublabel: intl.formatMessage({id:"generic.domain.containers.empty"}), formatter: null });
        } else {
            index ++;
            bHeaders.push({ id: 'id' + index, align: "left", label: intl.formatMessage({id:"generic.total"}) , sublabel: intl.formatMessage({id:"generic.domain.containers"}), formatter: null });
            index ++;
            bHeaders.push({ id: 'id' + index, align: "left", label: intl.formatMessage({id:"generic.total"}) , sublabel: intl.formatMessage({id:"generic.domain.charges"}), formatter: null });
        }
        setHeadersP(bHeaders);
    }

    const buildHeadersC = () => {
        const bHeaders = [
            { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.cage"}), formatter: null },
        ];
        let index = 1;
        for (let i = 0; i < stock.length; i++) {
            let label = stock[i].warehouse.name + " [" + stock[i].agency.id + "]"
            index ++;
            bHeaders.push({ id: 'id' + index, align: "left", label: label , sublabel: intl.formatMessage({id:"generic.domain.units"}), formatter: null });
        }
        if (stock.length > 1) {
            index ++;
            bHeaders.push({ id: 'id' + index, align: "left", label: intl.formatMessage({id:"generic.total"}) , sublabel: intl.formatMessage({id:"generic.domain.units"}), formatter: null });
        }
        setHeadersC(bHeaders);
    }

    if (isLoading) return (<Loader />)

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Table
                    headers={headersP}
                    data={dataP}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
            <Grid item xs={12}><br/><br/></Grid>
            <Grid item xs={12}>
                <Table
                    headers={headersC}
                    data={dataC}
                    isLoading={isLoading}
                    isExportable
                />
            </Grid>
        </Grid>
    )
}

export default WarehouseStockTab;