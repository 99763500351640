import React, { useEffect, useState } from 'react';
import {Link as RouterLink} from "react-router-dom";
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import * as cageService from '../../../../services/domain/warehouse/cageService';
import * as productService from '../../../../services/domain/warehouse/productService';
import * as warehouseService from "../../../../services/domain/warehouse/warehouseService";
import * as warehouseMovementsService from "../../../../services/domain/warehouse/warehouseMovementsService";
import * as validateService from "../../../../services/validateService";
import * as arrayHelper from "../../../../helpers/array";
import {getValueFromField} from "../../../../helpers/form";
import * as validator from "../../../../helpers/validator";

import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import DeleteIcon from '../../../../components/icons/Delete';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Loader from '../../../../components/Loader/Loader';
import Modal from "../../../../components/Modal/Modal";
import PrintButton from "../../../../components/form/Button/print";
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";

import WarehouseMovementForm from "./WarehouseMovementForm";
import CageForm from "./CageForm";
import ProductForm from "./ProductForm";



export const WarehouseMovement = (props) => {
    const intl = useIntl();

    const newMovement = {
        id: null,
        number: null,
        date: null,
        warehouseId: null,
        warehouse: null,
        movementType: null,
        movementTypeId: null,
        obs: null,
        canceled: 0,
        products: [],
        cages: [],
    }
    const movementValidations = {
        warehouseId: [{type: validateService.REQUIRED}],
        movementTypeId: [{type: validateService.REQUIRED}],
        obs: [{type: validateService.REQUIRED}],
    }
    const newProduct = {
        productId: null,
        containers: 0,
        charges: 0,
    };
    const productValidations = {
        productId: [{type: validateService.REQUIRED}],
        containers: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_NON_ZERO}],
        charges: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
    };
    const newCage = {
        cageId: null,
        units: 0,
    };
    const cageValidations = {
        cageId: [{type: validateService.REQUIRED}],
        units: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_NON_ZERO}],
    };

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.warehouse'})}
    ];

    const [cage, setCage] = useState(newCage);
    const [cages, setCages] = useState([]);
    const [dataC, setDataC] = useState([]);
    const [dataP, setDataP] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorsP, setErrorsP] = useState({});
    const [errorsC, setErrorsC] = useState({});
    const [loading, setLoading] = useState(false);
    const [openP, setOpenP] = useState(false);
    const [openC, setOpenC] = useState(false);
    const [product, setProduct] = useState(newProduct);
    const [products, setProducts] = useState([]);
    const [movement, setMovement] = useState(newMovement);
    const [readOnly, setReadOnly] = useState(false); //TODO: mirar si el expe esta cerrado
    const [refreshGrids, setRefreshGrids] = useState(0);
    const [warehouseMovementTypes, setWarehouseMovementTypes] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        const number = props.match.params.number;

        //TODO: hacer get para traer sólo el mov que se consulta
        async function loadMov() {
            setLoading(true);
            const response = await warehouseMovementsService.list();
            if (response.status === 200) {
                const obj = arrayHelper.getObjectByKey(response.data.warehouseMovements, 'number', number);
                if (obj !== null) {
                    obj.warehouseId = obj.warehouse.id;
                    obj.movementTypeId = obj.movementType.id;
                    setMovement(obj);
                    setReadOnly(!validator.isToday(obj.date) || validator.isTrue(obj.canceled));
                } else {
                    setMovement(newMovement);
                    props.errorMessage(intl.formatMessage({id: 'app.error.code.NOT_FOUND'}));
                    props.history.push('/warehouse-movements/new');
                }
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        async function load() {
            setLoading(true);
            const responseW = await warehouseService.listWarehouses();
            const responseT = await warehouseMovementsService.listTypes();
            const responseC = await cageService.getCages();
            const responseP = await productService.getProducts();
            if (responseW.status === 200 && responseT.status === 200 && responseC.status === 200 && responseP.status === 200) {
                setWarehouses(responseW.data.warehouses);
                setWarehouseMovementTypes(responseT.data.warehouseMovementTypes);
                setCages(responseC.data.cages);
                setProducts(responseP.data.products);
                setLoading(false);
                if (number === 'new') {
                    setMovement(newMovement);
                } else if (props.location.state && props.location.state.item && props.location.state.item.number === number) {
                    const item = props.location.state.item;
                    item.warehouseId = item.warehouse.id;
                    item.movementTypeId = item.movementType.id;
                    setMovement(item);

                    //TODO: habilitar cuando esté habilitado el modificar
                    //setReadOnly(!validator.isToday(props.location.state.item.date) || validator.isTrue(props.location.state.item.canceled));
                    setReadOnly(true);
                } else {
                    loadMov();
                }
            }
        }
        load();
    }, []);

    useEffect(() => {
        setDataP(movement.products.map(item => convertProductRowData(item)));
        setDataC(movement.cages.map(item => convertCageRowData(item)));
    }, [movement, refreshGrids]);

    const handleOpenC = () => {
        setOpenC(true);
    };
    const handleOpenP = () => {
        setOpenP(true);
    };
    const handleClose = () => {
        setOpenP(false);
        setOpenC(false);
    };

    /** movement */
    const handleNew = () => {
        setMovement(newMovement);
        setReadOnly(false);
        props.history.push('/warehouse-movements/new');
    }
    const handleDelete = () => {
        //TODO:
    }
    const handleChange = (e) => {
        setMovement({
            ...movement,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSubmit = () => {
        const err = validateService.validate(movement, movementValidations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            if (isReadyForSave()) {
                const response = warehouseMovementsService.create(movement);
                response
                    .then((resp) => {
                        setReadOnly(true);
                        setMovement({
                            ...movement,
                            id: resp.data.id,
                            number: resp.data.number
                        });
                        props.successMessage(intl.formatMessage({id: "generic.saved"}));
                    })
                    .catch(err => {
                        props.errorMessage(err.response.data.message ? err.response.data.message : "Error");
                        console.error(err.response);
                    });
            }
        }
    }
    const isReadyForSave = () => {
        if (movement.products.length === 0 && movement.cages.length === 0) {
            const items = intl.formatMessage({id: "generic.domain.productsOrCages"});
            props.warningMessage(intl.formatMessage({id: "app.error.code.EMPTY"}, {items: items}));
            return false;
        }
        return true;
    }

    /** products */
    const existProduct = () => {
        return movement.products.filter(item => {
            const sameProduct = item.productId === product.productId;
            const sameSituation = item.productSituationId === product.productSituationId;
            return sameProduct && sameSituation;
        }).length > 0;
    }
    const handleAddProduct = () => {
        if (!existProduct()) {
            movement.products.push(product);
            setProduct(newProduct);
            setRefreshGrids(Date.now());
        }
    }
    const handleChangeProduct = (e) => {
        setProduct({
            ...product,
            [e.target.name]: getValueFromField(e)
        });
    }
    const handleSubmitProduct = () => {
        const err = validateService.validate(product, productValidations);
        setErrorsP(err);
        if (!validateService.hasErrors(err)) {
            handleAddProduct();
        }
    }
    const handleDeleteProduct = (item) => {
        movement.products = movement.products.filter(product => {
            const sameProduct = item.productId === product.productId;
            return !sameProduct;
        });
        setRefreshGrids(Date.now());
    }

    /** cages */
    const existsCage = () => {
        return movement.cages.filter(item => {
            return item.cageId === cage.cageId
        }).length > 0;
    }
    const handleChangeCage = (e) => {
        setCage({
            ...cage,
            [e.target.name]: getValueFromField(e)
        });
    }
    const handleSubmitCage = () => {
        const err = validateService.validate(cage, cageValidations);
        setErrorsC(err);
        if (!validateService.hasErrors(err)) {
            if (!existsCage()) {
                movement.cages.push(cage);
                setCage(newCage);
                setRefreshGrids(Date.now());
            }
        }
    }
    const handleDeleteCage = (item) => {
        movement.cages = movement.cages.filter(cage => {
            return cage.cageId !== item.cageId
        });
        setRefreshGrids(Date.now());
    }

    const handleDownload = () => {
        warehouseMovementsService.download({
            id: movement.id,
            number: movement.number
        });
    }

    const pHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.containers"}), sublabel: null, formatter: null, width: 200 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.charges"}), sublabel: null, formatter: null, width: 200 },
        { id: 'actions', align: "center", width: 60 },
    ];
    const convertProductRowData = item => {
        const product = item.product ? item.product = item.product :
            arrayHelper.getObjectById(products, item.productId);
        return {
            id1: product.name,
            id2: item.containers,
            id3: item.charges,
            actions: <DeleteIcon button onClick={() => handleDeleteProduct(item)} />
        }
    }
    const cHeaders = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.cage"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.units"}), sublabel: null, formatter: null, width: 200 },
        { id: 'actions', align: "center", width: 60 },
    ];
    const convertCageRowData = item => {
        const cage = item.cage ? item.cage :
            arrayHelper.getObjectById(cages, item.cageId);
        return {
            id1: item.cage ? item.cage.name : cage.name,
            id2: item.units,
            actions: <DeleteIcon button onClick={() => handleDeleteCage(item)} />
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            {loading && (
                <Grid item xs={12}><Loader/></Grid>
            )}
            {!loading && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <WarehouseMovementForm
                            movement={movement}
                            errors={errors}
                            warehouses={warehouses}
                            warehouseMovementTypes={warehouseMovementTypes}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <RightBox>
                            <Button color="primary" float size="small" onClick={handleOpenP} disabled={readOnly}><AddIcon color="white"/></Button>
                        </RightBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            headers={pHeaders}
                            data={dataP}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <RightBox>
                            <Button color="primary" float size="small" onClick={handleOpenC} disabled={readOnly}><AddIcon color="white"/></Button>
                        </RightBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            headers={cHeaders}
                            data={dataC}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        <RightBox>
                            <Box display="flex">
                                <Box padding={1}>
                                    <RouterLink to={{pathname: `/warehouse-movements`, state: {back: true}}}>
                                        <Button color="primary" variant="contained" size="small">{intl.formatMessage({id: 'generic.back'})}</Button>
                                    </RouterLink>
                                </Box>
                                <Box padding={1}>
                                    <PrintButton
                                        onClick={handleDownload}
                                        disabled={movement.id === null}
                                    />
                                </Box>
                                <Box padding={1}>
                                    <Button color="primary" variant="contained" size="small" onClick={handleNew}>{intl.formatMessage({id: 'generic.new.male'})}</Button>
                                </Box>
                                <Box padding={1}>
                                    <Button color="primary" variant="contained" size="small" onClick={handleDelete} disabled={true}>{intl.formatMessage({id: 'generic.delete'})}</Button>
                                </Box>
                                <Box padding={1}>
                                    <Button color="primary" variant="contained" size="small" onClick={handleSubmit} disabled={readOnly}>{intl.formatMessage({id: 'generic.save'})}</Button>
                                </Box>
                            </Box>
                        </RightBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Modal open={openP} title={intl.formatMessage({id: 'generic.domain.product'})} onClose={handleClose} width={400} height={350}>
                            <ProductForm
                                product={product}
                                products={products}
                                errors={errorsP}
                                onClose={handleClose}
                                onChange={handleChangeProduct}
                                onSubmit={handleSubmitProduct}
                            />
                        </Modal>
                        <Modal open={openC} title={intl.formatMessage({id: 'generic.domain.cage'})} onClose={handleClose} width={400} height={350}>
                            <CageForm
                                cage={cage}
                                cages={cages}
                                errors={errorsC}
                                onClose={handleClose}
                                onChange={handleChangeCage}
                                onSubmit={handleSubmitCage}
                            />
                        </Modal>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    )
}

export default withMessageBox(WarehouseMovement);
