import React, {useState} from 'react';
import { useIntl } from 'react-intl';
import withMessageBox from '../../hocs/withMessageBox';

import * as formatter from "../../helpers/formatter";
import * as orderService from '../../services/domain/agency/orderService';

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from '../../components/Breadcrumbs/index';
import Filter from "../../components/Filter/Filter";
import EnabledIcon from "../../components/icons/Enabled";
import ExportButton from "../../components/form/Button/export";
import Table from '../../components/table';
import {RightBox} from "../../components/Box";

import {getValueFromField} from "../../helpers/form";
import OrderListFilterForm from "./OrderListFilterForm";


export const OrderList = (props) => {

    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.orders'})},
    ];

    const newFilter = {
        startDate: formatter.dateApiFormatter(),
        startTime: null,
        endDate: formatter.dateApiFormatter(),
        endTime: null,
        id: null,
        contractNumber: null,
        host: null
    }

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    const headers = [
        { id: 'id1', align: "left", label: intl.formatMessage({id:"generic.date"}), sublabel: null, formatter: formatter.dateTimeFormatter, width: 130 },
        { id: 'id2', align: "left", label: intl.formatMessage({id:"generic.domain.agency.order.id"}), sublabel: null, formatter: null, width: 80 },
        { id: 'id3', align: "left", label: intl.formatMessage({id:"generic.domain.agency.order"}), sublabel: null, formatter: null, width: 80, sortable: false },
        { id: 'id4', align: "left", label: intl.formatMessage({id:"generic.domain.agency.contract"}), sublabel: null, formatter: null, width: 90 },
        { id: 'id5', align: "left", label: intl.formatMessage({id:"generic.domain.agency.name"}), sublabel: null, formatter: null },
        { id: 'id6', align: "left", label: intl.formatMessage({id:"generic.domain.agency.address"}), sublabel: null, formatter: null },
        { id: 'id7', align: "left", label: intl.formatMessage({id:"generic.domain.agency.tf"}), sublabel: null, formatter: null, width: 80 },
        { id: 'id8', align: "center", label: intl.formatMessage({id:"generic.canceled2.male"}), sublabel: null, formatter: (val) => <EnabledIcon value={val} hideOnFalse />, width: 50 },
    ];


    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }

    const convertRowData = item => {
        const address = `${item.contract.address.street.name}, ${item.contract.address.number} (${item.contract.address.street.city})`
        return ({
            id1: item.createdAt,
            id2: item.id,
            id3: `${item.quantity} - ${item.product}`,
            id4: item.contractNumber,
            id5: item.contract.customer.name,
            id6: address,
            id7: item.telefon,
            id8: item.cancelled
        });
    }

    const handleSearch = () => {
        setIsLoading(true);
        const response = orderService.getOrders(filter);
        response
            .then((res) => {
                setData(res.data.orders.map(order => convertRowData(order)))
            })
            .catch(err => {
                props.errorMessage('Error');
                console.error(err.response);
            })
            .finally(() => setIsLoading(false));
    }

    const handleExport = async () => {
        setIsExporting(true);
        const response = orderService.exportOrdersNace(filter);
        response
            .then(res => {

            })
            .catch(err => {
                //TODO: error
                props.errorMessage('Error al exportar');
                console.error("Error al exportar", err);
            })
            .finally(() => setIsExporting(false));
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Filter defaultExpanded onSearch={handleSearch} onClear={handleFilterClear} isLoading={isLoading}>
                    <OrderListFilterForm
                        filter={filter}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
            </Grid>
            <Grid item xs={12}>
                <br />
                <RightBox>
                    <ExportButton isLoading={isExporting} onClick={handleExport} />
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={isLoading}
                    isExportable
                    ordered
                />
            </Grid>
        </Grid>
    )
};

export default withMessageBox(OrderList);
