import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from "../../../../hocs/withMessageBox";

import * as accountingService from "../../../../services/domain/warehouse/accountingService";
import * as validateService from "../../../../services/validateService";
import * as formatter from '../../../../helpers/formatter';
import {getValueFromField} from "../../../../helpers/form";

import Grid from '@material-ui/core/Grid';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import PaymentForm from "./PaymentForm";




export const Payment = (props) => {

    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.accounting'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.accounting.payments'})}
    ];

    const newPayment = {
        id: null,
        date: formatter.now(),
        amount: 0,
        description: null,
    }

    const validations = {
        date: [{type: validateService.REQUIRED}],
        amount: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_THAN_ZERO}],
        description: [{type: validateService.REQUIRED}],
    }

    const [errors, setErrors] = useState({});
    const [payment, setPayment] = useState(newPayment);


    const handleChange = (e) => {
        e.preventDefault();
        setPayment({
            ...payment,
            [e.target.name]: getValueFromField(e)
        })
    };
    const handleSubmit = () => {
        const err = validateService.validate(payment, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            const response = accountingService.createPayment({
                ...payment,
                date: formatter.dateTimeApiFormatter(payment.date)
            });
            response
                .then(() => {
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                    setPayment(newPayment);
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                });
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <PaymentForm
                    movement={payment}
                    errors={errors}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                />
            </Grid>
        </Grid>
    )
}

export default withMessageBox(Payment);