import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import {isNull} from "../../../helpers/validator";


export const HelperText = (props) => {

    const intl = useIntl();

    const [text, setText] = useState("");

    useEffect(() => {
        setText(props.text);
    }, [props.text]);

    useEffect(() => {
        if (!isNull(props.textId))
            setText(intl.formatMessage({id: props.textId}));
    }, [props.textId]);

    return (
        <div style={{color: '#888888', fontSize: '10px'}}>{text}</div>
    )
}

export default HelperText;
