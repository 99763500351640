import * as apiService from '../../apiService';

const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const NOTIFICATION_SCOPE = {
    employees: 'WAREHOUSE_EMPLOYEES',
    vehicles: 'WAREHOUSE_VEHICLES'
};

export const getNotifications = async () => {
    return await apiService.get(`${URL}/notifications`);
};
