import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { useAppContext } from '../../../core/context/AppContext';
import withMessageBox from '../../../../hocs/withMessageBox';
import * as apiService from '../../../../services/apiService';
import * as validateService from "../../../../services/validateService";
import * as companyService from "../../../../services/domain/warehouse/companyService";
import * as employeeService from "../../../../services/domain/warehouse/employeeService";
import * as vehicleService from '../../../../services/domain/warehouse/vehicleService';
import { getValueFromField } from '../../../../helpers/form';

import Grid from '@material-ui/core/Grid';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from "../../../../components/icons/Enabled";
import PersonAddIcon from '../../../../components/icons/PersonAdd';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Chip from '../../../../components/Chip';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";

import VehicleForm from './VehicleForm';
import AssignDriverToVehicleForm from './AssignDriverToVehicleForm';



export const VehiclesList = (props) => {

    const intl = useIntl();
    const { refreshNotifications } = useAppContext();

    const newVehicle = {
        id: null,
        plate: null,
        plateDate: null,
        brand: null,
        model: null,
        pma: null,
        tara: null,
        itvExpiration: null,
        assuranceCompany: null,
        assuranceNumber: null,
        assuranceExpiration: null,
        transportCardNumber: null,
        transportCardClass: null,
        transportCardExpiration: null,
        transportCardAmbit: null,
        companyId: null,
        picture: null,
        enabled: 1,
        documents: []
    };
    const validations = {
        plate: [{type: validateService.REQUIRED}],
        plateDate: [{type: validateService.REQUIRED}, {type: validateService.DATE_FORMAT}],
        brand: [{type: validateService.REQUIRED}],
        model: [{type: validateService.REQUIRED}],
        pma: [{type: validateService.REQUIRED}],
        tara: [{type: validateService.REQUIRED}],
        companyId: [{type: validateService.REQUIRED}],
        itvExpiration: [{type: validateService.REQUIRED}, {type: validateService.DATE_FORMAT}],
        assuranceCompany: [{type: validateService.REQUIRED}],
        assuranceNumber: [{type: validateService.REQUIRED}],
        assuranceExpiration: [{type: validateService.REQUIRED}, {type: validateService.DATE_FORMAT}],
        transportCardNumber: [{type: validateService.REQUIRED}],
        transportCardExpiration: [{type: validateService.REQUIRED}, {type: validateService.DATE_FORMAT}],
        transportCardAmbit: [{type: validateService.NUMBER_FORMAT}],
    };

    const newAssign = {
        driver: null,
        vehicle: null
    };

    const validationsAssign = {
        driver: [{type: validateService.REQUIRED}],
    };

    const [assign, setAssing] = useState(newAssign);
    const [open, setOpen] = useState(false);
    const [openDriverAssign, setOpenDriverAssign] = useState(false);
    const [vehicle, setVehicle] = useState(newVehicle);
    const [companies, setCompanies] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorsAssign, setErrorsAssign] = useState({});
    const [reload, setReload] = useState(0);

    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseV = await vehicleService.getVehicles();
            const responseC = await companyService.getCompanies();
            const drivers = await employeeService.getDrivers();

            if (responseV.status === 200 && responseC.status === 200) {
                setData(responseV.data);
                setCompanies(responseC.data.companies);
                setLoading(false);
                setDrivers(employeeService.getUnassignedDriversFromVehicles(responseV.data.vehicles, drivers));
            } else {}
        }
        load();
    }, [reload]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.vehicles'})}
    ];

    const handleClose = () => {
        setOpen(false);
        setOpenDriverAssign(false);
    };
    const handleNew = () => {
        setVehicle(newVehicle);
        setOpen(true);
    };
    const handleEdit = (item) => {
        setVehicle({
            ...item,
            companyId: item.company.id
        });
        setOpen(true);
    };
    const handleChange = (e) => {
        setVehicle({
            ...vehicle,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSubmit = (item) => {
        const err = validateService.validate(item, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            setVehicle(item);
            if (item.id == null) {
                addVehicle();
            } else {
                modifyVehicle();
            }
        }
    };

    const URL = process.env.REACT_APP_API_SERVER + '/api/v1/vehicles';
    const listVehicles = async () => {
        setLoading(true);
        apiService.get(URL)
            .then(res => {
                setData(res.data);
                refreshNotifications();
            })
            .catch(error => props.errorMessage(intl.formatMessage({id: "app.error.code." + error.code})))
            .finally(() => setLoading(false));
    };
    const addVehicle = async () => {
        apiService.post(URL, vehicle)
            .then(() => {
                setOpen(false);
                listVehicles();
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)});
    };
    const modifyVehicle = async () => {
        vehicle.companyId = typeof vehicle.companyId === "undefinad" ? vehicle.company.id : vehicle.companyId;
        apiService.put(URL + '/' + vehicle.id, vehicle)
            .then(() => {
                setOpen(false);
                listVehicles();
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)});
    };

    const handleOpenAssignDriverForm = (item) => {
        setVehicle(item);
        setOpenDriverAssign(true);
    };
    const handleChangeAssignForm = (e) => {
        setAssing({
            vehicle: vehicle.id,
            driver: getValueFromField(e)
        });
    };
    const handleAssign = () => {
        const err = validateService.validate(assign, validationsAssign);
        setErrorsAssign(err);
        if (!validateService.hasErrors(err)) {
            setOpenDriverAssign(false);
            addAssign();
        }
    };
    const addAssign = async() => {
        const response = await vehicleService.assignDriver(assign.vehicle, assign.driver);
        if (response.status === 204) {
            setReload(reload + 1);
            props.successMessage(intl.formatMessage({id: "generic.saved"}));
        } else {
            //TODO: mensaje error
            props.errorMessage('Error');
        }
    };
    const deleteAssign = async(vehicleId, driverId) => {
        const response = await vehicleService.unassignDriver(vehicleId, driverId);
        if (response.status === 204) {
            setReload(reload + 1);
            props.successMessage(intl.formatMessage({id: "generic.saved"}));
        } else {
            //TODO: mensaje error
            props.errorMessage('Error');
        }
    };

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"app.vehicles.plate"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"app.vehicles.brand"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"app.vehicles.model"}), sublabel: null, formatter: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"app.vehicles.pma"}), sublabel: null, formatter: null },
        { id: 'id5', align: "center", label: intl.formatMessage({id:"app.vehicles.itv"}), sublabel: intl.formatMessage({id:"app.validTo"}), formatter: (date) =>  moment(date).format('DD MMM YYYY'), width: 120 },
        { id: 'id6', align: "center", label: intl.formatMessage({id:"app.vehicles.assurance"}), sublabel: intl.formatMessage({id:"app.validTo"}), formatter: (date) =>  moment(date).format('DD MMM YYYY'), width: 120 },
        { id: 'id7', align: "left",   label: intl.formatMessage({id:"app.employees.driver"}) },
        { id: 'id8', align: "center", label: intl.formatMessage({id:"generic.enabled"}), width: 80, formatter: (val) => <EnabledIcon value={val}/> },
        { id: 'id9', align: "center", label: undefined, width: 30, formatter: (val) => val ? <PriorityHighIcon style={{color: 'gray'}}  /> : <></> },
        { id: 'actions', align: "center", width: 50 },
    ];

    const convertRowData = item => {
        const driverName = item.drivers.length > 0 ? item.drivers[0].name + ' ' + item.drivers[0].surname:"";
        const driverId = item.drivers.length > 0 ? item.drivers[0].id:"";
        return {
            id1: item.plate,
            id2: item.brand,
            id3: item.model,
            id4: item.pma,
            id5: item.itvExpiration,
            id6: item.assuranceExpiration,
            id7: item.drivers.length > 0 ? <Chip label={driverName} onDelete={() => deleteAssign(item.id, driverId)}/> : <PersonAddIcon button onClick={() => handleOpenAssignDriverForm(item)}/>,
            id8: item.enabled,
            id9: item.enabled && item.notifications.length > 0,
            actions: <EditIcon button onClick={() => handleEdit(item)}/>
        }
    };

    const rows = data.vehicles ? data.vehicles.map(item => convertRowData(item)) : [];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={rows}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal open={open} title={intl.formatMessage({id: "app.vehicles.vehicle"})} onClose={handleClose}>
                    <VehicleForm
                        vehicle={vehicle}
                        companies={companies}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
                <Modal open={openDriverAssign} title={intl.formatMessage({id: "generic.domain.assign.driverToVehicle"}, {vehicle: vehicle.plate})} onClose={handleClose} width={500} height={300}>
                    <Grid container style={{padding : '20px 0 0 0'}}>
                        <Grid item xs={12} style={{padding : '30px 15px 0 15px'}}>
                            <AssignDriverToVehicleForm
                                assign={assign}
                                drivers={drivers}
                                errors={errorsAssign}
                                onChange={handleChangeAssignForm}
                                onSubmit={handleAssign}
                            />
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
        </Grid>
    )
};

export default withMessageBox(VehiclesList);
