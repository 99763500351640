import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Input from "../../../../components/form/Input/Input";



export const ProductForm = (props) => {
    const {product, errors} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Input
                        name="name"
                        label={intl.formatMessage({id: 'generic.domain.name'})}
                        value={product.name}
                        error={errors.name}
                        onChange={props.onChange}
                        disabled
                    />
                </Grid>
                <Grid item xs={9}>
                    <Input
                        name="description"
                        label={intl.formatMessage({id: 'generic.domain.desc'})}
                        value={product.description}
                        error={errors.description}
                        onChange={props.onChange}
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export default ProductForm;