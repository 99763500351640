import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import AppBar from './AppBar';
import Drawer from './Drawer';


const MainLayout = (props) => {

    const {children, menuSelectedItem} = props;
    const [paddingLeft, setPaddingLeft] = useState(150);

    const handleDrawerExtOpen = (open) => {
        setTimeout(() => {setPaddingLeft(open ? 400 : 150)}, 100);
    };

    return (
        <div id="div-main-layout">
            <Drawer
                open={true}
                onDrawerExtOpen={handleDrawerExtOpen}
                menuSelectedItem={menuSelectedItem}
            />
            <AppBar paddingLeft={paddingLeft} />
            <Grid container style={{marginTop: '50px', paddingLeft: paddingLeft + 'px', paddingRight: '50px'}}>
                <Grid item xs={12}>{children}</Grid>
            </Grid>
        </div>
    )

}

export default MainLayout;
