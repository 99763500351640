import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles/index";
import { useIntl } from 'react-intl';

import * as authService from '../../../../services/authService';
import { useAppContext } from '../../context/AppContext';

import Box from '@material-ui/core/Box';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MUIDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CenterBox, RightBox } from '../../../../components/Box';
import menuItems from '../../menu/menuItems';

import LogoRepsol from '../../../../assets/images/logo_repsol.jpg';




const BACKGROUND_COLOR = '#4F5F6F';
const BACKGROUND_COLOR_SELECTED = '#394959';
const COLOR = '#FFF';
export const KEY_DRAWER_OPTIONS = "drawerOptions";

const StyledList = withStyles({
    root: {
        width: '100%'
    }
})(List);
const StyledListItem = withStyles({
        selected: {
            backgroundColor: BACKGROUND_COLOR_SELECTED + ' !important',
            borderTopColor: 'black !important',
            borderTop: 'solid 1px'
        }
})(ListItem);
const StyledExpansionPanel = withStyles({
    root: {
        backgroundColor: BACKGROUND_COLOR,
        color: COLOR     
    }
})(ExpansionPanel);
const StyledExpansionPanelSummary = withStyles({
    expanded: {
        minHeight: '10px !important',
        marginBottom: '0 !important'
    }
})(ExpansionPanelSummary);
const StyledExpansionPanelDetails = withStyles({
    root: {
        padding: 0,
        backgroundColor: '#6B7986'
    }
})(ExpansionPanelDetails);
const StyledDrawer = withStyles({
    paper: {
        width: '100px',
        backgroundColor: BACKGROUND_COLOR,
        color: COLOR,
        overflow: 'hidden',
        zIndex: 10000
    }
})(MUIDrawer);
const StyledDrawerExt = withStyles({
    paper: {
        width: '350px',
        backgroundColor: BACKGROUND_COLOR,
        color: COLOR
    }
})(MUIDrawer);



const Drawer = (props) => {
    const intl = useIntl();
    const appContext = useAppContext();

    const [secondLevelMenuItems, setSecondLevelMenuItems] = useState([]);

    const drawerOptionsObj = {
        drawerExtOpen: false,
        listItemSelected: null, //first level
        midLevelListItemExpanded: null, //mid level
        lastLevelListItemSelected: null //last level
    };
    const drawerOptionsLSObj = JSON.parse(localStorage.getItem(KEY_DRAWER_OPTIONS));
    const [drawerOptions, setDrawerOptions] = useState(drawerOptionsLSObj || drawerOptionsObj);


    useEffect(() => {
        if (!appContext.agencyId) {
            handleCloseDrawerExt(true);
        } else {
        //if (drawerOptionsLSObj && drawerOptionsLSObj.drawerExtOpen) {
            loadSelection();
        }
    }, []);

    /**
     *  Load selection from route menuSelectedItem attribute in case feature is accessed by url
     */
    const loadSelection = () => {
        const {menuSelectedItem} = props;
        if (menuSelectedItem) { //accessing by url with not null menuSelectedItem
            const levels = menuSelectedItem.split('.');
            const firstLevel = levels[0] + '.' + levels[1];
            const midLevel = levels.length > 2 ? levels[0] + '.' + levels[1] + '.' + levels[2] : null;
            const lastLevel = menuSelectedItem;
            switch (levels.length) {
                case 2: //for instance: main.dashboard
                case 3:
                    handleCloseDrawerExt(true);  
                    handleSelect(firstLevel, midLevel); 
                    break;
                case 4: //for instance: main.warehouse.management.warehouse
                    handleSelect(firstLevel, midLevel, lastLevel);
                    break;
                default:
                    throw new Error('Incorrect menu levels in:' + menuSelectedItem);
            } 
        } else {
            handleSelect(null);
        }
    };


    const setDrawerOptionsObj = (drawerOptionsObj) => {
        setDrawerOptions(drawerOptionsObj);
        localStorage.setItem(KEY_DRAWER_OPTIONS, JSON.stringify(drawerOptionsObj));
    };

    /** first level click */
    const handleSelect = (id, midLevelListItemExpanded = null, lastLevelListItemSelected = null) => {
        if (id) {
            const listItemSelected = menuItems.MainMenu.find(item => item.id === id);
            setSecondLevelMenuItems(listItemSelected.children);
            setDrawerOptionsObj({
                drawerExtOpen: listItemSelected.children.length > 0,
                listItemSelected: id,
                midLevelListItemExpanded: midLevelListItemExpanded,
                lastLevelListItemSelected: lastLevelListItemSelected
            });
            props.onDrawerExtOpen(listItemSelected.children.length > 0);
        } else {
            setDrawerOptionsObj(drawerOptionsObj);
            props.onDrawerExtOpen(false);
        }
    };

    const handleCloseDrawerExt = (unselectFirstLevel = false) => {
        setDrawerOptionsObj({
            ...drawerOptions,
            //listItemSelected: !unselectFirstLevel ? drawerOptions.listItemSelected : null,
            drawerExtOpen: false});
        props.onDrawerExtOpen(false);
    };

    const handleSelectDrawerExt = (id) => {
        setDrawerOptionsObj({...drawerOptions, lastLevelListItemSelected: id});
    };

    const handleExpandMenuItem = (id) => {
        const drawerOptionsObj = {
            ...drawerOptions,
            midLevelListItemExpanded: id === drawerOptions.midLevelListItemExpanded ? null : id
        };
        setDrawerOptionsObj(drawerOptionsObj);
    };

    const renderLastLevel = (items) => {
        return (
            <StyledList>
                {
                    items.map(item => {
                        if (!authService.userHasRole(item.roles)) return null;
                        return (
                            <StyledListItem
                                button
                                component={Link}
                                to={item.path}
                                onClick={(e) => {
                                    if (!appContext.agencyId) e.preventDefault();
                                    handleSelectDrawerExt(item.id)
                                }}
                                selected={drawerOptions.lastLevelListItemSelected === item.id}
                                style={{paddingLeft: '35px'}}
                                key={item.label}
                            >
                                {intl.formatMessage({id: item.label})}
                            </StyledListItem>
                        )
                    })
                }
            </StyledList>
        )
    }

    return (
        <div>
            <StyledDrawer
                variant="permanent"
                open={true}
            >
                <StyledList>
                    <StyledListItem
                        component={Link}
                        to='/'
                    >
                        <CenterBox>
                            <img 
                                src={LogoRepsol}
                                style={{margin: '-13px 0 0 -13px'}}
                            />
                        </CenterBox>
                    </StyledListItem>
                    {
                        menuItems.MainMenu.map(item => {
                            if (!authService.userHasRole(item.roles)) return null;
                            return (
                                <StyledListItem
                                    button
                                    key={item.id}
                                    component={item.children.length === 0 ? Link : null}
                                    to={item.path}
                                    onClick={(e) => {
                                        if (!appContext.agencyId) {
                                            handleCloseDrawerExt(true);
                                            e.preventDefault();
                                        } else handleSelect(item.id)
                                    }}
                                    selected={drawerOptions.listItemSelected === item.id}
                                >
                                    <Box width="100px">
                                        <ListItemIcon key={item.label}>
                                            <CenterBox fontSize={20}>
                                                {item.icon !== null && React.createElement(item.icon)}
                                            </CenterBox>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{style: {textAlign: 'center'}}}>
                                            {intl.formatMessage({id: item.label})}
                                        </ListItemText>
                                    </Box>
                                </StyledListItem>
                            )
                        })
                    }
                </StyledList>
            </StyledDrawer>
            <StyledDrawerExt
                variant="persistent"
                open={drawerOptions.drawerExtOpen}
            >
                <Box paddingLeft='100px'>
                    <RightBox style={{paddingBottom: '0px'}}>
                        <IconButton onClick={() => handleCloseDrawerExt()}>
                            <ChevronLeftIcon style={{color: COLOR}} />
                        </IconButton>
                    </RightBox>
                    <CenterBox style={{fontSize: '18px', padding: '0 10px 10px 10px', height: '40px'}}></CenterBox>
                    <StyledList>
                    {
                        secondLevelMenuItems.map(item => {
                            if (!authService.userHasRole(item.roles)) return null;
                            return (
                                <StyledExpansionPanel
                                    expanded={drawerOptions.midLevelListItemExpanded === item.id}
                                    onChange={() => handleExpandMenuItem(item.id)}
                                    key={item.id}
                                >
                                    <StyledExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon style={{color: COLOR}} />}
                                    >
                                        {intl.formatMessage({id: item.label})}
                                    </StyledExpansionPanelSummary>
                                    <StyledExpansionPanelDetails>
                                        {renderLastLevel(item.children)}
                                    </StyledExpansionPanelDetails>
                                </StyledExpansionPanel>
                            )
                        })
                    }
                    </StyledList>
                </Box>
            </StyledDrawerExt>
        </div>
    )
};

export default Drawer;
