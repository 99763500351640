import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from "../../../../hocs/withMessageBox";

import Grid from "@material-ui/core/Grid";

import Breadcrumbs from "../../../../components/Breadcrumbs";



export const PurchaseOrderInvoice = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.billing.po'})}
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
        </Grid>
    )
}

export default PurchaseOrderInvoice;