import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as warehouseService from '../../../../services/domain/warehouse/warehouseService';
import * as warehouseStockService from '../../../../services/domain/warehouse/warehouseStockService';
import {getValueFromField} from "../../../../helpers/form";

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabPanel from '../../../../components/tabs/TabPanel';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import Select from '../../../../components/form/Select/index';

import WarehouseStockTab from "./WarehouseStockTab";



export const WarehouseStock = (props) => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.stock'})}
    ];

    const [activeTab, setActiveTab] = useState(0);

    const [warehouseId, setWarehouseId] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const [agencyStock, setAgencyStock] = useState([]);
    const [warehouseStock, setWarehouseStock] = useState([]);
    const [warehouseGroupStock, setWarehouseGroupStock] = useState([]);

    useEffect(() => {
        async function load() {
            const responseW = await warehouseService.listWarehouses();
            if (responseW.status === 200) {
                setWarehouses(responseW.data.warehouses);
            } else {
                console.error('error', responseW);
            }
        }
        load();
    }, []);

    useEffect(() => {
        setWarehouseItems(
            warehouses.map(warehouse => {
                return {value: warehouse.id, label: warehouse.name};
            })
        );
    }, [warehouses]);

    useEffect(() => {
        if (agencyStock.length > 0)
            setWarehouseStock(agencyStock.filter(ws => {return ws.warehouse.id === warehouseId}));
    }, [warehouseId, agencyStock]);

    const handleChangeTab = (e, newValue) => {
        setActiveTab(newValue);
    };

    const loadStock = async (wId) => {
        const responseAS = warehouseStockService.getAgencyStock(wId);
        responseAS
            .then(res => {
                setAgencyStock(res.data.warehouses);
                const responseGS = warehouseStockService.getGroupStock(wId);
                responseGS
                    .then(res => {
                        setWarehouseGroupStock(res.data.warehouses);
                    });
            });
    }

    const handleChange = (e) => {
        const wId = getValueFromField(e);

        if (wId !== null && agencyStock.length === 0) {
            loadStock(wId);
        } else {

        }
        setWarehouseId(wId);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Select
                    name="warehouseId"
                    label={intl.formatMessage({id: 'generic.domain.warehouse'})}
                    items={warehouseItems}
                    value={warehouseId}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" color="primary">
                    <Tabs
                        variant="fullWidth"
                        value={activeTab}
                        onChange={handleChangeTab}
                    >
                        <Tab label={intl.formatMessage({id: "generic.domain.stock"})}/>
                        <Tab label={intl.formatMessage({id: "generic.domain.stock.agency"})}/>
                        <Tab label={intl.formatMessage({id: "generic.domain.stock.shared"})}/>
                    </Tabs>
                </AppBar>
                <Paper style={{padding: '20px'}}>
                    <TabPanel index={0} value={activeTab}>
                        <WarehouseStockTab
                            stock={warehouseStock}
                        />
                    </TabPanel>
                    <TabPanel index={1} value={activeTab}>
                        <WarehouseStockTab
                            stock={agencyStock}
                        />
                    </TabPanel>
                    <TabPanel index={2} value={activeTab}>
                        <WarehouseStockTab
                            stock={warehouseGroupStock}
                        />
                    </TabPanel>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default WarehouseStock;