import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as employeeService from '../../../../services/domain/warehouse/employeeService';
import * as vehicleService from '../../../../services/domain/warehouse/vehicleService';

import Table from '../../../../components/table';
import ZoneChip from './ZoneChip';


const VehiclesRoutesTable = (props) => {
    const {loading, vehicles} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(vehicles.map(item => convertRowData(item)));
    }, [vehicles]);

    const headers = [
        { id: 'id1', align: "left", label: intl.formatMessage({id: 'app.vehicles.vehicle'}), width: 100 },
        { id: 'id2', align: "left", label: intl.formatMessage({id: 'generic.domain.driver'}), width: 200 },
        { id: 'id3', align: "left", label: intl.formatMessage({id: 'app.routes.route'}), sortable: false },
    ];

    const convertRowData = item => {
        const driver = vehicleService.getDriverFromVehicles(vehicles, item.plate);
        return {
            id1: item.plate,
            id2: driver ? employeeService.getFullName(driver) : "-",
            id3: item.zones.map((zone, index) => <ZoneChip key={index} label={zone.zone.name} onDelete={() => props.onDeleteZone(item, zone.zone)}/>)
        }
    };

    return (
        <div>
            <Table
                headers={headers}
                data={data}
                isLoading={loading}
                totalRows
                ordered
                isExportable
            />
        </div>
    )
};

export default VehiclesRoutesTable;
