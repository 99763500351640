import {isNumber} from "./validator";

export const TYPE_BOOLEAN = "boolean";
export const TYPE_FLOAT = "float";
export const TYPE_INTEGER = "integer";
export const TYPE_NUMBER = "number";
export const TYPE_STRING = "string";

/**
 * Return value from field form
 * if parameter obj is setted, the returned value is same type
 * @param e Event
 * @param obj Object
 * @param type String typeof value
 * @returns {*}
 */
export const getValueFromField = (e, obj = null, type = null) => {
    let value;
    switch (e.target.type) {
        case "checkbox":
            value = e.target.checked ? 1 : 0;
            break;
        case "select":
        case "date":
            value = e.target.value !== "" ? e.target.value : null;
            break;
        case "text":
            value = e.target.value;
            break;
        default:
            value = e.target.value !== "" ? e.target.value : null;
    }

    if (value !== "") {
        if (type !== null) {
            switch (type) {
                case TYPE_INTEGER:
                    value = parseInt(value);
                    break;
                case TYPE_FLOAT:
                    value = value.replace(',', '.');
                    value = valueToFloat(value);
                    break;
                case TYPE_BOOLEAN:
                    value = value === true || value === 1;
                    break;
            }
        } else {
            if (obj !== null) {
                switch (typeof obj[e.target.name]) {
                    case TYPE_NUMBER:
                        value = value.replace(',', '.');
                        if (value.indexOf('.') !== -1) {
                            value = valueToFloat(value);
                        } else {
                            value = parseInt(value);
                        }
                        break;
                }
            }
        }

    }

    return value;
};

const valueToFloat = (value) => {
    if (!isNumber(value)) return "";
    if (value.indexOf('.') !== -1) {
        if (value.split('.').length > 2) return value;
        if (value.lastIndexOf('.') === value.length - 1) return value;
        if (value.endsWith('.0')) return value;

        return parseFloat(value);
    }
    return parseInt(value);
}
