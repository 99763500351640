import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from "../../../../hocs/withMessageBox";

import * as productService from '../../../../services/domain/warehouse/productService';
import * as saleChannelService from '../../../../services/domain/warehouse/saleChannelService';
import * as zoneService from '../../../../services/domain/warehouse/zoneService';

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from "../../../../components/icons/Enabled";
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Table from '../../../../components/table';
import { RightBox } from '../../../../components/Box/index';
import {fullAddressFormatter} from '../../../../helpers/formatter';
import Product from './Product';
import Modal from "../../../../components/Modal/Modal";
import {getValueFromField} from "../../../../helpers/form";
import {priceFormatter} from "../../../../helpers/formatter";
import * as validateService from "../../../../services/validateService";


export const ProductsList = (props) => {

    const intl = useIntl();

    const newSupplement = {
        id: null,
        supplementTypeId: "",
        supplementPrice: 0,
        channelId: "",
        zoneId: "",
        from: "",
        to: "",
        weekend: 0,
        agencyId: null,
        createdBy: "",
        enabled: 1
    };
    const supplementValidations = {
        supplementTypeId: [{type: validateService.REQUIRED}],
        supplementPrice: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
        from: [{type: validateService.TIME_FORMAT}],
        to: [{type: validateService.TIME_FORMAT}],
    };

    const newPrice = {
        basePrice: null,
        lostPrice: null,
        authMaxPrice: null,
        agencyId: null
    };
    const priceValidations = {
        basePrice: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
        lostPrice: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
        authMaxPrice: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_FORMAT}],
    };

    const newProviderCommissions = {
        activityCommission: 0,
        qualityCommission: 0,
        deliveryCommission: 0,
        subscribeCommission: 0
    };
    const providerCommissionsValidations = {
        activityCommission: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        qualityCommission: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        deliveryCommission: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
        subscribeCommission: [{type: validateService.REQUIRED}, {type: validateService.NUMBER_GREATER_OR_EQUAL_TO_ZERO}],
    }

    const [data, setData] = useState([]);
    const [deliveryCommissions, setDeliveryCommissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [saleChannels, setSaleChannels] = useState([]);
    const [price, setPrice] = useState(newPrice);
    const [supplement, setSupplement] = useState(newSupplement);
    const [supplementTypes, setSupplementTypes] = useState([]);
    const [zones, setZones] = useState([]);
    const [reload, setReload] = useState(0);
    const [open, setOpen] = useState(false);
    const [priceErrors, setPriceErrors] = useState({});
    const [supplementErrors, setSupplementErrors] = useState({});
    const [providerCommissions, setProviderCommissions] = useState(newProviderCommissions);
    const [providerCommissionsErrors, setProviderCommissionsErrors] = useState({});


    useEffect(() => {
        async function loadProducts() {
            setLoading(true);
            const response = await productService.getProducts();
            const responseSC = await saleChannelService.getSaleChannels();
            const responseZ = await zoneService.getZones();
            if (response.status === 200 && responseSC.status === 200 && responseZ.status === 200) {
                setData(response.data.products.map(item => convertRowData(item)));
                setSaleChannels(responseSC.data.saleChannels);
                setZones(responseZ.data.zones);
                setSupplementTypes(productService.getSupplementTypeItems());

                if (typeof product.id !== "undefined") {
                    const productLoaded = response.data.products.filter(p => {return p.id === product.id});
                    setProduct(productLoaded[0]);
                    setPrice(productLoaded[0].productPrice);
                }
            } else {
            }
            setLoading(false);
        }
        loadProducts();
    }, [reload]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.products'})}
    ];

    const handleClose = () => {
        setOpen(false);
    };
    const handleEdit = (item) => {
        setProduct(item);
        setPrice(item.productPrice);
        setDeliveryCommissions(productService.getDeliveryCommissionsFromProduct(item));
        setProviderCommissions(item.productProviderCommissions);
        setOpen(true);
    };

    const handleChangeCommissions = (e) => {
        const channelId = parseInt(e.target.dataset.channel);
        const value = e.target.value.replace("€", "").replace(",", "").trim();
        const commissions = deliveryCommissions;
        const commissionsModified =
            commissions.map(commission => {
                if (commission.saleChannel.id === channelId) {
                    return {
                        ...commission,
                        [e.target.name]: value
                    }
                } else return commission;
            });

        setDeliveryCommissions(commissionsModified);
    };
    const handleSaveCommissions = () => {
        const data = {
            agencyId: deliveryCommissions[0].agencyId,
            deliveryCommissions: deliveryCommissions
        };
        const response = productService.updateDeliveryCommissions(product.id, data);
        response
            .then(() => {
                setReload(reload + 1);
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)});
    };

    const handleChangeSupplement = (e) => {
        setSupplement({
            ...supplement,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSaveSupplement = (id = null) => {
        const err = validateService.validate(supplement, supplementValidations);
        setSupplementErrors(err);
        if (!validateService.hasErrors(err)) {
            if (id === null) {
                const response = productService.addSupplementPrice(product.id, supplement);
                response
                    .then(() => {
                        setReload(reload + 1);
                        setSupplement(newSupplement);
                        props.successMessage(intl.formatMessage({id: "generic.saved"}));
                    })
                    .catch(err => {
                        props.errorMessage('Error');
                        console.error(err.response)
                    });
            } else {
                const response = productService.deleteSupplementPrice(id);
                response
                    .then(() => {
                        setReload(reload + 1);
                        props.successMessage(intl.formatMessage({id: "generic.saved"}));
                    })
                    .catch(err => {
                        props.errorMessage('Error');
                        console.error(err.response)
                    });
            }
        }
    };

    const handleChangePrice = (e) => {
        setPrice({
            ...price,
            [e.target.name]: getValueFromField(e)
        });
    };
    const handleSavePrice = () => {
        const err = validateService.validate(price, priceValidations);
        setPriceErrors(err);
        if (!validateService.hasErrors(err)) {
            const response = productService.updatePrice(product.id, price);
            response
                .then(() => {
                    setReload(reload + 1);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                });
        }
    };

    const handleChangeProviderCommission = (e) => {
        setProviderCommissions({
            ...providerCommissions,
            [e.target.name]: getValueFromField(e)
        });
    }
    const handleSaveProviderCommission = () => {
        const err = validateService.validate(providerCommissions, providerCommissionsValidations);
        setProviderCommissionsErrors(err);
        if (!validateService.hasErrors(err)) {
            const response = productService.updateProviderCommissions(product.id, providerCommissions);
            response
                .then(() => {
                    setReload(reload + 1);
                    props.successMessage(intl.formatMessage({id: "generic.saved"}));
                })
                .catch(err => {
                    props.errorMessage('Error');
                    console.error(err.response)
                });
        }
    }


    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.name"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.desc"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.price"}), sublabel: null, formatter: priceFormatter, width: 100 },
        { id: 'id4', align: "center",   label: intl.formatMessage({id:"generic.enabled"}), sublabel: null, formatter: (val) => <EnabledIcon value={val}/>, width: 80 },
        { id: 'actions', align: "center", width: 80 },
    ];

    const convertRowData = item => {
        return {
            id1: item.name,
            id2: item.description,
            id3: item.productPrice.basePrice,
            id4: item.enabled,
            actions: <EditIcon button onClick={() => handleEdit(item)}/>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/>
                <Table
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal open={open} title={product.name + " - " + product.description} onClose={handleClose} width={900}>
                    <Product
                        product={product}
                        deliveryCommissions={deliveryCommissions}
                        changeCommissions={handleChangeCommissions}
                        saveCommissions={handleSaveCommissions}
                        price={price}
                        priceErrors={priceErrors}
                        changePrice={handleChangePrice}
                        savePrice={handleSavePrice}
                        supplement={supplement}
                        supplementTypes={supplementTypes}
                        supplementErrors={supplementErrors}
                        changeSupplement={handleChangeSupplement}
                        saveSupplement={handleSaveSupplement}
                        providerCommissions={providerCommissions}
                        providerCommissionsErrors={providerCommissionsErrors}
                        changeProviderCommission={handleChangeProviderCommission}
                        saveProviderCommission={handleSaveProviderCommission}
                        saleChannels={saleChannels}
                        zones={zones}
                    />
                </Modal>
            </Grid>
        </Grid>
    )
};


export default withMessageBox(ProductsList);
