import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as arrayHelper from "../../../../helpers/array";

import Grid from '@material-ui/core/Grid';

import Modal from "../../../../components/Modal/Modal";
import ReturnIcon from "../../../../components/icons/Return";
import Table from '../../../../components/table';

import ProductForm from './ProductForm';
import CageForm from './CageForm';



export const ReturnTab = (props) => {
    const {authorization, openProductModal, openCageModal, product, products, errors, cage, cages, errorsCage, refreshGrids} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);
    const [dataC, setDataC] = useState([]);
    const [openProduct, setOpenProduct] = useState(false);
    const [openCage, setOpenCage] = useState(false);

    useEffect(() => {
        setData(authorization.products.map(item => convertProductRowData(item)));
        setDataC(authorization.cages.map(item => convertCageRowData(item)));
    }, [authorization, refreshGrids]);

    useEffect(() => {
        if (openProductModal !== openProduct) setOpenProduct(openProductModal);
    }, [openProductModal]);
    useEffect(() => {
        if (openCageModal !== openCage) setOpenCage(openCageModal);
    }, [openCageModal]);

    const handleClose = () => {
        setOpenProduct(false);
        setOpenCage(false);
        props.onCloseModal();
    };
    const handleEditProduct = (item) => {
        setOpenProduct(true);
        props.handleEditProduct(item);
    };
    const handleEditCage = (item) => {
        setOpenCage(true);
        props.handleEditCage(item);
    };

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.product"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.containers.aut"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.charges.aut"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id6', align: "left",   label: intl.formatMessage({id:"generic.domain.containers.toReturn"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id7', align: "left",   label: intl.formatMessage({id:"generic.domain.containers.returned"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id8', align: "left",   label: intl.formatMessage({id:"generic.domain.charges.returned"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.containers.toPos"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.charges.toPos"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id9', align: "left",   label: intl.formatMessage({id:"generic.domain.containers.lost"}), sublabel: null, formatter: null, width: 100 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertProductRowData = item => {
        const productId = item.product ? item.product.id : item.productId;
        const product = arrayHelper.getObjectById(products, productId);
        return {
            id1: product ? product.name : "",
            id2: item.containersAuth,
            id3: item.chargesAuth,
            id4: item.containersToPos,
            id5: item.chargesToPos,
            id6: item.containersToReturn,
            id7: item.containersReturned,
            id8: item.chargesReturned,
            id9: item.containersLost,
            actions:
                authorizationService.isLiquidation(authorization)
                    ?
                <ReturnIcon button onClick={() => handleEditProduct(item)} />
                    :
                <div></div>
        }
    };

    const headersC = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.cage"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.units.aut"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.units.returned"}), sublabel: null, formatter: null, width: 100 },
        { id: 'actions', align: "center", width: 60 },
    ];

    const convertCageRowData = item => {
        const cageId = item.cage ? item.cage.id : item.cageId;
        const cage = arrayHelper.getObjectById(cages, cageId);
        return {
            id1: cage ? cage.name : "",
            id2: item.unitsAuth,
            id3: item.unitsReturned,
            actions:
                authorizationService.isLiquidation(authorization)
                    ?
                    <ReturnIcon button onClick={() => handleEditCage(item)} />
                    :
                    <div></div>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{minHeight: '200px'}}>
                <Table
                    headers={headers}
                    data={data}
                />
            </Grid>
            <Grid item xs={12} style={{minHeight: '100px', paddingTop: '20px'}}>
                <Table
                    headers={headersC}
                    data={dataC}
                />
            </Grid>
            <Grid item xs={12}>
                <Modal open={openProduct} title={intl.formatMessage({id: 'generic.domain.product'})} onClose={handleClose} width={400} height={500}>
                    <ProductForm
                        isAuthorization={authorizationService.isAuthorization(authorization)}
                        product={product}
                        products={products}
                        errors={errors}
                        onChange={props.handleChangeProduct}
                        onSubmit={props.handleSubmitProduct}
                        onClose={handleClose}
                    />
                </Modal>
                <Modal open={openCage} title={intl.formatMessage({id: 'generic.domain.cage'})} onClose={handleClose} width={400} height={500}>
                    <CageForm
                        isAuthorization={authorizationService.isAuthorization(authorization)}
                        cage={cage}
                        cages={cages}
                        errors={errorsCage}
                        onChange={props.handleChangeCage}
                        onSubmit={props.handleSubmitCage}
                        onClose={handleClose}
                    />
                </Modal>
            </Grid>
        </Grid>
    );
};

export default ReturnTab;