import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import withMessageBox from '../../../../hocs/withMessageBox';

import { getValueFromField } from '../../../../helpers/form';
import * as employeeService from "../../../../services/domain/warehouse/employeeService";
import * as vehicleService from "../../../../services/domain/warehouse/vehicleService";
import * as zoneService from "../../../../services/domain/warehouse/zoneService";

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import TabPanel from '../../../../components/tabs/TabPanel';

import AssignZonesToEmployeesForm from './AssignZonesToEmployeesForm';
import AssignZonesToVehiclesForm from './AssignZonesToVehiclesForm';
import EmployeesRoutesTable from './EmployeesRoutesTable';
import RouterPrompt from "../../../../components/RouterPrompt/RouterPrompt";
import VehiclesRoutesTable from './VehiclesRoutesTable';
import ZonesTable from './ZonesTable';


export const RoutesList = (props) => {

    const intl = useIntl();

    const newAssignZV = {
        vehicle: "",
        zone: ""
    };
    const newAssignZE = {
        employee: "",
        zone: ""
    };

    const [activeTab, setActiveTab] = useState(0);
    const [assignZV, setAssignZV] = useState(newAssignZV);
    const [assignZE, setAssignZE] = useState(newAssignZE);
    const [employees, setEmployees] = useState([]);
    const [freeZonesV, setFreeZonesV] = useState([]);
    const [freeZonesE, setFreeZonesE] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reloadZV, setReloadZV] = useState(0);
    const [reloadZE, setReloadZE] = useState(0);
    const [vehicles, setVehicles] = useState([]);
    const [zones, setZones] = useState([]);

    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseV = await vehicleService.getVehicles({enabled: 1});
            const responseZ = await zoneService.getZones({enabled: 1});
            if (responseV.status === 200 && responseZ.status === 200) {
                setVehicles(responseV.data.vehicles);
                setZones(responseZ.data.zones);
                setFreeZonesV(zoneService.getFreeZV(responseZ.data.zones, responseV.data.vehicles));
                setFreeZonesE(zoneService.getFreeZE(responseZ.data.zones));
                //TODO: cuando vengan las zonas con los employees cambiar esto por employeeService.getEmployees
                setEmployees(await employeeService.getEmployeesFromZones(responseZ.data.zones));
                setLoading(false);
            } else {}
        }
        load();
    }, [reloadZV, reloadZE]);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.routes'})}
    ];

    const handleChangeTab = (e, newValue) => {
        setActiveTab(newValue);
    };
    const handleChangeZVForm = (e) => {
        setAssignZV({
            ...assignZV,
            [e.target.name]: getValueFromField(e)
        })
    };
    const validateZVForm = () => {
        return assignZV.vehicle !== "" && assignZV.zone !== "";
    };
    const handleAssignZV = async () => {
        if (validateZVForm()) {
            const response = await vehicleService.assignZone(assignZV.vehicle, assignZV.zone);
            if (response.status === 204) {
                setReloadZV(reloadZV + 1);
                setAssignZV(newAssignZV);
            } else {
                //TODO: mensaje error
                props.errorMessage('Error');
            }
        }
    };
    const handleUnassignZV = async (vehicle, zone) => {
        const response = await vehicleService.unassignZone(vehicle.id, zone.id);
        if (response.status === 204) {
            setReloadZV(reloadZV + 1);
            setAssignZV(newAssignZV);
        } else {
            //TODO: mensaje error
            props.errorMessage('Error');
        }
    };
    const handleChangeZEForm = (e) => {
        setAssignZE({
            ...assignZE,
            [e.target.name]: getValueFromField(e)
        })
    };
    const validateZEForm = () => {
        return assignZE.employee !== "" && assignZE.zone !== "";
    };
    const handleAssignZE = async () => {
        if (validateZEForm()) {
            const response = await employeeService.assignZone(assignZE.employee, assignZE.zone);
            if (response.status === 204) {
                setReloadZE(reloadZE + 1);
                setAssignZE(newAssignZE);
            } else {
                //TODO: mensaje error
                props.errorMessage('Error');
            }
        }
    };
    const handleUnassignZE = async (employee, zone) => {
        const response = await employeeService.unassignZone(employee.id, zone.id);
        if (response.status === 204) {
            setReloadZE(reloadZE + 1);
            setAssignZE(newAssignZE);
        } else {
            //TODO: mensaje error
            props.errorMessage('Error');
        }
    };

    return (
        <Grid container>
            <RouterPrompt
                when={freeZonesV.length > 0}
                title={intl.formatMessage({id: 'generic.domain.routes'})}
                message="Aún hay rutas sin asignar. ¿Desea salir?"
            />
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12} style={{padding : '20px 0 0 0'}}>
                <Paper>
                    <AppBar position="static" color="primary">
                        <Tabs
                            variant="fullWidth"
                            value={activeTab}
                            onChange={handleChangeTab}
                        >
                            <Tab label={intl.formatMessage({id: 'generic.domain.vehicles'})} />
                            <Tab label={"colaboradores"} disabled />
                            <Tab label={intl.formatMessage({id: 'generic.domain.itineraries'})} />
                        </Tabs>
                    </AppBar>
                    <TabPanel index={0} value={activeTab}>
                        <Grid container style={{padding : '20px 0 0 0'}}>
                            <Grid item xs={9} style={{padding : '0 15px'}}>
                                <VehiclesRoutesTable
                                    vehicles={vehicles}
                                    loading={loading}
                                    onDeleteZone={handleUnassignZV}
                                />
                            </Grid>
                            <Grid item xs={3} style={{padding : '30px 15px 0 15px'}}>
                                <Paper style={{padding: '20px'}}>
                                    <AssignZonesToVehiclesForm
                                        assign={assignZV}
                                        vehicles={vehicles}
                                        zones={freeZonesV}
                                        onChange={handleChangeZVForm}
                                        onSubmit={handleAssignZV}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel index={1} value={activeTab}>
                        <Grid container style={{padding : '20px 0 0 0'}}>
                            <Grid item xs={9} style={{padding : '0 15px'}}>
                                <EmployeesRoutesTable
                                    employees={employees}
                                    loading={loading}
                                    onDeleteZone={handleUnassignZE}
                                />
                            </Grid>
                            <Grid item xs={3} style={{padding : '30px 15px 0 15px'}}>
                                <Paper style={{padding: '20px'}}>
                                    <AssignZonesToEmployeesForm
                                        assign={assignZE}
                                        employees={employees}
                                        zones={freeZonesE}
                                        onChange={handleChangeZEForm} onSubmit={handleAssignZE}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel index={2} value={activeTab}>
                        <ZonesTable
                            vehicles={vehicles}
                            zones={zones}
                            loading={loading}
                        />
                    </TabPanel>
                </Paper>
            </Grid>
        </Grid>
    )
};

export default withMessageBox(RoutesList);
