import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppContext } from '../../../core/context/AppContext';
import withMessageBox from '../../../../hocs/withMessageBox';
import * as apiService from "../../../../services/apiService";
import * as companyService from "../../../../services/domain/warehouse/companyService";
import * as employeeService from "../../../../services/domain/warehouse/employeeService";
import * as validateService from "../../../../services/validateService";
import { getValueFromField } from '../../../../helpers/form';
import { priceFormatter } from '../../../../helpers/formatter';

import Grid from '@material-ui/core/Grid';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import AddIcon from '../../../../components/icons/Add';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from '../../../../components/icons/Enabled';
import GroupAddIcon from '../../../../components/icons/GroupAdd';
import Breadcrumbs from '../../../../components/Breadcrumbs/index';
import Button from '../../../../components/form/Button/index';
import Modal from '../../../../components/Modal/Modal';
import Table from '../../../../components/table';
import { RightBox } from '../../../../components/Box/index';
import EmployeeForm from './EmployeeForm';
import HelpersTable from './HelpersTable';
import AssignHelperToDriverForm from './AssignHelperToDriverForm';


export const EmployeesList = (props) => {

    const intl = useIntl();
    const { refreshNotifications } = useAppContext();
    
    const newEmployee = {
        id: null,
        code: null,
        nif: null,
        name: null,
        surname: null,
        birthDate: null,
        mobile: null,
        address: null,
        zipCode: null,
        town: null,
        province: null,
        tf: null,
        email: null,
        nifExpiration: null,
        adrExpiration: null,
        ss: null,
        ssPayExpiration: null,
        driverLicenseNumber: null,
        driverLicenseExpiration: null,
        driverLicenseType: null,
        irpf: null,
        picture: null,
        username: null,
        incidenceBalanceGas: 0,
        incidenceBalance: 0,
        incidenceBalanceLimit: null,
        enabled: 1,
        employeeTypeId: null,
        employeeType: {id: null, type: null},
        contractTypeId: null,
        contractType: {id: null, type: null},
        companyId: null,
        contractDate: null,
        contractCancellationDate: null,
        cap: null,
        documents: []
    };
    const validations = {
        name: [{type: validateService.REQUIRED}],
        surname: [{type: validateService.REQUIRED}],
        username: [{type: validateService.REQUIRED}],
        nif: [{type: validateService.REQUIRED}],
        mobile: [{type: validateService.REQUIRED}, {type: validateService.TF_FORMAT}],
        tf: [{type: validateService.TF_FORMAT}],
        email: [{type: validateService.EMAIL_FORMAT}],
        zipCode: [{type: validateService.CP_FORMAT}],
        employeeTypeId: [{type: validateService.REQUIRED}],
        irpf: [{type: validateService.REQUIRED},{type: validateService.NUMBER_FORMAT}],
        companyId: [{type: validateService.REQUIRED}],
        contractTypeId: [{type: validateService.REQUIRED}],
        incidenceBalanceGas: [{type: validateService.REQUIRED}],
        incidenceBalanceLimit: [{type: validateService.REQUIRED},{type: validateService.NUMBER_FORMAT}],
        birthDate: [{type: validateService.DATE_FORMAT}],
        nifExpiration: [{type: validateService.DATE_FORMAT}],
        adrExpiration: [{type: validateService.DATE_FORMAT}],
        driverLicenseExpiration: [{type: validateService.DATE_FORMAT}],
        contractDate: [{type: validateService.DATE_FORMAT}],
        contractCancellationDate: [{type: validateService.DATE_FORMAT}],
        cap: [{type: validateService.DATE_FORMAT}],
    };

    const newAssign = {
        driver: null,
        helper: null
    };

    const validationsAssign = {
        helper: [{type: validateService.REQUIRED}],
    };

    const [assign, setAssing] = useState(newAssign);
    const [open, setOpen] = useState(false);
    const [openHelperAssign, setOpenHelperAssign] = useState(false);
    const [employee, setEmployee] = useState(newEmployee);
    const [companies, setCompanies] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorsAssign, setErrorsAssign] = useState({});


    useEffect(() => {
        async function load() {
            setLoading(true);
            const responseE = await employeeService.getEmployees();
            const responseC = await companyService.getCompanies();
            if (responseE.status === 200 && responseC.status === 200) {
                setData(responseE.data);
                setCompanies(responseC.data.companies);
                setLoading(false);
            } else {}
        }
        load();
    }, []);

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.management.employees'})}
    ];

    const handleClose = () => {
        setOpen(false);
        setOpenHelperAssign(false);
    };
    const handleNew = () => {
        setEmployee(newEmployee);
        setOpen(true);
    };
    const handleEdit = (item) => {
        setEmployee(item);
        setOpen(true);
    };
    const handleChange = (e) => {
        let attribute = e.target.name;

        switch (attribute) {
            case 'employeeType':
                attribute = 'employeeTypeId';
                break;
            case 'contractType':
                attribute = 'contractTypeId';
                break;
        }
        switch (attribute) {
            case 'contractTypeId':
                setEmployee({
                    ...employee,
                    irpf: "0",
                    [attribute]: getValueFromField(e)
                });
                break;
            case 'driverLicenseType':
                setEmployee({
                    ...employee,
                    driverLicenseNumber: getValueFromField(e) === "" ? "" : employee.nif,
                    [attribute]: getValueFromField(e)
                });
                break;

            default:
                setEmployee({
                    ...employee,
                    [attribute]: getValueFromField(e)
                });
        }

    };
    const handleSubmit = (item) => {
        const err = validateService.validate(employee, validations);
        setErrors(err);
        if (!validateService.hasErrors(err)) {
            setEmployee(item);
            if (item.id == null) {
                addEmployee();
            } else {
                modifyEmployee();
            }
        }
    };

    const URL = process.env.REACT_APP_API_SERVER + '/api/v1/employees';
    const listEmployees = async () => {
        setLoading(true);
        apiService.get(URL)
            .then(res => {
                setData(res.data);
                if (employee.id !== null) {
                    refreshEmployeeSelected(res.data, employee.id);
                }
                refreshNotifications();
            })
            .catch(error => props.errorMessage(intl.formatMessage({id: "app.error.code." + error.code})))
            .finally(() => setLoading(false));
    };
    const addEmployee = async () => {
        const obj = buildEmployeeToPersist();
        apiService.post(URL, obj)
            .then(() => {
                setOpen(false);
                listEmployees();
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)});
    };
    const modifyEmployee = async () => {
        const obj = buildEmployeeToPersist();
        apiService.put(URL + '/' + obj.id, obj)
            .then(() => {
                setOpen(false);
                listEmployees();
                props.successMessage(intl.formatMessage({id: "generic.saved"}));
            })
            .catch(err => {props.errorMessage('Error');console.error(err.response)});
    };

    const buildEmployeeToPersist = () => {
        return {
            ...employee,
            employeeType: typeof employee.employeeTypeId === "undefined" ? employee.employeeType.id : employee.employeeTypeId,
            contractType: typeof employee.contractTypeId === "undefined" ? employee.contractType.id : employee.contractTypeId,
            companyId: typeof employee.companyId === "undefined" ? employee.company.id : employee.companyId
        }
    };

    const handleOpenHelpersTable = (item) => {
        setEmployee(item);
        setOpenHelperAssign(true);
    };
    const handleChangeAssignHelperForm = (e) => {
        setAssing({
            driver: employee.id,
            helper: getValueFromField(e)
        });
    };
    const handleAssign = () => {
        const err = validateService.validate(assign, validationsAssign);
        setErrorsAssign(err);
        if (!validateService.hasErrors(err)) {
            addAssign();
        }
    };
    const addAssign = async() => {
        const response = await employeeService.assignHelper(assign.driver, assign.helper);
        if (response.status === 204) {
            //setOpenHelperAssign(false);
            listEmployees();
            props.successMessage(intl.formatMessage({id: "generic.saved"}));
        } else {
            //TODO: mensaje error
            props.errorMessage('Error');
        }
    };
    const handleUnassign = (driverId, helperId) => {
        deleteAssign(driverId, helperId);
    };
    const deleteAssign = async(driverId, helperId) => {
        const response = await employeeService.unassignHelper(driverId, helperId);
        if (response.status === 204) {
            //setOpenHelperAssign(false);
            listEmployees();
            props.successMessage(intl.formatMessage({id: "generic.saved"}));
        } else {
            //TODO: mensaje error
            props.errorMessage('Error');
        }
    };
    const refreshEmployeeSelected = (newData, id) => {
        const result = newData.employees.filter(item => {
            return item.id === id;
        });
        setAssing(newAssign);
        setEmployee(result[0]);
    };


    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"app.employees.name"}), sublabel: null, formatter: null, width: 250 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"app.employees.nif"}), sublabel: null, formatter: null },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"app.employees.mobile"}), sublabel: null, formatter: null },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"app.employees.type"}), sublabel: null, formatter: null },
        { id: 'id5', align: "center", label: intl.formatMessage({id:"app.employees.incidenceBalanceGas"}), formatter: priceFormatter },
        { id: 'id6', align: "center", label: intl.formatMessage({id:"app.employees.helpers"}) },
        { id: 'id7', align: "center", label: intl.formatMessage({id:"generic.assigned"}), formatter: (val) => val !== "" ? <EnabledIcon value={val}/> : null},
        { id: 'id8', align: "center", label: intl.formatMessage({id:"generic.enabled"}), width: 80, formatter: (val) => <EnabledIcon value={val}/> },
        { id: 'id9', align: "center", label: undefined, width: 30, formatter: (val) => val ? <PriorityHighIcon style={{color: 'gray'}}  /> : <></> },
        { id: 'actions', align: "center", width: 120 },
    ];

    const convertRowData = item => {
        return {
            id1: item.name + ' ' + item.surname,
            id2: item.nif,
            id3: item.mobile,
            id4: item.employeeType.type,
            id5: item.incidenceBalanceGas,
            id6: employeeService.isDriver(item) ? item.helpers.length : "",
            id7: employeeService.isHelper(item) ? (item.driver !== null ? 1 : 0) : "",
            id8: item.enabled,
            id9: item.enabled && item.notifications.length > 0,
            actions:
                <>
                    {employeeService.isDriver(item) && (
                        <GroupAddIcon button onClick={() => handleOpenHelpersTable(item)}/>
                    )}
                    <EditIcon button onClick={() => handleEdit(item)}/>
                </>
        }
    };

    const rows = data.employees ? data.employees.map(item => convertRowData(item)) : [];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <RightBox>
                    <Button color="primary" float size="small" onClick={handleNew}><AddIcon color="white"/></Button>
                </RightBox>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headers}
                    data={rows}
                    isLoading={loading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
                <Modal open={open} title={intl.formatMessage({id: "app.employees.employee"})} onClose={handleClose}>
                    <EmployeeForm
                        employee={employee}
                        companies={companies}
                        errors={errors}
                        onClose={handleClose}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                </Modal>
                <Modal open={openHelperAssign} title={employeeService.getFullName(employee)} onClose={handleClose} width={500} height={350}>
                    <Grid container style={{padding : '20px 0 0 0'}}>
                        <Grid item xs={12} style={{padding : '0px 15px 0 15px'}}>
                            <AssignHelperToDriverForm
                                assign={assign}
                                helpers={employeeService.getHelpersFromEmployees(data.employees, true)}
                                errors={errorsAssign}
                                onChange={handleChangeAssignHelperForm}
                                onSubmit={handleAssign}
                            />
                            <br/>
                        </Grid>
                        <Grid item xs={12} style={{padding : '0 15px'}}>
                            <HelpersTable
                                driver={employee}
                                onDelete={handleUnassign}
                            />
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
        </Grid>
    )
};

export default withMessageBox(EmployeesList);
