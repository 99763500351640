import * as apiService from '../../apiService';


const URL = process.env.REACT_APP_API_SERVER + '/api/v1';

export const list = async (filter) => {
    return await apiService.get(`${URL}/delivery-notes`, filter);
}

export const update = async (data) => {
    return await apiService.patch(`${URL}/delivery-notes/${data.id}`, data);
}

export const listInvoices = async (filter) => {
    return await apiService.get(`${URL}/delivery-notes/invoices`, filter);
}

export const createInvoices = async (data) => {
    return await apiService.post(`${URL}/delivery-notes/invoices`, data);
}

export const downloadInvoice = (data) => {
    const fileName = "Liquidación PV  " + data.number;
    apiService.downloadFile(`${URL}/delivery-notes/invoices/${data.id}/download`, {}, fileName);
};
