import React from 'react';
import { useIntl } from 'react-intl';

import Icon from '@material-ui/icons/Clear';

import Button from "./index";


const ClearButton = (props) => {
    const intl = useIntl();

    return (
        <Button
            startIcon={<Icon />}
            {...props}
        >
            {intl.formatMessage({id: 'generic.clear'})}
        </Button>
    )
}

export default ClearButton;