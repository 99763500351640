import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';
import Switch from '../../../../components/form/Switch';



export const PurchaseOrderForm = (props) => {
    const {po, factories, warehouses, errors} = props;

    const intl = useIntl();

    const [warehouseItems, setWarehouseItems] = useState([]);

    useEffect(() => {
        setWarehouseItems(
            warehouses.map(warehouse => {
                return {value: warehouse.id, label: warehouse.name};
            })
        );
    }, [warehouses]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <Input
                        name="number"
                        label={intl.formatMessage({id: 'generic.domain.number'})}
                        value={po.number}
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        type="date"
                        name="date"
                        label={intl.formatMessage({id: 'generic.date'})}
                        value={po.date}
                        disabled
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="reference"
                        label={intl.formatMessage({id: 'generic.domain.reference'})}
                        value={po.reference}
                        error={errors.reference}
                        required
                        onChange={props.onChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        name="factoryId"
                        label={intl.formatMessage({id: 'generic.domain.factory'})}
                        items={factories.getSelectItems()}
                        value={po.factoryId}
                        error={errors.factoryId}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <Switch
                        name="canceled"
                        label={intl.formatMessage({id: 'generic.canceled.fem'})}
                        checked={po.canceled === true || po.canceled === 1}
                        onChange={props.onChange}
                        disabled
                    />
                </Grid>
                <Grid item xs={2}>
                    <Input
                        name="agencyId"
                        label={intl.formatMessage({id: 'generic.domain.agency'})}
                        value={po.agencyId}
                        error={errors.agencyId}
                        onChange={props.onChange}
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        name="warehouseId"
                        label={intl.formatMessage({id: 'generic.domain.warehouse'})}
                        items={warehouseItems}
                        value={po.warehouseId}
                        error={errors.warehouseId}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        name="obs"
                        label={intl.formatMessage({id: 'generic.domain.obs'})}
                        value={po.obs}
                        onChange={props.onChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    )
};

export default PurchaseOrderForm;
