import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../services/domain/warehouse/authorizationService';
import * as productSituationService from '../../../../services/domain/warehouse/productSituationService';

import Grid from '@material-ui/core/Grid';

import AddIcon from '../../../../components/icons/Add';
import Button from '../../../../components/form/Button/index';
import DeleteIcon from '../../../../components/icons/Delete';
import EditIcon from '../../../../components/icons/Edit';
import EnabledIcon from "../../../../components/icons/Enabled";
import PrintIcon from '../../../../components/icons/Print';
import Modal from "../../../../components/Modal/Modal";
import Table from '../../../../components/table';
import { RightBox } from "../../../../components/Box/index";

import DeliveryNotePos from './DeliveryNotePos';



export const DeliveryNotesPosTab = (props) => {
    const {authorization, pos, products, errors, deliveryNotePos, deliveryNotePosProduct, errorsDeliveryNotePos, errorsDeliveryNotePosProduct, refreshGrids} = props;

    const intl = useIntl();

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setData(authorization.deliveryNotesPos.map(deliveryNote => convertDeliveryNoteRowData(deliveryNote)));
    }, [authorization, refreshGrids]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        props.handleNewDeliveryNotePos();
        setOpen(true);
    };

    const handleEdit = (item) => {
        props.handleEditDeliveryNotePos(item);
        setOpen(true);
    }

    const handleSubmit = () => {
        setOpen(false);
        props.handleSubmitDeliveryNotePos();
    };

    const productsFormatter = (dnProducts) => {
        return (
            <ul style={{paddingLeft: '15px'}}>
                {
                    dnProducts.map((product, index) => {
                        //TODO: cambiar productSituationId per productSituation.type
                        const productName = product.product ? product.product.name : products.getKeyValueByObjectId(product.productId, 'name');
                        const productSit = product.situation ? product.situation.type : productSituationService.getProductSituationType(product.productSituationId);
                        return (
                            <li key={index}>
                                {product.containers}/{product.charges}&nbsp;
                                {productName}&nbsp;
                                {productSit}
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    const headers = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.pos"}), sublabel: null, formatter: null },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.deliveryNote"}), sublabel: null, formatter: null, width: 120 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.deliveryDate"}), sublabel: null, formatter: null, width: 120 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.liquidatedToEmployee"}), sublabel: null, formatter: (value) => <EnabledIcon value={value} />, width: 100 },
        { id: 'id5', align: "left",   label: intl.formatMessage({id:"generic.domain.products"}), sublabel: null, formatter: productsFormatter, width: 200 },
        { id: 'actions', align: "center", width: 120 },
    ];

    const convertDeliveryNoteRowData = item => {
        const posId = item.pos ? item.pos.id : item.posId;
        const posItem = pos.getObjectById(posId);
        const alias = posItem.alias ? " (" + posItem.alias + ")" : "";
        return {
            id1: posItem.name + alias,
            id2: item.number,
            id3: item.date,
            id4: item.paid,
            id5: item.products,
            actions:
                <div>
                    {
                    !authorizationService.isLiquidated(authorization) ?
                        <div>
                            <EditIcon button onClick={() => handleEdit(item)}/>
                            <DeleteIcon button onClick={() => props.handleDeleteDeliveryNotePos(item)}/>
                        </div>
                        :
                        <div></div>
                    }
                    {
                        item.id && (<PrintIcon button onClick={() => props.handlePrintDeliveryNotePos(item)} />)
                    }
                </div>
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <RightBox>
                    <Button
                        float
                        color="primary"
                        size="small"
                        onClick={handleOpen}
                        disabled={authorizationService.isLiquidated(authorization)}
                    >
                        <AddIcon color="white"/>
                    </Button>
                </RightBox>
            </Grid>
            <Grid item xs={12} style={{minHeight: '200px'}}>
                <Table
                    headers={headers}
                    data={data}
                />
            </Grid>
            <Grid item xs={12}>
                <Modal open={open} title={intl.formatMessage({id: 'generic.domain.deliveryNote'})} onClose={handleClose} width={450} height={550}>
                  <DeliveryNotePos
                      deliveryNotePos={deliveryNotePos}
                      deliveryNotePosProduct={deliveryNotePosProduct}
                      errorsDeliveryNotePos={errorsDeliveryNotePos}
                      errorsDeliveryNotePosProduct={errorsDeliveryNotePosProduct}
                      pos={pos}
                      products={products}
                      refreshGrids={refreshGrids}

                      handleChangeDeliveryNotePos={props.handleChangeDeliveryNotePos}
                      handleSubmitDeliveryNotePos={props.handleSubmitDeliveryNotePos}

                      handleChangeDeliveryNotePosProduct={props.handleChangeDeliveryNotePosProduct}
                      handleDeleteDeliveryNotePosProduct={props.handleDeleteDeliveryNotePosProduct}
                      handleSubmitDeliveryNotePosProduct={props.handleSubmitDeliveryNotePosProduct}

                      onClose={handleClose}
                      onSubmit={handleSubmit}
                  />
                </Modal>
            </Grid>
        </Grid>
    )
};

export default DeliveryNotesPosTab;