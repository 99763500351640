import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {isNull} from "../../../../helpers/validator";

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Input from '../../../../components/form/Input/Input';
import Select from '../../../../components/form/Select';



export const StreetForm = (props) => {
    const {street, streetTypes = [], towns = [], provinces = [], errors, loading} = props;

    const intl = useIntl();

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Input
                        name="id"
                        label="Id"
                        value={street.id}
                        disabled
                    />
                </Grid>

                <Grid item xs={3}>
                    <Select
                        name="streetTypeId"
                        label={intl.formatMessage({id: 'generic.domain.streetType'})}
                        items={streetTypes.getSelectItems()}
                        value={street.streetTypeId}
                        error={errors.streetTypeId}
                        loading={loading}
                        onChange={props.onChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={9}>
                    <Input
                        name="name"
                        label={intl.formatMessage({id: 'generic.domain.name'})}
                        value={street.name}
                        error={errors.name}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box width={170}>
                            <Select
                                name="provinceId"
                                label={intl.formatMessage({id: 'generic.domain.province'})}
                                items={provinces.getSelectItems()}
                                value={street.provinceId}
                                error={errors.provinceId}
                                loading={loading}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Box>
                        <Box width={120} style={{padding: '0 10px'}}>
                            <Input
                                name="zipCode"
                                label={intl.formatMessage({id: 'generic.domain.cp'})}
                                value={isNull(street.town.zipCode) ? "[" + intl.formatMessage({id: 'generic.several'}) + "]" : street.town.zipCode}
                                disabled
                            />
                        </Box>
                        <Box flexGrow={1}>
                            <Select
                                name="townId"
                                label={intl.formatMessage({id: 'generic.domain.town'})}
                                items={towns.getSelectItems()}
                                value={street.townId}
                                error={errors.townId}
                                loading={loading}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}

export default StreetForm;