import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';

import * as authorizationService from '../../../../../services/domain/warehouse/authorizationService';
import * as formatter from '../../../../../helpers/formatter';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import Filter from "../../../../../components/Filter/Filter";
import Table from '../../../../../components/table';
import {getValueFromField} from "../../../../../helpers/form";
import PrePaymentsFilterForm from "./PrePaymentsFilterForm";



export const PrePaymentList = () => {
    const intl = useIntl();

    const sections = [
        {label: intl.formatMessage({id: 'app.menu.warehouse'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements'})},
        {label: intl.formatMessage({id: 'app.menu.warehouse.movements.prePayments'})}
    ];

    const headerD = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.vehicle"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.liquidation"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id4', align: "left",   label: intl.formatMessage({id:"generic.domain.amount-i"}), sublabel: null, formatter: formatter.priceFormatter, width: 50 },
    ];
    const headerSP = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.type"}), sublabel: null, formatter: null, width: 100 },
        { id: 'id2', align: "left",   label: intl.formatMessage({id:"generic.domain.amount-i"}), sublabel: null, formatter: formatter.priceFormatter, width: 50 },
    ];
    const preHeaderSV = [
        { id: 'id1', align: "left",   label: intl.formatMessage({id:"generic.domain.vehicle"}), sublabel: null, formatter: null, width: 50 },
        { id: 'id3', align: "left",   label: intl.formatMessage({id:"generic.domain.pre-payments"}), sublabel: null, formatter: null, width: 50 },
    ];

    const newFilter = {
        startDate: formatter.dateApiFormatter(),
        endDate: formatter.dateApiFormatter(),
        liquidated: true
    }

    const [dataD, setDataD] = useState([]);
    const [dataSP, setDataSP] = useState([]);
    const [dataSV, setDataSV] = useState([]);
    const [headerSV, setHeaderSV] = useState(preHeaderSV);
    const [filter, setFilter] = useState(newFilter);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
    }, [headerSV]);

    async function loadAuthorizations() {
        setIsLoading(true);
        const response = await authorizationService.getAuthorizations(filter);
        if (response.status === 200) {
            const prepayments = authorizationService.getPrePayments(response.data.authorizations);
            setDataD(prepayments.detail.map(item => convertRowDataD(item)));
            /** byPrepayments */
            const dataSummary = [];
            for (const key in prepayments.summary.byPrepayment) {
                dataSummary.push({id1: key, id2: prepayments.summary.byPrepayment[key].amount});
            }
            setDataSP(dataSummary);
            /** byVehicle */
            const header = generateHeaderSV(prepayments.summary.byPrepayment);
            const data = [];
            for (let vehicle in prepayments.summary.byVehicle) {
                data.push(convertRowDataSV(vehicle, prepayments.summary.byVehicle[vehicle], header));
            }
            setHeaderSV(header);
            setDataSV(data);

        } else {
            console.error(response);
        }
        setIsLoading(false);
    }

    const convertRowDataD = item => {
        return {
            id1: item.vehicle,
            id2: item.liquidation,
            id3: item.bonusType.type,
            id4: item.amount
        }
    }

    const generateHeaderSV = prePaymentList => {
        let i = 2;
        const header = [{ id: 'id1', align: "left", label: intl.formatMessage({id:"generic.domain.vehicle"}), width: 50 }];
        for (let prePayment in prePaymentList) {
            header.push({ id: 'id' + i, align: "left", label: prePayment, sublabel: null, formatter: formatter.priceFormatter, width: 50 });
            i++;
        }
        return header;
    }
    const convertRowDataSV = (vehicle, prePaymentList, header) => {
        const row = {id1: vehicle};
        for (let prePayment in prePaymentList) {
            row[header.getObjectByKeyValue('label', prePayment).id] = prePaymentList[prePayment].amount;
        }
        return row;
    }

    const handleFilterClear = () => {
        setFilter(newFilter);
    }
    const handleFilterFormChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: getValueFromField(e)
        });
    }
    const handleSearch = () => {
        loadAuthorizations();
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs sections={sections}/>
            </Grid>
            <Grid item xs={12}>
                <br/><br/>
                <Filter onSearch={handleSearch} onClear={handleFilterClear} isLoading={isLoading}>
                    <PrePaymentsFilterForm
                        filter={filter}
                        onChange={handleFilterFormChange}
                    />
                </Filter>
                <br/><br/>
            </Grid>
            <Grid item xs={12}>
                <Table
                    headers={headerSV}
                    data={dataSV}
                    isLoading={isLoading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <Table
                    headers={headerSP}
                    data={dataSP}
                    isLoading={isLoading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
            <Grid item md={2} xs={12}></Grid>
            <Grid item md={6} xs={12}>
                <Table
                    headers={headerD}
                    data={dataD}
                    isLoading={isLoading}
                    totalRows
                    ordered
                    stripedRows
                    isExportable
                />
            </Grid>
        </Grid>
    )

}

export default PrePaymentList;
