import React from 'react';

import MUIDashboardIcon from '@material-ui/icons/Dashboard';


const DashboardIcon = () => {

    return (
        <MUIDashboardIcon style={{color: "#FFF"}} />
    )
}

export default DashboardIcon;