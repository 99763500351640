import React from 'react';
import { useIntl } from 'react-intl';

import Icon from '@material-ui/icons/SystemUpdateAlt';

import Button from "./index";


const ExportButton = (props) => {
    const intl = useIntl();
    const label = props.label ? props.label : intl.formatMessage({id: 'generic.export'});

    return (
        <Button
            startIcon={<Icon />}
            {...props}
        >
            {label}
        </Button>
    )
}

export default ExportButton;