import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import Button from '../../../components/form/Button/index';
import Input from '../../../components/form/Input/Input';
import Select from '../../../components/form/Select/index';
import SearchButton from "../../../components/form/Button/search";



export const ReportForm = (props) => {
    const {report, warehouses, errors, isLoading, isCalculating = false, isSearching = false} = props;

    const intl = useIntl();

    const [warehouseItems, setWarehouseItems] = useState([]);

    useEffect(() => {
        setWarehouseItems(
            warehouses.map(warehouse => {
                return {value: warehouse.id, label: warehouse.name};
            })
        );
    }, [warehouses]);

    return (
        <form>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Select
                        name="warehouseId"
                        label={intl.formatMessage({id: 'generic.domain.warehouse'})}
                        items={warehouseItems}
                        value={report.warehouseId}
                        error={errors.warehouseId}
                        loading={isLoading}
                        onChange={props.onChange}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={9}>
                    <Input
                        type="date"
                        name="date"
                        label={intl.formatMessage({id: 'generic.date'})}
                        value={report.date}
                        error={errors.date}
                        onChange={props.onChange}
                        required
                    />
                    <SearchButton
                        style={{marginLeft: '10px', marginTop: '8px'}}
                        onClick={(e) => {e.preventDefault(); props.onSubmit()}}
                        disabled={isLoading}
                        isLoading={isSearching}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        style={{marginLeft: '10px', marginTop: '8px', width: '130px'}}
                        onClick={props.onCalculate}
                        disabled={isLoading}
                        isLoading={isCalculating}
                    >
                        {intl.formatMessage({id: "generic.calculate"})}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
};

export default ReportForm;