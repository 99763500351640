import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import MUITooltip from '@material-ui/core/Tooltip';

import { TEXT_COLOR } from '../../assets/themes/theme';


const StyledTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#FFF',
        color: TEXT_COLOR,
        fontSize: 11,
        boxShadow: theme.shadows[4],
        paddingBottom: 5,
    },
    arrow: {
        color: '#FFF',
    }
}))(MUITooltip);

export class Tooltip extends React.Component {
    render() {
        const { children, ...otherProps } = this.props;

        return (
            <StyledTooltip
                {...otherProps}
            >
                {children}
            </StyledTooltip>
        )
    }
}

export default Tooltip;
