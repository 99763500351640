import { withStyles } from '@material-ui/core/styles';

import EnhancedTable from "./EnhancedTable";

export const styles = theme => ({
    root: {
    },
    table: {
        //minWidth: 1020,
    },
    tableHeaderCell: {
        //verticalAlign: 'bottom'
    },
    tableCell: {
        border: 0,
        height: '40px'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    headerLabel: {
        height: 15,
        fontWeight: 'bold',
        lineHeight: '15px'
    },
    headerSublabel: {
        height: 15,
        fontSize: '11px',
        color: '#7b7b7f'
    },
    tableBorderedRow: {
        borderBottom: 'solid 1px #E0E0E0'
    },
    tableStripedRow: {
        //backgroundColor: '#DCDCDC',
        backgroundColor: '#FAD2AF',
    },
    tableSummaryCell: {
        fontSize: '14px',
        fontWeight: 'bold'
    }
});

export default withStyles(styles)(EnhancedTable);